import React from 'react'
import { Box, Flex, IconButton, Switch, Text, Textarea } from '@chakra-ui/react'
import { PrimaryInput } from '../../../atoms/PrimaryInput'
import { PrimaryNumberInput } from '../../../atoms/PrimaryNumberInput'
import { PrimarySelectBox } from '../../../atoms/PrimarySelectBox'
import { AiOutlineDelete, AiOutlinePlus } from 'react-icons/ai'
import { StringSelectBox } from '../../../atoms/StringSelectBox'

export const FormProtectionInspection = (props) => {
  const {
    printReport,
    setPrintReport,
    isLoadingAction,
    masterList,
    timeInfo,
    defectiveCountDetailInfo,
  } = props

  const totalTime = () => {
    return printReport.protection_inspection.time.reduce(
      (sum, time) =>
        sum +
        (time.finish_at && time.start_at
          ? (new Date(time.finish_at).getTime() -
              new Date(time.start_at).getTime()) /
            1000
          : 0),
      0
    )
  }

  return (
    <Flex textAlign="center" align="center" border="1px" mt={6} w="1450px">
      <Box>
        <Flex textAlign="center" align="center">
          <Box>
            <Text w="100px" minWidth="100px" h="50px" lineHeight="50px">
              6
            </Text>
            <Text w="100px" minWidth="100px" h="50px" lineHeight="70px">
              保護シート
            </Text>
            <Text w="100px" minWidth="100px" h="50px">
              貼り
            </Text>
          </Box>
        </Flex>
      </Box>
      <Box w="1050px" minWidth="1050px" borderLeft="1px">
        <Flex
          textAlign="center"
          align="center"
          borderRight="1px"
          borderBottom="1px"
        >
          <Text flexGrow={1} h="50px" lineHeight="50px" borderRight="1px">
            作業日
          </Text>
          <Text
            w="150px"
            minWidth="150px"
            h="50px"
            lineHeight="50px"
            borderRight="1px"
          >
            作業
          </Text>
          <Text
            w="150px"
            minWidth="150px"
            h="50px"
            lineHeight="50px"
            borderRight="1px"
          >
            作業者
          </Text>
          <Text
            w="100px"
            minWidth="100px"
            h="50px"
            lineHeight="50px"
            borderRight="1px"
          >
            追加/削除
          </Text>
          <Text w="150px" minWidth="150px" h="50px" lineHeight="50px">
            TOTAL時間
          </Text>
        </Flex>
        {printReport.protection_inspection.time.map((time, index) => {
          return (
            <Flex
              key={index}
              textAlign="center"
              align="center"
              borderRight="1px"
              borderBottom="1px"
            >
              <PrimaryInput
                type="datetime-local"
                min="2000-01-01 00:00"
                max="2050-01-01 23:59"
                value={time.start_at}
                onChange={(e) => {
                  setPrintReport({
                    ...printReport,
                    protection_inspection: {
                      ...printReport.protection_inspection,
                      time: printReport.protection_inspection.time.map(
                        (info, number) =>
                          index == number
                            ? {
                                ...info,
                                start_at: e.target.value,
                              }
                            : info
                      ),
                    },
                  })
                }}
                flexGrow={1}
                disabled={isLoadingAction}
              />
              <PrimaryInput
                type="datetime-local"
                min="2000-01-01 00:00"
                max="2050-01-01 23:59"
                value={time.finish_at}
                onChange={(e) => {
                  setPrintReport({
                    ...printReport,
                    protection_inspection: {
                      ...printReport.protection_inspection,
                      time: printReport.protection_inspection.time.map(
                        (info, number) =>
                          index == number
                            ? {
                                ...info,
                                finish_at: e.target.value,
                              }
                            : info
                      ),
                    },
                  })
                }}
                flexGrow={1}
                disabled={isLoadingAction}
              />
              <Flex
                w="150px"
                minWidth="150px"
                borderLeft="1px"
                borderRight="1px"
              >
                <Box pt="5px">
                  <PrimaryNumberInput
                    value={time.count}
                    minValue={0}
                    maxValue={100}
                    onChange={(num) =>
                      setPrintReport({
                        ...printReport,
                        protection_inspection: {
                          ...printReport.protection_inspection,
                          time: printReport.protection_inspection.time.map(
                            (info, number) =>
                              index == number
                                ? {
                                    ...info,
                                    count: num,
                                  }
                                : info
                          ),
                        },
                      })
                    }
                    width="100px"
                    disabled={isLoadingAction}
                  />
                </Box>
                <Text w="50px" minWidth="50px" h="50px" lineHeight="50px">
                  工程
                </Text>
              </Flex>
              <PrimarySelectBox
                options={masterList.users}
                defaultOption={time.worker}
                optionElement="name"
                onChangeSelect={(num) =>
                  setPrintReport({
                    ...printReport,
                    protection_inspection: {
                      ...printReport.protection_inspection,
                      time: printReport.protection_inspection.time.map(
                        (info, number) =>
                          index == number
                            ? {
                                ...info,
                                worker: masterList.users[num]['name'],
                              }
                            : info
                      ),
                    },
                  })
                }
                width="150px"
                disabled={isLoadingAction}
              />
              <Flex
                w="100px"
                minWidth="100px"
                h="50px"
                justify="center"
                align="center"
                borderLeft="1px"
              >
                {printReport.protection_inspection.time.length == index + 1 ? (
                  <IconButton
                    aria-label="追加"
                    disabled={isLoadingAction}
                    onClick={() =>
                      setPrintReport({
                        ...printReport,
                        protection_inspection: {
                          ...printReport.protection_inspection,
                          time: [
                            ...printReport.protection_inspection.time,
                            timeInfo,
                          ],
                        },
                      })
                    }
                    icon={<AiOutlinePlus />}
                  />
                ) : (
                  <IconButton
                    aria-label="削除"
                    disabled={isLoadingAction}
                    onClick={() => {
                      console.log(
                        printReport.protection_inspection.time.filter(
                          (num) => index !== num
                        )
                      )
                      setPrintReport({
                        ...printReport,
                        protection_inspection: {
                          ...printReport.protection_inspection,
                          time: printReport.protection_inspection.time.filter(
                            (num) => index !== num
                          ),
                        },
                      })
                    }}
                    icon={<AiOutlineDelete />}
                  />
                )}
              </Flex>
              {index == 0 ? (
                <Text
                  w="151px"
                  minWidth="151px"
                  h="50px"
                  lineHeight="50px"
                  borderLeft="1px"
                >
                  {Math.floor(totalTime() / 3600)}
                  時間
                  {Math.floor((totalTime() % 3600) / 60)}分
                </Text>
              ) : (
                <Box w="151px" minWidth="151px" h="50px" borderLeft="1px" />
              )}
            </Flex>
          )
        })}
        <Flex textAlign="center" borderRight="1px">
          <Box flexGrow={1}>
            <Flex
              textAlign="center"
              align="center"
              borderRight="1px"
              borderBottom="1px"
              flexGrow={1}
            >
              <Text
                w="250px"
                minWidth="250px"
                h="50px"
                lineHeight="50px"
                borderRight="1px"
              >
                作業数
              </Text>
              <Text
                w="249px"
                minWidth="249px"
                h="50px"
                lineHeight="50px"
                borderRight="1px"
              >
                良品数
              </Text>
              <Text
                w="249px"
                minWidth="249px"
                h="50px"
                lineHeight="50px"
                borderRight="1px"
              >
                不良数
              </Text>
              <Text w="149px" minWidth="149px" h="50px" lineHeight="50px">
                不良率
              </Text>
            </Flex>
            <Flex
              textAlign="center"
              align="center"
              borderRight="1px"
              flexGrow={1}
            >
              <Box pt="5px" pb="4px" flexGrow={1} borderRight="1px">
                <PrimaryNumberInput
                  value={printReport.protection_inspection.count.input_count}
                  minValue={0}
                  maxValue={100000}
                  onChange={(num) =>
                    setPrintReport({
                      ...printReport,
                      protection_inspection: {
                        ...printReport.protection_inspection,
                        count: {
                          ...printReport.protection_inspection.count,
                          input_count: num,
                        },
                      },
                    })
                  }
                  width="100%"
                  disabled={isLoadingAction}
                />
              </Box>
              <Box pt="5px" pb="4px" flexGrow={1} borderRight="1px">
                <PrimaryNumberInput
                  value={
                    printReport.protection_inspection.count.non_defective_count
                  }
                  minValue={0}
                  maxValue={100000}
                  onChange={(num) =>
                    setPrintReport({
                      ...printReport,
                      protection_inspection: {
                        ...printReport.protection_inspection,
                        count: {
                          ...printReport.protection_inspection.count,
                          non_defective_count: num,
                        },
                      },
                    })
                  }
                  width="100%"
                  disabled={isLoadingAction}
                />
              </Box>
              <Box pt="5px" pb="4px" flexGrow={1} borderRight="1px">
                <PrimaryNumberInput
                  value={
                    printReport.protection_inspection.count.defective_count
                  }
                  minValue={0}
                  maxValue={100000}
                  onChange={(num) =>
                    setPrintReport({
                      ...printReport,
                      protection_inspection: {
                        ...printReport.protection_inspection,
                        count: {
                          ...printReport.protection_inspection.count,
                          defective_count: num,
                        },
                      },
                    })
                  }
                  width="100%"
                  disabled={isLoadingAction}
                />
              </Box>
              <Flex
                textAlign="center"
                align="center"
                justify="space-around"
                w="149px"
                minWidth="149px"
              >
                <Text w="100px" minWidth="100px" h="50px" lineHeight="50px">
                  {printReport.protection_inspection.count.input_count > 0
                    ? Math.round(
                        (printReport.protection_inspection.count
                          .defective_count /
                          printReport.protection_inspection.count.input_count) *
                          100
                      )
                    : 0}
                </Text>
                <Text w="50px" minWidth="50px" h="50px" lineHeight="50px">
                  %
                </Text>
              </Flex>
            </Flex>
            {/* 不良内容・不良数 */}
            <Flex
              textAlign="center"
              align="center"
              borderTop="1px"
              flexGrow={1}
            >
              <Box>
                <Text w="180px" minWidth="180px" h="50px" lineHeight="50px">
                  不良内容
                </Text>
                <Text w="180px" minWidth="180px" h="50px" lineHeight="50px">
                  不良数
                </Text>
              </Box>
              <Flex
                textAlign="center"
                align="center"
                borderRight="1px"
                borderLeft="1px"
                w="718px"
                flexWrap="wrap"
              >
                {printReport.protection_inspection.count.defective_count_detail.map(
                  (elm, index) => {
                    return (
                      <Box
                        borderRight={
                          (index + 1) % 4 !== 0 ? '1px' : index == 0 && '1px'
                        }
                        borderTop={index >= 4 && '1px'}
                        key={index}
                      >
                        <Box
                          pt="5px"
                          pb="4px"
                          width="178px"
                          minWidth="178px"
                          borderBottom="1px"
                        >
                          <StringSelectBox
                            width="100%"
                            options={masterList.printing_defective.drying}
                            defaultOption={elm.defective_detail}
                            onChangeSelect={(value) => {
                              setPrintReport({
                                ...printReport,
                                protection_inspection: {
                                  ...printReport.protection_inspection,
                                  count: {
                                    ...printReport.protection_inspection.count,
                                    defective_count_detail:
                                      printReport.protection_inspection.count.defective_count_detail.map(
                                        (info, number) =>
                                          index == number
                                            ? {
                                                ...info,
                                                defective_detail: value,
                                              }
                                            : info
                                      ),
                                  },
                                },
                              })
                            }}
                            flexGrow={1}
                            disabled={isLoadingAction}
                          />
                        </Box>
                        <Box pt="5px" pb="4px" width="178px" minWidth="178px">
                          <PrimaryNumberInput
                            value={elm.count}
                            minValue={0}
                            maxValue={100000}
                            onChange={(num) =>
                              setPrintReport({
                                ...printReport,
                                protection_inspection: {
                                  ...printReport.protection_inspection,
                                  count: {
                                    ...printReport.protection_inspection.count,
                                    defective_count_detail:
                                      printReport.protection_inspection.count.defective_count_detail.map(
                                        (info, number) =>
                                          index == number
                                            ? {
                                                ...info,
                                                count: num,
                                              }
                                            : info
                                      ),
                                  },
                                },
                              })
                            }
                            width="100%"
                            disabled={isLoadingAction}
                          />
                        </Box>
                      </Box>
                    )
                  }
                )}
              </Flex>
            </Flex>
            <Flex
              textAlign="center"
              align="center"
              borderTop="1px"
              flexGrow={1}
            >
              <Text w="180px" minWidth="180px" h="50px" lineHeight="50px">
                チェック項目
              </Text>
              <Text
                w="179px"
                minWidth="179px"
                h="50px"
                lineHeight="50px"
                borderLeft="1px"
              >
                仕様確認(シルバー)
              </Text>
              <Box flexGrow={1} borderLeft="1px">
                <Switch
                  h="50px"
                  lineHeight="50px"
                  value={
                    printReport.protection_inspection.is_specification_confirmed
                  }
                  onChange={(e) =>
                    setPrintReport({
                      ...printReport,
                      protection_inspection: {
                        ...printReport.protection_inspection,
                        is_specification_confirmed: e.target.checked,
                      },
                    })
                  }
                  isChecked={
                    printReport.protection_inspection.is_specification_confirmed
                  }
                  isDisabled={isLoadingAction}
                />
              </Box>
              <Text
                w="179px"
                minWidth="179px"
                h="50px"
                lineHeight="50px"
                borderLeft="1px"
              >
                版確認
              </Text>
              <Box flexGrow={1} borderLeft="1px" borderRight="1px">
                <Switch
                  h="50px"
                  lineHeight="50px"
                  value={printReport.protection_inspection.is_plate_confirmed}
                  onChange={(e) =>
                    setPrintReport({
                      ...printReport,
                      protection_inspection: {
                        ...printReport.protection_inspection,
                        is_plate_confirmed: e.target.checked,
                      },
                    })
                  }
                  isChecked={
                    printReport.protection_inspection.is_plate_confirmed
                  }
                  isDisabled={isLoadingAction}
                />
              </Box>
            </Flex>
          </Box>
          <Box>
            <Text
              w="150px"
              minWidth="150px"
              h="51px"
              lineHeight="51px"
              borderBottom="1px"
            >
              記入者
            </Text>
            <Box
              pt="5px"
              pb="5px"
              flexGrow={1}
              borderRight="1px"
              borderBottom="1px"
            >
              <PrimarySelectBox
                options={masterList.users}
                defaultOption={
                  printReport.protection_inspection.count.registered_by
                }
                optionElement="name"
                onChangeSelect={(num) =>
                  setPrintReport({
                    ...printReport,
                    protection_inspection: {
                      ...printReport.protection_inspection,
                      count: {
                        ...printReport.protection_inspection.count,
                        registered_by: masterList.users[num]['name'],
                      },
                    },
                  })
                }
                width="150px"
                disabled={isLoadingAction}
              />
            </Box>
            <Box>
              <Text
                w="150px"
                minWidth="150px"
                h="50px"
                lineHeight="50px"
                mb={1}
              >
                不良を追加
              </Text>
              <IconButton
                aria-label="追加"
                disabled={isLoadingAction}
                onClick={() =>
                  setPrintReport({
                    ...printReport,
                    protection_inspection: {
                      ...printReport.protection_inspection,
                      count: {
                        ...printReport.protection_inspection.count,
                        defective_count_detail: [
                          ...printReport.protection_inspection.count
                            .defective_count_detail,
                          defectiveCountDetailInfo,
                        ],
                      },
                    },
                  })
                }
                icon={<AiOutlinePlus />}
              />
            </Box>
          </Box>
        </Flex>
      </Box>
      <Box h="100%">
        <Text w="100%" h="50px" lineHeight="50px">
          備考
        </Text>
        <Textarea
          value={printReport.protection_inspection.note}
          onChange={(e) =>
            setPrintReport({
              ...printReport,
              protection_inspection: {
                ...printReport.protection_inspection,
                note: e.target.value,
              },
            })
          }
          size="sm"
          bg="white"
          h="250px"
          w="100%"
          minWidth="298px"
          _focus={{ boxShadow: 'none' }}
          border="none"
          disabled={isLoadingAction}
        />
      </Box>
    </Flex>
  )
}
