import React from 'react'
import { Header2 } from '../organisms/Header2'
import { useCurrentUser2 } from '../../hooks/users/useCurrentUser2'
import { useMessage } from '../../hooks/useMessage'
import { Navigate } from 'react-router-dom'
import { Box } from '@chakra-ui/react'

export const HeaderLayout = (props) => {
  const { children } = props
  const user = useCurrentUser2()
  const { message } = useMessage()
  !user &&
    message({
      title: 'セッションの有効期限が切れました。',
      status: 'warning',
    })

  return (
    <>
      {user ? (
        <Box ml={10}>
          <Header2 />
          {children}
        </Box>
      ) : (
        <Navigate to="/" />
      )}
    </>
  )
}
