import { Route, Navigate, Routes } from 'react-router-dom'
import { useCurrentUser2 } from '../hooks/users/useCurrentUser2'
import { useMessage } from '../hooks/useMessage'

export const AuthRoute = ({ path, children }) => {
  const user = useCurrentUser2()
  const { message } = useMessage()
  !user && message({
    title: 'セッションの有効期限が切れました。',
    status: 'warning',
  })
  return (
    <Routes>
      <Route
        path={path}
        element={user ? children : <Navigate to="/" />}
      />
    </Routes>
  )
}
