import React from 'react'
import {
  IconButton,
  Select,
  Td,
} from '@chakra-ui/react'
import { PrimaryInput } from '../../atoms/PrimaryInput'
import { PrimaryNumberInput } from '../../atoms/PrimaryNumberInput'
import { AiOutlineDelete, AiOutlinePlus } from 'react-icons/ai'
import { StringSelectBox } from '../../atoms/StringSelectBox'

export const TableBodyMachiningPrototype3 = (props) => {
  const {
    type,
    machines,
    processingName,
    machineName,
    takeCount,
    manHours,
    unit,
    wageRate,
    minWageRate,
    totalSellingPrice,
    totalBuyingPrice,
    onChangeProcessingName,
    onChangeMachineName,
    onChangeTakeCount,
    onChangeManHours,
    onChangeMachiningUnit,
    onChangeWageRate,
    onChangeMinWageRate,
    onClickDeleteMachining,
    onClickAddProduct,
    isDisabled,
    disabledAddButton,
  } = props

  const units = [
    { value: '', label: '' },
    { value: '時', label: '時' },
    { value: '分', label: '分' },
    { value: '秒', label: '秒' },
  ]

  return (
    <>
      <Td>
        <PrimaryInput
          placeholder=""
          disabled={isDisabled}
          value={processingName}
          onChange={(e) => onChangeProcessingName(e)}
          htmlSize={14}
        />
      </Td>
      <Td>
        <StringSelectBox
          options={machines}
          defaultOption={machineName}
          onChangeSelect={onChangeMachineName}
          width="120px"
          disabled={isDisabled}
        />
      </Td>
      <Td>
        <PrimaryNumberInput
          value={takeCount}
          minValue={0}
          maxValue={100000}
          onChange={(num) => onChangeTakeCount(num)}
          width="120px"
          disabled={isDisabled}
        />
      </Td>
      <Td>
        <PrimaryNumberInput
          value={manHours}
          minValue={0}
          maxValue={100000}
          onChange={(num) => onChangeManHours(num)}
          width="120px"
          disabled={isDisabled}
        />
      </Td>
      <Td>
        <Select
          onChange={(e) => onChangeMachiningUnit(e)}
          value={unit}
          bg="white"
          w="120px"
          _focus={{ boxShadow: 'none' }}
          disabled={isDisabled}
        >
          {units.map((unit, index) => {
            return (
              <option value={unit.value} key={index}>
                {unit.label}
              </option>
            )
          })}
        </Select>
      </Td>
      <Td>
        <PrimaryNumberInput
          value={wageRate}
          minValue={0}
          maxValue={100000}
          onChange={(num) => onChangeWageRate(num)}
          step={0.01}
          precision={2}
          width="120px"
          disabled={isDisabled}
        />
      </Td>
      <Td>
        <PrimaryNumberInput
          value={minWageRate}
          minValue={0}
          maxValue={100000}
          onChange={(num) => onChangeMinWageRate(num)}
          step={0.01}
          precision={2}
          width="120px"
          disabled={isDisabled}
        />
      </Td>
      <Td width="120px">
        ¥{(Math.round(totalSellingPrice * 100 || 0) / 100).toLocaleString()}
      </Td>
      <Td width="120px">
        ¥{(Math.round(totalBuyingPrice * 100 || 0) / 100).toLocaleString()}
      </Td>
      <Td width="120px" />
      <Td width="120px" />
      <Td isNumeric width="100px">
        {type == 'adding' ? (
          <IconButton
            aria-label="add"
            disabled={isDisabled || disabledAddButton}
            onClick={onClickAddProduct}
            icon={<AiOutlinePlus />}
          />
        ) : (
          <IconButton
            aria-label="delete"
            disabled={isDisabled}
            onClick={onClickDeleteMachining}
            icon={<AiOutlineDelete />}
          />
        )}
      </Td>
    </>
  )
}
