import React from 'react'
import { Flex, Heading } from '@chakra-ui/react'

export const Page404 = () => {
  return (
    <Flex justify="center" align="center">
      <Heading>Page not found</Heading>
    </Flex>
  )
}
