import React from 'react'
import { Box, Flex, Switch, Text } from '@chakra-ui/react'
import { PrimarySelectBox } from '../../../atoms/PrimarySelectBox'
import { PrimaryInput } from '../../../atoms/PrimaryInput'
import { PrimaryNumberInput } from '../../../atoms/PrimaryNumberInput'

export const FormConditionConfirmation = (props) => {
  const { moldingReport, setMoldingReport, masterList, isLoadingAction } = props
  return (
    <Box mt={6}>
      <Flex h="50px" align="center">
        <Text w="200px" h="50px" lineHeight="50px" bg="black" textColor="white">
          条件確認
        </Text>
        <Text w="200px">昼勤</Text>
        <Text w="200px">夜勤</Text>
      </Flex>
      <Flex align="center" borderTop="1px" borderLeft="1px" borderRight="1px">
        <Text w="30px">温度℃</Text>
        <Box flexGrow={1}>
          <Flex align="center" borderBottom="1px">
            <Text
              w="170px"
              minWidth="170px"
              h="50px"
              lineHeight="50px"
              borderLeft="1px"
              borderRight="1px"
            >
              ノズル
            </Text>
            <PrimaryNumberInput
              value={moldingReport.condition_confirmation.day.nozzle}
              minValue={0}
              maxValue={100000}
              step={0.1}
              precision={1}
              onChange={(num) =>
                setMoldingReport({
                  ...moldingReport,
                  condition_confirmation: {
                    ...moldingReport.condition_confirmation,
                    day: {
                      ...moldingReport.condition_confirmation.day,
                      nozzle: num,
                    },
                  },
                })
              }
              flexGrow={1}
              disabled={isLoadingAction}
            />
            <Text w="40px" h="50px" lineHeight="50px" borderRight="1px">
              (℃)
            </Text>
            <PrimaryNumberInput
              value={moldingReport.condition_confirmation.night.nozzle}
              minValue={0}
              maxValue={100000}
              step={0.1}
              precision={1}
              onChange={(num) =>
                setMoldingReport({
                  ...moldingReport,
                  condition_confirmation: {
                    ...moldingReport.condition_confirmation,
                    night: {
                      ...moldingReport.condition_confirmation.night,
                      nozzle: num,
                    },
                  },
                })
              }
              flexGrow={1}
              disabled={isLoadingAction}
            />
            <Text w="40px" h="50px" lineHeight="50px">
              (℃)
            </Text>
          </Flex>
          <Flex align="center" borderBottom="1px">
            <Text
              w="170px"
              minWidth="170px"
              h="50px"
              lineHeight="50px"
              borderLeft="1px"
              borderRight="1px"
            >
              1ゾーン
            </Text>
            <PrimaryNumberInput
              value={moldingReport.condition_confirmation.day.zone1}
              minValue={0}
              maxValue={100000}
              step={0.1}
              precision={1}
              onChange={(num) =>
                setMoldingReport({
                  ...moldingReport,
                  condition_confirmation: {
                    ...moldingReport.condition_confirmation,
                    day: {
                      ...moldingReport.condition_confirmation.day,
                      zone1: num,
                    },
                  },
                })
              }
              flexGrow={1}
              disabled={isLoadingAction}
            />
            <Text w="40px" h="50px" lineHeight="50px" borderRight="1px">
              (℃)
            </Text>
            <PrimaryNumberInput
              value={moldingReport.condition_confirmation.night.zone1}
              minValue={0}
              maxValue={100000}
              step={0.1}
              precision={1}
              onChange={(num) =>
                setMoldingReport({
                  ...moldingReport,
                  condition_confirmation: {
                    ...moldingReport.condition_confirmation,
                    night: {
                      ...moldingReport.condition_confirmation.night,
                      zone1: num,
                    },
                  },
                })
              }
              flexGrow={1}
              disabled={isLoadingAction}
            />
            <Text w="40px" h="50px" lineHeight="50px">
              (℃)
            </Text>
          </Flex>
          <Flex align="center" borderBottom="1px">
            <Text
              w="170px"
              minWidth="170px"
              h="50px"
              lineHeight="50px"
              borderLeft="1px"
              borderRight="1px"
            >
              2ゾーン
            </Text>
            <PrimaryNumberInput
              value={moldingReport.condition_confirmation.day.zone2}
              minValue={0}
              maxValue={100000}
              step={0.1}
              precision={1}
              onChange={(num) =>
                setMoldingReport({
                  ...moldingReport,
                  condition_confirmation: {
                    ...moldingReport.condition_confirmation,
                    day: {
                      ...moldingReport.condition_confirmation.day,
                      zone2: num,
                    },
                  },
                })
              }
              flexGrow={1}
              disabled={isLoadingAction}
            />
            <Text w="40px" h="50px" lineHeight="50px" borderRight="1px">
              (℃)
            </Text>
            <PrimaryNumberInput
              value={moldingReport.condition_confirmation.night.zone2}
              minValue={0}
              maxValue={100000}
              step={0.1}
              precision={1}
              onChange={(num) =>
                setMoldingReport({
                  ...moldingReport,
                  condition_confirmation: {
                    ...moldingReport.condition_confirmation,
                    night: {
                      ...moldingReport.condition_confirmation.night,
                      zone2: num,
                    },
                  },
                })
              }
              flexGrow={1}
              disabled={isLoadingAction}
            />
            <Text w="40px" h="50px" lineHeight="50px">
              (℃)
            </Text>
          </Flex>
          <Flex align="center" borderBottom="1px">
            <Text
              w="170px"
              minWidth="170px"
              h="50px"
              lineHeight="50px"
              borderLeft="1px"
              borderRight="1px"
            >
              3ゾーン
            </Text>
            <PrimaryNumberInput
              value={moldingReport.condition_confirmation.day.zone3}
              minValue={0}
              maxValue={100000}
              step={0.1}
              precision={1}
              onChange={(num) =>
                setMoldingReport({
                  ...moldingReport,
                  condition_confirmation: {
                    ...moldingReport.condition_confirmation,
                    day: {
                      ...moldingReport.condition_confirmation.day,
                      zone3: num,
                    },
                  },
                })
              }
              flexGrow={1}
              disabled={isLoadingAction}
            />
            <Text w="40px" h="50px" lineHeight="50px" borderRight="1px">
              (℃)
            </Text>
            <PrimaryNumberInput
              value={moldingReport.condition_confirmation.night.zone3}
              minValue={0}
              maxValue={100000}
              step={0.1}
              precision={1}
              onChange={(num) =>
                setMoldingReport({
                  ...moldingReport,
                  condition_confirmation: {
                    ...moldingReport.condition_confirmation,
                    night: {
                      ...moldingReport.condition_confirmation.night,
                      zone3: num,
                    },
                  },
                })
              }
              flexGrow={1}
              disabled={isLoadingAction}
            />
            <Text w="40px" h="50px" lineHeight="50px">
              (℃)
            </Text>
          </Flex>
          <Flex align="center" borderBottom="1px">
            <Text
              w="170px"
              minWidth="170px"
              h="50px"
              lineHeight="50px"
              borderLeft="1px"
              borderRight="1px"
            >
              4ゾーン
            </Text>
            <PrimaryNumberInput
              value={moldingReport.condition_confirmation.day.zone4}
              minValue={0}
              maxValue={100000}
              step={0.1}
              precision={1}
              onChange={(num) =>
                setMoldingReport({
                  ...moldingReport,
                  condition_confirmation: {
                    ...moldingReport.condition_confirmation,
                    day: {
                      ...moldingReport.condition_confirmation.day,
                      zone4: num,
                    },
                  },
                })
              }
              flexGrow={1}
              disabled={isLoadingAction}
            />
            <Text w="40px" h="50px" lineHeight="50px" borderRight="1px">
              (℃)
            </Text>
            <PrimaryNumberInput
              value={moldingReport.condition_confirmation.night.zone4}
              minValue={0}
              maxValue={100000}
              step={0.1}
              precision={1}
              onChange={(num) =>
                setMoldingReport({
                  ...moldingReport,
                  condition_confirmation: {
                    ...moldingReport.condition_confirmation,
                    night: {
                      ...moldingReport.condition_confirmation.night,
                      zone4: num,
                    },
                  },
                })
              }
              flexGrow={1}
              disabled={isLoadingAction}
            />
            <Text w="40px" h="50px" lineHeight="50px">
              (℃)
            </Text>
          </Flex>
          <Flex align="center" borderBottom="1px">
            <Text
              w="170px"
              minWidth="170px"
              h="50px"
              lineHeight="50px"
              borderLeft="1px"
              borderRight="1px"
            >
              5ゾーン
            </Text>
            <PrimaryNumberInput
              value={moldingReport.condition_confirmation.day.zone5}
              minValue={0}
              maxValue={100000}
              step={0.1}
              precision={1}
              onChange={(num) =>
                setMoldingReport({
                  ...moldingReport,
                  condition_confirmation: {
                    ...moldingReport.condition_confirmation,
                    day: {
                      ...moldingReport.condition_confirmation.day,
                      zone5: num,
                    },
                  },
                })
              }
              flexGrow={1}
              disabled={isLoadingAction}
            />
            <Text w="40px" h="50px" lineHeight="50px" borderRight="1px">
              (℃)
            </Text>
            <PrimaryNumberInput
              value={moldingReport.condition_confirmation.night.zone5}
              minValue={0}
              maxValue={100000}
              step={0.1}
              precision={1}
              onChange={(num) =>
                setMoldingReport({
                  ...moldingReport,
                  condition_confirmation: {
                    ...moldingReport.condition_confirmation,
                    night: {
                      ...moldingReport.condition_confirmation.night,
                      zone5: num,
                    },
                  },
                })
              }
              flexGrow={1}
              disabled={isLoadingAction}
            />
            <Text w="40px" h="50px" lineHeight="50px">
              (℃)
            </Text>
          </Flex>
          <Flex align="center" borderBottom="1px">
            <Text
              w="170px"
              minWidth="170px"
              h="50px"
              lineHeight="50px"
              borderLeft="1px"
              borderRight="1px"
            >
              金型キャビ
            </Text>
            <PrimaryNumberInput
              value={moldingReport.condition_confirmation.day.cavity}
              minValue={0}
              maxValue={100000}
              step={0.1}
              precision={1}
              onChange={(num) =>
                setMoldingReport({
                  ...moldingReport,
                  condition_confirmation: {
                    ...moldingReport.condition_confirmation,
                    day: {
                      ...moldingReport.condition_confirmation.day,
                      cavity: num,
                    },
                  },
                })
              }
              flexGrow={1}
              disabled={isLoadingAction}
            />
            <Text w="40px" h="50px" lineHeight="50px" borderRight="1px">
              (℃)
            </Text>
            <PrimaryNumberInput
              value={moldingReport.condition_confirmation.night.cavity}
              minValue={0}
              maxValue={100000}
              step={0.1}
              precision={1}
              onChange={(num) =>
                setMoldingReport({
                  ...moldingReport,
                  condition_confirmation: {
                    ...moldingReport.condition_confirmation,
                    night: {
                      ...moldingReport.condition_confirmation.night,
                      cavity: num,
                    },
                  },
                })
              }
              flexGrow={1}
              disabled={isLoadingAction}
            />
            <Text w="40px" h="50px" lineHeight="50px">
              (℃)
            </Text>
          </Flex>
          <Flex align="center" borderBottom="1px">
            <Text
              w="170px"
              minWidth="170px"
              h="50px"
              lineHeight="50px"
              borderLeft="1px"
              borderRight="1px"
            >
              金型コアー
            </Text>
            <PrimaryNumberInput
              value={moldingReport.condition_confirmation.day.core}
              minValue={0}
              maxValue={100000}
              step={0.1}
              precision={1}
              onChange={(num) =>
                setMoldingReport({
                  ...moldingReport,
                  condition_confirmation: {
                    ...moldingReport.condition_confirmation,
                    day: {
                      ...moldingReport.condition_confirmation.day,
                      core: num,
                    },
                  },
                })
              }
              flexGrow={1}
              disabled={isLoadingAction}
            />
            <Text w="40px" h="50px" lineHeight="50px" borderRight="1px">
              (℃)
            </Text>
            <PrimaryNumberInput
              value={moldingReport.condition_confirmation.night.core}
              minValue={0}
              maxValue={100000}
              step={0.1}
              precision={1}
              onChange={(num) =>
                setMoldingReport({
                  ...moldingReport,
                  condition_confirmation: {
                    ...moldingReport.condition_confirmation,
                    night: {
                      ...moldingReport.condition_confirmation.night,
                      core: num,
                    },
                  },
                })
              }
              flexGrow={1}
              disabled={isLoadingAction}
            />
            <Text w="40px" h="50px" lineHeight="50px">
              (℃)
            </Text>
          </Flex>
          <Flex align="center">
            <Text
              w="170px"
              minWidth="170px"
              h="50px"
              lineHeight="50px"
              borderLeft="1px"
              borderRight="1px"
            >
              乾燥温度
            </Text>
            <PrimaryNumberInput
              value={moldingReport.condition_confirmation.day.drying}
              minValue={0}
              maxValue={100000}
              step={0.1}
              precision={1}
              onChange={(num) =>
                setMoldingReport({
                  ...moldingReport,
                  condition_confirmation: {
                    ...moldingReport.condition_confirmation,
                    day: {
                      ...moldingReport.condition_confirmation.day,
                      drying: num,
                    },
                  },
                })
              }
              flexGrow={1}
              disabled={isLoadingAction}
            />
            <Text w="40px" h="50px" lineHeight="50px" borderRight="1px">
              (℃)
            </Text>
            <PrimaryNumberInput
              value={moldingReport.condition_confirmation.night.drying}
              minValue={0}
              maxValue={100000}
              step={0.1}
              precision={1}
              onChange={(num) =>
                setMoldingReport({
                  ...moldingReport,
                  condition_confirmation: {
                    ...moldingReport.condition_confirmation,
                    night: {
                      ...moldingReport.condition_confirmation.night,
                      drying: num,
                    },
                  },
                })
              }
              flexGrow={1}
              disabled={isLoadingAction}
            />
            <Text w="40px" h="50px" lineHeight="50px">
              (℃)
            </Text>
          </Flex>
        </Box>
      </Flex>
      <Flex align="center" borderTop="1px" borderLeft="1px" borderRight="1px">
        <Text w="30px" minWidth="30px">
          時間
        </Text>
        <Box flexGrow={1}>
          <Flex align="center" borderBottom="1px">
            <Text
              w="170px"
              minWidth="170px"
              h="50px"
              lineHeight="50px"
              borderLeft="1px"
              borderRight="1px"
            >
              充電時間
            </Text>
            <PrimaryNumberInput
              value={moldingReport.condition_confirmation.day.filling_time}
              minValue={0}
              maxValue={100000}
              step={0.01}
              precision={2}
              onChange={(num) =>
                setMoldingReport({
                  ...moldingReport,
                  condition_confirmation: {
                    ...moldingReport.condition_confirmation,
                    day: {
                      ...moldingReport.condition_confirmation.day,
                      filling_time: num,
                    },
                  },
                })
              }
              flexGrow={1}
              disabled={isLoadingAction}
            />
            <Text w="40px" h="50px" lineHeight="50px" borderRight="1px">
              (秒)
            </Text>
            <PrimaryNumberInput
              value={moldingReport.condition_confirmation.night.filling_time}
              minValue={0}
              maxValue={100000}
              step={0.01}
              precision={2}
              onChange={(num) =>
                setMoldingReport({
                  ...moldingReport,
                  condition_confirmation: {
                    ...moldingReport.condition_confirmation,
                    night: {
                      ...moldingReport.condition_confirmation.night,
                      filling_time: num,
                    },
                  },
                })
              }
              flexGrow={1}
              disabled={isLoadingAction}
            />
            <Text w="40px" h="50px" lineHeight="50px">
              (秒)
            </Text>
          </Flex>
          <Flex align="center" borderBottom="1px">
            <Text
              w="170px"
              minWidth="170px"
              h="50px"
              lineHeight="50px"
              borderLeft="1px"
              borderRight="1px"
            >
              計量時間
            </Text>
            <PrimaryNumberInput
              value={moldingReport.condition_confirmation.day.measuring_time}
              minValue={0}
              maxValue={100000}
              step={0.01}
              precision={2}
              onChange={(num) =>
                setMoldingReport({
                  ...moldingReport,
                  condition_confirmation: {
                    ...moldingReport.condition_confirmation,
                    day: {
                      ...moldingReport.condition_confirmation.day,
                      measuring_time: num,
                    },
                  },
                })
              }
              flexGrow={1}
              disabled={isLoadingAction}
            />
            <Text w="40px" h="50px" lineHeight="50px" borderRight="1px">
              (秒)
            </Text>
            <PrimaryNumberInput
              value={moldingReport.condition_confirmation.night.measuring_time}
              minValue={0}
              maxValue={100000}
              step={0.01}
              precision={2}
              onChange={(num) =>
                setMoldingReport({
                  ...moldingReport,
                  condition_confirmation: {
                    ...moldingReport.condition_confirmation,
                    night: {
                      ...moldingReport.condition_confirmation.night,
                      measuring_time: num,
                    },
                  },
                })
              }
              flexGrow={1}
              disabled={isLoadingAction}
            />
            <Text w="40px" h="50px" lineHeight="50px">
              (秒)
            </Text>
          </Flex>
          <Flex align="center">
            <Text
              w="170px"
              minWidth="170px"
              h="50px"
              lineHeight="50px"
              borderLeft="1px"
              borderRight="1px"
            >
              サイクル時間
            </Text>
            <PrimaryNumberInput
              value={moldingReport.condition_confirmation.day.cycle_time}
              minValue={0}
              maxValue={100000}
              step={0.01}
              precision={2}
              onChange={(num) =>
                setMoldingReport({
                  ...moldingReport,
                  condition_confirmation: {
                    ...moldingReport.condition_confirmation,
                    day: {
                      ...moldingReport.condition_confirmation.day,
                      cycle_time: num,
                    },
                  },
                })
              }
              flexGrow={1}
              disabled={isLoadingAction}
            />
            <Text w="40px" h="50px" lineHeight="50px" borderRight="1px">
              (秒)
            </Text>
            <PrimaryNumberInput
              value={moldingReport.condition_confirmation.night.cycle_time}
              minValue={0}
              maxValue={100000}
              step={0.01}
              precision={2}
              onChange={(num) =>
                setMoldingReport({
                  ...moldingReport,
                  condition_confirmation: {
                    ...moldingReport.condition_confirmation,
                    night: {
                      ...moldingReport.condition_confirmation.night,
                      cycle_time: num,
                    },
                  },
                })
              }
              flexGrow={1}
              disabled={isLoadingAction}
            />
            <Text w="40px" h="50px" lineHeight="50px">
              (秒)
            </Text>
          </Flex>
        </Box>
      </Flex>
      <Flex align="center" borderTop="1px" borderLeft="1px" borderRight="1px">
        <Text w="30px" minWidth="30px">
          圧力
        </Text>
        <Box flexGrow={1}>
          <Flex align="center">
            <Text
              w="170px"
              minWidth="170px"
              h="50px"
              lineHeight="50px"
              borderLeft="1px"
              borderRight="1px"
            >
              型締圧
            </Text>
            <PrimaryNumberInput
              value={moldingReport.condition_confirmation.day.clamping_pressure}
              minValue={0}
              maxValue={100000}
              step={0.1}
              precision={1}
              onChange={(num) =>
                setMoldingReport({
                  ...moldingReport,
                  condition_confirmation: {
                    ...moldingReport.condition_confirmation,
                    day: {
                      ...moldingReport.condition_confirmation.day,
                      clamping_pressure: num,
                    },
                  },
                })
              }
              flexGrow={1}
              disabled={isLoadingAction}
            />
            <Text w="40px" h="50px" lineHeight="50px" borderRight="1px">
              (kW)
            </Text>
            <PrimaryNumberInput
              value={
                moldingReport.condition_confirmation.night.clamping_pressure
              }
              minValue={0}
              maxValue={100000}
              step={0.1}
              precision={1}
              onChange={(num) =>
                setMoldingReport({
                  ...moldingReport,
                  condition_confirmation: {
                    ...moldingReport.condition_confirmation,
                    night: {
                      ...moldingReport.condition_confirmation.night,
                      clamping_pressure: num,
                    },
                  },
                })
              }
              flexGrow={1}
              disabled={isLoadingAction}
            />
            <Text w="40px" h="50px" lineHeight="50px">
              (kW)
            </Text>
          </Flex>
        </Box>
      </Flex>
      <Flex align="center" borderTop="1px" borderLeft="1px" borderRight="1px">
        <Text w="30px" minWidth="30px">
          その他
        </Text>
        <Box flexGrow={1}>
          <Flex align="center" borderBottom="1px">
            <Text
              w="170px"
              minWidth="170px"
              h="50px"
              lineHeight="50px"
              borderLeft="1px"
              borderRight="1px"
            >
              最小クッション数
            </Text>
            <PrimaryNumberInput
              value={
                moldingReport.condition_confirmation.day.minimum_cushion_length
              }
              minValue={0}
              maxValue={100000}
              step={0.01}
              precision={2}
              onChange={(num) =>
                setMoldingReport({
                  ...moldingReport,
                  condition_confirmation: {
                    ...moldingReport.condition_confirmation,
                    day: {
                      ...moldingReport.condition_confirmation.day,
                      minimum_cushion_length: num,
                    },
                  },
                })
              }
              flexGrow={1}
              disabled={isLoadingAction}
            />
            <Text w="40px" h="50px" lineHeight="50px" borderRight="1px">
              (mm)
            </Text>
            <PrimaryNumberInput
              value={
                moldingReport.condition_confirmation.night
                  .minimum_cushion_length
              }
              minValue={0}
              maxValue={100000}
              step={0.01}
              precision={2}
              onChange={(num) =>
                setMoldingReport({
                  ...moldingReport,
                  condition_confirmation: {
                    ...moldingReport.condition_confirmation,
                    night: {
                      ...moldingReport.condition_confirmation.night,
                      minimum_cushion_length: num,
                    },
                  },
                })
              }
              flexGrow={1}
              disabled={isLoadingAction}
            />
            <Text w="40px" h="50px" lineHeight="50px">
              (mm)
            </Text>
          </Flex>
          <Flex align="center" borderBottom="1px">
            <Text
              w="170px"
              minWidth="170px"
              h="50px"
              lineHeight="50px"
              borderLeft="1px"
              borderRight="1px"
            >
              外観チェック
            </Text>
            <Switch
              h="50px"
              lineHeight="50px"
              borderRight="1px"
              value={
                moldingReport.condition_confirmation.day.is_appearance_check
              }
              onChange={(e) =>
                setMoldingReport({
                  ...moldingReport,
                  condition_confirmation: {
                    ...moldingReport.condition_confirmation,
                    day: {
                      ...moldingReport.condition_confirmation.day,
                      is_appearance_check: e.target.checked,
                    },
                  },
                })
              }
              isChecked={
                moldingReport.condition_confirmation.day.is_appearance_check
              }
              flexGrow={1}
              isDisabled={isLoadingAction}
            />
            <Switch
              h="50px"
              lineHeight="50px"
              value={
                moldingReport.condition_confirmation.night.is_appearance_check
              }
              onChange={(e) =>
                setMoldingReport({
                  ...moldingReport,
                  condition_confirmation: {
                    ...moldingReport.condition_confirmation,
                    night: {
                      ...moldingReport.condition_confirmation.night,
                      is_appearance_check: e.target.checked,
                    },
                  },
                })
              }
              isChecked={
                moldingReport.condition_confirmation.night.is_appearance_check
              }
              flexGrow={1}
              isDisabled={isLoadingAction}
            />
          </Flex>
          <Flex align="center" borderBottom="1px">
            <Text
              w="170px"
              minWidth="170px"
              h="50px"
              lineHeight="50px"
              borderLeft="1px"
              borderRight="1px"
            >
              基本条件照合
            </Text>
            <Switch
              h="50px"
              lineHeight="50px"
              borderRight="1px"
              value={
                moldingReport.condition_confirmation.day
                  .is_basic_condition_matching
              }
              onChange={(e) =>
                setMoldingReport({
                  ...moldingReport,
                  condition_confirmation: {
                    ...moldingReport.condition_confirmation,
                    day: {
                      ...moldingReport.condition_confirmation.day,
                      is_basic_condition_matching: e.target.checked,
                    },
                  },
                })
              }
              isChecked={
                moldingReport.condition_confirmation.day
                  .is_basic_condition_matching
              }
              flexGrow={1}
              isDisabled={isLoadingAction}
            />
            <Switch
              h="50px"
              lineHeight="50px"
              value={
                moldingReport.condition_confirmation.night
                  .is_basic_condition_matching
              }
              onChange={(e) =>
                setMoldingReport({
                  ...moldingReport,
                  condition_confirmation: {
                    ...moldingReport.condition_confirmation,
                    night: {
                      ...moldingReport.condition_confirmation.night,
                      is_basic_condition_matching: e.target.checked,
                    },
                  },
                })
              }
              isChecked={
                moldingReport.condition_confirmation.night
                  .is_basic_condition_matching
              }
              flexGrow={1}
              isDisabled={isLoadingAction}
            />
          </Flex>
          <Flex align="center" borderBottom="1px">
            <Text
              w="170px"
              minWidth="170px"
              h="50px"
              lineHeight="50px"
              borderLeft="1px"
              borderRight="1px"
            >
              糸切り穴径確認
            </Text>
            <Switch
              h="50px"
              lineHeight="50px"
              borderRight="1px"
              value={
                moldingReport.condition_confirmation.day
                  .is_check_thread_cutting_hole_diameter
              }
              onChange={(e) =>
                setMoldingReport({
                  ...moldingReport,
                  condition_confirmation: {
                    ...moldingReport.condition_confirmation,
                    day: {
                      ...moldingReport.condition_confirmation.day,
                      is_check_thread_cutting_hole_diameter: e.target.checked,
                    },
                  },
                })
              }
              isChecked={
                moldingReport.condition_confirmation.day
                  .is_check_thread_cutting_hole_diameter
              }
              flexGrow={1}
              isDisabled={isLoadingAction}
            />
            <Switch
              h="50px"
              lineHeight="50px"
              value={
                moldingReport.condition_confirmation.night
                  .is_check_thread_cutting_hole_diameter
              }
              onChange={(e) =>
                setMoldingReport({
                  ...moldingReport,
                  condition_confirmation: {
                    ...moldingReport.condition_confirmation,
                    night: {
                      ...moldingReport.condition_confirmation.night,
                      is_check_thread_cutting_hole_diameter: e.target.checked,
                    },
                  },
                })
              }
              isChecked={
                moldingReport.condition_confirmation.night
                  .is_check_thread_cutting_hole_diameter
              }
              flexGrow={1}
              isDisabled={isLoadingAction}
            />
          </Flex>
          <Text flexGrow={1} py={1}>
            *NG写真と比較して同等の場合は廃棄し、新品に交換すること
          </Text>
        </Box>
      </Flex>
      <Flex align="center" borderTop="1px" borderLeft="1px" borderRight="1px">
        <Text
          w="200px"
          minWidth="200px"
          h="50px"
          lineHeight="50px"
          borderRight="1px"
        >
          記入者
        </Text>
        <Box borderRight="1px" w="200px" minWidth="200px" py={1}>
          <PrimarySelectBox
            options={masterList.users}
            defaultOption={
              moldingReport.condition_confirmation.day.registered_by
            }
            optionElement="name"
            onChangeSelect={(num) =>
              setMoldingReport({
                ...moldingReport,
                condition_confirmation: {
                  ...moldingReport.condition_confirmation,
                  day: {
                    ...moldingReport.condition_confirmation.day,
                    registered_by: masterList.users[num]['name'],
                  },
                },
              })
            }
            width="199px"
            disabled={isLoadingAction}
          />
        </Box>
        <Box w="200px" minWidth="200px" py={1}>
          <PrimarySelectBox
            options={masterList.users}
            defaultOption={
              moldingReport.condition_confirmation.night.registered_by
            }
            optionElement="name"
            onChangeSelect={(num) =>
              setMoldingReport({
                ...moldingReport,
                condition_confirmation: {
                  ...moldingReport.condition_confirmation,
                  night: {
                    ...moldingReport.condition_confirmation.night,
                    registered_by: masterList.users[num]['name'],
                  },
                },
              })
            }
            width="198px"
            disabled={isLoadingAction}
          />
        </Box>
      </Flex>
      <Flex align="center" border="1px">
        <Text
          w="200px"
          minWidth="200px"
          h="50px"
          lineHeight="50px"
          borderRight="1px"
        >
          確認者
        </Text>
        <Box borderRight="1px" w="200px" minWidth="200px" py={1}>
          <PrimarySelectBox
            options={masterList.users}
            defaultOption={
              moldingReport.condition_confirmation.day.confirmed_by
            }
            optionElement="name"
            onChangeSelect={(num) =>
              setMoldingReport({
                ...moldingReport,
                condition_confirmation: {
                  ...moldingReport.condition_confirmation,
                  day: {
                    ...moldingReport.condition_confirmation.day,
                    confirmed_by: masterList.users[num]['name'],
                  },
                },
              })
            }
            width="199px"
            disabled={isLoadingAction}
          />
        </Box>
        <Box w="200px" minWidth="200px" py={1}>
          <PrimarySelectBox
            options={masterList.users}
            defaultOption={
              moldingReport.condition_confirmation.night.confirmed_by
            }
            optionElement="name"
            onChangeSelect={(num) =>
              setMoldingReport({
                ...moldingReport,
                condition_confirmation: {
                  ...moldingReport.condition_confirmation,
                  night: {
                    ...moldingReport.condition_confirmation.night,
                    confirmed_by: masterList.users[num]['name'],
                  },
                },
              })
            }
            width="198px"
            disabled={isLoadingAction}
          />
        </Box>
      </Flex>
      <Flex h="50px" align="center" borderBottom="1px" borderRight="1px">
        <Text w="200px" h="50px" lineHeight="50px" bg="black" textColor="white">
          支給品関連
        </Text>
        <Text w="100px" h="50px" lineHeight="50px" borderRight="1px">
          ロット
        </Text>
        <PrimaryInput
          value={moldingReport.supplied_lot_num}
          onChange={(e) =>
            setMoldingReport({
              ...moldingReport,
              supplied_lot_num: e.target.value,
            })
          }
          flexGrow={1}
          disabled={isLoadingAction}
        />
      </Flex>
    </Box>
  )
}
