import React from 'react'
import { Navigate } from 'react-router'
import { useMessage } from '../hooks/useMessage'
import { useCurrentUser2 } from '../hooks/users/useCurrentUser2'

export const UserValidationWorker = ({ children }) => {
  const user = useCurrentUser2()
  const { message } = useMessage()
  return (
    <>
      {user.access_level == 'sales' ? (
        <>
          {message({
            title: 'ページへのアクセス権限がありません。',
            status: 'warning',
            position: 'top',
            duration: 2000,
          })}
          <Navigate to="/daily_report" />
        </>
      ) : (
        children
      )}
    </>
  )
}
