import React from 'react'
import {
  Box,
  Flex,
  Radio,
  RadioGroup,
  Text,
  Textarea,
} from '@chakra-ui/react'
import { PrimarySelectBox } from '../../../atoms/PrimarySelectBox'

export const FormTools = (props) => {
  const {
    moldingReport,
    setMoldingReport,
    masterList,
    isLoadingAction,
  } = props
  return (
    <Box mt={6}>
      <Text w="200px" h="50px" lineHeight="50px" bg="black" textColor="white">
        金型・取出機関連
      </Text>
      <Flex align="center" borderTop="1px" borderLeft="1px" borderRight="1px">
        <Text w="100px" minWidth="100px" h="100px" pt="25px" borderRight="1px">
          段取り時の金型点検結果
        </Text>
        <RadioGroup
          onChange={(value) =>
            setMoldingReport({
              ...moldingReport,
              tools: {
                ...moldingReport.tools,
                mold_inspection_result: {
                  ...moldingReport.tools.mold_inspection_result,
                  is_well: JSON.parse(value),
                },
              },
            })
          }
          value={moldingReport.tools.mold_inspection_result.is_well}
        >
          <Box w="70px" borderRight="1px">
            <Radio h="50px" lineHeight="50px" value={true}>
              OK
            </Radio>
            <Box borderBottom="1px" w="70px" />
            <Radio h="50px" lineHeight="50px" value={false}>
              NG
            </Radio>
          </Box>
        </RadioGroup>
        <Text
          w="70px"
          minWidth="70px"
          h="100px"
          pt="25px"
          borderRight="1px"
          overflowWrap="break-word"
        >
          不具合内容と処置
        </Text>
        <Textarea
          value={moldingReport.tools.mold_inspection_result.defective_reason}
          onChange={(e) =>
            setMoldingReport({
              ...moldingReport,
              tools: {
                ...moldingReport.tools,
                mold_inspection_result: {
                  ...moldingReport.tools.mold_inspection_result,
                  defective_reason: e.target.value,
                },
              },
            })
          }
          w="160px"
          h="100px"
          disabled={isLoadingAction}
        />
        <Box borderLeft="1px">
          <Flex align="center" borderBottom="1px">
            <Text
              w="50px"
              minWidth="50px"
              h="50px"
              lineHeight="50px"
              borderRight="1px"
            >
              記入者
            </Text>
            <PrimarySelectBox
              options={masterList.users}
              defaultOption={
                moldingReport.tools.mold_inspection_result.registered_by
              }
              optionElement="name"
              onChangeSelect={(num) =>
                setMoldingReport({
                  ...moldingReport,
                  tools: {
                    ...moldingReport.tools,
                    mold_inspection_result: {
                      ...moldingReport.tools.mold_inspection_result,
                      registered_by: masterList.users[num]['name'],
                    },
                  },
                })
              }
              width="150px"
              disabled={isLoadingAction}
            />
          </Flex>
          <Flex align="center">
            <Text
              w="50px"
              minWidth="50px"
              h="50px"
              lineHeight="50px"
              borderRight="1px"
            >
              確認者
            </Text>
            <PrimarySelectBox
              options={masterList.users}
              defaultOption={
                moldingReport.tools.mold_inspection_result.confirmed_by
              }
              optionElement="name"
              onChangeSelect={(num) =>
                setMoldingReport({
                  ...moldingReport,
                  tools: {
                    ...moldingReport.tools,
                    mold_inspection_result: {
                      ...moldingReport.tools.mold_inspection_result,
                      confirmed_by: masterList.users[num]['name'],
                    },
                  },
                })
              }
              width="150px"
              disabled={isLoadingAction}
            />
          </Flex>
        </Box>
      </Flex>
      <Flex align="center" border="1px">
        <Text w="100px" minWidth="100px" h="100px" pt="25px" borderRight="1px">
          製品取出時の動作確認結果
        </Text>
        <RadioGroup
          onChange={(value) =>
            setMoldingReport({
              ...moldingReport,
              tools: {
                ...moldingReport.tools,
                operation_confirmation_result: {
                  ...moldingReport.tools.operation_confirmation_result,
                  is_well: JSON.parse(value),
                },
              },
            })
          }
          value={moldingReport.tools.operation_confirmation_result.is_well}
        >
          <Box w="70px" borderRight="1px">
            <Radio h="50px" lineHeight="50px" value={true}>
              OK
            </Radio>
            <Box borderBottom="1px" w="70px" />
            <Radio h="50px" lineHeight="50px" value={false}>
              NG
            </Radio>
          </Box>
        </RadioGroup>
        <Text
          w="70px"
          minWidth="70px"
          h="100px"
          pt="25px"
          borderRight="1px"
          overflowWrap="break-word"
        >
          不具合内容と処置
        </Text>
        <Textarea
          value={
            moldingReport.tools.operation_confirmation_result.defective_reason
          }
          onChange={(e) =>
            setMoldingReport({
              ...moldingReport,
              tools: {
                ...moldingReport.tools,
                operation_confirmation_result: {
                  ...moldingReport.tools.operation_confirmation_result,
                  defective_reason: e.target.value,
                },
              },
            })
          }
          w="160px"
          h="100px"
          disabled={isLoadingAction}
        />
        <Box borderLeft="1px">
          <Flex align="center" borderBottom="1px">
            <Text
              w="50px"
              minWidth="50px"
              h="50px"
              lineHeight="50px"
              borderRight="1px"
            >
              記入者
            </Text>
            <PrimarySelectBox
              options={masterList.users}
              defaultOption={
                moldingReport.tools.operation_confirmation_result.registered_by
              }
              optionElement="name"
              onChangeSelect={(num) =>
                setMoldingReport({
                  ...moldingReport,
                  tools: {
                    ...moldingReport.tools,
                    operation_confirmation_result: {
                      ...moldingReport.tools.operation_confirmation_result,
                      registered_by: masterList.users[num]['name'],
                    },
                  },
                })
              }
              width="150px"
              disabled={isLoadingAction}
            />
          </Flex>
          <Flex align="center">
            <Text
              w="50px"
              minWidth="50px"
              h="50px"
              lineHeight="50px"
              borderRight="1px"
            >
              確認者
            </Text>
            <PrimarySelectBox
              options={masterList.users}
              defaultOption={
                moldingReport.tools.operation_confirmation_result.confirmed_by
              }
              optionElement="name"
              onChangeSelect={(num) =>
                setMoldingReport({
                  ...moldingReport,
                  tools: {
                    ...moldingReport.tools,
                    operation_confirmation_result: {
                      ...moldingReport.tools.operation_confirmation_result,
                      confirmed_by: masterList.users[num]['name'],
                    },
                  },
                })
              }
              width="150px"
              disabled={isLoadingAction}
            />
          </Flex>
        </Box>
      </Flex>
    </Box>
  )
}
