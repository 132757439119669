import React from 'react'
import {
  Thead,
  Tr,
  Th,
  Tbody,
} from '@chakra-ui/react'
import { TableBodyProductList } from '../../molecules/project/TableBodyProductList'

export const TableProductList = (props) => {
  const {
    lots,
    onClickEditInstruction,
    onClickExportProduct,
    onChangeStampType,
    onClickEditProduct,
    onClickDeleteProduct,
    isDisabled,
    isDisabledButton,
  } = props

  return (
    <>
      <Thead>
        <Tr>
          <Th>指示書番号</Th>
          <Th>品番</Th>
          <Th>品名</Th>
          <Th>数量</Th>
          <Th>単価</Th>
          <Th>小計</Th>
          <Th>作業指示書</Th>
          <Th>詳細見積書</Th>
          <Th isNumeric>編集</Th>
          <Th isNumeric>削除</Th>
        </Tr>
      </Thead>
      <Tbody>
        {lots.map((lot, index) => {
          return (
            <Tr key={index}>
              <TableBodyProductList
                instructionIndex={lot.instruction_num}
                productNum={lot.product_num}
                productName={lot.product_name}
                count={lot.lot_amount}
                price={Math.round(lot.unit_cost)}
                amount={Math.round(lot.product_cost)}
                stamp={lot.stamp_type}
                onClickEditInstruction={() =>
                  onClickEditInstruction(lot.lot_num, lot.instruction_num)
                }
                onClickExportProduct={() =>
                  onClickExportProduct(index, lot.lot_num)
                }
                onChangeStampType={(value) => onChangeStampType(value, index)}
                onClickEditProduct={() => onClickEditProduct(lot)}
                onClickDeleteProduct={() =>
                  onClickDeleteProduct(lot.lot_num, index)
                }
                isDisabled={isDisabled}
                isDisabledButton={isDisabledButton}
              />
            </Tr>
          )
        })}
      </Tbody>
    </>
  )
}
