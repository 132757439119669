import React, { useRef } from 'react'
import {
  useDisclosure,
  Button,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  RadioGroup,
  Stack,
  Radio,
} from '@chakra-ui/react'
import { PrimaryButton } from '../atoms/PrimaryButton'

export const AlertModalWithRadioButton = (props) => {
  const {
    buttonMessage,
    title,
    body,
    value,
    options,
    onClick,
    onChange,
    disabled,
    primaryColor = true,
    colorScheme,
  } = props
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef()

  return (
    <>
      {primaryColor ? (
        <PrimaryButton
          onClick={onOpen}
          disabled={disabled}
          colorScheme={colorScheme}
        >
          {buttonMessage}
        </PrimaryButton>
      ) : (
        <Button onClick={onOpen} disabled={disabled} size="xs">
          {buttonMessage}
        </Button>
      )}
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader
              fontSize="lg"
              fontWeight="bold"
              textAlign="center"
            >
              {title}
            </AlertDialogHeader>
            <AlertDialogBody fontSize="md">
              {body}
              <RadioGroup onChange={onChange} defaultValue={value}>
                <Stack direction="row">
                  {options.map((option, index) => {
                    return (
                      <Radio key={index} value={option.value} m={4}>
                        {option.name}
                      </Radio>
                    )
                  })}
                </Stack>
              </RadioGroup>
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                onClick={onClose}
                disabled={disabled}
                mr={4}
              >
                キャンセル
              </Button>
              {/* <PrimaryButton onClick={onClose} disabled={disabled} mr={4}>
                閉じる
              </PrimaryButton> */}
              <PrimaryButton
                onClick={() => {
                  onClose()
                  onClick()
                }}
                disabled={disabled}
              >
                実行
              </PrimaryButton>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}
