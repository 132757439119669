import React from 'react'
import {
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Heading,
} from '@chakra-ui/react'
import { TableBodyMaterialPrototype3 } from '../../molecules/lot/TableBodyMaterialPrototype3'

export const TableMaterialPrototype3 = (props) => {
  const {
    id,
    tableName,
    materialLists,
    onChangeMaterialName,
    onChangeMaterialChargeQuantity,
    onChangeMaterialUnit,
    onChangeMaterialSellingPrice,
    onChangeMaterialBuyingPrice,
    onClickDeleteMaterial,
    addProductInfo,
    onChangeMaterialNameAdd,
    onChangeMaterialChargeQuantityAdd,
    onChangeMaterialUnitAdd,
    onChangeMaterialSellingPriceAdd,
    onChangeMaterialBuyingPriceAdd,
    onClickAddMaterial,
    isDisabled,
    disabledAddButton,
  } = props

  return (
    <>
      <Heading as="h4" size="md">
        {tableName}
      </Heading>
      <TableContainer mb={2}>
        <Table variant="striped" colorScheme="linkedin">
          <Thead>
            <Tr>
              <Th>材料名</Th>
              <Th>仕込数量</Th>
              <Th>単位</Th>
              <Th>売単価</Th>
              <Th>仕入単価</Th>
              <Th></Th>
              <Th>売単価</Th>
              <Th>仕入単価</Th>
              <Th />
              <Th />
              <Th isNumeric>追加/削除</Th>
            </Tr>
          </Thead>
          <Tbody>
            {materialLists.map((material, index) => {
              return (
                <Tr key={index}>
                  <TableBodyMaterialPrototype3
                    type="existing"
                    materialName={material.material_name}
                    materialChargeQuantity={material.material_charge_quantity}
                    unit={material.unit}
                    sellingPrice={material.selling_price}
                    buyingPrice={material.buying_price}
                    totalSellingPrice={
                      material.material_charge_quantity * material.selling_price
                    }
                    totalBuyingPrice={
                      material.material_charge_quantity * material.buying_price
                    }
                    onChangeMaterialName={(e) =>
                      onChangeMaterialName(e, id, index)
                    }
                    onChangeMaterialChargeQuantity={(num) =>
                      onChangeMaterialChargeQuantity(num, id, index)
                    }
                    onChangeMaterialUnit={(num) =>
                      onChangeMaterialUnit(num, id, index)
                    }
                    onChangeMaterialSellingPrice={(num) =>
                      onChangeMaterialSellingPrice(num, id, index)
                    }
                    onChangeMaterialBuyingPrice={(num) =>
                      onChangeMaterialBuyingPrice(num, id, index)
                    }
                    onClickDeleteMaterial={() =>
                      onClickDeleteMaterial(id, index)
                    }
                    isDisabled={material.is_delete == true || isDisabled}
                  />
                </Tr>
              )
            })}
            <Tr>
              <TableBodyMaterialPrototype3
                type="adding"
                materialName={addProductInfo.materialName}
                materialChargeQuantity={addProductInfo.materialChargeQuantity}
                unit={addProductInfo.materialUnit}
                sellingPrice={addProductInfo.sellingPrice}
                buyingPrice={addProductInfo.buyingPrice}
                totalSellingPrice={
                  addProductInfo.materialChargeQuantity *
                  addProductInfo.sellingPrice
                }
                totalBuyingPrice={
                  addProductInfo.materialChargeQuantity *
                  addProductInfo.buyingPrice
                }
                onChangeMaterialName={(e) => onChangeMaterialNameAdd(e, id)}
                onChangeMaterialChargeQuantity={(num) =>
                  onChangeMaterialChargeQuantityAdd(num, id)
                }
                onChangeMaterialUnit={(num) => onChangeMaterialUnitAdd(num, id)}
                onChangeMaterialSellingPrice={(num) =>
                  onChangeMaterialSellingPriceAdd(num, id)
                }
                onChangeMaterialBuyingPrice={(num) =>
                  onChangeMaterialBuyingPriceAdd(num, id)
                }
                onClickAddProduct={() => {
                  onClickAddMaterial(id)
                }}
                isDisabled={isDisabled}
                disabledAddButton={disabledAddButton}
              />
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>
    </>
  )
}
