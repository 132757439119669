import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Flex, Box, Heading, Divider, Stack } from '@chakra-ui/react'
import { MenuButton } from '../atoms/MenuButton'
import { useCurrentUser2 } from '../../hooks/users/useCurrentUser2'
import { useLogout2 } from '../../hooks/users/useLogout2'

export const MainMenu = () => {
  const navigate = useNavigate()
  const {logout}=useLogout2()
  const user = useCurrentUser2()

  return (
    <Flex h="100vh" justifyContent="center" alignItems="center">
      <Box
        borderRadius="md"
        shadow="md"
        p={4}
        bg="white"
        textAlign="center"
        w="400px"
      >
        <Heading as="h1" size="lg">
          メニュー
        </Heading>
        <Divider my={4} />
        <Stack spacing={4}>
          {user.access_level !== 'worker' && (
            <>
              <MenuButton onClick={() => navigate('/projects/index')}>
                <Heading as="h2" size="md" zIndex={1}>
                  案件一覧
                </Heading>
              </MenuButton>
              {/* <MenuButton onClick={() => navigate('/projects/mass_produce')}>
                <Heading as="h2" size="md" zIndex={1}>
                  量産案件作成
                </Heading>
              </MenuButton> */}
              <MenuButton onClick={() => navigate('/projects/format_a')}>
                <Heading as="h2" size="md" zIndex={1}>
                  試作案件作成
                </Heading>
              </MenuButton>
              <MenuButton
                onClick={() => navigate('/projects/format_a/export_only')}
              >
                <Heading as="h2" size="md" zIndex={1}>
                  試作案件直接出力
                </Heading>
              </MenuButton>
              <MenuButton
                onClick={() =>
                  navigate(`/projects/outsource?isOnlyExport=${true}`)
                }
              >
                <Heading as="h2" size="md" zIndex={1}>
                  発注書直接出力
                </Heading>
              </MenuButton>
            </>
          )}
          {user.access_level !== 'sales' && (
            <MenuButton onClick={() => navigate('/daily_report')}>
              <Heading as="h2" size="md" zIndex={1}>
                日報
              </Heading>
            </MenuButton>
          )}
          {/* {user.access_level == 'admin' && (
            <MenuButton onClick={() => navigate('/data_analysis')}>
              <Heading as="h2" size="md" zIndex={1}>
                データ分析
              </Heading>
            </MenuButton>
          )}
          <MenuButton onClick={() => navigate('/scheduling')}>
            <Heading as="h2" size="md" zIndex={1}>
              ガントチャート
            </Heading>
          </MenuButton> */}
          <MenuButton onClick={() => logout()}>
            <Heading as="h2" size="md" zIndex={1}>
              ログアウト
            </Heading>
          </MenuButton>
        </Stack>
      </Box>
    </Flex>
  )
}
