import React from 'react'
import {
  Box,
  Flex,
  IconButton,
  Radio,
  RadioGroup,
  Text,
} from '@chakra-ui/react'
import { PrimarySelectBox } from '../../../atoms/PrimarySelectBox'
import { PrimaryInput } from '../../../atoms/PrimaryInput'
import { PrimaryNumberInput } from '../../../atoms/PrimaryNumberInput'
import { AiOutlineDelete, AiOutlinePlus } from 'react-icons/ai'

export const FormWorkingAchievement = (props) => {
  const {
    moldingReport,
    setMoldingReport,
    masterList,
    productList,
    isLoadingAction,
  } = props
  return (
    <Box mt={6}>
      <Flex h="50px" align="center">
        <Text w="200px" h="50px" lineHeight="50px" bg="black" textColor="white">
          作業実績
        </Text>
        <Text w="200px">昼勤</Text>
        <Text w="200px">夜勤</Text>
      </Flex>
      <Flex
        h="50px"
        align="center"
        borderTop="1px"
        borderLeft="1px"
        borderRight="1px"
      >
        <Text w="200px" h="50px" lineHeight="50px" borderRight="1px">
          作業内容
        </Text>
        <RadioGroup
          onChange={(value) =>
            setMoldingReport({
              ...moldingReport,
              working_achievement: {
                ...moldingReport.working_achievement,
                is_mass: JSON.parse(value),
              },
            })
          }
          value={moldingReport.working_achievement.is_mass}
        >
          <Flex justify="space-around" w="398px">
            <Radio value={true}>量産</Radio>
            <Radio value={false}>トライ</Radio>
          </Flex>
        </RadioGroup>
      </Flex>
      <Flex
        h="50px"
        align="center"
        borderTop="1px"
        borderLeft="1px"
        borderRight="1px"
      >
        <Text w="200px" h="50px" lineHeight="50px" borderRight="1px">
          品番
          <Box as="span" color="red">
            *
          </Box>
        </Text>
        {moldingReport.working_achievement.is_mass ? (
          <PrimarySelectBox
            options={productList}
            defaultOption={moldingReport.working_achievement.product_number}
            optionElement="product_number"
            onChangeSelect={(index) =>
              setMoldingReport({
                ...moldingReport,
                working_achievement: {
                  ...moldingReport.working_achievement,
                  product_number: productList[index]['product_number'],
                  product_name: productList[index]['product_name'],
                },
              })
            }
            flexGrow={1}
            disabled={isLoadingAction}
          />
        ) : (
          <PrimaryInput
            value={moldingReport.working_achievement.product_number}
            onChange={(e) =>
              setMoldingReport({
                ...moldingReport,
                working_achievement: {
                  ...moldingReport.working_achievement,
                  product_number: e.target.value,
                },
              })
            }
            flexGrow={1}
            disabled={isLoadingAction}
          />
        )}
      </Flex>
      <Flex
        h="50px"
        align="center"
        borderTop="1px"
        borderLeft="1px"
        borderRight="1px"
      >
        <Text w="200px" h="50px" lineHeight="50px" borderRight="1px">
          品名
        </Text>
        {moldingReport.working_achievement.is_mass ? (
          <Text w="400px" h="50px" lineHeight="50px">
            {moldingReport.working_achievement.product_name}
          </Text>
        ) : (
          <PrimaryInput
            value={moldingReport.working_achievement.product_name}
            onChange={(e) =>
              setMoldingReport({
                ...moldingReport,
                working_achievement: {
                  ...moldingReport.working_achievement,
                  product_name: e.target.value,
                },
              })
            }
            flexGrow={1}
            disabled={isLoadingAction}
          />
        )}
      </Flex>
      <Flex
        h="50px"
        align="center"
        borderTop="1px"
        borderLeft="1px"
        borderRight="1px"
      >
        <Text w="200px" h="50px" lineHeight="50px" borderRight="1px">
          取数
        </Text>
        <PrimaryNumberInput
          value={moldingReport.working_achievement.take_count}
          minValue={0}
          maxValue={100000}
          onChange={(num) =>
            setMoldingReport({
              ...moldingReport,
              working_achievement: {
                ...moldingReport.working_achievement,
                take_count: num,
              },
            })
          }
          flexGrow={1}
          disabled={isLoadingAction}
        />
      </Flex>
      <Flex align="center" borderTop="1px" borderLeft="1px" borderRight="1px">
        <Text w="30px">材料</Text>
        <Box flexGrow={1}>
          <Flex h="50px" align="center">
            <Text
              w="170px"
              h="50px"
              lineHeight="50px"
              borderLeft="1px"
              borderRight="1px"
            >
              材料名
            </Text>
            {moldingReport.working_achievement.is_mass ? (
              <PrimarySelectBox
                options={masterList.molding_material_info}
                defaultOption={moldingReport.working_achievement.material_name}
                optionElement="material_name"
                onChangeSelect={(index) =>
                  setMoldingReport({
                    ...moldingReport,
                    working_achievement: {
                      ...moldingReport.working_achievement,
                      material_name:
                        masterList.molding_material_info[index][
                          'material_name'
                        ],
                      grade: masterList.molding_material_info[index]['grade'],
                      tone: masterList.molding_material_info[index]['tone'],
                    },
                  })
                }
                flexGrow={1}
                disabled={isLoadingAction}
              />
            ) : (
              <PrimaryInput
                value={moldingReport.working_achievement.material_name}
                onChange={(e) =>
                  setMoldingReport({
                    ...moldingReport,
                    working_achievement: {
                      ...moldingReport.working_achievement,
                      material_name: e.target.value,
                    },
                  })
                }
                flexGrow={1}
                disabled={isLoadingAction}
              />
            )}
          </Flex>
          <Flex h="50px" align="center" borderTop="1px">
            <Text
              w="170px"
              h="50px"
              lineHeight="50px"
              borderLeft="1px"
              borderRight="1px"
            >
              グレード
            </Text>
            {/* {moldingReport.working_achievement.is_mass ? (
              <PrimarySelectBox
                options={masterList.molding_material_info}
                defaultOption={moldingReport.working_achievement.grade}
                optionElement="product_number"
                onChangeSelect={(index) =>
                  setMoldingReport({
                    ...moldingReport,
                    working_achievement: {
                      ...moldingReport.working_achievement,
                      grade: productList[index]['product_number'],
                    },
                  })
                }
                flexGrow={1}
                disabled={isLoadingAction}
              />
            ) : ( */}
            <PrimaryInput
              value={moldingReport.working_achievement.grade}
              onChange={(e) =>
                setMoldingReport({
                  ...moldingReport,
                  working_achievement: {
                    ...moldingReport.working_achievement,
                    grade: e.target.value,
                  },
                })
              }
              flexGrow={1}
              disabled={
                moldingReport.working_achievement.is_mass || isLoadingAction
              }
            />
            {/* )} */}
          </Flex>
          <Flex h="50px" align="center" borderTop="1px">
            <Text
              w="170px"
              h="50px"
              lineHeight="50px"
              borderLeft="1px"
              borderRight="1px"
            >
              色調
            </Text>
            {/* {moldingReport.working_achievement.is_mass ? (
              <PrimarySelectBox
                options={productList}
                defaultOption={moldingReport.working_achievement.tone}
                optionElement="product_number"
                onChangeSelect={(index) =>
                  setMoldingReport({
                    ...moldingReport,
                    working_achievement: {
                      ...moldingReport.working_achievement,
                      tone: productList[index]['product_number'],
                    },
                  })
                }
                flexGrow={1}
                disabled={isLoadingAction}
              />
            ) : ( */}
            <PrimaryInput
              value={moldingReport.working_achievement.tone}
              onChange={(e) =>
                setMoldingReport({
                  ...moldingReport,
                  working_achievement: {
                    ...moldingReport.working_achievement,
                    tone: e.target.value,
                  },
                })
              }
              flexGrow={1}
              disabled={
                moldingReport.working_achievement.is_mass || isLoadingAction
              }
            />
            {/* )} */}
          </Flex>
          {moldingReport.working_achievement.material_lot_num.map(
            (elm, num) => {
              return (
                <Flex h="50px" align="center" borderTop="1px" key={num}>
                  <Text
                    w="170px"
                    h="50px"
                    lineHeight="50px"
                    borderLeft="1px"
                    borderRight="1px"
                  >
                    {num + 1}:ロットNo.
                  </Text>
                  {moldingReport.working_achievement.is_mass ? (
                  <PrimarySelectBox
                    options={productList}
                    defaultOption={elm.lot_num}
                    optionElement="product_number"
                    onChangeSelect={(index) =>
                      setMoldingReport({
                        ...moldingReport,
                        working_achievement: {
                          ...moldingReport.working_achievement,
                          material_lot_num:
                            moldingReport.working_achievement.material_lot_num.map(
                              (info, number) =>
                                num == number
                                  ? {
                                      ...info,
                                      lot_num:
                                        productList[index]['product_number'],
                                    }
                                  : info
                            ),
                        },
                      })
                    }
                    flexGrow={1}
                    disabled={isLoadingAction}
                  />
                  ) : (
                    <PrimaryInput
                      value={elm.lot_num}
                      onChange={(e) =>
                        setMoldingReport({
                          ...moldingReport,
                          working_achievement: {
                            ...moldingReport.working_achievement,
                            material_lot_num:
                              moldingReport.working_achievement.material_lot_num.map(
                                (info, number) =>
                                  num == number
                                    ? {
                                        ...info,
                                        lot_num: e.target.value,
                                      }
                                    : info
                              ),
                          },
                        })
                      }
                      flexGrow={1}
                      disabled={isLoadingAction}
                    />
                  )}
                  {moldingReport.working_achievement.material_lot_num.length ==
                  num + 1 ? (
                    <IconButton
                      aria-label="追加"
                      disabled={isLoadingAction}
                      onClick={() =>
                        setMoldingReport({
                          ...moldingReport,
                          working_achievement: {
                            ...moldingReport.working_achievement,
                            material_lot_num: [
                              ...moldingReport.working_achievement
                                .material_lot_num,
                              { lot_num: '' },
                            ],
                          },
                        })
                      }
                      icon={<AiOutlinePlus />}
                    />
                  ) : (
                    <IconButton
                      aria-label="削除"
                      disabled={isLoadingAction}
                      onClick={() => {
                        setMoldingReport({
                          ...moldingReport,
                          working_achievement: {
                            ...moldingReport.working_achievement,
                            material_lot_num:
                              moldingReport.working_achievement.material_lot_num.filter(
                                (_, number) => num !== number
                              ),
                          },
                        })
                      }}
                      icon={<AiOutlineDelete />}
                    />
                  )}
                </Flex>
              )
            }
          )}
        </Box>
      </Flex>
      <Flex
        h="50px"
        align="center"
        borderTop="1px"
        borderLeft="1px"
        borderRight="1px"
      >
        <Text w="200px" h="50px" lineHeight="50px" borderRight="1px">
          立上調整数
        </Text>
        <PrimaryNumberInput
          value={moldingReport.working_achievement.adjustment_number}
          minValue={0}
          maxValue={100000}
          onChange={(num) =>
            setMoldingReport({
              ...moldingReport,
              working_achievement: {
                ...moldingReport.working_achievement,
                adjustment_number: num,
              },
            })
          }
          flexGrow={1}
          disabled={isLoadingAction}
        />
      </Flex>
      <Flex
        h="50px"
        align="center"
        borderTop="1px"
        borderLeft="1px"
        borderRight="1px"
      >
        <Text
          w="200px"
          minWidth="200px"
          h="50px"
          lineHeight="50px"
          borderRight="1px"
        >
          良品数(昼・夜)
        </Text>
        <Box borderRight="1px" py={1}>
          <PrimaryNumberInput
            value={
              moldingReport.working_achievement.count.day.non_defective_count
            }
            label="昼"
            minValue={0}
            maxValue={100000}
            onChange={(num) =>
              setMoldingReport({
                ...moldingReport,
                working_achievement: {
                  ...moldingReport.working_achievement,
                  count: {
                    ...moldingReport.working_achievement.count,
                    day: {
                      ...moldingReport.working_achievement.count.day,
                      non_defective_count: num,
                    },
                  },
                },
              })
            }
            flexGrow={1}
            disabled={isLoadingAction}
          />
        </Box>
        <PrimaryNumberInput
          value={
            moldingReport.working_achievement.count.night.non_defective_count
          }
          label="夜"
          minValue={0}
          maxValue={100000}
          onChange={(num) =>
            setMoldingReport({
              ...moldingReport,
              working_achievement: {
                ...moldingReport.working_achievement,
                count: {
                  ...moldingReport.working_achievement.count,
                  night: {
                    ...moldingReport.working_achievement.count.night,
                    non_defective_count: num,
                  },
                },
              },
            })
          }
          flexGrow={1}
          disabled={isLoadingAction}
        />
      </Flex>
      <Flex
        h="50px"
        align="center"
        borderTop="1px"
        borderLeft="1px"
        borderRight="1px"
      >
        <Text
          w="200px"
          minWidth="200px"
          h="50px"
          lineHeight="50px"
          borderRight="1px"
        >
          不良数合計
        </Text>
        <Box borderRight="1px" py={1}>
          <PrimaryNumberInput
            value={moldingReport.working_achievement.count.day.defective_count}
            label="昼"
            minValue={0}
            maxValue={100000}
            onChange={(num) =>
              setMoldingReport({
                ...moldingReport,
                working_achievement: {
                  ...moldingReport.working_achievement,
                  count: {
                    ...moldingReport.working_achievement.count,
                    day: {
                      ...moldingReport.working_achievement.count.day,
                      defective_count: num,
                    },
                  },
                },
              })
            }
            flexGrow={1}
            disabled={isLoadingAction}
          />
        </Box>
        <PrimaryNumberInput
          value={moldingReport.working_achievement.count.night.defective_count}
          label="夜"
          minValue={0}
          maxValue={100000}
          onChange={(num) =>
            setMoldingReport({
              ...moldingReport,
              working_achievement: {
                ...moldingReport.working_achievement,
                count: {
                  ...moldingReport.working_achievement.count,
                  night: {
                    ...moldingReport.working_achievement.count.night,
                    defective_count: num,
                  },
                },
              },
            })
          }
          flexGrow={1}
          disabled={isLoadingAction}
        />
      </Flex>
      <Flex
        h="50px"
        align="center"
        borderTop="1px"
        borderLeft="1px"
        borderRight="1px"
      >
        <Text w="200px" h="50px" lineHeight="50px" borderRight="1px">
          不良率
        </Text>
        <Text w="200px" h="50px" lineHeight="50px" borderRight="1px">
          {moldingReport.working_achievement.count.day.non_defective_count
            ? Math.round(
                (Number(
                  moldingReport.working_achievement.count.day.defective_count
                ) /
                  (Number(
                    moldingReport.working_achievement.count.day
                      .non_defective_count
                  ) +
                    Number(
                      moldingReport.working_achievement.count.day
                        .defective_count
                    ))) *
                  10000
              ) / 100
            : 0}
          %
        </Text>
        <Text w="198px" h="50px" lineHeight="50px">
          {moldingReport.working_achievement.count.night.non_defective_count
            ? Math.round(
                (Number(
                  moldingReport.working_achievement.count.night.defective_count
                ) /
                  (Number(
                    moldingReport.working_achievement.count.night
                      .non_defective_count
                  ) +
                    Number(
                      moldingReport.working_achievement.count.night
                        .defective_count
                    ))) *
                  10000
              ) / 100
            : 0}
          %
        </Text>
      </Flex>
      <Flex h="50px" align="center" border="1px">
        <Text w="200px" h="50px" lineHeight="50px" borderRight="1px">
          良品数合計
        </Text>
        <Text w="200px" h="50px" lineHeight="50px" borderRight="1px">
          {Number(
            moldingReport.working_achievement.count.day.non_defective_count
          ) +
            Number(
              moldingReport.working_achievement.count.night.non_defective_count
            )}
        </Text>
        <Flex align="center" flexGrow={1}>
          <Text w="30px" h="50px" borderRight="1px">
            確認
          </Text>
          <PrimarySelectBox
            options={masterList.users}
            defaultOption={moldingReport.working_achievement.confirmed_by}
            optionElement="name"
            onChangeSelect={(index) => {
              setMoldingReport({
                ...moldingReport,
                working_achievement: {
                  ...moldingReport.working_achievement,
                  confirmed_by: masterList.users[index]['name'],
                },
              })
            }}
            flexGrow={1}
            disabled={isLoadingAction}
          />
        </Flex>
      </Flex>
    </Box>
  )
}
