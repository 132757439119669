import React from 'react'
import {
  Grid,
  GridItem,
  Stack,
  Flex,
  StackDivider,
  Text,
  Box,
} from '@chakra-ui/react'
import { PrimaryInput } from '../../atoms/PrimaryInput'
import { useMessage } from '../../../hooks/useMessage'
import { PrimaryNumberInput } from '../../atoms/PrimaryNumberInput'

export const CommonInfoLotPrototype3 = (props) => {
  const {
    isCreate,
    lotIndex,
    projectId,
    clientName,
    assigner,
    projectName,
    dueDate,
    registeredDate,
    count,
    valueProductName,
    valueProductNum,
    valueLotAmount,
    valueCreatedAmount,
    valueSettingChangeNum,
    onChangeProductName,
    onChangeProductNum,
    onChangeLotAmount,
    onChangeCreatedAmount,
    onChangeSettingChangeNum,
    isDisabled,
  } = props
  const { message } = useMessage()

  return (
    <>
      <Stack divider={<StackDivider borderColor="gray.700" />} spacing={2}>
        <Grid
          m={4}
          h={160}
          templateRows="repeat(6, 1fr)"
          templateColumns="repeat(12, 1fr)"
          gap={2}
        >
          <GridItem rowSpan={6} colSpan={3} align="right">
            <Stack spacing={2}>
              <Flex justify="space-between" align="center">
                <Text>案件番号</Text>
                <Text opacity={isDisabled && 0.3}>{projectId}</Text>
              </Flex>
              <Flex justify="space-between" align="center">
                <Text>顧客名</Text>
                <Text opacity={isDisabled && 0.3}>{clientName}</Text>
              </Flex>
              <Flex justify="space-between" align="center">
                <Text>担当</Text>
                <Text opacity={isDisabled && 0.3}>{assigner}</Text>
              </Flex>
              <Flex justify="space-between" align="center">
                <Text>案件名</Text>
                <Text opacity={isDisabled && 0.3}>{projectName}</Text>
              </Flex>
              <Flex justify="space-between" align="center">
                <Text mt={2}>納期</Text>
                <Text opacity={isDisabled && 0.3}>{dueDate}</Text>
              </Flex>
            </Stack>
          </GridItem>
          <GridItem rowSpan={6} colStart={5} colEnd={8} align="center">
            <Stack spacing={2}>
              <Flex justify="space-between" align="center">
                <Text>見積日</Text>
                <Text opacity={isDisabled && 0.3}>{registeredDate}</Text>
              </Flex>
              <Flex justify="space-between" align="center">
                <Text>数量</Text>
                <Text opacity={isDisabled && 0.3}>{count}</Text>
              </Flex>
            </Stack>
          </GridItem>
        </Grid>

        <Grid
          m={4}
          h={120}
          templateRows="repeat(5, 1fr)"
          templateColumns="repeat(12, 1fr)"
          gap={2}
        >
          <GridItem rowSpan={3} colSpan={3} align="right">
            <Stack spacing={2}>
              {/* <Flex
                justify="space-between"
                align="center"
                visibility={isCreate && 'hidden'}
              >
                <Text>指示書番号</Text>
                <Text>{lotIndex}</Text>
              </Flex> */}
              <Flex justify="space-between" align="center">
                <Text>
                  部品番号
                  <Box as="span" color="red">
                    *
                  </Box>
                </Text>
                <PrimaryInput
                  placeholder=""
                  value={valueProductNum}
                  onChange={(e) => onChangeProductNum(e)}
                  htmlSize={20}
                  disabled={isDisabled}
                />
              </Flex>
              <Flex justify="space-between" align="center">
                <Text>
                  発注総数
                  <Box as="span" color="red">
                    *
                  </Box>
                </Text>
                <PrimaryNumberInput
                  value={valueLotAmount}
                  minValue={0}
                  maxValue={100000}
                  onChange={(num) => onChangeLotAmount(num)}
                  width="210px"
                  disabled={isDisabled}
                />
              </Flex>
            </Stack>
          </GridItem>
          <GridItem rowSpan={1} colStart={5} colEnd={8} align="center" />
          <GridItem rowSpan={2} colStart={5} colEnd={8} align="center">
            <Stack spacing={2}>
              <Flex justify="space-between" align="center" mt={1}>
                <Text>
                  部品名
                  <Box as="span" color="red">
                    *
                  </Box>
                </Text>
                <PrimaryInput
                  placeholder=""
                  value={valueProductName}
                  onChange={(e) => onChangeProductName(e)}
                  htmlSize={20}
                  disabled={isDisabled}
                />
              </Flex>
              <Flex justify="space-between" align="center">
                <Text>
                  ① 加工数
                  <Box as="span" color="red">
                    *
                  </Box>
                </Text>
                <PrimaryNumberInput
                  value={valueCreatedAmount}
                  minValue={0}
                  maxValue={100000}
                  onChange={(num) => onChangeCreatedAmount(num)}
                  width="210px"
                  disabled={isDisabled}
                />
              </Flex>
            </Stack>
          </GridItem>
          <GridItem rowSpan={2} colStart={9} colEnd={12} align="center">
            <Stack spacing={2}>
              <Flex justify="space-between" align="center">
                <Text>設変No.</Text>
                <PrimaryNumberInput
                  value={valueSettingChangeNum}
                  minValue={0}
                  maxValue={100000}
                  onChange={(num) => onChangeSettingChangeNum(num)}
                  width="210px"
                  disabled={isDisabled}
                />
              </Flex>
            </Stack>
          </GridItem>
        </Grid>
      </Stack>
    </>
  )
}
