import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  IconButton,
  Input,
  InputGroup,
  InputLeftAddon,
  Select,
  TableContainer,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useMessage } from '../../../hooks/useMessage'
import { useGetData } from '../../../hooks/useGetData'
import { useCurrentUser2 } from '../../../hooks/users/useCurrentUser2'
import { PrimaryInput } from '../../atoms/PrimaryInput'
import { PrimaryNumberInput } from '../../atoms/PrimaryNumberInput'
import { PrimarySelectBox } from '../../atoms/PrimarySelectBox'
import axios from 'axios'
import { massProjectList, createMassProject, targetMassProjectList } from '../../../urls'
import { BeatLoader } from 'react-spinners'
import { AiOutlinePlus } from 'react-icons/ai'
import { InputAddonText } from '../../atoms/InputAddonText'
import { AlertModal } from '../../organisms/AlertModal'
import { InputAddonNumber } from '../../atoms/InputAddonNumber'
import { isDisabled } from '@testing-library/user-event/dist/utils'

export const MassProduce02 = () => {
  const location = useLocation()
  const { message } = useMessage()
  const user = useCurrentUser2()
  const { getProducts, productList, getMasters, masterList, isLoading, getTargetProjectMass, projectMass, setProjectMass } =
    // const { getProducts, productList, getMasters, isLoading, getTargetProjectMass, projectMass, setProjectMass } =
    useGetData()
  const today = new Date()

  useEffect(() => {
    const getData = async () => {
      if (location.state == null) {
        setIsCreate(true)
      } else {
        const project = await axios.get(targetMassProjectList(location.state.projectId))
        setMassProduceProduct(project.data)
        await getTargetProjectMass(location.state.projectId)

        project.data.estimate_plans && project.data.estimate_plans[0].products.forEach(() => {
          setMaterialDetailList([...materialDetailList, materialDetailObj])
        })
        // console.log(location.state.projectId)
        // getTargetProjectMass(location.state.projectId)
        // // console.log('projectMass:', projectMass)
        // setMassProduceProduct(projectMass)
      }
      // await getTargetProjectMass(location.state.projectId)
      // getMasters()
      await getMasters()
    }
    getData()
  }, [])

  // useEffect(() => {

  // })

  ///////////////////////////
  // 初期値を設定
  ///////////////////////////
  // 材料詳細情報
  const materialDetailInfo = []

  const materialDetailObj = {
    id: 0,
    category: '', // カテゴリ
    name: '', // 名称
    input: 0, // 投入DS
    unit_cost: 0, // 単価
    take_count: 0, // 取り数
    profit_rate: 0, // 利益
    // is_delete: false,
  }

  const [materialDetailList, setMaterialDetailList] = useState([materialDetailObj])

  // 構成部品情報
  const componentInfo = {
    id: 0,
    product_number: '', // 部番
    product_name: '', // 品名
    product_price: 0, // 単価
    amount: 0, // 数量
    // is_delete: false,
  }

  // 塗装詳細
  const paintingElementInfo = {
    processes: [
      {
        id: 0,
        process: '主剤',
        time: 0,
        wage_rate: 0
      },
      {
        id: 0,
        process: '硬化剤',
        time: 0,
        wage_rate: 0
      },
      {
        id: 0,
        process: 'シンナー',
        time: 0,
        wage_rate: 0
      },
      {
        id: 0,
        process: '治具付け',
        time: 0,
        wage_rate: 0.67
      },
      {
        id: 0,
        process: 'エアブロー',
        time: 0,
        wage_rate: 0.67
      },
      {
        id: 0,
        process: 'ワイピング',
        time: 0,
        wage_rate: 0.67
      },
      {
        id: 0,
        process: '塗装',
        time: 0,
        wage_rate: 2.00
      },
      {
        id: 0,
        process: 'ベーク',
        time: 0,
        wage_rate: 0.005
      },
      {
        id: 0,
        process: '治具外し',
        time: 0,
        wage_rate: 0.67
      },
      {
        id: 0,
        process: '検査',
        time: 0,
        wage_rate: 0.67
      },
    ],
    paint_cost: 0,
    defective_rate: 0
  }

  // 加工詳細
  const annealObj = {
    id: 0,
    category: 'アニール', // カテゴリ
    cost: 0, // コスト
    furnaces_amount: 0,
    anneal_count: 0
  }

  const plating = {
    id: 0,
    category: 'メッキ',
    cost: 0,
    cost_price: 0
  }

  const padPrinting = {
    id: 0,
    category: 'パッド印刷',
    processes: [
      {
        id: 0,
        process: 'エアーブロー',
        time: 0,
        wage_rate: 0.67
      },
      {
        id: 0,
        process: '治具付け・セッティング01',
        time: 0,
        wage_rate: 0.75
      },
      {
        id: 0,
        process: '印刷01',
        time: 0,
        wage_rate: 0.75
      },
      {
        id: 0,
        process: '印刷02',
        time: 0,
        wage_rate: 0.75
      },
      {
        id: 0,
        process: '治具外し01',
        time: 0,
        wage_rate: 0.75
      },
      {
        id: 0,
        process: '検査',
        time: 0,
        wage_rate: 0.67
      },
      {
        id: 0,
        process: '治具付け・セッティング02',
        time: 0,
        wage_rate: 0.75
      },
      {
        id: 0,
        process: '印刷03',
        time: 0,
        wage_rate: 0.75
      },
      {
        id: 0,
        process: '治具外し02',
        time: 0,
        wage_rate: 0.75
      },
      {
        id: 0,
        process: '検査02',
        time: 0,
        wage_rate: 0.67
      },
    ],
    "process": 0,
    "cost": 0,
    "defective_rate01": 0,
    "defective_rate02": 0,
    "arrangement": 0
  }

  const painting = {
    id: 0,
    category: '塗装',
    elements: [paintingElementInfo],
    "name": '',
    "cost": 0,
    "take_count": 0,
    "arrangement": 0
  }

  const laser = {
    id: 0,
    category: 'レーザー',
    processes: [
      {
        id: 0,
        process: 'エアブロー',
        time: 0,
        wage_rate: 0.67
      },
      {
        id: 0,
        process: '治具付け・セッティング',
        time: 0,
        wage_rate: 0.67
      },
      {
        id: 0,
        process: 'レーザー',
        time: 0,
        wage_rate: 0.75
      },
      {
        id: 0,
        process: '治具外し',
        time: 0,
        wage_rate: 0.67
      },
      {
        id: 0,
        process: '検査',
        time: 0,
        wage_rate: 0.67
      },
    ],
    cost: 0,
    defective_rate: 0,
    arrangement: 0
  }

  const silkPrinting = {
    id: 0,
    category: 'シルク印刷',
    process_count: 0,
    cost: 0,
    process: 0,
    wage_rate: 0,
    imposition: 0,
    defective_rate: 0
  }

  const filmPress = {
    id: 0,
    category: 'プレス（FILM）',
    cost: 0,
    film_count: 0,
    press_count: 0,
    wage_rate: 0
  }

  const doubleSidedTapePress = {
    id: 0,
    category: 'プレス（両面テープ）',
    cost: 0,
    film_count: 0,
    press_count: 0,
    wage_rate: 0
  }

  const laserWrapping = {
    id: 0,
    category: '革巻き/組立',
    cost: 0,
    time: 0
  }

  const pastingDoubleSidedTape = {
    id: 0,
    category: '両面テープ貼り',
    cost: 0,
    time: 0
  }

  const pastingProtectiveSheet = {
    id: 0,
    category: '保護シート/不織布貼り',
    cost: 0,
    time: 0
  }

  const inspection = {
    id: 0,
    category: '検査',
    cost: 0,
    time: 0
  }


  // 加工詳細情報
  const processingDetailInfo = []

  const processingDetailObj = {
    id: 0,
    category: '',
    // is_delete: false,
  }

  const [processingDetailList, setProcessingDetailList] = useState([processingDetailObj])

  const initialProcessingDetailInfo = {
    category: '',
  }

  // 型・治工具
  const investmentInfo = {
    id: 0,
    category: '',
    count: 0,
    cost: 0,
    // is_delete: false,
  }

  // 部品
  const tempProduct =
  {
    // 部品基本情報
    basic_info: {
      setting_amount: 0, // 設定台数
      product_number: '', // 部番
      product_name: '', // 品名
      design_change_number: '', // 設変番号
    },
    // 材料基本情報
    material_basic: {
      material: '',
      name: '',
      color: '',
      completed_weight: 0,
      preparing_weight: 0,
      unit_price: 0,
      cost_price: 0,
    },
    // 材料詳細情報
    material_detail: materialDetailInfo,
    // 構成部品
    components: [componentInfo],
    // 加工基本情報
    processing_basic: {
      weight: 0, // 成形トン数
      cycle: 0, // 成形サイクル
      wage_rate: 0, // 賃率
      take_count: 0, // 型取数
      cost: 0, // 成形加工費
      defective_rate: 0, // 成形不良率
      defective_cost: 0, // 成形不良分
    },
    // 加工詳細情報
    processing_detail: processingDetailInfo,
    // processing_detail: [],
    // 型・治工具
    investments: [investmentInfo]
  }

  // 見積もりタイプ
  const tempEstimateList = {
    id: 0,
    estimate_plan_name: '', //見積もりタイプ名
    products: [tempProduct],
    // is_delete: false,
  }

  // 量産案件
  const tempProject = {
    project_id: 'PJ00000',
    client_name: '',
    assigner: '',
    project_name: '',
    registered_date: null,
    update_date: today,
    estimate_plans: [tempEstimateList],
    // is_delete: false,
  }

  // const masterList = {
  //   "users": [
  //     { name: "新田真剣佑" },
  //     { name: "佐藤健" },
  //     { name: "菅田将暉" },
  //     { name: "吉沢亮" },
  //     { name: "山瀬陽志" },
  //     { name: "福山雅治" },
  //     { name: "高橋一生" },
  //     { name: "山崎賢人" },
  //     { name: "横浜流星" }
  //   ],
  //   clients: [
  //     { client_name: "A技研" },
  //     { client_name: "B工業" },
  //     { client_name: "C塗装" },
  //     { client_name: "D工場" }
  //   ]
  // }


  ///////////////////////////
  // useStateの設定
  ///////////////////////////
  const [isCreate, setIsCreate] = useState(false)
  const [isLoadingAction, setIsLoadingAction] = useState(false)
  const [massProduceProduct, setMassProduceProduct] = useState(tempProject)
  const [addTempProduct, setAddTempProduct] = useState(tempProduct)
  const [addMaterialInfo, setAddMaterialInfo] = useState(materialDetailInfo)
  const [addComponentInfo, setAddComponentInfo] = useState(componentInfo)
  const [addElementInfo, setAddElementInfo] = useState(paintingElementInfo)
  const [addProcessingInfo, setAddProcessingInfo] = useState(initialProcessingDetailInfo)
  const [addInvestmentInfo, setAddInvestmentInfo] = useState(investmentInfo)



  ///////////////////////////
  // 通信
  ///////////////////////////
  const postParams = {
    method: isCreate ? 'POST' : 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(massProduceProduct),
  }

  const handleCreateProject = () => {
    // console.log(location.state.project)
    console.log(postParams.body, "postParams")
    // console.log(product)
    setIsLoadingAction(true)
    fetch(createMassProject, postParams)
      .then((res) => res.json())
      .then((myjson) => {
        message({ title: '案件の新規追加に成功しました。', status: 'success' })
        setIsCreate(true)
        // setIsUpdate(true)
        console.log(myjson)
      })
      .catch(() => {
        message({ title: '通信エラーが発生しました', status: 'warning' })
      })
      .finally(() => {
        setIsLoadingAction(false)
      })
  }

  const handleUpdateProject = () => {
    setIsLoadingAction(true)
    fetch(targetMassProjectList(massProduceProduct.project_id), postParams)
      .then((res) => res.json())
      .then((myjson) => {
        setMassProduceProduct({
          ...massProduceProduct,
          myjson,
        })
        message({ title: '案件の更新に成功しました。', status: 'success' })
      })
      .catch(() =>
        message({ title: '通信エラーが発生しました', status: 'warning' })
      )
      .finally(() => setIsLoadingAction(false))
  }



  ///////////////////////////
  // イベントの設定
  ///////////////////////////

  /***************************
  ** 案件基本情報
  ***************************/
  const onChangeProjectString = (e, property) => {
    setMassProduceProduct({
      ...massProduceProduct,
      [property]: e.target.value,
    })
  }

  const onClickAddProduct = (estimateIndex) => {
    setMassProduceProduct({
      ...massProduceProduct,
      estimate_plans: massProduceProduct.estimate_plans.map((estimate, estimateNum) =>
        estimateIndex === estimateNum
          ? {
            ...estimate,
            products: [
              ...estimate.products,
              tempProduct
            ],
          }
          : estimate
      ),
    });

    setMaterialDetailList([...materialDetailList, materialDetailObj]);
    setProcessingDetailList([...processingDetailList, processingDetailObj]);
  };

  /***************************
  ** 見積プラン
  ***************************/
  const onChangeEstimate = (e, estimateIndex) => {
    setMassProduceProduct({
      ...massProduceProduct,
      estimate_plans: massProduceProduct.estimate_plans.map((estimate, estimateNum) => {
        return estimateIndex === estimateNum ? (
          {
            ...estimate,
            estimate_plan_name: e.target.value,
          }
        ) : estimate
      })
    })
  }

  const onClickAddEstimate = () => {
    setMassProduceProduct({
      ...massProduceProduct,
      estimate_plans: [
        ...massProduceProduct.estimate_plans,
        tempEstimateList
      ]
    });

    setMaterialDetailList([...materialDetailList, materialDetailObj]);
    setProcessingDetailList([...processingDetailList, processingDetailObj]);
  };

  /***************************
  ** 部品基本情報
  ***************************/
  const onChangeBasicInfoString = (e, estimateIndex, productIndex, property) => {
    setMassProduceProduct({
      ...massProduceProduct,
      estimate_plans: massProduceProduct.estimate_plans.map((estimate, estimateNum) => {
        return estimateIndex === estimateNum ? (
          {
            ...estimate,
            products: estimate.products.map((product, productNum) => {
              return productIndex === productNum ? (
                {
                  ...product,
                  basic_info: {
                    ...product.basic_info,
                    [property]: e.target.value,
                  }
                }
              ) : product
            })
          }
        ) : estimate
      })
    })
  }

  const onChangeBasicInfoNum = (num, estimateIndex, productIndex, property) => {
    setMassProduceProduct({
      ...massProduceProduct,
      estimate_plans: massProduceProduct.estimate_plans.map((estimate, estimateNum) => {
        return estimateIndex === estimateNum ? (
          {
            ...estimate,
            products: estimate.products.map((product, productNum) => {
              return productIndex === productNum ? (
                {
                  ...product,
                  basic_info: {
                    ...product.basic_info,
                    [property]: num,
                  }
                }
              ) : product
            })
          }
        ) : estimate
      })
    })
  }

  /***************************
  ** 材料基本情報
  ***************************/
  const onChangeMaterialBasicString = (e, estimateIndex, productIndex, property) => {
    { console.log('e, estimateIndex, productIndex:', e, estimateIndex, productIndex) }
    setMassProduceProduct({
      ...massProduceProduct,
      estimate_plans: massProduceProduct.estimate_plans.map((estimate, estimateNum) => {
        return estimateIndex === estimateNum ? (
          {
            ...estimate,
            products: estimate.products.map((product, productNum) => {
              return productIndex === productNum ? (
                {
                  ...product,
                  material_basic: {
                    ...product.material_basic,
                    [property]: e.target.value,
                  }
                }
              ) : product
            })
          }
        ) : estimate
      })
    })
  }

  const onChangeMaterialBasicNum = (num, estimateIndex, productIndex, property) => {
    setMassProduceProduct({
      ...massProduceProduct,
      estimate_plans: massProduceProduct.estimate_plans.map((estimate, estimateNum) => {
        return estimateIndex === estimateNum ? (
          {
            ...estimate,
            products: estimate.products.map((product, productNum) => {
              return productIndex === productNum ? (
                {
                  ...product,
                  material_basic: {
                    ...product.material_basic,
                    [property]: num,
                  }
                }
              ) : product
            })
          }
        ) : estimate
      })
    })
  }

  const recalculateMaterialCost = (estimateIndex, productIndex) => {
    const unitPrice = massProduceProduct.estimate_plans[estimateIndex].products[productIndex].material_basic.unit_price;
    const preparingWeight = massProduceProduct.estimate_plans[estimateIndex].products[productIndex].material_basic.preparing_weight;

    const material_cost = unitPrice * preparingWeight / 1000;
    // console.log(unitPrice)
    // console.log(preparingWeight)
    // console.log(material_cost)
    return material_cost
  }



  /***************************
  ** 材料詳細
  ***************************/
  const materialCategories = [
    { value: '', label: '' },
    { value: '革', label: '革' },
    { value: '接着剤', label: '接着剤' },
    { value: 'プライマー', label: 'プライマー' },
    { value: '糸', label: '糸' },
    { value: 'PCシート', label: 'PCシート' },
    { value: '両面テープ', label: '両面テープ' },
    { value: 'その他', label: 'その他' },
  ]

  const isDisabledInputList = ['接着剤', 'プライマー', '糸', 'PCシート', '両面テープ', 'その他']
  // const isDisabledUnitCostList = ['糸', 'その他']
  const isDisabledTakeCountList = ['革', '接着剤', 'プライマー', '糸', 'その他']
  const isDisabledProfitRateList = ['糸', 'その他']

  const onChangeMaterialDetailCategory = (e, productIndex) => {
    setMaterialDetailList(
      materialDetailList.map((elm, index) =>
        productIndex === index ? {
          ...elm,
          category: e.target.value
        }
          : elm
      )
    )
  }

  const onClickAddMaterialDetail = (estimateIndex, productIndex) => {
    setMassProduceProduct({
      ...massProduceProduct,
      estimate_plans: massProduceProduct.estimate_plans.map((estimate, estimateNum) => {
        return estimateIndex === estimateNum ? (
          {
            ...estimate,
            products: estimate.products.map((product, productNum) => {
              return productIndex === productNum ? (
                {
                  ...product,
                  material_detail: [
                    ...product.material_detail,
                    materialDetailList[productIndex]
                    // materialDetailList[0]
                  ]
                }
              ) : product
            })
          }
        ) : estimate
      })
    })
  }

  const onChangeMaterialDetailString = (e, estimateIndex, productIndex, materialIndex, property) => {
    setMassProduceProduct({
      ...massProduceProduct,
      estimate_plans: massProduceProduct.estimate_plans.map((estimate, estimateNum) => {
        return estimateIndex === estimateNum ? (
          {
            ...estimate,
            products: estimate.products.map((product, productNum) => {
              return productIndex === productNum ? (
                {
                  ...product,
                  material_detail: product.material_detail.map((material, materialNum) => {
                    return materialIndex === materialNum ? {
                      ...material,
                      [property]: e.target.value,
                    }
                      : material
                  }
                  )
                }
              ) : product
            })
          }
        ) : estimate
      })
    })
  }

  const onChangeMaterialDetailNum = (num, estimateIndex, productIndex, materialIndex, property) => {
    setMassProduceProduct({
      ...massProduceProduct,
      estimate_plans: massProduceProduct.estimate_plans.map((estimate, estimateNum) => {
        return estimateIndex === estimateNum ? (
          {
            ...estimate,
            products: estimate.products.map((product, productNum) => {
              return productIndex === productNum ? (
                {
                  ...product,
                  material_detail: product.material_detail.map((material, materialNum) => {
                    return materialIndex === materialNum ? {
                      ...material,
                      [property]: num,
                    }
                      : material
                  }
                  )
                }
              ) : product
            })
          }
        ) : estimate
      })
    })
  }

  const recalculateMaterialDetailSubtotal = (estimateIndex, productIndex, materialIndex, category) => {
    const input = massProduceProduct.estimate_plans[estimateIndex].products[productIndex].material_detail[materialIndex].input;
    const unitCost = massProduceProduct.estimate_plans[estimateIndex].products[productIndex].material_detail[materialIndex].unit_cost;
    const takeCount = massProduceProduct.estimate_plans[estimateIndex].products[productIndex].material_detail[materialIndex].take_count;
    const profitRate = massProduceProduct.estimate_plans[estimateIndex].products[productIndex].material_detail[materialIndex].profit_rate;

    let material_detail_subtotal = 0;

    category === '革' ? (
      material_detail_subtotal = input * unitCost * (1 + profitRate / 100)
    ) : category === '接着剤' || category === 'プライマー' ? (
      material_detail_subtotal = unitCost * (1 + profitRate / 100)
    ) : category === '糸' || category === 'その他' ? (
      material_detail_subtotal = unitCost
    ) : category === 'PCシート' || category === '両面テープ' ? (
      material_detail_subtotal = unitCost / takeCount * (1 + profitRate / 100)
    ) : null

    return material_detail_subtotal
  }

  const recalculateMaterialTotalAmount = (estimateIndex, productIndex) => {
    let totalAmount = 0;

    massProduceProduct.estimate_plans[estimateIndex].products[productIndex].material_detail.map((material, materialIndex) => {
      totalAmount += recalculateMaterialDetailSubtotal(
        estimateIndex,
        productIndex,
        materialIndex,
        material.category,
      );
    });

    return totalAmount
  }

  // const onClickAddMaterial = (estimateIndex, productIndex) => {
  //   setMassProduceProduct({
  //     ...massProduceProduct,
  //     estimate_plans: massProduceProduct.estimate_plans.map((estimate, estimateNum) => {
  //       return estimateIndex === estimateNum ? (
  //         {
  //           ...estimate,
  //           products: estimate.products.map((product, productNum) =>
  //             productIndex == productNum
  //               ? {
  //                 ...product,
  //                 material_detail: [
  //                   ...product.material_detail,
  //                   {
  //                     category: null,
  //                     name: null,
  //                     input: null,
  //                     unit_cost: 0,
  //                     take_count: 0,
  //                     profit_rate: 0,
  //                   },
  //                 ],
  //               }
  //               : product
  //           ),
  //         }
  //       ) : (
  //         { estimate }
  //       )
  //     })
  //   })
  // }



  /***************************
  ** 構成部品
  ***************************/
  const onChangeComponentsString = (e, estimateIndex, productIndex, componentIndex, property) => {
    setMassProduceProduct({
      ...massProduceProduct,
      estimate_plans: massProduceProduct.estimate_plans.map((estimate, estimateNum) => {
        return estimateIndex === estimateNum ? (
          {
            ...estimate,
            products: estimate.products.map((product, productNum) => {
              return productIndex === productNum ? (
                {
                  ...product,
                  components: product.components.map((component, componentNum) => {
                    return componentIndex === componentNum ? {
                      ...component,
                      [property]: e.target.value,
                    }
                      : component
                  }
                  )
                }
              ) : product
            })
          }
        ) : estimate
      })
    })
  }

  const onChangeComponentsNum = (num, estimateIndex, productIndex, componentIndex, property) => {
    setMassProduceProduct({
      ...massProduceProduct,
      estimate_plans: massProduceProduct.estimate_plans.map((estimate, estimateNum) => {
        return estimateIndex === estimateNum ? (
          {
            ...estimate,
            products: estimate.products.map((product, productNum) => {
              return productIndex === productNum ? (
                {
                  ...product,
                  components: product.components.map((component, componentNum) => {
                    return componentIndex === componentNum ? {
                      ...component,
                      [property]: num,
                    }
                      : component
                  }
                  )
                }
              ) : product
            })
          }
        ) : estimate
      })
    })
  }

  const recalculateComponentSubtotal = (estimateIndex, productIndex, componentIndex) => {
    const productPrice = massProduceProduct.estimate_plans[estimateIndex].products[productIndex].components[componentIndex].product_price;
    const amount = massProduceProduct.estimate_plans[estimateIndex].products[productIndex].components[componentIndex].amount;

    const component_subtotal = productPrice * amount;

    // console.log(massProduceProduct.estimate_plans[estimateIndex].products[productIndex])
    // console.log(productPrice)
    // console.log(amount)
    // console.log(component_subtotal)
    return component_subtotal
  }

  const recalculateComponentTotalAmount = (estimateIndex, productIndex) => {
    let totalAmount = 0;

    massProduceProduct.estimate_plans[estimateIndex].products[productIndex].components.map((component, componentIndex) => {
      totalAmount += component.product_price * component.amount;
    });

    return totalAmount
  }

  const onClickAddComponent = (estimateIndex, productIndex) => {
    setMassProduceProduct({
      ...massProduceProduct,
      estimate_plans: massProduceProduct.estimate_plans.map((estimate, estimateNum) => {
        return estimateIndex === estimateNum ? (
          {
            ...estimate,
            products: estimate.products.map((product, productNum) =>
              productIndex == productNum
                ? {
                  ...product,
                  components: [
                    ...product.components,
                    {
                      product_number: '',
                      product_name: '',
                      product_price: 0,
                      amount: 0
                    },
                  ],
                }
                : product
            ),
          }
        ) : (
          estimate
        )
      })
    })
  }



  /***************************
  ** 加工基本情報
  ***************************/
  const onChangeProcessingBasicNum = (num, estimateIndex, productIndex, property) => {
    setMassProduceProduct({
      ...massProduceProduct,
      estimate_plans: massProduceProduct.estimate_plans.map((estimate, estimateNum) => {
        return estimateIndex === estimateNum ? (
          {
            ...estimate,
            products: estimate.products.map((product, productNum) => {
              return productIndex === productNum ? (
                {
                  ...product,
                  processing_basic: {
                    ...product.processing_basic,
                    [property]: num,
                  }
                }
              ) : product
            })
          }
        ) : estimate
      })
    })
  }

  const recalculateProcessingCost = (estimateIndex, productIndex) => {
    const cycle = massProduceProduct.estimate_plans[estimateIndex].products[productIndex].processing_basic.cycle;
    const wageRate = massProduceProduct.estimate_plans[estimateIndex].products[productIndex].processing_basic.wage_rate;
    const takeCount = massProduceProduct.estimate_plans[estimateIndex].products[productIndex].processing_basic.take_count;

    const processingCost = cycle * wageRate / takeCount;

    // console.log(massProduceProduct.estimate_plans[estimateIndex].products[productIndex])
    // console.log(productPrice)
    // console.log(amount)
    // console.log(component_subtotal)
    return processingCost
  }

  const recalculateProcessingDefectiveCost = (estimateIndex, productIndex) => {
    const materialTotalAmount = recalculateMaterialTotalAmount(estimateIndex, productIndex);
    const processingCost = recalculateProcessingCost(estimateIndex, productIndex);
    const defectiveRate = massProduceProduct.estimate_plans[estimateIndex].products[productIndex].processing_basic.defective_rate;

    const defectiveCost = (materialTotalAmount + processingCost) * defectiveRate

    return defectiveCost
  }


  /***************************
  ** 加工情報詳細
  ***************************/
  const processingCategories = [
    { value: '', label: '' },
    { id: 0, value: 'アニール', label: 'アニール' },
    { id: 1, value: 'メッキ', label: 'メッキ' },
    { id: 2, value: 'パッド印刷', label: 'パッド印刷' },
    { id: 3, value: 'レーザー', label: 'レーザー' },
    { id: 4, value: 'シルク印刷', label: 'シルク印刷' },
    { id: 5, value: 'プレス（FILM）', label: 'プレス（FILM）' },
    { id: 6, value: 'プレス（両面テープ）', label: 'プレス（両面テープ）' },
    { id: 7, value: '塗装', label: '塗装' },
    { id: 8, value: '革巻き/組立', label: '革巻き/組立' },
    { id: 9, value: '両面テープ貼り', label: '両面テープ貼り' },
    { id: 10, value: '保護シート/不織布貼り', label: '保護シート/不織布貼り' },
    { id: 11, value: '検査', label: '検査' },
  ]

  const onChangeProcessingDetailCategory = (e, productIndex) => {
    setProcessingDetailList(
      processingDetailList.map((elm, index) =>
        productIndex === index ? {
          ...elm,
          category: e.target.value
        }
          : elm
      )
    )
  }

  const onClickAddProcessingDetail = (estimateIndex, productIndex, category) => {
    setMassProduceProduct({
      ...massProduceProduct,
      estimate_plans: massProduceProduct.estimate_plans.map((estimate, estimateNum) => {
        return estimateIndex === estimateNum ? (
          {
            ...estimate,
            products: estimate.products.map((product, productNum) => {
              return productIndex === productNum ? (
                {
                  ...product,
                  processing_detail: [
                    ...product.processing_detail,
                    category === 'アニール' ? (
                      annealObj
                    ) : category === 'メッキ' ? (
                      plating
                    ) : category === 'パッド印刷' ? (
                      padPrinting
                    ) : category === '塗装' ? (
                      painting
                    ) : category === 'レーザー' ? (
                      laser
                    ) : category === 'シルク印刷' ? (
                      silkPrinting
                    ) : category === 'プレス（FILM）' ? (
                      filmPress
                    ) : category === 'プレス（両面テープ）' ? (
                      doubleSidedTapePress
                    ) : category === '革巻き/組立' ? (
                      laserWrapping
                    ) : category === '両面テープ貼り' ? (
                      pastingDoubleSidedTape
                    ) : category === '保護シート/不織布貼り' ? (
                      pastingProtectiveSheet
                    ) : category === '検査' ? (
                      inspection
                    ) : null
                  ]
                }
              ) : product
            })
          }
        ) : estimate
      })
    })
  }

  const onClickAddElement = (estimateIndex, productIndex, processingIndex) => {
    // setAddTempProduct({
    //   ...addTempProduct,
    //   products: addTempProduct.products.map((product, productNum) => {
    //     return productIndex === productNum ? (
    //       {
    //         ...product,
    //         processing_detail: product.processing_detail.map((processing, processingNum) => {
    //           return processingIndex === processingNum ? {
    //             ...processing,
    //             elements: [
    //               ...processing.elements,
    //               paintingElementInfo
    //             ]
    //           }
    //             : processing
    //         }
    //         )
    //       }
    //     ) : product
    //   })
    // })
    setMassProduceProduct({
      ...massProduceProduct,
      estimate_plans: massProduceProduct.estimate_plans.map((estimate, estimateNum) => {
        return estimateIndex === estimateNum ? (
          {
            ...estimate,
            products: estimate.products.map((product, productNum) => {
              return productIndex === productNum ? (
                {
                  ...product,
                  processing_detail: product.processing_detail.map((processing, processingNum) => {
                    return processingIndex === processingNum ? {
                      ...processing,
                      elements: [
                        ...processing.elements,
                        paintingElementInfo
                      ]
                    }
                      : processing
                  }
                  )
                }
              ) : product
            })
          }
        ) : estimate
      })
    })
  }

  const onChangeProcessingDetailString = (e, estimateIndex, productIndex, processingIndex, property) => {
    setMassProduceProduct({
      ...massProduceProduct,
      estimate_plans: massProduceProduct.estimate_plans.map((estimate, estimateNum) => {
        return estimateIndex === estimateNum ? (
          {
            ...estimate,
            products: estimate.products.map((product, productNum) => {
              return productIndex === productNum ? (
                {
                  ...product,
                  processing_detail: product.processing_detail.map((processing, processingNum) => {
                    return processingIndex === processingNum ? {
                      ...processing,
                      [property]: e.target.value,
                    }
                      : processing
                  }
                  )
                }
              ) : product
            })
          }
        ) : estimate
      })
    })
  }

  const onChangeProcessingDetailNum = (num, estimateIndex, productIndex, processingIndex, property) => {
    setMassProduceProduct({
      ...massProduceProduct,
      estimate_plans: massProduceProduct.estimate_plans.map((estimate, estimateNum) => {
        return estimateIndex === estimateNum ? (
          {
            ...estimate,
            products: estimate.products.map((product, productNum) => {
              return productIndex === productNum ? (
                {
                  ...product,
                  processing_detail: product.processing_detail.map((processing, processingNum) => {
                    return processingIndex === processingNum ? {
                      ...processing,
                      [property]: num,
                    }
                      : processing
                  }
                  )
                }
              ) : product
            })
          }
        ) : estimate
      })
    })
  }

  const onChangeElementsInProcessingDetailNum = (num, estimateIndex, productIndex, processingIndex, elementIndex, property) => {
    setMassProduceProduct({
      ...massProduceProduct,
      estimate_plans: massProduceProduct.estimate_plans.map((estimate, estimateNum) => {
        return estimateIndex === estimateNum ? (
          {
            ...estimate,
            products: estimate.products.map((product, productNum) => {
              return productIndex === productNum ? (
                {
                  ...product,
                  processing_detail: product.processing_detail.map((processing, processingNum) => {
                    return processingIndex === processingNum ? {
                      ...processing,
                      elements: processing.elements.map((element, elementNum) => {
                        return elementIndex === elementNum ? {
                          ...element,
                          [property]: num,
                        }
                          : element
                      })
                    }
                      : processing
                  }
                  )
                }
              ) : product
            })
          }
        ) : estimate
      })
    })
  }

  const onChangeProcessesInProcessingDetailNum = (num, estimateIndex, productIndex, processingIndex, property, processName) => {
    setMassProduceProduct({
      ...massProduceProduct,
      estimate_plans: massProduceProduct.estimate_plans.map((estimate, estimateNum) => {
        return estimateIndex === estimateNum ? (
          {
            ...estimate,
            products: estimate.products.map((product, productNum) => {
              return productIndex === productNum ? (
                {
                  ...product,
                  processing_detail: product.processing_detail.map((processing, processingNum) => {
                    return processingIndex === processingNum ? {
                      ...processing,
                      processes: processing.processes.map((process, processNum) => {
                        return process.process === processName ? {
                          ...process,
                          [property]: num,
                        }
                          : process
                      }),
                    }
                      : processing
                  }
                  )
                }
              ) : product
            })
          }
        ) : estimate
      })
    })
  }

  const onChangeProcessesInElementsNum = (num, estimateIndex, productIndex, processingIndex, elementIndex, property, processName) => {
    setMassProduceProduct({
      ...massProduceProduct,
      estimate_plans: massProduceProduct.estimate_plans.map((estimate, estimateNum) => {
        return estimateIndex === estimateNum ? (
          {
            ...estimate,
            products: estimate.products.map((product, productNum) => {
              return productIndex === productNum ? (
                {
                  ...product,
                  processing_detail: product.processing_detail.map((processing, processingNum) => {
                    return processingIndex === processingNum ? {
                      ...processing,
                      elements: processing.elements.map((element, elementNum) => {
                        return elementIndex === elementNum ? {
                          ...element,
                          processes: element.processes.map((process, processNum) => {
                            // {console.log('process.process:', process.process)}
                            // {console.log('processName:', processName)}
                            return process.process === processName ? {
                              ...process,
                              // time: num,
                              [property]: num,
                            }
                              : process
                          }),
                        }
                          : element
                      })
                    }
                      : processing
                  }
                  )
                }
              ) : product
            })
          }
        ) : estimate
      })
    })
  }

  // シルク印刷不良
  const recalculateProcessingDefective = (estimateIndex, productIndex, processingIndex) => {
    const materialTotalAmount = recalculateMaterialTotalAmount(estimateIndex, productIndex);
    const processingCost = recalculateProcessingCost(estimateIndex, productIndex);
    let annealCost = 0

    massProduceProduct.estimate_plans[estimateIndex].products[productIndex].processing_detail.map((processing, processingNum) => {
      return processingIndex === processingNum && processing.category === 'アニール' ? (
        annealCost += processing.cost_price * 0.010 / processing.furnaces_amount
      ) : 0
    });

    const processCount = massProduceProduct.estimate_plans[estimateIndex].products[productIndex].processing_detail[processingIndex].process_count;
    const imposition = massProduceProduct.estimate_plans[estimateIndex].products[productIndex].processing_detail[processingIndex].imposition;
    const defectiveRate = massProduceProduct.estimate_plans[estimateIndex].products[productIndex].processing_detail[processingIndex].defective_rate;

    const defective = (materialTotalAmount + processingCost + annealCost + (processCount * 2 / imposition)) * defectiveRate;

    return defective
  }

  const recalculateLaserSubtotal = (estimateIndex, productIndex, processing) => {
    const materialTotalAmount = recalculateMaterialTotalAmount(estimateIndex, productIndex);
    const processingCost = recalculateProcessingCost(estimateIndex, productIndex);

    const calculateProcessCost = (process) => process.time * process.wage_rate;
    const processCosts = processing.processes.map(calculateProcessCost);
    const totalProcessCost = processCosts.reduce((acc, cost) => acc + cost, 0);

    const subtotal = (totalProcessCost + (materialTotalAmount + (processingCost + processCosts + totalProcessCost) * processing.defective_rate + processing.arrangement))

    return subtotal
  }


  /***************************
  ** 型・治工具
  ***************************/
  const investmentCategories = [
    { value: '', label: '' },
    { value: '成形金型', label: '成形金型' },
    { value: 'パッド版', label: 'パッド版' },
    { value: 'レーザー治具', label: 'レーザー治具' },
    { value: '印刷治具', label: '印刷治具' },
    { value: '塗装治具', label: '塗装治具' },
    { value: 'メッキ治具', label: 'メッキ治具' },
    { value: 'シルク版', label: 'シルク版' },
    { value: '革巻き治具/組立治具', label: '革巻き治具/組立治具' },
    { value: '抜型(FILM)', label: '抜型(FILM)' },
    { value: '抜型(TAPE A)', label: '抜型(TAPE A)' },
    { value: '抜型(TAPE B)', label: '抜型(TAPE B)' },
    { value: '検具', label: '検具' },
    { value: '梱包材', label: '梱包材' },
  ]

  const isDisabledCountList = ['レーザー治具', '印刷治具', '塗装治具', '革巻き治具/組立治具', '抜型(FILM)', '抜型(TAPE A)', '抜型(TAPE B)', '検具', '梱包材']

  const onChangeInvestmentsString = (e, estimateIndex, productIndex, investmentIndex, property) => {
    setMassProduceProduct({
      ...massProduceProduct,
      estimate_plans: massProduceProduct.estimate_plans.map((estimate, estimateNum) => {
        return estimateIndex === estimateNum ? (
          {
            ...estimate,
            products: estimate.products.map((product, productNum) => {
              return productIndex === productNum ? (
                {
                  ...product,
                  investments: product.investments.map((investment, investmentNum) => {
                    return investmentIndex === investmentNum ? {
                      ...investment,
                      [property]: e.target.value,
                    }
                      : investment
                  }
                  )
                }
              ) : product
            })
          }
        ) : estimate
      })
    })
  }

  const onChangeInvestmentsNum = (num, estimateIndex, productIndex, investmentIndex, property) => {
    setMassProduceProduct({
      ...massProduceProduct,
      estimate_plans: massProduceProduct.estimate_plans.map((estimate, estimateNum) => {
        return estimateIndex === estimateNum ? (
          {
            ...estimate,
            products: estimate.products.map((product, productNum) => {
              return productIndex === productNum ? (
                {
                  ...product,
                  investments: product.investments.map((investment, investmentNum) => {
                    return investmentIndex === investmentNum ? {
                      ...investment,
                      [property]: num,
                    }
                      : investment
                  }
                  )
                }
              ) : product
            })
          }
        ) : estimate
      })
    })
  }

  const onClickAddInvestment = (estimateIndex, productIndex) => {
    setMassProduceProduct({
      ...massProduceProduct,
      estimate_plans: massProduceProduct.estimate_plans.map((estimate, estimateNum) => {
        return estimateIndex === estimateNum ? (
          {
            ...estimate,
            products: estimate.products.map((product, productNum) =>
              productIndex == productNum
                ? {
                  ...product,
                  investments: [
                    ...product.investments,
                    investmentInfo,
                  ],
                }
                : product
            ),
          }
        ) : (
          estimate
        )
      })
    })
  }


  return (
    <>
      {isLoading ? (
        <Flex h="100vh" alignItems="center" justifyContent="center">
          <BeatLoader size={30} color={'#3b5998'} />
        </Flex>
      ) : (
        <>
          {masterList.users && (
            <>
              {console.log(massProduceProduct, location.state)}
              {/* {console.log(processingDetailInfo)} */}
              {/* {console.log('processingDetailList:', processingDetailList)} */}
              {/* {console.log('isLoading:', isLoading)} */}
              {console.log('materialDetailList:', materialDetailList)}
              {/* {console.log('productList:', productList)} */}
              {/* {console.log('masterList:', masterList)} */}
              {/* {console.log('processingDetailList:', processingDetailList)} */}
              {/* {isLoading && productList && masterList ? (
        <Flex h="100vh" alignItems="center" justifyContent="center" mt={-8}>
          <BeatLoader size={30} color={'#3b5998'} />
        </Flex>
      ) : ( */}
              <Box m={2}>
                <Heading mb={10} align="center">
                  量産案件{isCreate ? '登録' : '詳細'}
                </Heading>
                <Flex direction='column'>
                  <ButtonGroup justifyContent='center'>
                    <AlertModal
                      buttonMessage={isCreate ? "案件作成" : "案件更新"}
                      title={isCreate ? "案件作成" : "案件更新"}
                      body={`この内容で案件を${isCreate ? "新規作成" : "更新"}しますか？`}
                      onClick={
                        isCreate ? handleCreateProject : handleUpdateProject
                      }
                      m={[0, 'auto']}
                      disabled={
                        isLoadingAction ||
                        !massProduceProduct.project_name ||
                        !massProduceProduct.assigner ||
                        !massProduceProduct.client_name ||
                        !massProduceProduct.registered_date
                        // !product.lots.created_amount ||
                        // !product.lots.lot_amount ||
                        // !product.lots.registered_date ||
                        // !product.lots.due_date ||
                        // product.lots.direct_material_total_costs.filter(
                        //   (obj) => {
                        //     return obj.material_weight && obj.cost_per_weight
                        //   }
                        // ).length !==
                        // product.lots.direct_material_total_costs.length ||
                        // product.lots.indirect_material_costs.filter((obj) => {
                        //   return obj.material_weight && obj.cost_per_weight
                        // }).length !==
                        // product.lots.indirect_material_costs.length ||
                        // product.lots.direct_process_costs.filter((obj) => {
                        //   return (
                        //     obj.take_count &&
                        //     obj.process_plan_hours &&
                        //     obj.process_plan_wage
                        //   )
                        // }).length !==
                        // product.lots.direct_process_costs.length ||
                        // product.lots.indirect_process_costs.filter((obj) => {
                        //   return (
                        //     obj.take_count &&
                        //     obj.process_plan_hours &&
                        //     obj.process_plan_wage
                        //   )
                        // }).length !== product.lots.indirect_process_costs.length
                      }
                    />
                  </ButtonGroup>
                </Flex>
                <TableContainer mt={10}>
                  <Table>
                    <Thead>
                      <Tr>
                        <Th textAlign="center" fontSize={16} width={16}>案件名<Box display="inline-block" color="red">*</Box></Th>
                        <Th textAlign="center" fontSize={16} width={16}>クライアント名<Box display="inline-block" color="red">*</Box></Th>
                        <Th textAlign="center" fontSize={16} width={16}>入力者<Box display="inline-block" color="red">*</Box></Th>
                        <Th textAlign="center" fontSize={16} width={16}>見積日<Box display="inline-block" color="red">*</Box></Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      <Tr>
                        <Td>
                          {/* <Input
                        placeholder={'案件名'}
                        value={massProduceProduct.project_name}
                        onChange={(e) =>
                          onChangeProjectString(
                            e,
                            'project_name'
                          )
                        }
                        htmlSize={16}
                        disabled={isLoadingAction}
                      /> */}
                          <PrimaryInput
                            placeholder={'案件名'}
                            value={massProduceProduct.project_name}
                            onChange={(e) =>
                              onChangeProjectString(
                                e,
                                'project_name'
                              )
                            }
                            htmlSize={16}
                            disabled={isLoadingAction}
                            my={0}
                            mx={'auto'}
                          />
                        </Td>
                        <Td>
                          {/* <Select
                    value={materialDetailList.category}
                    onChangeSelect={(index) =>
                      setMassProduceProduct({
                        ...massProduceProduct,
                        client_name: masterList.clients[index]['client_name'],
                      })
                    }
                    bg="white"
                    _focus={{ boxShadow: 'none' }}
                    htmlSize={16}
                    disabled={isLoadingAction}
                  >
                    {materialCategories.map((category, index) => {
                      return (
                        <option value={category.value} key={index}>
                          {category.label}
                        </option>
                      )
                    })}
                  </Select> */}
                          <PrimarySelectBox
                            options={masterList.clients}
                            defaultOption={massProduceProduct.client_name}
                            // defaultOption={'A工業'}
                            optionElement="client_name"
                            onChangeSelect={(index) =>
                              // onChangeSelect={(e) =>
                              setMassProduceProduct({
                                ...massProduceProduct,
                                // client_name: e.target,
                                client_name: masterList.clients[index]['client_name'],
                              })
                            }
                            htmlSize={16}
                            disabled={isLoadingAction}
                            my={0}
                            mx={'auto'}
                          />
                        </Td>
                        <Td>
                          <PrimarySelectBox
                            options={masterList.users}
                            defaultOption={massProduceProduct.assigner}
                            optionElement="name"
                            onChangeSelect={(index) =>
                              // onChangeSelect={(e) =>
                              setMassProduceProduct({
                                ...massProduceProduct,
                                assigner: masterList.users[index]['name']
                                // assigner: e.target.value,
                              })
                            }
                            htmlSize={16}
                            disabled={isLoadingAction}
                            my={0}
                            mx={'auto'}
                          />
                        </Td>
                        <Td>
                          <PrimaryInput
                            type="date"
                            min="2000-01-01"
                            max="2050-01-01"
                            value={massProduceProduct.registered_date}
                            onChange={(e) =>
                              onChangeProjectString(
                                e,
                                'registered_date'
                              )
                            }
                            htmlSize={16}
                            disabled={isLoadingAction}
                            my={0}
                            mx={'auto'}
                          />
                        </Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </TableContainer>
                <ButtonGroup w={'100%'}>
                  <Button
                    my={6}
                    ml={3}
                    mr={'auto'}
                    aria-label="見積プラン追加"
                    disabled={isLoadingAction}
                    onClick={() => onClickAddEstimate()}
                    leftIcon={<AiOutlinePlus />}
                    colorScheme='facebook'
                    variant='outline'
                    size='lg'
                  >
                    見積プラン追加
                  </Button>
                </ButtonGroup>
                <Flex>
                  {
                    // 見積一覧
                    massProduceProduct.estimate_plans.map((estimate, estimateIndex) => {
                      // 見積もり
                      return (
                        <Flex direction="column">
                          <Flex
                            justifyContent='space-between'
                            alignItems='center'
                            px={3}
                            py={2}
                            mx={2}
                            bg='facebook.500'
                          >
                            <PrimaryInput
                              placeholder="見積プラン名"
                              value={estimate.estimate_plan_name}
                              onChange={(e) =>
                                onChangeEstimate(
                                  e,
                                  estimateIndex,
                                )
                              }
                              htmlSize={16}
                              disabled={isLoadingAction}
                            />
                            <Button
                              my={2}
                              aria-label="部品追加"
                              disabled={isLoadingAction}
                              onClick={() => onClickAddProduct(estimateIndex)}
                              leftIcon={<AiOutlinePlus />}
                              colorScheme='white'
                              variant='solid'
                            >
                              部品追加
                            </Button>
                          </Flex>

                          <Flex
                            key={estimateIndex}
                            p={3}
                            mx={2}
                            border='2px'
                            borderColor='facebook.500'
                          >
                            {estimate.products.map((product, productIndex) => {
                              // 部品
                              return (
                                <Flex key={productIndex} direction="column" m={2}>
                                  {/* 基本情報 */}
                                  <Flex direction="column">
                                    <Heading as="h4" size="md" mt={1} mb={2} ml={1}>
                                      部品基本情報
                                    </Heading>
                                    <InputAddonNumber
                                      addonMessage="設定台数（台/月）"
                                      value={product.basic_info.setting_amount}
                                      minValue={0}
                                      maxValue={100000}
                                      onChange={(num) =>
                                        onChangeBasicInfoNum(
                                          num,
                                          estimateIndex,
                                          productIndex,
                                          'setting_amount'
                                        )
                                      }
                                      htmlSize={10}
                                      disabled={isLoadingAction}
                                    />
                                    <InputAddonText
                                      addonMessage="部番"
                                      value={product.basic_info.product_number}
                                      onChange={(e) =>
                                        onChangeBasicInfoString(
                                          e,
                                          estimateIndex,
                                          productIndex,
                                          'product_number'
                                        )
                                      }
                                      htmlSize={16}
                                      disabled={isLoadingAction}
                                    />
                                    <InputAddonText
                                      addonMessage="品名"
                                      value={product.basic_info.product_name}
                                      onChange={(e) =>
                                        onChangeBasicInfoString(
                                          e,
                                          estimateIndex,
                                          productIndex,
                                          'product_name'
                                        )
                                      }
                                      htmlSize={16}
                                      disabled={isLoadingAction}
                                    />
                                    <InputAddonText
                                      addonMessage="設変番号"
                                      value={product.basic_info.design_change_number}
                                      onChange={(e) =>
                                        onChangeBasicInfoString(
                                          e,
                                          estimateIndex,
                                          productIndex,
                                          'design_change_number'
                                        )
                                      }
                                      htmlSize={16}
                                      disabled={isLoadingAction}
                                    />
                                  </Flex>
                                  {/* 材料 */}
                                  <Flex direction="column" mt={6}>
                                    {/* 材料基本情報 */}
                                    <Heading as="h4" size="md" mt={1} mb={2} ml={1}>
                                      材料基本情報
                                    </Heading>
                                    <InputAddonText
                                      addonMessage="材質"
                                      value={product.material_basic.material}
                                      onChange={(e) =>
                                        onChangeMaterialBasicString(
                                          e,
                                          estimateIndex,
                                          productIndex,
                                          'material'
                                        )
                                      }
                                      htmlSize={16}
                                      disabled={isLoadingAction}
                                    />
                                    <InputAddonText
                                      addonMessage="材料名"
                                      value={product.material_basic.name}
                                      onChange={(e) =>
                                        onChangeMaterialBasicString(
                                          e,
                                          estimateIndex,
                                          productIndex,
                                          'name'
                                        )
                                      }
                                      htmlSize={16}
                                      disabled={isLoadingAction}
                                    />
                                    <InputAddonText
                                      addonMessage="材料色"
                                      value={product.material_basic.color}
                                      onChange={(e) =>
                                        onChangeMaterialBasicString(
                                          e,
                                          estimateIndex,
                                          productIndex,
                                          'color'
                                        )
                                      }
                                      htmlSize={16}
                                      disabled={isLoadingAction}
                                    />
                                    <InputAddonNumber
                                      addonMessage="仕上重量"
                                      value={product.material_basic.completed_weight}
                                      minValue={0}
                                      maxValue={100000}
                                      onChange={(num) =>
                                        onChangeMaterialBasicNum(
                                          num,
                                          estimateIndex,
                                          productIndex,
                                          'completed_weight'
                                        )
                                      }
                                      htmlSize={10}
                                      disabled={isLoadingAction}
                                    />
                                    <InputAddonNumber
                                      addonMessage="仕込重量"
                                      value={product.material_basic.preparing_weight}
                                      minValue={0}
                                      maxValue={100000}
                                      onChange={(num) => {
                                        onChangeMaterialBasicNum(
                                          num,
                                          estimateIndex,
                                          productIndex,
                                          'preparing_weight'
                                        ),
                                          recalculateMaterialCost(
                                            estimateIndex,
                                            productIndex
                                          )
                                      }
                                      }
                                      htmlSize={10}
                                      disabled={isLoadingAction}
                                    />
                                    <InputAddonNumber
                                      addonMessage="材料単価"
                                      value={product.material_basic.unit_price}
                                      minValue={0}
                                      maxValue={100000}
                                      onChange={(num) => {
                                        onChangeMaterialBasicNum(
                                          num,
                                          estimateIndex,
                                          productIndex,
                                          'unit_price'
                                        ),
                                          recalculateMaterialCost(
                                            estimateIndex,
                                            productIndex
                                          )
                                      }
                                      }
                                      htmlSize={10}
                                      disabled={isLoadingAction}
                                    />
                                    <InputAddonNumber
                                      addonMessage="材料原価"
                                      value={product.material_basic.cost_price}
                                      minValue={0}
                                      maxValue={100000}
                                      onChange={(num) =>
                                        onChangeMaterialBasicNum(
                                          num,
                                          estimateIndex,
                                          productIndex,
                                          'cost_price'
                                        )
                                      }
                                      htmlSize={10}
                                      disabled={isLoadingAction}
                                    />
                                    <InputAddonText
                                      addonMessage="材料費"
                                      value={'¥' + (Math.round(recalculateMaterialCost(estimateIndex, productIndex) * 100 || 0) / 100).toLocaleString()}
                                      htmlSize={22}
                                      isReadOnly={true}
                                      disabled={isLoadingAction}
                                    />
                                  </Flex>

                                  <Flex direction="column" mt={6}>
                                    {/* 材料詳細情報 */}
                                    <Heading as="h4" size="md" mt={1} mb={2} ml={1}>
                                      材料詳細情報
                                    </Heading>
                                    <Flex>
                                      <>
                                        <Select
                                          onChange={(e) =>
                                            onChangeMaterialDetailCategory(
                                              e,
                                              productIndex
                                            )
                                          }
                                          value={materialDetailList.category}
                                          bg="white"
                                          // w="120px"
                                          _focus={{ boxShadow: 'none' }}
                                          // disabled={isDisabled}
                                          mb={10}
                                        >
                                          {materialCategories.map((category, index) => {
                                            return (
                                              <option value={category.value} key={index}>
                                                {category.label}
                                              </option>
                                            )
                                          })}
                                        </Select>
                                        <IconButton
                                          aria-label="追加"
                                          disabled={isLoadingAction}
                                          onClick={() =>
                                            onClickAddMaterialDetail(
                                              estimateIndex,
                                              productIndex
                                            )
                                          }
                                          icon={<AiOutlinePlus />}
                                        />
                                      </>
                                    </Flex>
                                    <Accordion defaultIndex={[0]} allowMultiple>
                                      {product.material_detail.map(
                                        (material, materialIndex) => {
                                          return (
                                            <>
                                              <AccordionItem>
                                                <Heading as="h2">
                                                  <AccordionButton>
                                                    <Box as="span" flex="1" textAlign="left">
                                                      {material.category}
                                                    </Box>
                                                    <AccordionIcon />
                                                  </AccordionButton>
                                                </Heading>
                                                <AccordionPanel pb={4}>
                                                  <Flex direction="column">
                                                    <InputAddonText
                                                      addonMessage="名前"
                                                      value={material.name}
                                                      onChange={(e) =>
                                                        onChangeMaterialDetailString(
                                                          e,
                                                          estimateIndex,
                                                          productIndex,
                                                          materialIndex,
                                                          'name'
                                                        )
                                                      }
                                                      htmlSize={16}
                                                      disabled={isLoadingAction}
                                                    />
                                                    <InputAddonNumber
                                                      addonMessage="投入DS"
                                                      value={material.input}
                                                      minValue={0}
                                                      maxValue={100000}
                                                      onChange={(num) => {
                                                        onChangeMaterialDetailNum(
                                                          num,
                                                          estimateIndex,
                                                          productIndex,
                                                          materialIndex,
                                                          'input'
                                                        ),
                                                          recalculateMaterialDetailSubtotal(
                                                            estimateIndex,
                                                            productIndex,
                                                            materialIndex,
                                                            material.category,
                                                          )
                                                      }
                                                      }
                                                      htmlSize={10}
                                                      disabled={isDisabledInputList.includes(material.category)}
                                                    />
                                                    <InputAddonNumber
                                                      addonMessage="単価"
                                                      value={material.unit_cost}
                                                      minValue={0}
                                                      maxValue={100000}
                                                      onChange={(num) => {
                                                        onChangeMaterialDetailNum(
                                                          num,
                                                          estimateIndex,
                                                          productIndex,
                                                          materialIndex,
                                                          'unit_cost'
                                                        ),
                                                          recalculateMaterialDetailSubtotal(
                                                            estimateIndex,
                                                            productIndex,
                                                            materialIndex,
                                                            material.category,
                                                          )
                                                      }
                                                      }
                                                      htmlSize={10}
                                                      disabled={isDisabled}
                                                    />
                                                    <InputAddonNumber
                                                      addonMessage="取数"
                                                      value={material.take_count}
                                                      minValue={0}
                                                      maxValue={100000}
                                                      onChange={(num) => {
                                                        onChangeMaterialDetailNum(
                                                          num,
                                                          estimateIndex,
                                                          productIndex,
                                                          materialIndex,
                                                          'take_count'
                                                        ),
                                                          recalculateMaterialDetailSubtotal(
                                                            estimateIndex,
                                                            productIndex,
                                                            materialIndex,
                                                            material.category,
                                                          )
                                                      }
                                                      }
                                                      htmlSize={10}
                                                      disabled={isDisabledTakeCountList.includes(material.category)}
                                                    />
                                                    <InputAddonNumber
                                                      addonMessage="利益"
                                                      value={material.profit_rate}
                                                      minValue={0}
                                                      maxValue={100000}
                                                      onChange={(num) => {
                                                        onChangeMaterialDetailNum(
                                                          num,
                                                          estimateIndex,
                                                          productIndex,
                                                          materialIndex,
                                                          'profit_rate'
                                                        ),
                                                          recalculateMaterialDetailSubtotal(
                                                            estimateIndex,
                                                            productIndex,
                                                            materialIndex,
                                                            material.category,
                                                          )
                                                      }
                                                      }
                                                      htmlSize={10}
                                                      disabled={isDisabledProfitRateList.includes(material.category)}
                                                    />
                                                    <InputAddonText
                                                      addonMessage="小計"
                                                      value={'¥' + (Math.round(recalculateMaterialDetailSubtotal(estimateIndex, productIndex, materialIndex, material.category) * 100 || 0) / 100).toLocaleString()}
                                                      htmlSize={20}
                                                      isReadOnly={true}
                                                      disabled={isLoadingAction}
                                                    />
                                                  </Flex>
                                                </AccordionPanel>
                                              </AccordionItem>
                                            </>
                                          )
                                        })}
                                    </Accordion>
                                  </Flex>
                                  <InputAddonText
                                    addonMessage="材料合計"
                                    value={'¥' + (Math.round(recalculateMaterialTotalAmount(estimateIndex, productIndex) * 100 || 0) / 100).toLocaleString()}
                                    htmlSize={20}
                                    isReadOnly={true}
                                    disabled={isLoadingAction}
                                  />

                                  <Flex direction="column" mt={10}>
                                    <Heading as="h4" size="md" mt={1} mb={2} ml={1}>
                                      構成部品
                                    </Heading>
                                    {product.components.map(
                                      (component, componentIndex) => {
                                        return (
                                          // 構成部品
                                          <Flex
                                            key={componentIndex}
                                            direction="column"
                                            mb={1}
                                          >
                                            <InputAddonText
                                              addonMessage={'部番'}
                                              value={component.product_number}
                                              onChange={(e) =>
                                                onChangeComponentsString(
                                                  e,
                                                  estimateIndex,
                                                  productIndex,
                                                  componentIndex,
                                                  'product_number'
                                                )
                                              }
                                              htmlSize={16}
                                              disabled={isLoadingAction}
                                            />
                                            <InputAddonText
                                              addonMessage={
                                                '品名'
                                              }
                                              value={component.product_name}
                                              onChange={(e) =>
                                                onChangeComponentsString(
                                                  e,
                                                  estimateIndex,
                                                  productIndex,
                                                  componentIndex,
                                                  'product_name'
                                                )
                                              }
                                              htmlSize={16}
                                              disabled={isLoadingAction}
                                            />
                                            <InputAddonNumber
                                              addonMessage="単価"
                                              value={component.product_price}
                                              minValue={0}
                                              maxValue={100000}
                                              onChange={(num) => {
                                                onChangeComponentsNum(
                                                  num,
                                                  estimateIndex,
                                                  productIndex,
                                                  componentIndex,
                                                  'product_price'
                                                ),
                                                  recalculateComponentSubtotal(
                                                    estimateIndex,
                                                    productIndex,
                                                    componentIndex
                                                  )
                                              }
                                              }
                                              htmlSize={10}
                                              disabled={isLoadingAction}
                                            />
                                            <InputAddonNumber
                                              addonMessage="数量"
                                              value={component.amount}
                                              minValue={0}
                                              maxValue={100000}
                                              onChange={(num) => {
                                                onChangeComponentsNum(
                                                  num,
                                                  estimateIndex,
                                                  productIndex,
                                                  componentIndex,
                                                  'amount'
                                                ),
                                                  recalculateComponentSubtotal(
                                                    estimateIndex,
                                                    productIndex,
                                                    componentIndex
                                                  )
                                              }
                                              }
                                              htmlSize={10}
                                              disabled={isLoadingAction}
                                            />
                                            <InputAddonText
                                              addonMessage="小計"
                                              value={'¥' + (Math.round(recalculateComponentSubtotal(estimateIndex, productIndex, componentIndex) * 100 || 0) / 100).toLocaleString()}
                                              htmlSize={24}
                                              isReadOnly={true}
                                              disabled={isLoadingAction}
                                            />
                                          </Flex>
                                        )
                                      }
                                    )}
                                    <IconButton
                                      aria-label="構成部品追加"
                                      disabled={isLoadingAction}
                                      onClick={() =>
                                        onClickAddComponent(
                                          estimateIndex,
                                          productIndex
                                        )
                                      }
                                      icon={<AiOutlinePlus />}
                                    />
                                    <InputAddonText
                                      addonMessage="構成部品合計"
                                      value={'¥' + (Math.round(recalculateComponentTotalAmount(estimateIndex, productIndex) * 100 || 0) / 100).toLocaleString()}
                                      htmlSize={16}
                                      isReadOnly={true}
                                      disabled={isLoadingAction}
                                    />
                                  </Flex>

                                  {/* 加工 */}
                                  <Flex direction="column">
                                    {/* 加工基本情報 */}
                                    <Heading as="h4" size="md" mt={10} mb={2} ml={1}>
                                      加工基本情報
                                    </Heading>
                                    <InputAddonNumber
                                      addonMessage="成型　トン数"
                                      value={product.processing_basic.weight}
                                      minValue={0}
                                      maxValue={100000}
                                      onChange={(num) =>
                                        onChangeProcessingBasicNum(
                                          num,
                                          estimateIndex,
                                          productIndex,
                                          'weight'
                                        )
                                      }
                                      htmlSize={10}
                                      disabled={isLoadingAction}
                                    />
                                    <InputAddonNumber
                                      addonMessage="成型サイクル（秒）"
                                      value={product.processing_basic.cycle}
                                      minValue={0}
                                      maxValue={100000}
                                      onChange={(num) =>
                                        onChangeProcessingBasicNum(
                                          num,
                                          estimateIndex,
                                          productIndex,
                                          'cycle'
                                        )
                                      }
                                      htmlSize={10}
                                      disabled={isLoadingAction}
                                    />
                                    <InputAddonNumber
                                      addonMessage="賃率　（秒）"
                                      value={product.processing_basic.wage_rate}
                                      minValue={0}
                                      maxValue={100000}
                                      onChange={(num) =>
                                        onChangeProcessingBasicNum(
                                          num,
                                          estimateIndex,
                                          productIndex,
                                          'wage_rate'
                                        )
                                      }
                                      htmlSize={10}
                                      disabled={isLoadingAction}
                                    />
                                    <InputAddonNumber
                                      addonMessage="型取数"
                                      value={product.processing_basic.take_count}
                                      minValue={0}
                                      maxValue={100000}
                                      onChange={(num) =>
                                        onChangeProcessingBasicNum(
                                          num,
                                          estimateIndex,
                                          productIndex,
                                          'take_count'
                                        )
                                      }
                                      htmlSize={10}
                                      disabled={isLoadingAction}
                                    />
                                    <InputAddonText
                                      addonMessage="成型加工費"
                                      value={'¥' + (Math.round(recalculateProcessingCost(estimateIndex, productIndex) * 100 || 0) / 100).toLocaleString()}
                                      htmlSize={18}
                                      isReadOnly={true}
                                      disabled={isLoadingAction}
                                    />
                                    <InputAddonNumber
                                      addonMessage="不良率"
                                      value={product.processing_basic.defective_rate}
                                      minValue={0}
                                      maxValue={100000}
                                      onChange={(num) =>
                                        onChangeProcessingBasicNum(
                                          num,
                                          estimateIndex,
                                          productIndex,
                                          'defective_rate'
                                        )
                                      }
                                      htmlSize={10}
                                      disabled={isLoadingAction}
                                    />
                                    <InputAddonText
                                      addonMessage="成型不良分"
                                      value={'¥' + (Math.round(recalculateProcessingDefectiveCost(estimateIndex, productIndex) * 100 || 0) / 100).toLocaleString()}
                                      htmlSize={18}
                                      isReadOnly={true}
                                      disabled={isLoadingAction}
                                    />
                                  </Flex>

                                  <Flex direction="column" mt={10}>
                                    {/* 加工詳細情報 */}
                                    <Heading as="h4" size="md" mt={1} mb={2} ml={1}>
                                      加工詳細情報
                                    </Heading>
                                    <Flex>
                                      <>
                                        <Select
                                          onChange={(e) =>
                                            onChangeProcessingDetailCategory(
                                              e,
                                              productIndex,
                                            )
                                          }
                                          // value={materialDetailList.category}
                                          bg="white"
                                          // w="120px"
                                          _focus={{ boxShadow: 'none' }}
                                          // disabled={isDisabled}
                                          mb={10}
                                        >
                                          {processingCategories.map((category, index) => {
                                            return (
                                              <option value={category.value} key={index}>
                                                {category.label}
                                              </option>
                                            )
                                          })}
                                        </Select>
                                        <IconButton
                                          aria-label="追加"
                                          disabled={isLoadingAction}
                                          onClick={() =>
                                            onClickAddProcessingDetail(
                                              estimateIndex,
                                              productIndex,
                                              processingDetailList[productIndex].category
                                            )
                                          }
                                          icon={<AiOutlinePlus />}
                                        />
                                      </>
                                    </Flex>
                                    <Accordion defaultIndex={[0]} allowMultiple>
                                      {product.processing_detail.map(
                                        (processing, processingIndex) => {
                                          return (
                                            <>
                                              <AccordionItem>
                                                <Heading as="h2">
                                                  <AccordionButton>
                                                    <Box as="span" flex="1" textAlign="left">
                                                      {processing.category}
                                                    </Box>
                                                    <AccordionIcon />
                                                  </AccordionButton>
                                                </Heading>
                                                <AccordionPanel pb={4}>
                                                  <Flex direction="column">
                                                    {processing.category === 'アニール' ? (
                                                      <>
                                                        <InputAddonNumber
                                                          addonMessage="アニール炉入数"
                                                          value={processing.furnaces_amount}
                                                          minValue={0}
                                                          maxValue={100000}
                                                          onChange={(num) =>
                                                            onChangeProcessingDetailNum(
                                                              num,
                                                              estimateIndex,
                                                              productIndex,
                                                              processingIndex,
                                                              'furnaces_amount'
                                                            )
                                                          }
                                                          disabled={isLoadingAction}
                                                        />
                                                        <InputAddonNumber
                                                          addonMessage="数値"
                                                          value={processing.anneal_count}
                                                          minValue={0}
                                                          maxValue={100000}
                                                          onChange={(num) =>
                                                            onChangeProcessingDetailNum(
                                                              num,
                                                              estimateIndex,
                                                              productIndex,
                                                              processingIndex,
                                                              'anneal_count'
                                                            )
                                                          }
                                                          step={0.01}
                                                          precision={2}
                                                          disabled={isLoadingAction}
                                                        />
                                                        <InputAddonText
                                                          addonMessage="小計"
                                                          value={'¥' + (Math.round(processing.anneal_count * 0.010 / processing.furnaces_amount * 100 || 0) / 100).toLocaleString()}
                                                          htmlSize={22}
                                                          isReadOnly={true}
                                                          disabled={isLoadingAction}
                                                        />
                                                      </>
                                                    ) : processing.category === 'メッキ' ? (
                                                      <InputAddonNumber
                                                        addonMessage="単価"
                                                        value={processing.cost_price}
                                                        minValue={0}
                                                        maxValue={100000}
                                                        onChange={(num) =>
                                                          onChangeProcessingDetailNum(
                                                            num,
                                                            estimateIndex,
                                                            productIndex,
                                                            processingIndex,
                                                            'cost_price'
                                                          )
                                                        }
                                                        step={0.01}
                                                        precision={2}
                                                        disabled={isLoadingAction}
                                                      />
                                                    ) : processing.category === 'パッド印刷' ? (
                                                      <>
                                                        <InputAddonNumber
                                                          addonMessage="工程数"
                                                          value={processing.process}
                                                          minValue={0}
                                                          maxValue={100000}
                                                          onChange={(num) =>
                                                            onChangeProcessingDetailNum(
                                                              num,
                                                              estimateIndex,
                                                              productIndex,
                                                              processingIndex,
                                                              'process'
                                                            )
                                                          }
                                                          disabled={isLoadingAction}
                                                        />
                                                        {
                                                          processing.processes.map((process, index) => {
                                                            return (
                                                              <React.Fragment key={index}>
                                                                <InputAddonNumber
                                                                  addonMessage={process.process}
                                                                  value={processing.processes.find(processObj => processObj.process === process.process).time}
                                                                  minValue={0}
                                                                  maxValue={100000}
                                                                  onChange={(num) =>
                                                                    onChangeProcessesInProcessingDetailNum(
                                                                      num,
                                                                      estimateIndex,
                                                                      productIndex,
                                                                      processingIndex,
                                                                      'time',
                                                                      process.process,
                                                                    )
                                                                  }
                                                                  disabled={isLoadingAction}
                                                                />
                                                                {index === 5 && (
                                                                  <InputAddonNumber
                                                                    addonMessage={"不良率"}
                                                                    value={processing.defective_rate01}
                                                                    minValue={0}
                                                                    maxValue={100000}
                                                                    onChange={(num) =>
                                                                      onChangeProcessingDetailNum(
                                                                        num,
                                                                        estimateIndex,
                                                                        productIndex,
                                                                        processingIndex,
                                                                        'defective_rate01',
                                                                      )
                                                                    }
                                                                    disabled={isLoadingAction}
                                                                  />
                                                                )}
                                                              </React.Fragment>
                                                            )
                                                          })
                                                        }
                                                        <InputAddonNumber
                                                          addonMessage={"不良率"}
                                                          value={processing.defective_rate02}
                                                          minValue={0}
                                                          maxValue={100000}
                                                          onChange={(num) =>
                                                            onChangeProcessingDetailNum(
                                                              num,
                                                              estimateIndex,
                                                              productIndex,
                                                              processingIndex,
                                                              'defective_rate02',
                                                            )
                                                          }
                                                          disabled={isLoadingAction}
                                                        />
                                                        <InputAddonNumber
                                                          addonMessage="調整"
                                                          value={processing.arrangement}
                                                          minValue={0}
                                                          maxValue={100000}
                                                          onChange={(num) =>
                                                            onChangeProcessingDetailNum(
                                                              num,
                                                              estimateIndex,
                                                              productIndex,
                                                              processingIndex,
                                                              'arrangement'
                                                            )
                                                          }
                                                          disabled={isLoadingAction}
                                                        />
                                                        <InputAddonText
                                                          addonMessage="小計"
                                                          value={
                                                            (Math.round(0 * 100 || 0
                                                            ) / 100).toLocaleString()
                                                          }
                                                          htmlSize={22}
                                                          isReadOnly={true}
                                                          disabled={isLoadingAction}
                                                        />
                                                      </>
                                                    ) : processing.category === '塗装' ? (
                                                      <>
                                                        <InputAddonText
                                                          addonMessage="名称"
                                                          value={processing.name}
                                                          htmlSize={22}
                                                          onChange={(e) =>
                                                            onChangeProcessingDetailString(
                                                              e,
                                                              estimateIndex,
                                                              productIndex,
                                                              processingIndex,
                                                              'name'
                                                            )
                                                          }
                                                          disabled={isLoadingAction}
                                                        />
                                                        <InputAddonNumber
                                                          addonMessage="取り数"
                                                          value={processing.take_count}
                                                          minValue={0}
                                                          maxValue={100000}
                                                          onChange={(num) =>
                                                            onChangeProcessingDetailNum(
                                                              num,
                                                              estimateIndex,
                                                              productIndex,
                                                              processingIndex,
                                                              'take_count'
                                                            )
                                                          }
                                                          disabled={isLoadingAction}
                                                        />
                                                        {
                                                          processing.elements.map((element, elementIndex) => {
                                                            return (
                                                              <Box mb={1}>
                                                                <InputAddonNumber
                                                                  addonMessage={"塗料代"}
                                                                  value={processing.paint_cost}
                                                                  minValue={0}
                                                                  maxValue={100000}
                                                                  onChange={(num) =>
                                                                    onChangeElementsInProcessingDetailNum(
                                                                      num,
                                                                      estimateIndex,
                                                                      productIndex,
                                                                      processingIndex,
                                                                      elementIndex,
                                                                      'paint_cost',
                                                                    )
                                                                  }
                                                                  disabled={isLoadingAction}
                                                                />
                                                                {element.processes.map((process) => {
                                                                  return (
                                                                    <InputAddonNumber
                                                                      addonMessage={process.process}
                                                                      value={element.processes.find(processObj => processObj.process === process.process).time}
                                                                      minValue={0}
                                                                      maxValue={100000}
                                                                      onChange={(num) =>
                                                                        onChangeProcessesInElementsNum(
                                                                          num,
                                                                          estimateIndex,
                                                                          productIndex,
                                                                          processingIndex,
                                                                          elementIndex,
                                                                          'time',
                                                                          process.process,
                                                                        )
                                                                      }
                                                                      disabled={isLoadingAction}
                                                                    />
                                                                  )
                                                                })
                                                                }
                                                                <InputAddonNumber
                                                                  addonMessage={"不良率"}
                                                                  value={processing.defective_rate}
                                                                  minValue={0}
                                                                  maxValue={100000}
                                                                  onChange={(num) =>
                                                                    onChangeElementsInProcessingDetailNum(
                                                                      num,
                                                                      estimateIndex,
                                                                      productIndex,
                                                                      processingIndex,
                                                                      elementIndex,
                                                                      'defective_rate',
                                                                    )
                                                                  }
                                                                  disabled={isLoadingAction}
                                                                />
                                                              </Box>
                                                            )
                                                          })
                                                        }
                                                        <IconButton
                                                          aria-label="追加"
                                                          disabled={isLoadingAction}
                                                          onClick={() =>
                                                            onClickAddElement(
                                                              estimateIndex,
                                                              productIndex,
                                                              processingIndex,
                                                            )
                                                          }
                                                          icon={<AiOutlinePlus />}
                                                        />
                                                        <InputAddonNumber
                                                          addonMessage="調整"
                                                          value={processing.arrangement}
                                                          minValue={0}
                                                          maxValue={100000}
                                                          onChange={(num) =>
                                                            onChangeProcessingDetailNum(
                                                              num,
                                                              estimateIndex,
                                                              productIndex,
                                                              processingIndex,
                                                              'arrangement'
                                                            )
                                                          }
                                                          disabled={isLoadingAction}
                                                        />
                                                      </>
                                                    ) : processing.category === 'レーザー' ? (
                                                      <>
                                                        {processing.processes.map((process) => {
                                                          return (
                                                            <InputAddonNumber
                                                              addonMessage={process.process}
                                                              value={processing.processes.find(processObj => processObj.process === process.process).time}
                                                              minValue={0}
                                                              maxValue={100000}
                                                              onChange={(num) =>
                                                                onChangeProcessesInProcessingDetailNum(
                                                                  num,
                                                                  estimateIndex,
                                                                  productIndex,
                                                                  processingIndex,
                                                                  'time',
                                                                  process.process,
                                                                )
                                                              }
                                                              disabled={isLoadingAction}
                                                            />
                                                          )
                                                        })
                                                        }
                                                        <InputAddonNumber
                                                          addonMessage="不良率"
                                                          value={processing.defective_rate}
                                                          minValue={0}
                                                          maxValue={100000}
                                                          onChange={(num) =>
                                                            onChangeProcessingDetailNum(
                                                              num,
                                                              estimateIndex,
                                                              productIndex,
                                                              processingIndex,
                                                              'defective_rate'
                                                            )
                                                          }
                                                          disabled={isLoadingAction}
                                                        />
                                                        <InputAddonNumber
                                                          addonMessage="調整"
                                                          value={processing.arrangement}
                                                          minValue={0}
                                                          maxValue={100000}
                                                          onChange={(num) =>
                                                            onChangeProcessingDetailNum(
                                                              num,
                                                              estimateIndex,
                                                              productIndex,
                                                              processingIndex,
                                                              'arrangement'
                                                            )
                                                          }
                                                          disabled={isLoadingAction}
                                                        />
                                                        <InputAddonText
                                                          addonMessage="小計"
                                                          value={
                                                            (Math.round(
                                                              (recalculateLaserSubtotal) * 100 || 0
                                                            ) / 100).toLocaleString()
                                                          }
                                                          htmlSize={22}
                                                          isReadOnly={true}
                                                          disabled={isLoadingAction}
                                                        />
                                                      </>
                                                    ) : processing.category === 'シルク印刷' ? (
                                                      <>
                                                        <InputAddonNumber
                                                          addonMessage="工程数"
                                                          value={processing.process_count}
                                                          minValue={0}
                                                          maxValue={100000}
                                                          onChange={(num) =>
                                                            onChangeProcessingDetailNum(
                                                              num,
                                                              estimateIndex,
                                                              productIndex,
                                                              processingIndex,
                                                              'process_count'
                                                            )
                                                          }
                                                          disabled={isLoadingAction}
                                                        />
                                                        <InputAddonNumber
                                                          addonMessage="賃率"
                                                          value={processing.wage_rate}
                                                          minValue={0}
                                                          maxValue={100000}
                                                          onChange={(num) =>
                                                            onChangeProcessingDetailNum(
                                                              num,
                                                              estimateIndex,
                                                              productIndex,
                                                              processingIndex,
                                                              'wage_rate'
                                                            )
                                                          }
                                                          step={0.01}
                                                          precision={2}
                                                          disabled={isLoadingAction}
                                                        />
                                                        <InputAddonNumber
                                                          addonMessage="面付"
                                                          value={processing.imposition}
                                                          minValue={0}
                                                          maxValue={100000}
                                                          onChange={(num) =>
                                                            onChangeProcessingDetailNum(
                                                              num,
                                                              estimateIndex,
                                                              productIndex,
                                                              processingIndex,
                                                              'imposition'
                                                            )
                                                          }
                                                          step={0.01}
                                                          precision={2}
                                                          disabled={isLoadingAction}
                                                        />
                                                        <InputAddonNumber
                                                          addonMessage="不良率"
                                                          value={processing.defective_rate}
                                                          minValue={0}
                                                          maxValue={100000}
                                                          onChange={(num) =>
                                                            onChangeProcessingDetailNum(
                                                              num,
                                                              estimateIndex,
                                                              productIndex,
                                                              processingIndex,
                                                              'defective_rate'
                                                            )
                                                          }
                                                          step={0.01}
                                                          precision={2}
                                                          disabled={isLoadingAction}
                                                        />
                                                        <InputAddonText
                                                          addonMessage="不良"
                                                          value={
                                                            (Math.round((recalculateProcessingDefective(estimateIndex, productIndex, processingIndex)) * 100 || 0) / 100).toLocaleString()
                                                          }
                                                          htmlSize={22}
                                                          isReadOnly={true}
                                                          disabled={isLoadingAction}
                                                        />
                                                        <InputAddonText
                                                          addonMessage="小計"
                                                          value={
                                                            (Math.round(
                                                              (processing.process_count * processing.wage_rate / processing.imposition) * 100 || 0
                                                            ) / 100).toLocaleString()
                                                          }
                                                          htmlSize={22}
                                                          isReadOnly={true}
                                                          disabled={isLoadingAction}
                                                        />
                                                      </>
                                                    ) : processing.category === 'プレス（FILM）' ? (
                                                      <>
                                                        <InputAddonNumber
                                                          addonMessage="フィルム数"
                                                          value={processing.film_count}
                                                          minValue={0}
                                                          maxValue={100000}
                                                          onChange={(num) =>
                                                            onChangeProcessingDetailNum(
                                                              num,
                                                              estimateIndex,
                                                              productIndex,
                                                              processingIndex,
                                                              'film_count'
                                                            )
                                                          }
                                                          step={0.01}
                                                          precision={2}
                                                          disabled={isLoadingAction}
                                                        />
                                                        <InputAddonNumber
                                                          addonMessage="面付"
                                                          value={processing.press_count}
                                                          minValue={0}
                                                          maxValue={100000}
                                                          onChange={(num) =>
                                                            onChangeProcessingDetailNum(
                                                              num,
                                                              estimateIndex,
                                                              productIndex,
                                                              processingIndex,
                                                              'press_count'
                                                            )
                                                          }
                                                          disabled={isLoadingAction}
                                                        />
                                                        <InputAddonNumber
                                                          addonMessage="賃率"
                                                          value={processing.wage_rate}
                                                          minValue={0}
                                                          maxValue={100000}
                                                          onChange={(num) =>
                                                            onChangeProcessingDetailNum(
                                                              num,
                                                              estimateIndex,
                                                              productIndex,
                                                              processingIndex,
                                                              'wage_rate'
                                                            )
                                                          }
                                                          disabled={isLoadingAction}
                                                        />
                                                        <InputAddonText
                                                          addonMessage="小計"
                                                          value={
                                                            (Math.round(
                                                              (processing.wage_rate / processing.press_count * processing.film_count) * 100 || 0
                                                            ) / 100).toLocaleString()
                                                          }
                                                          htmlSize={22}
                                                          isReadOnly={true}
                                                          disabled={isLoadingAction}
                                                        />
                                                      </>
                                                    ) : processing.category === 'プレス（両面テープ）' ? (
                                                      <>
                                                        <InputAddonNumber
                                                          addonMessage="フィルム数"
                                                          value={processing.film_count}
                                                          minValue={0}
                                                          maxValue={100000}
                                                          onChange={(num) =>
                                                            onChangeProcessingDetailNum(
                                                              num,
                                                              estimateIndex,
                                                              productIndex,
                                                              processingIndex,
                                                              'film_count'
                                                            )
                                                          }
                                                          step={0.01}
                                                          precision={2}
                                                          disabled={isLoadingAction}
                                                        />
                                                        <InputAddonNumber
                                                          addonMessage="面付"
                                                          value={processing.press_count}
                                                          minValue={0}
                                                          maxValue={100000}
                                                          onChange={(num) =>
                                                            onChangeProcessingDetailNum(
                                                              num,
                                                              estimateIndex,
                                                              productIndex,
                                                              processingIndex,
                                                              'press_count'
                                                            )
                                                          }
                                                          disabled={isLoadingAction}
                                                        />
                                                        <InputAddonText
                                                          addonMessage="小計"
                                                          value={
                                                            (Math.round(
                                                              (30 / processing.press_count * processing.film_count) * 100 || 0
                                                            ) / 100).toLocaleString()
                                                          }
                                                          htmlSize={22}
                                                          isReadOnly={true}
                                                          disabled={isLoadingAction}
                                                        />
                                                      </>
                                                    ) : processing.category === '革巻き/組立' || processing.category === '両面テープ貼り' || processing.category === '保護シート/不織布貼り' || processing.category === '検査' ? (
                                                      <>
                                                        <InputAddonNumber
                                                          addonMessage="時間（秒）"
                                                          value={processing.time}
                                                          minValue={0}
                                                          maxValue={100000}
                                                          onChange={(num) =>
                                                            onChangeProcessingDetailNum(
                                                              num,
                                                              estimateIndex,
                                                              productIndex,
                                                              processingIndex,
                                                              'time'
                                                            )
                                                          }
                                                          step={0.01}
                                                          precision={2}
                                                          disabled={isLoadingAction}
                                                        />
                                                        <InputAddonText
                                                          addonMessage="小計"
                                                          value={
                                                            (Math.round(
                                                              (processing.time * 0.67) * 100 || 0
                                                            ) / 100).toLocaleString()
                                                          }
                                                          htmlSize={22}
                                                          isReadOnly={true}
                                                          disabled={isLoadingAction}
                                                        />
                                                      </>
                                                    ) : null}
                                                  </Flex>
                                                </AccordionPanel>
                                              </AccordionItem>
                                            </>
                                          )
                                        })}
                                    </Accordion>
                                  </Flex>
                                  <InputAddonText
                                    addonMessage="加工費"
                                    value={product.processing_basic.cost}
                                    htmlSize={22}
                                    isReadOnly={true}
                                    disabled={isLoadingAction}
                                  />
                                  <InputAddonText
                                    addonMessage="小計"
                                    value={product.processing_basic.cost}
                                    htmlSize={24}
                                    isReadOnly={true}
                                    disabled={isLoadingAction}
                                  />
                                  <InputAddonText
                                    addonMessage="管理費率"
                                    value={product.processing_basic.cost}
                                    htmlSize={20}
                                    disabled={isLoadingAction}
                                  />
                                  <InputAddonText
                                    addonMessage="管理費"
                                    value={product.processing_basic.cost}
                                    htmlSize={22}
                                    isReadOnly={true}
                                    disabled={isLoadingAction}
                                  />
                                  <InputAddonText
                                    addonMessage="型レス価格(税別)"
                                    value={product.processing_basic.cost}
                                    htmlSize={13}
                                    isReadOnly={true}
                                    disabled={isLoadingAction}
                                  />

                                  {/* 投資 */}
                                  <Heading as="h4" size="md" mt={10} mb={2} ml={1}>
                                    投資
                                  </Heading>
                                  {
                                    product.investments.map(
                                      (investment, investmentIndex) => {
                                        return (
                                          // 構成部品
                                          <Flex
                                            key={investmentIndex}
                                            direction="column"
                                            mb={1}
                                          >
                                            <Select
                                              onChange={(e) =>
                                                onChangeInvestmentsString(
                                                  e,
                                                  estimateIndex,
                                                  productIndex,
                                                  investmentIndex,
                                                  'category'
                                                )
                                              }
                                              value={investment.category}
                                              bg="white"
                                              // w="120px"
                                              _focus={{ boxShadow: 'none' }}
                                            // disabled={isDisabled}
                                            >
                                              {investmentCategories.map((category, index) => {
                                                return (
                                                  <option value={category.value} key={index}>
                                                    {category.label}
                                                  </option>
                                                )
                                              })}
                                            </Select>
                                            <InputAddonNumber
                                              addonMessage="数量"
                                              value={investment.count}
                                              minValue={0}
                                              maxValue={100000}
                                              onChange={(num) =>
                                                onChangeInvestmentsNum(
                                                  num,
                                                  estimateIndex,
                                                  productIndex,
                                                  investmentIndex,
                                                  'count'
                                                )
                                              }
                                              htmlSize={10}
                                              disabled={isDisabledCountList.includes(investment.category)}
                                            />
                                            <InputAddonNumber
                                              addonMessage="費用"
                                              value={investment.cost}
                                              minValue={0}
                                              maxValue={100000}
                                              onChange={(num) =>
                                                onChangeInvestmentsNum(
                                                  num,
                                                  estimateIndex,
                                                  productIndex,
                                                  investmentIndex,
                                                  'cost'
                                                )
                                              }
                                              htmlSize={10}
                                              disabled={isLoadingAction}
                                            />
                                          </Flex>
                                        )
                                      })
                                  }
                                  <IconButton
                                    aria-label="型・治工具追加"
                                    disabled={isLoadingAction}
                                    onClick={() =>
                                      onClickAddInvestment(
                                        estimateIndex,
                                        productIndex
                                      )
                                    }
                                    icon={<AiOutlinePlus />}
                                  />
                                  <InputAddonText
                                    addonMessage="投資合計"
                                    value={'¥' + (Math.round(0 * 100 || 0) / 100).toLocaleString()}
                                    htmlSize={20}
                                    isReadOnly={true}
                                    disabled={isLoadingAction}
                                  />
                                </Flex>
                              )
                            })}
                          </Flex>
                        </Flex>
                      )
                    })
                  }
                </Flex>
                <Flex direction='column' mt={10}>
                  <ButtonGroup justifyContent='center'>
                    <AlertModal
                      buttonMessage={isCreate ? "案件作成" : "案件更新"}
                      title={isCreate ? "案件作成" : "案件更新"}
                      body={`この内容で案件を${isCreate ? "新規作成" : "更新"}しますか？`}
                      onClick={
                        isCreate ? handleCreateProject : handleUpdateProject
                      }
                      m={[0, 'auto']}
                      disabled={
                        isLoadingAction ||
                        !massProduceProduct.project_name ||
                        !massProduceProduct.assigner ||
                        !massProduceProduct.client_name ||
                        !massProduceProduct.registered_date
                        // massProduceProduct.estimate_plans.filter(
                        //   (obj) => {
                        //     return obj.estimate.estimate_plan_name
                        //   }
                        // ).length !==
                      }
                    />
                  </ButtonGroup>
                </Flex>
              </Box >
              {/* )} */}
              {/* )} */}
            </>
          )}
        </>
      )
      }
    </>
  )
}
