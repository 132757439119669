import React from 'react'
import { HStack, Text } from '@chakra-ui/react'

export const Slide = ({ title }) => {
  return (
    <HStack
      w="100%"
      h="300px"
      bg="blue.400"
      border="blue.700"
      alignContent="center"
      justifyContent="center"
    >
      <Text color="white" fontWeight="bold">
        {title}
      </Text>
    </HStack>
  )
}
