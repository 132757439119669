import React from 'react'
import {
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Heading,
} from '@chakra-ui/react'
import { TableBodyToolCosts } from '../../molecules/lot/TableBodyToolCosts'

export const TableToolCosts = (props) => {
  const {
    toolCostList,
    addToolCost,
    tableName,
    onChangeToolName,
    onChangeAmount,
    onChangeSellingPrice,
    onChangeBuyingPrice,
    onChangeToolNameAdd,
    onChangeAmountAdd,
    onChangeSellingPriceAdd,
    onChangeBuyingPriceAdd,
    onClickDeleteToolCost,
    onClickAddToolCost,
    isDisabled,
    disabledAddElement,
    disabledAddButton,
  } = props

  return (
    <>
      <Heading as="h4" size="md">
        {tableName}
      </Heading>
      <TableContainer mb={10}>
        <Table variant="striped" colorScheme="linkedin">
          <Thead>
            <Tr>
              <Th>名称</Th>
              <Th>数量</Th>
              <Th>売単価</Th>
              <Th>仕入単価</Th>
              <Th></Th>
              <Th></Th>
              <Th>売単価</Th>
              <Th>仕入単価</Th>
              <Th>粗利益</Th>
              <Th>粗利率</Th>
              <Th isNumeric>追加/削除</Th>
            </Tr>
          </Thead>
          <Tbody>
            {toolCostList &&
              toolCostList.map((toolCost, index) => {
                return (
                  <Tr key={index}>
                    <TableBodyToolCosts
                      type="existing"
                      toolName={toolCost.tool_name}
                      amount={toolCost.amount}
                      sellingPrice={toolCost.selling_price}
                      buyingPrice={toolCost.buying_price}
                      totalSellingPrice={
                        toolCost.amount * toolCost.selling_price
                      }
                      totalBuyingPrice={toolCost.amount * toolCost.buying_price}
                      grossProfit={
                        toolCost.amount *
                        (toolCost.selling_price - toolCost.buying_price)
                      }
                      grossProfitMargin={
                        1 - toolCost.buying_price / toolCost.selling_price
                      }
                      onChangeToolName={(e) => onChangeToolName(e, index)}
                      onChangeAmount={(num) => onChangeAmount(num, index)}
                      onChangeSellingPrice={(num) =>
                        onChangeSellingPrice(num, index)
                      }
                      onChangeBuyingPrice={(num) =>
                        onChangeBuyingPrice(num, index)
                      }
                      onClickDeleteToolCost={() => onClickDeleteToolCost(index)}
                      isDisabled={toolCost.is_delete == true || isDisabled}
                    />
                  </Tr>
                )
              })}

            <Tr>
              <TableBodyToolCosts
                type="adding"
                toolName={addToolCost.toolName}
                amount={addToolCost.amount}
                sellingPrice={addToolCost.sellingPrice}
                buyingPrice={addToolCost.buyingPrice}
                totalSellingPrice={
                  addToolCost.amount * addToolCost.sellingPrice
                }
                totalBuyingPrice={addToolCost.amount * addToolCost.buyingPrice}
                grossProfit={
                  addToolCost.amount *
                    (addToolCost.sellingPrice - addToolCost.buyingPrice) || 0
                }
                grossProfitMargin={
                  (addToolCost.amount *
                    (addToolCost.sellingPrice - addToolCost.buyingPrice)) /
                    addToolCost.sellingPrice || 0
                }
                onChangeToolName={onChangeToolNameAdd}
                onChangeAmount={onChangeAmountAdd}
                onChangeSellingPrice={onChangeSellingPriceAdd}
                onChangeBuyingPrice={onChangeBuyingPriceAdd}
                onClickAddToolCost={onClickAddToolCost}
                isDisabled={isDisabled}
                disabledAddElement={disabledAddElement}
                disabledAddButton={disabledAddButton}
              />
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>
    </>
  )
}
