import React from 'react'
import { Routes, Route } from 'react-router-dom'
import { adminRoutes, salesRoutes, workerRoutes } from './routes'
import { HeaderLayout } from '../components/templates/HeaderLayout'
import { MainMenu } from '../components/pages/MainMenu'
import { Page404 } from '../components/pages/Page404'
import { UserValidation } from './UserValidation'
import { UserValidationAdmin } from './UserValidationAdmin'
import { UserValidationSales } from './UserValidationSales'
import { UserValidationWorker } from './UserValidationWorker'
import { GanttChart2 } from '../components/pages/GanttChart2'

export const AuthenticatedRoute = () => {
  return (
    <Routes>
      <Route
        path="/menu"
        element={
          <UserValidation>
            <MainMenu />
          </UserValidation>
        }
      />
      <Route
        path="/scheduling"
        element={
          <HeaderLayout>
            <GanttChart2 />
          </HeaderLayout>
        }
      />
      {salesRoutes.map((salesRoute) => {
        return (
          <Route
            key={salesRoute.path}
            path={salesRoute.path}
            element={
              <HeaderLayout>
                <UserValidationSales>{salesRoute.element}</UserValidationSales>
              </HeaderLayout>
            }
          />
        )
      })}
      {workerRoutes.map((workerRoute) => {
        return (
          <Route
            key={workerRoute.path}
            path={workerRoute.path}
            element={
              <HeaderLayout>
                <UserValidationWorker>
                  {workerRoute.element}
                </UserValidationWorker>
              </HeaderLayout>
            }
          />
        )
      })}
      {adminRoutes.map((adminRoute) => {
        return (
          <Route
            key={adminRoute.path}
            path={adminRoute.path}
            element={
              <HeaderLayout>
                <UserValidationAdmin>{adminRoute.element}</UserValidationAdmin>
              </HeaderLayout>
            }
          />
        )
      })}
      <Route path="*" element={<Page404 />} />
    </Routes>
  )
}
