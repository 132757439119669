import React from 'react'
import { Td, IconButton, Text, Flex, Switch, Select } from '@chakra-ui/react'
import { PrimaryInput } from '../../atoms/PrimaryInput'
import { PrimaryNumberInput } from '../../atoms/PrimaryNumberInput'
import { AiOutlineDelete, AiOutlinePlus } from 'react-icons/ai'

export const TableBodyOutsourceProviding = (props) => {
  const {
    type,
    valueIsProvided,
    valueIsFree,
    valueProductNum,
    valueProductName,
    valueCount,
    valueUnitPrice,
    onChangeIsProvided,
    onChangeIsFree,
    onChangeProductNum,
    onChanceProductName,
    onChangeCount,
    onChangeUnitPrice,
    onClickAddProduct,
    onClickDeleteProvidingProduct,
    isDisabled,
    disabledAddButton,
  } = props

  return (
    <>
      <Td>
        <Select
          onChange={(e) => onChangeIsProvided(e)}
          value={valueIsProvided}
          bg="white"
          width="120px"
          _focus={{ boxShadow: 'none' }}
          disabled={isDisabled}
        >
          <option value={true}>有</option>
          <option value={false}>無</option>
        </Select>
      </Td>
      <Td>
        <Select
          onChange={(e) => onChangeIsFree(e)}
          value={valueIsFree}
          bg="white"
          width="120px"
          _focus={{ boxShadow: 'none' }}
          disabled={isDisabled}
        >
          <option value={false}>有償</option>
          <option value={true}>無償</option>
        </Select>
      </Td>
      <Td>
        <PrimaryInput
          placeholder=""
          disabled={isDisabled}
          value={valueProductNum}
          onChange={(e) => onChangeProductNum(e)}
          htmlSize={16}
        />
      </Td>
      <Td>
        <PrimaryInput
          placeholder=""
          disabled={isDisabled}
          value={valueProductName}
          onChange={(e) => onChanceProductName(e)}
          htmlSize={16}
        />
      </Td>
      <Td>
        <PrimaryNumberInput
          value={valueCount}
          minValue={0}
          maxValue={100000}
          onChange={(num) => onChangeCount(num)}
          width="120px"
          disabled={isDisabled}
        />
      </Td>
      <Td>
        <PrimaryNumberInput
          value={valueUnitPrice.toLocaleString()}
          minValue={0}
          onChange={(num) => onChangeUnitPrice(num)}
          width="120px"
          disabled={isDisabled}
        />
      </Td>
      <Td>
        <Text opacity={isDisabled && 0.3}>
          ¥{(valueCount * valueUnitPrice || 0).toLocaleString()}
        </Text>
      </Td>
      <Td>
        {type == 'adding' ? (
          <IconButton
            aria-label="add"
            disabled={isDisabled || disabledAddButton}
            onClick={onClickAddProduct}
            icon={<AiOutlinePlus />}
          />
        ) : (
          <IconButton
            aria-label="delete"
            disabled={isDisabled}
            onClick={onClickDeleteProvidingProduct}
            icon={<AiOutlineDelete />}
          />
        )}
      </Td>
    </>
  )
}
