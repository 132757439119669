import React from 'react'
import { Td, IconButton, Text, Select } from '@chakra-ui/react'
import { AiOutlineDelete, AiOutlinePlus } from 'react-icons/ai'
import { PrimaryNumberInput } from '../../atoms/PrimaryNumberInput'
import { PrimaryInput } from '../../atoms/PrimaryInput'

export const TableBodyProductListOnlyExport = (props) => {
  const {
    type,
    instructionIndex,
    productNum,
    productName,
    count,
    price,
    amount,
    unit,
    onChangeInstructionIndex,
    onChangeProductNum,
    onChanceProductName,
    onChangeCount,
    onChangeUnit,
    onChangePrice,
    onClickAddProduct,
    onClickDeleteProduct,
    isDisabled,
    isDisabledAddButton,
  } = props

    const units = [
      { value: '', label: '' },
      { value: '個', label: '個' },
      { value: 'kg', label: 'kg' },
      { value: '箱', label: '箱' },
      { value: '本', label: '本' },
      { value: '枚', label: '枚' },
    ]

  return (
    <>
      {/* <Td>
        <PrimaryInput
          placeholder=""
          disabled={isDisabled}
          value={instructionIndex}
          onChange={(e) => onChangeInstructionIndex(e)}
          htmlSize={16}
        />
      </Td> */}
      <Td>
        <PrimaryInput
          placeholder=""
          disabled={isDisabled}
          value={productNum}
          onChange={(e) => onChangeProductNum(e)}
          htmlSize={16}
        />
      </Td>
      <Td>
        <PrimaryInput
          placeholder=""
          disabled={isDisabled}
          value={productName}
          onChange={(e) => onChanceProductName(e)}
          htmlSize={16}
        />
      </Td>
      <Td>
        <PrimaryNumberInput
          value={count}
          minValue={0}
          maxValue={100000}
          onChange={(num) => onChangeCount(num)}
          width="150px"
          disabled={isDisabled}
        />
      </Td>
      <Td>
        <Select
          onChange={(e) => onChangeUnit(e)}
          value={unit}
          bg="white"
          width="100px"
          _focus={{ boxShadow: 'none' }}
          disabled={isDisabled}
        >
          {units.map((unit, index) => {
            return (
              <option value={unit.value} key={index}>
                {unit.label}
              </option>
            )
          })}
        </Select>
      </Td>
      <Td>
        <PrimaryNumberInput
          value={price}
          minValue={0}
          maxValue={10000000}
          onChange={(num) => onChangePrice(num)}
          width="150px"
          disabled={isDisabled}
        />
      </Td>
      <Td>
        <Text w="110px">¥{amount.toLocaleString()}</Text>
      </Td>
      <Td>
        {type == 'adding' ? (
          <IconButton
            aria-label="add"
            disabled={isDisabled || isDisabledAddButton}
            onClick={onClickAddProduct}
            icon={<AiOutlinePlus />}
          />
        ) : (
          <IconButton
            aria-label="delete"
            disabled={isDisabled}
            onClick={onClickDeleteProduct}
            icon={<AiOutlineDelete />}
          />
        )}
      </Td>
    </>
  )
}
