import React from 'react'
import { IconButton, Select, Td } from '@chakra-ui/react'
import { PrimaryInput } from '../../atoms/PrimaryInput'
import { PrimaryNumberInput } from '../../atoms/PrimaryNumberInput'
import { AiOutlineDelete, AiOutlinePlus } from 'react-icons/ai'

export const TableBodyMaterialPrototype3 = (props) => {
  const {
    type,
    materialName,
    materialChargeQuantity,
    unit,
    sellingPrice,
    buyingPrice,
    totalSellingPrice,
    totalBuyingPrice,
    onChangeMaterialName,
    onChangeMaterialChargeQuantity,
    onChangeMaterialUnit,
    onChangeMaterialSellingPrice,
    onChangeMaterialBuyingPrice,
    onClickDeleteMaterial,
    onClickAddProduct,
    isDisabled,
    disabledAddButton,
  } = props

  const units = [
    { value: '', label: '' },
    { value: '個', label: '個' },
    { value: 'kg', label: 'kg' },
    { value: '箱', label: '箱' },
    { value: '本', label: '本' },
    { value: '枚', label: '枚' },
  ]

  return (
    <>
      <Td>
        <PrimaryInput
          placeholder=""
          disabled={isDisabled}
          value={materialName}
          onChange={(e) => onChangeMaterialName(e)}
          htmlSize={14}
        />
      </Td>
      <Td>
        <PrimaryNumberInput
          defaultValue={0}
          value={materialChargeQuantity}
          minValue={0}
          maxValue={100000}
          onChange={(num) => onChangeMaterialChargeQuantity(num)}
          width="120px"
          disabled={isDisabled}
        />
      </Td>
      <Td>
        <Select
          onChange={(e) => onChangeMaterialUnit(e)}
          value={unit}
          bg="white"
          w="120px"
          _focus={{ boxShadow: 'none' }}
          disabled={isDisabled}
        >
          {units.map((unit, index) => {
            return (
              <option value={unit.value} key={index}>
                {unit.label}
              </option>
            )
          })}
        </Select>
      </Td>
      <Td>
        <PrimaryNumberInput
          defaultValue={0}
          value={sellingPrice}
          minValue={0}
          maxValue={100000}
          onChange={(num) => onChangeMaterialSellingPrice(num)}
          width="120px"
          disabled={isDisabled}
        />
      </Td>
      <Td>
        <PrimaryNumberInput
          defaultValue={0}
          value={buyingPrice}
          minValue={0}
          maxValue={100000}
          onChange={(num) => onChangeMaterialBuyingPrice(num)}
          width="120px"
          disabled={isDisabled}
        />
      </Td>
      <Td width="120px" />
      <Td width="120px">
        ¥{(Math.round(totalSellingPrice * 100 || 0) / 100).toLocaleString()}
      </Td>
      <Td width="120px">
        ¥{(Math.round(totalBuyingPrice * 100 || 0) / 100).toLocaleString()}
      </Td>
      <Td />
      <Td />
      <Td isNumeric width="180px">
        {type == 'adding' ? (
          <IconButton
            aria-label="add"
            disabled={isDisabled || disabledAddButton}
            onClick={onClickAddProduct}
            icon={<AiOutlinePlus />}
          />
        ) : (
          <IconButton
            aria-label="delete"
            disabled={isDisabled}
            onClick={onClickDeleteMaterial}
            icon={<AiOutlineDelete />}
          />
        )}
      </Td>
    </>
  )
}
