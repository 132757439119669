import React, { memo } from 'react'
import { Icon } from '@chakra-ui/icon'
import { HamburgerIcon } from '@chakra-ui/icons'

export const MenuIconButton = memo(props => {
  const { onClick, position } = props
  return (
    <Icon boxSize={8} position={position} _hover={{ cursor: 'pointer' }} onClick={onClick}>
      <HamburgerIcon />
    </Icon>
  )
})
