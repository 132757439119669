import React from 'react'
import { TableContainer, Table, Thead, Tr, Th, Tbody } from '@chakra-ui/react'
import { TableBodyDailyReportList } from '../../molecules/projects/TableBodyDailyReportList'

export const TableDailyReportList = (props) => {
  const {reports, recordTypes, onClickOpenReportDetail}=props

  return (
    <TableContainer mb={2}>
      <Table variant="striped" colorScheme="linkedin">
        <Thead>
          <Tr>
            <Th>ID</Th>
            <Th>日報種別</Th>
            <Th>作業日</Th>
            <Th>品番</Th>
            <Th>確認済み/未確認</Th>
            <Th>承認済み/未承認</Th>
            {/* <Th>作成者</Th> */}
            {/* <Th>最終更新日時</Th> */}
          </Tr>
        </Thead>
        <Tbody>
          {reports.map((report, index) => {
            return (
              <Tr key={index}>
                <TableBodyDailyReportList
                  report={report}
                  recordTypes={recordTypes}
                  onClickOpenReportDetail={onClickOpenReportDetail}
                />
              </Tr>
            )
          })}
        </Tbody>
      </Table>
    </TableContainer>
  )
}
