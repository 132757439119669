import React from 'react'
import {
  Flex,
  Text,
} from '@chakra-ui/react'
import { useMessage } from '../../../hooks/useMessage'
import { PrimaryInput } from '../../atoms/PrimaryInput'
import { PrimarySelectBox } from '../../atoms/PrimarySelectBox'

export const CommonInfoOutsourceForExport = (props) => {
  const {
    members,
    companies,
    defaultCreator,
    defaultCompany,
    valueOutsourcingPerson,
    onChangeSelectCreator,
    onChangeSelectCompany,
    onChangeOutsourcingPerson,
    isDisabled,
  } = props

  const { message } = useMessage()

  return (
    <Flex>
      <Flex justify="space-between" align="center" h="40px">
        <Text mr={2}>仕入先</Text>
        <PrimarySelectBox
          options={companies}
          defaultOption={defaultCompany}
          optionElement="outsource_company_name"
          onChangeSelect={onChangeSelectCompany}
          width="165px"
          disabled={isDisabled}
        />
      </Flex>
      <Flex justify="space-between" align="center">
        <Text ml={12} mr={2}>
          仕入先担当者
        </Text>
        <PrimaryInput
          value={valueOutsourcingPerson}
          onChange={(e) => onChangeOutsourcingPerson(e)}
          htmlSize={16}
          disabled={isDisabled}
        />
      </Flex>
      <Flex justify="space-between" align="center">
        <Text ml={12} mr={2}>
          外注書作成者
        </Text>
        <PrimarySelectBox
          options={members}
          defaultOption={defaultCreator}
          optionElement="name"
          onChangeSelect={onChangeSelectCreator}
          width="165px"
          onFocus={() =>
            defaultCreator &&
            message({
              title: '重要情報です。編集の必要性を確認してください',
              status: 'warning',
              position: 'top',
              duration: 5000,
            })
          }
          disabled={isDisabled}
        />
      </Flex>
    </Flex>
  )
}