import React, { useEffect, useState } from 'react'
import { useMessage } from '../../../hooks/useMessage'
import { BeatLoader } from 'react-spinners'
import {
  Box,
  Flex,
  HStack,
  Heading,
} from '@chakra-ui/react'
import {
  clockingInAndOut,
  postDailyReport,
} from '../../../urls'
import { AlertModal } from '../../organisms/AlertModal'
import { useCurrentUser2 } from '../../../hooks/users/useCurrentUser2'
import { useLogout2 } from '../../../hooks/users/useLogout2'
import { useGetData } from '../../../hooks/useGetData'
import { TableDailyReport3 } from '../../organisms/operations/TableDailyReport3'
import { useBlockBrowserBack } from '../../../hooks/users/useBlockBrowserBack'
import { AlertModalWithoutButton } from '../../organisms/AlertModalWithoutButton'
import { DigitalClock } from '../../atoms/DigitalClock'

export const Prototype = () => {
  const { logout } = useLogout2()
  const { message } = useMessage()
  const user = useCurrentUser2()
    const { isBrowserBack, setIsBrowserBack } = useBlockBrowserBack()
  const [isLoadingAction, setIsLoadingAction] = useState(false)
  const { getDailyReportMasters, dailyReportMasters, isLoading } = useGetData()
  const today = new Date()
  const factories = ['志木工場', '朝霞工場', '栃木工場']

  const initAddProcessReport = {
    instructionNum: '',
    instructionNumIndex: 0,
    productNum: '',
    productName: '',
    processName: '',
    machine: '',
    setupTime: 0,
    processingTime: 0,
    handWorkTime: 0,
    completedCount: 0,
    defectiveCount: 0,
  }
  const [addProcessReport, setAddProcessReport] = useState(initAddProcessReport)
  const [isEditing, setIsEditing] = useState(false)
  const [isRegistered, setIsRegistered] = useState(false)
  const [lotsReport, setLotsReport] = useState([])

  useEffect(() => {
    if (user.is_working) {
      getDailyReportMasters()
    }
  }, [])

  const handleChangeInstructionNum = (selectedNum, index) => {
    // selectedNum: 選択したlotNumのindex
    // index: 登録済みの日報の内、lotNumを変更したユニットのindex
    console.log(selectedNum, 'selectedNum')
    console.log(index, 'index')
    setLotsReport(
      lotsReport.map((process, number) => {
        return index == number
          ? {
              ...process,
              instruction_num:
                dailyReportMasters.instructions[selectedNum]['instruction_num'],
              instruction_num_index: selectedNum,
              product_num:
                dailyReportMasters.instructions[selectedNum]['product_num'],
              product_name:
                dailyReportMasters.instructions[selectedNum]['product_name'],
            }
          : process
      })
    )
  }
  const handleChangeProcessName = (selectedName, index) => {
    setLotsReport(
      lotsReport.map((process, number) =>
        index == number
          ? {
              ...process,
              process_name: selectedName,
            }
          : process
      )
    )
  }
  const handleChangeMachine = (selectedName, index) => {
    setLotsReport(
      lotsReport.map((process, number) => {
        return index == number
          ? {
              ...process,
              machine_name: selectedName,
              act_kakou_minute:
                selectedName == '未使用' ? 0 : process.act_kakou_minute,
            }
          : process
      })
    )
  }
  const handleChangeSetupTime = (num, index) => {
    setLotsReport(
      lotsReport.map((process, number) => {
        return index == number
          ? {
              ...process,
              act_setup_minute: num,
            }
          : process
      })
    )
  }
  const handleChangeProcessingTime = (num, index) => {
    setLotsReport(
      lotsReport.map((process, number) => {
        return index == number
          ? {
              ...process,
              act_kakou_minute: num,
            }
          : process
      })
    )
  }
  const handleChangeHandWorkTime = (num, index) => {
    setLotsReport(
      lotsReport.map((process, number) => {
        return index == number
          ? {
              ...process,
              act_handwork_minute: num,
            }
          : process
      })
    )
  }
  const handleChangeCompletedCount = (num, index) => {
    setLotsReport(
      lotsReport.map((process, number) => {
        return index == number
          ? {
              ...process,
              completed_number: num,
            }
          : process
      })
    )
  }
  const handleChangeDefectiveCount = (num, index) => {
    setLotsReport(
      lotsReport.map((process, number) => {
        return index == number
          ? {
              ...process,
              defective_number: num,
            }
          : process
      })
    )
  }

  const handleChangeAddInstructionNum = (index) => {
    setAddProcessReport({
      ...addProcessReport,
      instructionNum: dailyReportMasters.instructions[index]['instruction_num'],
      instructionNumIndex: Number(index),
      productNum: dailyReportMasters.instructions[index]['product_num'],
      productName: dailyReportMasters.instructions[index]['product_name'],
    })
    setIsEditing(true)
  }

  const handleChangeAddProcessName = (name) => {
    setAddProcessReport({
      ...addProcessReport,
      processName: name,
    })
    setIsEditing(true)
  }

  const handleChangeAddMachine = (name) => {
    setAddProcessReport({
      ...addProcessReport,
      machine: name,
      processingTime: name == '未使用' ? 0 : addProcessReport.processingTime,
    })
    setIsEditing(true)
  }

  const handleChangeAddSetupTime = (num) => {
    setAddProcessReport({
      ...addProcessReport,
      setupTime: num,
    })
    setIsEditing(true)
  }

  const handleChangeAddProcessingTime = (num) => {
    setAddProcessReport({
      ...addProcessReport,
      processingTime: num,
    })
    setIsEditing(true)
  }

  const handleChangeAddHandWorkTime = (num) => {
    setAddProcessReport({
      ...addProcessReport,
      handWorkTime: num,
    })
    setIsEditing(true)
  }

  const handleChangeAddCompletedCount = (num) => {
    setAddProcessReport({
      ...addProcessReport,
      completedCount: num,
    })
    setIsEditing(true)
  }

  const handleChangeAddDefectiveCount = (num) => {
    setAddProcessReport({
      ...addProcessReport,
      defectiveCount: num,
    })
    setIsEditing(true)
  }

  const handleDeleteProcessReport = (num) => {
    setLotsReport(lotsReport.filter((_, index) => num !== index))
  }

  const handleClickAddProcessReport = () => {
    setLotsReport([
      ...lotsReport,
      {
        instruction_num: addProcessReport.instructionNum,
        instruction_num_index: addProcessReport.instructionNumIndex,
        product_num: addProcessReport.productNum,
        product_name: addProcessReport.productName,
        process_name: addProcessReport.processName,
        machine_name: addProcessReport.machine,
        act_setup_minute: addProcessReport.setupTime,
        act_kakou_minute: addProcessReport.processingTime,
        act_handwork_minute: addProcessReport.handWorkTime,
        completed_number: addProcessReport.completedCount,
        defective_number: addProcessReport.defectiveCount,
      },
    ])
    setAddProcessReport(initAddProcessReport)
    setIsEditing(false)
  }

  const postParams = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      working_hour_id: '',
      work_start_time: '',
      work_end_time: '',
      user_id: user.user_id,
      work_place: '',
      lot_processes: lotsReport,
      is_direct: true,
    }),
  }

  const handleClickRegisterReport = () => {
    setIsLoadingAction(true)
    fetch(postDailyReport, postParams)
      .then((res) => res.json())
      .then((myjson) => {
        message({ title: '日報の記録に成功しました。', status: 'success' })
        console.log(myjson)
        setIsRegistered(true)
      })
      .catch(() => {
        message({ title: '通信エラーが発生しました', status: 'warning' })
      })
      .finally(() => {
        setIsLoadingAction(false)
      })
  }

  const handleClickClockOut = () => {
    setIsLoadingAction(true)
    fetch(clockingInAndOut, postParams)
      .then((res) => res.json())
      .then((myjson) => {
        console.log(myjson)
        message({
          title: '退勤を記録しました。',
          status: 'success',
        })
        logout()
      })
      .catch(() =>
        message({ title: '通信エラーが発生しました', status: 'warning' })
      )
      .finally(() => {
        setIsLoadingAction(false)
      })
  }

  return (
    <Box m={2}>
      {/* <Flex justifyContent="flex-end" m={5}>
        <Text fontWeight="bold">ログイン者：{user.name}さん</Text>
      </Flex> */}
      <DigitalClock />
      <Heading textAlign="center" mb={10}>
        試作日報作成
      </Heading>
      {isLoading ? (
        <Flex h="100vh" alignItems="center" justifyContent="center" mt={-8}>
          <BeatLoader size={30} color={'#3b5998'} />
        </Flex>
      ) : (
        <>
          {console.log(lotsReport)}
          {console.log(dailyReportMasters)}
          <TableDailyReport3
            instructions={dailyReportMasters.instructions}
            machines={dailyReportMasters.machines}
            processes={lotsReport}
            onChangeInstructionNum={handleChangeInstructionNum}
            onChangeProcessName={handleChangeProcessName}
            onChangeSetupTime={handleChangeSetupTime}
            onChangeProcessingTime={handleChangeProcessingTime}
            onChangeMachine={handleChangeMachine}
            onChangeHandWorkTime={handleChangeHandWorkTime}
            onChangeCompletedCount={handleChangeCompletedCount}
            onChangeDefectiveCount={handleChangeDefectiveCount}
            onChangeAddInstructionNum={handleChangeAddInstructionNum}
            onChangeAddProcessName={handleChangeAddProcessName}
            onChangeAddMachine={handleChangeAddMachine}
            onChangeAddSetupTime={handleChangeAddSetupTime}
            onChangeAddProcessingTime={handleChangeAddProcessingTime}
            onChangeAddHandWorkTime={handleChangeAddHandWorkTime}
            onChangeAddCompletedCount={handleChangeAddCompletedCount}
            onChangeAddDefectiveCount={handleChangeAddDefectiveCount}
            onClickDeleteProcessReport={handleDeleteProcessReport}
            onClickAddProcessReport={handleClickAddProcessReport}
            addProcessReport={addProcessReport}
            disabled={isLoadingAction}
          />
          <Flex justifyContent="center" alignItems="center" mt={6}>
            <HStack>
              <AlertModal
                buttonMessage="記録"
                title="記録"
                body="この内容で日報を記録しますか？"
                onClick={handleClickRegisterReport}
                disabled={
                  isLoadingAction || lotsReport.length === 0 || isEditing
                }
              />
              <AlertModal
                buttonMessage="キャンセル"
                buttonLeft="日報登録に戻る"
                buttonRight="ログアウト"
                title="キャンセル"
                colorScheme="blackAlpha"
                body="日報登録を中断してログアウトしますか？"
                onClick={() => logout()}
                disabled={isLoadingAction}
              />
            </HStack>
          </Flex>
          {isRegistered && (
            <AlertModal
              needButton={false}
              buttonLeft="勤務に戻る"
              buttonRight="退勤"
              title="退勤"
              body="このまま退勤しますか？"
              onClick={handleClickClockOut}
              onClickCancel={() => logout()}
            />
          )}
        </>
      )}
      {isBrowserBack && (
        <AlertModalWithoutButton
          title="戻る"
          body="データを変更した場合、更新ボタンを押さないと編集内容が破棄されますがよろしいですか？"
          onClick={() => window.history.back()}
          onClickCancel={() => {
            window.history.pushState(null, '', window.location.href)
            setIsBrowserBack(false)
          }}
        />
      )}
    </Box>
  )
}
