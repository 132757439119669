import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import BeatLoader from 'react-spinners/BeatLoader'
import { Box, Text, Flex } from '@chakra-ui/react'
import { useGetData } from '../../../hooks/useGetData'
import { SearchModalPrototype2 } from '../../organisms/SearchModalPrototype2'
import { TableMassProjectList2 } from '../../organisms/TableMassProjectList2'

export const ShowList9 = () => {
  const initKeyword = {
    clientName: '',
    projectName: '',
    productName: '',
    lotNum: '',
    startDate: '',
    endDate: '',
    status: '',
    assigner: '',
    minAmount: undefined,
    maxAmount: undefined,
  }
  const defaultRange = {
    lowerDate: '2000-01-01',
    upperDate: '2050-01-01',
    minAmount: 0,
    maxAmount: 9999,
  }

  const initSortOpt = {
    client_name: false,
    project_name: false,
    registered_date: false,
  }

  const [keyword, setKeyword] = useState(initKeyword)
  const [isSearching, setIsSearching] = useState(false)
    const [isReverseSort, setIsReverseSort] = useState(initSortOpt)

  const navigate = useNavigate()
  const {
    getMasters,
    masterList,
    getAllProjectsMass,
    initProjectList,
    projectList,
    setProjectList,
  } = useGetData()

  useEffect(() => {
    const fetchData = async () => {
      await getAllProjectsMass()
      getMasters()
    }
    fetchData()
  }, [])

  const handleSearchList = () => {
    // 検索
    const result = initProjectList
      .filter((clientNameCheck) => {
        return clientNameCheck.client_name.indexOf(keyword.clientName) !== -1
      })
      .filter((projectNameCheck) => {
        return projectNameCheck.project_name.indexOf(keyword.projectName) !== -1
      })
      .filter((project) => {
        return keyword.productName
          ? project.lots.some(
              (productNameCheck) =>
                productNameCheck.product_name.indexOf(keyword.productName) !==
                -1
            ) == true
          : project
      })
      .filter((project) => {
        return keyword.lotNum
          ? project.lots.some(
              (lotNumCheck) =>
                lotNumCheck.lot_num.indexOf(keyword.lotNum) !== -1
            ) == true
          : project
      })
      .filter((registeredDateCheck) => {
        return (
          new Date(registeredDateCheck.registered_date).getTime() >=
            (keyword.startDate == ''
              ? new Date(defaultRange.lowerDate).getTime()
              : new Date(keyword.startDate).getTime()) &&
          new Date(registeredDateCheck.registered_date).getTime() <=
            (keyword.endDate == ''
              ? new Date(defaultRange.upperDate).getTime()
              : new Date(keyword.endDate).getTime())
        )
      })
      .filter((assignerCheck) => {
        return assignerCheck.assigner.indexOf(keyword.assigner) !== -1
      })
      .filter((amountRangeCheck) => {
        return (
          amountRangeCheck.total_amount >=
            (keyword.minAmount == undefined
              ? defaultRange.minAmount * 10000
              : keyword.minAmount * 10000) &&
          amountRangeCheck.total_amount <=
            (keyword.maxAmount == undefined
              ? defaultRange.maxAmount * 10000
              : keyword.maxAmount * 10000)
        )
      })
      .filter((statusCheck) => {
        return statusCheck.project_status.indexOf(keyword.status) !== -1
      })
    setProjectList(result)
    setIsSearching(true)
  }

  const handleResetFilter = () => {
    setProjectList(initProjectList)
    setIsSearching(false)
    setKeyword(initKeyword)
    setIsReverseSort(initSortOpt)
  }

  const handleOpenProjectDetail = (projectId) => {
    navigate('/projects/mass_produce', { state: { projectId } })
  }

    const handleClickSort = (target) => {
      const tempList = Array.from(projectList)
      const tempIsReverse = initSortOpt
      tempList.sort((a, b) => {
        if (target == 'client_name' || target == 'project_name') {
          return a[target] > b[target] ? 1 : -1
        } else {
          return b[target] > a[target] ? 1 : -1
        }
      })
      setProjectList(tempList)
      Object.keys(tempIsReverse).forEach((key) => {
        key == target
          ? (tempIsReverse[key] = true)
          : (tempIsReverse[key] = false)
      })
      setIsReverseSort(tempIsReverse)
    }

    const handleClickSortReverse = () => {
      setProjectList(projectList.toReversed())
      setIsReverseSort(initSortOpt)
    }

  return (
    <>
      {masterList.clients ? (
        <Box m={2}>
          <Flex justify="space-between" textAlign="right">
            <Text visibility={!isSearching && 'hidden'} my={4}>
              該当数：{projectList.length}件
            </Text>
            <SearchModalPrototype2
              title="案件検索"
              clients={masterList.clients}
              defaultClient={keyword.clientName}
              projects={projectList}
              defaultProject={keyword.projectName}
              valueProjectName={keyword.projectName}
              valueProductName={keyword.productName}
              valueLotNum={keyword.lotNum}
              assigners={masterList.users}
              defaultAssigner={keyword.assigner}
              defaultStatus={keyword.status}
              valueStartDate={keyword.startDate}
              valueEndDate={keyword.endDate}
              lowerDate={defaultRange.lowerDate}
              upperDate={defaultRange.upperDate}
              minAmount={defaultRange.minAmount}
              maxAmount={defaultRange.maxAmount}
              valueMinAmount={keyword.minAmount}
              valueMaxAmount={keyword.maxAmount}
              onChangeSelectClientName={(index) =>
                setKeyword({
                  ...keyword,
                  clientName: masterList.clients[index]['client_name'],
                })
              }
              onChangeProjectName={(e) =>
                setKeyword({
                  ...keyword,
                  projectName: e.target.value,
                })
              }
              onChangeProductName={(e) =>
                setKeyword({
                  ...keyword,
                  productName: e.target.value,
                })
              }
              onChangeLotNum={(e) =>
                setKeyword({
                  ...keyword,
                  lotNum: e.target.value,
                })
              }
              onChangeStartDate={(e) =>
                setKeyword({ ...keyword, startDate: e.target.value })
              }
              onChangeEndDate={(e) =>
                setKeyword({ ...keyword, endDate: e.target.value })
              }
              onChangeSelectStatus={(e) =>
                setKeyword({
                  ...keyword,
                  status: e.target.value,
                })
              }
              onChangeSelectAssigner={(index) =>
                setKeyword({
                  ...keyword,
                  assigner: masterList.users[index]['name'],
                })
              }
              onChangeMinAmount={(num) =>
                setKeyword({ ...keyword, minAmount: num })
              }
              onChangeMaxAmount={(num) =>
                setKeyword({ ...keyword, maxAmount: num })
              }
              onClickSearch={handleSearchList}
              onClickReset={handleResetFilter}
              isDisabledResetButton={!isSearching}
            />
          </Flex>

          <Box mt={10}>
            <TableMassProjectList2
              projects={projectList}
              isReverse={isReverseSort}
              onClickOpenProjectDetail={handleOpenProjectDetail}
              onClickSort={handleClickSort}
              onClickSortReverse={handleClickSortReverse}
            />
          </Box>
        </Box>
      ) : (
        <Flex h="100vh" alignItems="center" justifyContent="center">
          <BeatLoader size={30} color={'#3b5998'} />
        </Flex>
      )}
    </>
  )
}
