import React from "react"
import { Button } from '@chakra-ui/react'

export const MenuButton=props=>{
    return(
        <Button 
            position="relative"
            color="white"
            bg="blue.500"
            boxShadow="xl"
            overflow="hidden"
            py={6}
            _before={{
                content: `""`,
                display: "inline-block",
                position: "absolute",
                top: "0",
                w: "100%",
                h: "100%",
                bg: "blue.700",
                transition: "0.5s",
                right: "100%"
            }}
            _hover={{
                _before: {right: 0}
            }} 
            onClick={props.onClick}
        >
            {props.children}
        </Button>
    )
}