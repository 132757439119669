export const exportPdf =
  'https://script.google.com/macros/s/AKfycbzTHX1gayltUCl4CPd59PXxWBDsmxjjyd5ihpgoxNWjlIF4Gv8U27Y8VyLglmRQ7NE2/exec'

export const projectData4 =
  'https://script.google.com/macros/s/AKfycbwA7xUwRzp0bVMG4cmH_SofkeZPsFa6ARnzvwfdV61HMjby1VXXjIxiY9ruZ4J1rlEL/exec'

export const allProjectsMass =
  'https://miyama-back.datamission.jp/miyama-giken/project-all/mass/'

export const allProjectsPrototype =
  'https://miyama-back.datamission.jp/miyama-giken/project-all/prototype/'

export const getProject = (projectId) =>
  `https://miyama-back.datamission.jp/miyama-giken/projects/${projectId}`

export const getNewProjectFormat =
  'https://miyama-back.datamission.jp/miyama-giken/project-initial'

export const createProject = `https://miyama-back.datamission.jp/api/projects/`

export const putProject = (projectId) =>
  `https://miyama-back.datamission.jp/api/projects/${projectId}/`

export const copyProject = (projectId) =>
  `https://miyama-back.datamission.jp/miyama-giken/projects/${projectId}/copy/`

export const getLot = (lotNum) =>
  `https://miyama-back.datamission.jp/miyama-giken/lots/${lotNum}`

export const createLot = `https://miyama-back.datamission.jp/api/lots/`

export const putLot = (lotNum) =>
  `https://miyama-back.datamission.jp/api/lots/${lotNum}/`

export const postSketch = () => `https://miyama-back.datamission.jp/api/food/`

export const exportOnly =
  'https://miyama-back.datamission.jp/miyama-giken/only-export/to-pdf/'

export const projectEstimate = (projectId) =>
  `https://miyama-back.datamission.jp/miyama-giken/projects/${projectId}/estimate/to-pdf`

export const projectBill = (projectId) =>
  `https://miyama-back.datamission.jp/miyama-giken/projects/${projectId}/bill/to-pdf`

export const projectReceipt = (projectId) =>
  `https://miyama-back.datamission.jp/miyama-giken/receipt/${projectId}/to-pdf/`

export const productEstimate = (lotNum) =>
  `https://miyama-back.datamission.jp/miyama-giken/lots/${lotNum}/estimate/to-pdf`

export const productBill = (projectId) =>
  `http://127.0.0.1:8000/miyama-giken/projects/${projectId}/bill/to-pdf`

export const login = 'https://miyama-back.datamission.jp/miyama-giken/login/'

// 打刻
export const clockingInAndOut =
  'https://miyama-back.datamission.jp/miyama-giken/working-hour'

// 日報
export const getDailyReportData =
  'https://miyama-back.datamission.jp/miyama-giken/login-master'

export const postDailyReport =
  'https://miyama-back.datamission.jp/miyama-giken/working-record/'

// 日報一覧
export const getDailyReportIndex =
  'https://miyama-back.datamission.jp/miyama-giken/mass-working-record/'

// レーザー日報
export const targetLaserReport = (id) =>
  `https://miyama-back.datamission.jp/api/laser-working-record/${id}/`
export const postLaserReport =
  'https://miyama-back.datamission.jp/api/laser-working-record/'

// 検査日報
export const targetInspectionReport = (id) =>
  `https://miyama-back.datamission.jp/api/inspection-working-record/${id}/`
export const postInspectionReport =
  'https://miyama-back.datamission.jp/api/inspection-working-record/'

// 塗装日報
export const targetPaintingReport = (id) =>
  `https://miyama-back.datamission.jp/api/painting-working-record/${id}/`
export const postPaintingReport =
  'https://miyama-back.datamission.jp/api/painting-working-record/'

// 印刷日報
export const targetPrintingReport = (id) =>
  `https://miyama-back.datamission.jp/api/printing-working-record/${id}/`
export const postPrintReport = "https://miyama-back.datamission.jp/api/printing-working-record/"

// 成形日報
export const targetMoldingReport = (id) =>
  `https://miyama-back.datamission.jp/api/molding-working-record/${id}/`
export const postMoldingReport = "https://miyama-back.datamission.jp/api/molding-working-record/"

// 作業指示書
export const getInstruction = (lotNum) =>
  `https://miyama-back.datamission.jp/miyama-giken/work-instruction/${lotNum}/`

export const createInstruction = `https://miyama-back.datamission.jp/api/work-instruction/`

export const putInstruction = (instructionNum) =>
  `https://miyama-back.datamission.jp/api/work-instruction/${instructionNum}/`

export const exportInstruction = (instructionNum) =>
  `https://miyama-back.datamission.jp/miyama-giken/work-instruction/${instructionNum}/to-pdf/`

// 外注・納品書
export const getOutsource = (outsourceNum) =>
  `https://miyama-back.datamission.jp/api/purchase-order/${outsourceNum}/`

export const createOutsource = `https://miyama-back.datamission.jp/api/purchase-order/`

export const putOutsource = (outsourceNum) =>
  `https://miyama-back.datamission.jp/api/purchase-order/${outsourceNum}/`

export const exportOutsource = (outsourceNum) =>
  `https://miyama-back.datamission.jp/miyama-giken/purchase-order/${outsourceNum}/to-pdf/`

// マスタデータ
export const masters = "https://miyama-back.datamission.jp/miyama-giken/master/"

// 製品一覧
export const products = 'https://miyama-back.datamission.jp/api/products/'

// 量産案件一覧
export const massProjectList =
  'https://miyama-back.datamission.jp/miyama-giken/project-all/mass/'

export const createMassProject =
  `https://miyama-back.datamission.jp/api/mass-project/`

export const targetMassProjectList = (projectId) =>
  `https://miyama-back.datamission.jp/api/mass-project/${projectId}/`

// 量産日報一覧
export const massDailyReportList =
  'https://miyama-back.datamission.jp/miyama-giken/mass-working-record/'