import React from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Flex,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  Heading,
  Stack,
} from '@chakra-ui/react'
import { MenuButton } from '../atoms/MenuButton'
import { MenuIconButton } from '../atoms/MenuIconButton'
import { useCurrentUser2 } from '../../hooks/users/useCurrentUser2'
import { useLogout2 } from '../../hooks/users/useLogout2'

export const Header2 = () => {
  const navigate = useNavigate()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const user = useCurrentUser2()
  const { logout } = useLogout2()

  const handleClickFormatA = () => {
    onClose()
    navigate('/projects/format_a')
    window.location.reload()
  }
  const handleClickFormatAOnlyExport = () => {
    onClose()
    navigate('/projects/format_a/export_only')
    window.location.reload()
  }
  const handleClickMassProduce = () => {
    onClose()
    navigate('/projects/mass_produce')
    window.location.reload()
  }
  const handleClickOutSource = () => {
    onClose()
    navigate(`/projects/outsource?isOnlyExport=${true}`)
    window.location.reload()
  }

  const handleClickList = () => {
    onClose()
    navigate('/projects/index')
  }
  const handleClickDailyReport = () => {
    onClose()
    navigate('/daily_report')
  }
  const handleClickDataAnalysis = () => {
    onClose()
    navigate('/data_analysis')
  }
  const handleClickGanttChart = () => {
    onClose()
    navigate('/scheduling')
  }
  const handleClickLogout = () => {
    // mutate()
    logout()
    onClose()
  }

  return (
    <>
      <Flex justifyContent="flex-end" p={4} position="relative" zIndex={100}>
        <MenuIconButton onClick={onOpen} position="fixed" />
      </Flex>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton _focus={{ boxShadow: 'none' }} />
          <DrawerHeader>メニュー</DrawerHeader>
          <DrawerBody mt={10}>
            <Stack spacing={2}>
              {user.access_level !== 'worker' && (
                <>
                  <MenuButton onClick={handleClickList}>
                    <Heading as="h4" size="sm" zIndex={1}>
                      案件一覧
                    </Heading>
                  </MenuButton>
                  {/* <MenuButton onClick={handleClickMassProduce}>
                    <Heading as="h4" size="sm" zIndex={1}>
                      量産案件作成
                    </Heading>
                  </MenuButton> */}
                  <MenuButton onClick={handleClickFormatA}>
                    <Heading as="h4" size="sm" zIndex={1}>
                      試作案件作成
                    </Heading>
                  </MenuButton>
                  <MenuButton onClick={handleClickFormatAOnlyExport}>
                    <Heading as="h4" size="sm" zIndex={1}>
                      試作案件直接出力
                    </Heading>
                  </MenuButton>
                  <MenuButton onClick={handleClickOutSource}>
                    <Heading as="h4" size="sm" zIndex={1}>
                      発注書直接出力
                    </Heading>
                  </MenuButton>
                </>
              )}
              {/* ヘッダーから日報に遷移したい場合は、currentUser情報にis_workingフラグを入れてもらう */}
              {user.access_level !== 'sales' && user.is_working && (
                <MenuButton onClick={handleClickDailyReport}>
                  <Heading as="h4" size="sm" zIndex={1}>
                    日報
                  </Heading>
                </MenuButton>
              )}
              {/* {user.access_level == 'admin' && (
                <MenuButton onClick={handleClickDataAnalysis}>
                  <Heading as="h4" size="sm" zIndex={1}>
                    データ分析
                  </Heading>
                </MenuButton>
              )}
              <MenuButton onClick={handleClickGanttChart}>
                <Heading as="h4" size="sm" zIndex={1}>
                  ガントチャート
                </Heading>
              </MenuButton> */}
              <MenuButton onClick={handleClickLogout}>
                <Heading as="h4" size="sm" zIndex={1}>
                  ログアウト
                </Heading>
              </MenuButton>
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}
