import React, { useState } from "react"
import {
  Gantt,
  Task as GanttTask,
  EventOption,
  StylingOption,
  ViewMode,
  DisplayOption,
} from 'gantt-task-react'
import 'gantt-task-react/dist/index.css'
import { getStartEndDateForProject, initTasks } from "../../helper/GanttHelper"
import { ViewSwitcher } from "../molecules/ganttChart/ViewSwicher"
import { Heading } from "@chakra-ui/react"

export const GanttChart2=()=>{
const [view, setView] = useState(ViewMode.Day)
const [tasks, setTasks] = useState(initTasks())
const [isChecked, setIsChecked] = useState(true)
let columnWidth = 65
if (view === ViewMode.Month) {
  columnWidth = 300
} else if (view === ViewMode.Week) {
  columnWidth = 250
}

const handleTaskChange = (task, targetTasks) => {
  console.log('On date change Id:' + task.id)
  let newTasks = targetTasks.map((t) => (t.id === task.id ? task : t))
  if (task.project) {
    const [start, end] = getStartEndDateForProject(newTasks, task.project)
    const project = newTasks[newTasks.findIndex((t) => t.id === task.project)]
    if (
      project.start.getTime() !== start.getTime() ||
      project.end.getTime() !== end.getTime()
    ) {
      const changedProject = { ...project, start, end }
      newTasks = newTasks.map((t) =>
        t.id === task.project ? changedProject : t
      )
    }
  }
  setTasks(newTasks)
}

const handleTaskDelete = (task, targetTasks) => {
  const conf = window.confirm('Are you sure about ' + task.name + ' ?')
  if (conf) {
    setTasks(targetTasks.filter((t) => t.id !== task.id))
  }
  return conf
}

const handleProgressChange = async (task, targetTasks) => {
  setTasks(targetTasks.map((t) => (t.id === task.id ? task : t)))
  console.log('On progress change Id:' + task.id)
}

const handleDblClick = (task) => {
  alert('On Double Click event Id:' + task.id)
}

const handleSelect = (task, isSelected) => {
  console.log(task.name + ' has ' + (isSelected ? 'selected' : 'unselected'))
}

const handleExpanderClick = (task, targetTasks) => {
  setTasks(targetTasks.map((t) => (t.id === task.id ? task : t)))
  console.log('On expander click Id:' + task.id)
}

return (
  <>
    <Heading textAlign="center" mb={10}>
      ガントチャート
    </Heading>
    <ViewSwitcher
      onViewModeChange={(viewMode) => setView(viewMode)}
      onViewListChange={setIsChecked}
      isChecked={isChecked}
    />
    <Gantt
      tasks={tasks}
      viewMode={view}
      onDateChange={(task)=>handleTaskChange(task, tasks)}
      onDelete={(task)=>handleTaskDelete(task, tasks)}
      onProgressChange={(task)=>handleProgressChange(task, tasks)}
      onDoubleClick={handleDblClick}
      onSelect={handleSelect}
      onExpanderClick={(task)=>handleExpanderClick(task, tasks)}
      listCellWidth={isChecked ? '155px' : ''}
      columnWidth={columnWidth}
      locale="jap"
    />
    {/* <h3>Gantt With Limited Height</h3>
    <Gantt
      tasks={tasks}
      viewMode={view}
      onDateChange={handleTaskChange}
      onDelete={handleTaskDelete}
      onProgressChange={handleProgressChange}
      onDoubleClick={handleDblClick}
      onSelect={handleSelect}
      onExpanderClick={handleExpanderClick}
      listCellWidth={isChecked ? '155px' : ''}
      ganttHeight={300}
      columnWidth={columnWidth}
    /> */}
  </>
)}