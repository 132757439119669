import { useState } from 'react'
import axios from 'axios'
import {
  massProjectList,
  allProjectsPrototype,
  copyProject,
  getDailyReportData,
  getDailyReportIndex,
  getInstruction,
  getLot,
  getNewProjectFormat,
  getOutsource,
  getProject,
  masters,
  products,
  projectData4,
  targetMassProjectList,
} from '../urls'
import { useSetRecoilState } from 'recoil'
import { useCurrentUser2 } from './users/useCurrentUser2'
import { productState5 } from './store/productState5'
import { initOutsource } from '../constants/initOutsource'

export const useGetData = () => {
  // const user = useCurrentUser()
  const user = useCurrentUser2()
  // const [cookies, setCookie, removeCookie] = useCookies(['user'])
  // const user = cookies.user

  const initProjectInfo = {
    data_type: 'new_project',
    clients: [
      {
        client_name: '',
        postal_code: '',
        address: '',
      },
    ],
    users: [
      {
        name: '',
        email: '',
      },
    ],
    products: [
      {
        product_id: '',
        product_name: '',
      },
    ],
    project: {
      project_id: '',
      project_name: '',
      update_date: '',
      project_status: '',
      due_date: '',
      expiring_date: '',
      count: '',
      registered_date: '',
      client_name: '',
      postal_code: '',
      address: '',
      assigner: user ? user.name : '',
      email: user ? user.email : '',
      deadline: '',
      is_stamp: true,
      tax_rate: '',
      sub_total: '',
      note_estimate: '',
      note_bill: '',
      is_prototype: false,
      lots: [
        {
          lot_num: '',
          product_num: '',
          product_name: '',
          lot_amount: '',
          unit_cost: '',
          product_cost: '',
          note_estimate: '',
          is_delete: false,
        },
      ],
    },
  }

  const [masterList, setMasterList] = useState({})
  const [productList, setProductList] = useState([])
  const [initProjectList, setInitProjectList] = useState([])
  const [projectList, setProjectList] = useState([])
  const [project, setProject] = useState(initProjectInfo)
  const [projectMass, setProjectMass] = useState({})
  const [instruction, setInstruction] = useState({})
  const [outsource, setOutsource] = useState(initOutsource)
  const [dailyReportMasters, setDailyReportMasters] = useState({})
  const [dailyReportList, setDailyReportList] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const setProduct = useSetRecoilState(productState5)

  const getProducts = async () => {
    const res = await axios.get(products)
    setProductList(res.data)
  }

  const getMasters = async () => {
    const res = await axios.get(masters)
    setMasterList(res.data)
    setIsLoading(false)
  }

  const getAllProjects = async () => {
    const res = await axios.get(projectData4)
    setProjectList(res.data)
    setIsLoading(false)
  }

  const getAllProjectsMass = async () => {
    const res = await axios.get(massProjectList)
    setProjectList(res.data.projects)
    setInitProjectList(res.data.projects)
  }

  const getAllProjectsPrototype = async () => {
    const res = await axios.get(allProjectsPrototype)
    setProjectList(res.data.projects)
    setInitProjectList(res.data.projects)
  }

  const getTargetProject = async (projectId) => {
    const res = await axios.get(getProject(projectId))
    setProject(res.data)
  }

  const getNewProject = async () => {
    const res = await axios.get(getNewProjectFormat)
    setProject(res.data)
  }

  const getTargetProjectMass = async (projectId) => {
    const res = await axios.get(targetMassProjectList(projectId))
    setProjectMass(res.data)
    setIsLoading(false)
  }

  const copyTargetProject = async (projectId) => {
    const res = await axios.get(copyProject(projectId))
    setProject(res.data)
    setIsLoading(false)
  }

  const getTargetProduct = async (lotNum) => {
    const res = await axios.get(getLot(lotNum))
    setProduct(res.data)
  }

  const getTargetInstruction = async (lotNum) => {
    const res = await axios.get(getInstruction(lotNum))
    console.log(res)
    setInstruction(res.data)
  }

  const getTargetOutsource = async (outsourceId) => {
    const res = await axios.get(getOutsource(outsourceId))
    console.log(res)
    setOutsource(res.data)
    setIsLoading(false)
  }

  const getDailyReportMasters = async () => {
    const res = await axios.get(getDailyReportData)
    console.log(res)
    setDailyReportMasters(res.data)
    setIsLoading(false)
    return res.data
  }

  const getDailyReportList = async (keyword) => {
    const res = await axios.get(getDailyReportIndex, { params: keyword })
    setDailyReportList(res.data)
    return res.data
  }

  return {
    getProducts,
    getMasters,
    getAllProjects,
    getAllProjectsMass,
    getAllProjectsPrototype,
    getTargetProject,
    getNewProject,
    getTargetProjectMass,
    copyTargetProject,
    getTargetProduct,
    getTargetInstruction,
    getTargetOutsource,
    getDailyReportMasters,
    getDailyReportList,
    dailyReportMasters,
    productList,
    masterList,
    initProjectList,
    projectList,
    setProjectList,
    project,
    setProject,
    projectMass,
    setProjectMass,
    instruction,
    setInstruction,
    outsource,
    setOutsource,
    dailyReportList,
    isLoading,
    setIsLoading,
    initProjectInfo,
  }
}
