import React, { useEffect, useState } from 'react'
import { useGetData } from '../../../hooks/useGetData'
import { CommonInfoInstruction3 } from '../../organisms/instruction/CommonInfoInstruction3'
import { TableInstruction3 } from '../../organisms/instruction/TableInstruction3'
import { Box, Flex, Heading, Stack } from '@chakra-ui/react'
import { BeatLoader } from 'react-spinners'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { AlertModal } from '../../organisms/AlertModal'
import {
  createInstruction,
  exportInstruction,
  putInstruction,
} from '../../../urls'
import { useMessage } from '../../../hooks/useMessage'
import { useBlockBrowserBack } from '../../../hooks/users/useBlockBrowserBack'
import { AlertModalWithoutButton } from '../../organisms/AlertModalWithoutButton'

export const Instruction4 = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { message } = useMessage()
  const [searchParams] = useSearchParams()
  const { isBrowserBack, setIsBrowserBack } = useBlockBrowserBack()
  const {
    getTargetInstruction,
    instruction,
    setInstruction,
    isLoading,
    setIsLoading,
  } = useGetData()
  const initAddProcess = {
    processName: '',
    etaSetupMinute: 0,
    etaProcessingMinute: 0,
    etaHandworkMinute: 0,
    dueDate: '',
    isOutsource: false,
  }
  const [isLoadingAction, setIsLoadingAction] = useState()
  const [isCreate, setIsCreate] = useState(false)
  const [addProcess, setAddProcess] = useState(initAddProcess)
  const lotNum = searchParams.get('lotNum')
  const instructionNum = searchParams.get('instructionNum')

  useEffect(() => {
    !instructionNum && setIsCreate(true)
    const getData = async () => {
      await getTargetInstruction(lotNum)
      setIsLoading(false)
    }
    getData()
  }, [])

  const handleChangeCustomerOrderNum = (e) => {
    setInstruction({
      ...instruction,
      instruction: {
        ...instruction.instruction,
        customer_order_num: e.target.value,
      },
    })
  }

  const handleChangeOrderedDate = (e) => {
    setInstruction({
      ...instruction,
      instruction: {
        ...instruction.instruction,
        ordered_date: e.target.value,
      },
    })
  }

  const handleChangeDueDate = (e) => {
    setInstruction({
      ...instruction,
      instruction: {
        ...instruction.instruction,
        due_date: e.target.value,
      },
    })
  }

  const handleChangeSelectCreator = (index) => {
    setInstruction({
      ...instruction,
      instruction: {
        ...instruction.instruction,
        created_by: instruction.users[index]['name'],
      },
    })
  }

  const handleChangeStockCount = (num) => {
    setInstruction({
      ...instruction,
      instruction: {
        ...instruction.instruction,
        stock_count: num,
      },
    })
  }

  const handleChangeIsProvided = (value, index) => {
    setInstruction({
      ...instruction,
      instruction: {
        ...instruction.instruction,
        materials: instruction.instruction.materials.map((info, number) =>
          index == number
            ? {
                ...info,
                is_provided: JSON.parse(value),
              }
            : info
        ),
      },
    })
  }

  const handleChangeNoteForInstruction = (e) => {
    setInstruction({
      ...instruction,
      instruction: {
        ...instruction.instruction,
        note: e.target.value,
      },
    })
  }

  const handleChangeProcessName = (e, index) => {
    setInstruction({
      ...instruction,
      instruction: {
        ...instruction.instruction,
        processes: instruction.instruction.processes.map((info, number) =>
          index == number
            ? {
                ...info,
                process_name: e.target.value,
              }
            : info
        ),
      },
    })
  }

  const handleChangeEtaSetUpMinute = (num, index) => {
    setInstruction({
      ...instruction,
      instruction: {
        ...instruction.instruction,
        processes: instruction.instruction.processes.map((info, number) =>
          index == number
            ? {
                ...info,
                eta_setup_minute: num,
              }
            : info
        ),
      },
    })
  }

  const handleChanceEtaProcessingMinute = (num, index) => {
    setInstruction({
      ...instruction,
      instruction: {
        ...instruction.instruction,
        processes: instruction.instruction.processes.map((info, number) =>
          index == number
            ? {
                ...info,
                eta_kakou_minute: num,
              }
            : info
        ),
      },
    })
  }

  const handleChangeEtaHandworkMinute = (num, index) => {
    setInstruction({
      ...instruction,
      instruction: {
        ...instruction.instruction,
        processes: instruction.instruction.processes.map((info, number) =>
          index == number
            ? {
                ...info,
                eta_handwork_minute: num,
              }
            : info
        ),
      },
    })
  }

  const handleChangeProcessDueDate = (e, index) => {
    setInstruction({
      ...instruction,
      instruction: {
        ...instruction.instruction,
        processes: instruction.instruction.processes.map((info, number) =>
          index == number
            ? {
                ...info,
                due_date: e.target.value,
              }
            : info
        ),
      },
    })
  }

  const handleChangeIsOutsource = (e, index) => {
    setInstruction({
      ...instruction,
      instruction: {
        ...instruction.instruction,
        processes: instruction.instruction.processes.map((info, number) =>
          index == number
            ? {
                ...info,
                is_outsource: e.target.checked,
              }
            : info
        ),
      },
    })
  }

  const handleChangeProcessNameAdd = (e) => {
    setAddProcess({
      ...addProcess,
      processName: e.target.value,
    })
  }

  const handleChangeEtaSetUpMinuteAdd = (num) => {
    setAddProcess({
      ...addProcess,
      etaSetupMinute: num,
    })
  }

  const handleChanceEtaProcessingMinuteAdd = (num) => {
    setAddProcess({
      ...addProcess,
      etaProcessingMinute: num,
    })
  }

  const handleChangeEtaHandworkMinuteAdd = (num) => {
    setAddProcess({
      ...addProcess,
      etaHandworkMinute: num,
    })
  }

  const handleChangeDueDateAdd = (e) => {
    setAddProcess({
      ...addProcess,
      dueDate: e.target.value,
    })
  }

  const handleChangeIsOutsourceAdd = (e) => {
    setAddProcess({
      ...addProcess,
      isOutsource: e.target.checked,
    })
  }

  const handleClickEditOutSource = (process) => {
    if (process.outsource_id) {
      navigate(
        `/projects/outsource?lotNum=${instruction.instruction.lot_num}&outSourceId=${process.outsource_id}&processName=${process.process_name}`
      )
    } else if (process.process_plan_id) {
      navigate(
        `/projects/outsource?lotNum=${instruction.instruction.lot_num}&processPlanId=${process.process_plan_id}&processName=${process.process_name}`
      )
    }
  }

  const handleAddProcess = () => {
    setInstruction({
      ...instruction,
      instruction: {
        ...instruction.instruction,
        processes: [
          ...instruction.instruction.processes,
          {
            process_plan_id: '',
            process_name: addProcess.processName,
            eta_setup_minute: addProcess.etaSetupMinute,
            act_setup_minute: 0,
            eta_kakou_minute: addProcess.etaProcessingMinute,
            act_kakou_minute: 0,
            eta_handwork_minute: addProcess.etaHandworkMinute,
            act_handwork_minute: 0,
            due_date: addProcess.dueDate,
            completed_number: 0,
            defective_number: 0,
            workers: '',
            is_outsource: addProcess.isOutsource,
            is_delete: false,
          },
        ],
      },
    })
    setAddProcess(initAddProcess)
  }

  const handleDeleteProcess = (index) => {
    setInstruction({
      ...instruction,
      instruction: {
        ...instruction.instruction,
        processes: instruction.instruction.processes.map((info, num) =>
          index == num
            ? {
                ...info,
                is_delete: true,
              }
            : info
        ),
      },
    })
  }

  const postParams = {
    method: isCreate ? 'POST' : 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(instruction.instruction),
  }

  const handleCreateInstruction = () => {
    setIsLoadingAction(true)
    fetch(createInstruction, postParams)
      .then((res) => res.json())
      .then((myjson) => {
        if (myjson.instruction_num) {
          message({
            title: '作業指示書の作成に成功しました。',
            status: 'success',
          })
          setInstruction({
            ...instruction,
            instruction: myjson,
          })
          setIsCreate(false)
          setIsLoadingAction(false)
        } else {
          message({ title: '通信エラーが発生しました', status: 'warning' })
        }
      })
      .catch(() =>
        message({ title: '通信エラーが発生しました', status: 'warning' })
      )
      .finally(() => setIsLoadingAction(false))
  }

  const handleUpdateInstruction = () => {
    setIsLoadingAction(true)
    fetch(putInstruction(instruction.instruction.instruction_num), postParams)
      .then((res) => res.json())
      .then((myjson) => {
        setInstruction({
          ...instruction,
          instruction: {
            ...instruction.instruction,
            processes: myjson.processes,
          },
        })
        message({
          title: '作業指示・実績書の更新に成功しました。',
          status: 'success',
        })
      })
      .catch(() =>
        message({ title: '通信エラーが発生しました', status: 'warning' })
      )
      .finally(() => setIsLoadingAction(false))
  }

  const handleExportInstruction = () => {
    setIsLoadingAction(true)
    fetch(exportInstruction(instruction.instruction.instruction_num), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((myjson) => {
        message({
          title: '書類の作成に成功しました。',
          status: 'success',
          duration: 10000,
        })
        window.open(myjson.message)
      })
      .catch(() =>
        message({ title: '通信エラーが発生しました', status: 'warning' })
      )
      .finally(() => setIsLoadingAction(false))
  }

  return (
    <>
      {isLoading ? (
        <Flex h="100vh" alignItems="center" justifyContent="center">
          <BeatLoader size={30} color={'#3b5998'} />
        </Flex>
      ) : (
        <Box m={6}>
          <Heading mb={10} align="center">
            作業指示・実績書{isCreate ? '登録' : '詳細'}
          </Heading>
          <CommonInfoInstruction3
            instruction={instruction.instruction}
            members={instruction.users}
            materials={instruction.instruction.materials}
            defaultCreator={instruction.instruction.created_by}
            valueCustomerOrderNum={instruction.instruction.customer_order_num}
            valueOrderedDate={instruction.instruction.ordered_date}
            valueDueDate={instruction.instruction.due_date}
            valueStockCount={instruction.instruction.stock_count}
            valueNoteForInstruction={instruction.instruction.note}
            onChangeCustomerOrderNum={handleChangeCustomerOrderNum}
            onChangeOrderedDate={handleChangeOrderedDate}
            onChangeDueDate={handleChangeDueDate}
            onChangeSelectCreator={handleChangeSelectCreator}
            onChangeStockCount={handleChangeStockCount}
            onChangeIsProvided={handleChangeIsProvided}
            onChangeNoteForInstruction={handleChangeNoteForInstruction}
            isDisabled={isLoadingAction}
          />
          <Box>
            <Heading as="h3" size="md" mb={4}>
              工程
            </Heading>
            <TableInstruction3
              processes={instruction.instruction.processes}
              addProcess={addProcess}
              onChangeProcessName={(e, index) =>
                handleChangeProcessName(e, index)
              }
              onChangeEtaSetUpMinute={(num, index) =>
                handleChangeEtaSetUpMinute(num, index)
              }
              onChanceEtaProcessingMinute={(num, index) =>
                handleChanceEtaProcessingMinute(num, index)
              }
              onChangeEtaHandworkMinute={(num, index) =>
                handleChangeEtaHandworkMinute(num, index)
              }
              onChangeDueDate={(e, index) =>
                handleChangeProcessDueDate(e, index)
              }
              onChangeIsOutsource={(e, index) =>
                handleChangeIsOutsource(e, index)
              }
              onChangeProcessNameAdd={handleChangeProcessNameAdd}
              onChangeEtaSetUpMinuteAdd={handleChangeEtaSetUpMinuteAdd}
              onChanceEtaProcessingMinuteAdd={
                handleChanceEtaProcessingMinuteAdd
              }
              onChangeEtaHandworkMinuteAdd={handleChangeEtaHandworkMinuteAdd}
              onChangeDueDateAdd={handleChangeDueDateAdd}
              onChangeIsOutsourceAdd={handleChangeIsOutsourceAdd}
              onClickEditOutSource={handleClickEditOutSource}
              onClickAddProcess={handleAddProcess}
              onClickDeleteProcess={(index) => handleDeleteProcess(index)}
              isCreate={isCreate}
              isLoadingAction={isLoadingAction}
            />
          </Box>
          <Box>
            <Flex my={4} mx={6} justify="space-between" align="flex-end">
              <Box>
                <AlertModal
                  body="データを変更した場合、更新ボタンを押さないと編集内容が破棄されますがよろしいですか？"
                  buttonMessage="案件詳細に戻る"
                  title="案件詳細に戻る"
                  onClick={() =>
                    navigate(
                      `/projects/format_a?id=${instruction.project.project_id}`
                    )
                  }
                  disabled={isLoadingAction}
                  primaryColor={false}
                />
                <AlertModal
                  body="データを変更した場合、更新ボタンを押さないと編集内容が破棄されますがよろしいですか？"
                  buttonMessage="部品詳細に戻る"
                  title="部品詳細に戻る"
                  onClick={() =>
                    navigate(
                      `/projects/format_b?projectId=${instruction.project.project_id}&lotNum=${instruction.instruction.lot_num}`
                    )
                  }
                  disabled={isLoadingAction}
                  primaryColor={false}
                />
              </Box>
              {isCreate ? (
                <AlertModal
                  buttonMessage="新規登録"
                  title="新規登録"
                  body="この内容で新規作成しますか？"
                  onClick={handleCreateInstruction}
                  width="150px"
                  disabled={
                    !instruction.instruction.customer_order_num ||
                    !instruction.instruction.created_by ||
                    !instruction.instruction.due_date ||
                    !instruction.instruction.ordered_date ||
                    isLoadingAction
                  }
                />
              ) : (
                <Flex direction="column">
                  <Stack spacing={2}>
                    <AlertModal
                      buttonMessage="更新"
                      title="更新"
                      body="この内容で更新しますか？"
                      onClick={handleUpdateInstruction}
                      width="150px"
                      disabled={isLoadingAction}
                    />
                    <AlertModal
                      buttonMessage="作業指示書出力"
                      title="作業指示書出力"
                      body="作業指示書をダウンロードしますか？"
                      onClick={handleExportInstruction}
                      disabled={isLoadingAction}
                      colorScheme="yellow"
                    />
                  </Stack>
                </Flex>
              )}
            </Flex>
          </Box>
          {isBrowserBack && (
            <AlertModalWithoutButton
              title="戻る"
              body="データを変更した場合、更新ボタンを押さないと編集内容が破棄されますがよろしいですか？"
              onClick={() => window.history.back()}
              onClickCancel={() => {
                window.history.pushState(null, '', window.location.href)
                setIsBrowserBack(false)
              }}
            />
          )}
        </Box>
      )}
    </>
  )
}
