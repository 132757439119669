import React from 'react'
import { Td, Text } from '@chakra-ui/react'

export const TableBodyDailyReportList = (props) => {
  const { report, recordTypes, onClickOpenReportDetail } = props

  return (
    <>
      <Td>
        <Text
          color="#3b5998"
          fontWeight="semibold"
          as="a"
          cursor="pointer"
          onClick={() => onClickOpenReportDetail(report)}
        >
          {report.id}
        </Text>
      </Td>
      <Td>
        <Text>
          {recordTypes.find(
            (recordType) => recordType.value == report.record_type
          ).name || '-'}
        </Text>
      </Td>
      <Td>
        <Text>{report.working_date}</Text>
      </Td>
      <Td>
        <Text>{report.product_number}</Text>
      </Td>
      {/* <Td>
        <Text>{report.is_confirmed == true ? '確認済み' : '未確認'}</Text>
      </Td> */}
      <Td>
        <Text>確認済み</Text>
      </Td>
      <Td>
        <Text>{report.is_approved == true ? '承認済み' : '未承認'}</Text>
      </Td>
      {/* <Td>
        <Text>{report.registered_by}</Text>
      </Td> */}
      {/* <Td>
        <Text>{report.updating_date}</Text>
      </Td> */}
    </>
  )
}
