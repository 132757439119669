import React, { useState, useEffect, useCallback } from 'react'
import {
  createProject,
  projectBill,
  projectEstimate,
  putProject,
  putLot,
  productEstimate,
  projectReceipt,
  exportOnly,
} from '../../../urls'
import { useNavigate, useSearchParams } from 'react-router-dom'
import {
  Box,
  Heading,
  Flex,
  Table,
  TableContainer,
  Text,
  IconButton,
} from '@chakra-ui/react'
import { useMessage } from '../../../hooks/useMessage'
import { AlertModal } from '../../organisms/AlertModal'
import { TableBottom4 } from '../../molecules/formatA/TableBottom4'
import { BeatLoader } from 'react-spinners'
import { useGetData } from '../../../hooks/useGetData'
import { CommonInfoProject3 } from '../../organisms/project/CommonInfoProject3'
import { AiOutlinePlus } from 'react-icons/ai'
import { TableProductList } from '../../organisms/project/TableProductList'
import { AlertModalWithoutButton } from '../../organisms/AlertModalWithoutButton'
import { useBlockBrowserBack } from '../../../hooks/users/useBlockBrowserBack'

export const Project8 = () => {
  const navigate = useNavigate()
  const { message } = useMessage()
  const [searchParams] = useSearchParams()
  const {
    getTargetProject,
    project,
    setProject,
    isLoading,
    getNewProject,
    copyTargetProject,
    getMasters,
    masterList,
  } = useGetData()

  const [isLoadingAction, setIsLoadingAction] = useState()
  const [isCreate, setIsCreate] = useState(false)
  const { isBrowserBack, setIsBrowserBack } = useBlockBrowserBack(1)
  const id = searchParams.get('id')

  useEffect(() => {
    const getData = async () => {
      if (id) {
        await getTargetProject(id)
      } else {
        setIsCreate(true)
        await getNewProject()
      }
      getMasters()
    }
    getData()
  }, [])

  const postParams = {
    method: isCreate ? 'POST' : 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(project.project),
  }

  const exportParams = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  }

  const handleCreateProject = () => {
    // 新規作成
    setIsLoadingAction(true)
    fetch(createProject, postParams)
      .then((res) => res.json())
      .then((myjson) => {
        message({ title: '案件の作成に成功しました。', status: 'success' })
        setProject({
          ...project,
          project: {
            ...project.project,
            project_id: myjson.project_id,
          },
        })
        setIsCreate(false)
        setIsLoadingAction(false)
      })
      .catch(() =>
        message({ title: '通信エラーが発生しました', status: 'warning' })
      )
      .finally(() => setIsLoadingAction(false))
  }

  const handleUpdateProject = useCallback(() => {
    // 内容更新
    setIsLoadingAction(true)
    fetch(putProject(project.project.project_id), postParams)
      .then((res) => res.json())
      .then((myjson) => {
        message({ title: '案件の更新に成功しました。', status: 'success' })
      })
      .catch(() =>
        message({ title: '通信エラーが発生しました', status: 'warning' })
      )
      .finally(() => setIsLoadingAction(false))
  })

  const handleDeleteProject = () => {
    // 見積書削除
    setIsLoadingAction(true)
    const postIdParams = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        project_id: project.project.project_id,
      }),
    }

    fetch(putProject(project.project.project_id), postIdParams)
      .then(() => {
        message({ title: '案件の削除に成功しました。', status: 'success' })
        navigate('/projects/index')
      })
      .catch(() =>
        message({ title: '通信エラーが発生しました', status: 'warning' })
      )
      .finally(() => setIsLoadingAction(false))
  }

  const handleAddProduct = () => {
    navigate(`/projects/format_b?projectId=${project.project.project_id}`)
    window.location.reload()
  }

  const handleEditProduct = (lot) => {
    navigate(
      // `/projects/format_b?projectId=${project.project.project_id}&lotNum=${lot.lot_num}&lotIndex=${lot.lot_index}`
      `/projects/format_b?projectId=${project.project.project_id}&lotNum=${lot.lot_num}`
    )
  }

  const handleDeleteProduct = (lotNum) => {
    setIsLoadingAction(true)
    const postIdParams = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        lot_num: lotNum,
      }),
    }
    fetch(putLot(lotNum), postIdParams)
      .then(() => {
        message({
          title: `部品の削除に成功しました。`,
          status: 'success',
        })
        setProject({
          ...project,
          project: {
            ...project.project,
            total_amount: project.project.lots.reduce((sum, lot) => {
              return lot.lot_num == lotNum ? sum : sum + lot.product_cost
            }, 0),
            lots: project.project.lots,
          },
        })
      })
      .catch(() =>
        message({
          title: '通信エラーが発生しました',
          status: 'warning',
        })
      )
      .finally(() => setIsLoadingAction(false))
  }

  const handleEditInstruction = (lotNum, instructionNum) => {
    instructionNum
      ? navigate(
          `/projects/instruction?lotNum=${lotNum}&instructionNum=${instructionNum}`
        )
      : navigate(`/projects/instruction?lotNum=${lotNum}`)
  }

  const handleClickCopyProject = () => {
    copyTargetProject(project.project.project_id)
    setIsCreate(true)
  }

  const handleClickExportEstimateProject = () => {
    // 案件見積書ダウンロード
    setIsLoadingAction(true)
    fetch(projectEstimate(project.project.project_id), exportParams)
      .then((res) => res.json())
      .then((myjson) => {
        message({
          title: '書類の作成に成功しました。',
          status: 'success',
          duration: 10000,
        })
        window.open(myjson.message)
      })
      .catch(() =>
        message({ title: '通信エラーが発生しました', status: 'warning' })
      )
      .finally(() => setIsLoadingAction(false))
  }

  const handleSelectStampType = (value, index) => {
    setProject({
      ...project,
      project: {
        ...project.project,
        lots: project.project.lots.map((info, number) =>
          index == number
            ? {
                ...info,
                stamp_type: value,
              }
            : info
        ),
      },
    })
  }

  const handleClickExportEstimateProduct = (index, lotNum) => {
    // 部品見積書ダウンロード
    const exportParams = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        stamp_type: project.project.lots[index].stamp_type,
      }),
    }

    setIsLoadingAction(true)
    fetch(productEstimate(lotNum), exportParams)
      .then((res) => res.json())
      .then((myjson) => {
        message({
          title: '書類の作成に成功しました。',
          status: 'success',
          duration: 10000,
        })
        window.open(myjson.message)
      })
      .catch(() =>
        message({ title: '通信エラーが発生しました', status: 'warning' })
      )
      .finally(() => setIsLoadingAction(false))
  }

  const handleClickExportBill = () => {
    // 請求書ダウンロード
    setIsLoadingAction(true)
    fetch(projectBill(project.project.project_id), exportParams)
      .then((res) => res.json())
      .then((myjson) => {
        message({
          title: '書類の作成に成功しました。',
          status: 'success',
          duration: 10000,
        })
        window.open(myjson.message)
      })
      .catch(() =>
        message({ title: '通信エラーが発生しました', status: 'warning' })
      )
      .finally(() => setIsLoadingAction(false))
  }
  const handleClickExportReceipt = () => {
    // 納品書・物品受領書ダウンロード
    setIsLoadingAction(true)
    fetch(projectReceipt(project.project.project_id), exportParams)
      .then((res) => res.json())
      .then((myjson) => {
        message({
          title: '書類の作成に成功しました。',
          status: 'success',
          duration: 10000,
        })
        window.open(myjson.message)
      })
      .catch(() =>
        message({ title: '通信エラーが発生しました', status: 'warning' })
      )
      .finally(() => setIsLoadingAction(false))
  }

  const handleClickExportInvoiceBeforeRegister = () => {
    // 未登録案件請求書ダウンロード
    const params = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        project: project.project,
        outsource: {},
      }),
    }
    setIsLoadingAction(true)
    fetch(exportOnly, params)
      .then((res) => res.json())
      .then((myjson) => {
        message({
          title: '書類の作成に成功しました。',
          status: 'success',
          duration: 10000,
        })
        window.open(myjson.message)
      })
      .catch(() =>
        message({ title: '通信エラーが発生しました', status: 'warning' })
      )
      .finally(() => setIsLoadingAction(false))
  }

  return (
    <>
      {isLoading ? (
        <Flex h="100vh" alignItems="center" justifyContent="center">
          <BeatLoader size={30} color={'#3b5998'} />
        </Flex>
      ) : (
        <Box m={2}>
          <Heading mb={10} align="center">
            {isCreate ? '案件登録' : '案件詳細'}
          </Heading>
          <CommonInfoProject3
            projectId={project.project.project_id}
            clients={project.clients}
            defaultClient={project.project.client_name}
            member={project.users}
            defaultAssigner={project.project.assigner}
            valueProjectName={project.project.project_name}
            valueDueDate={project.project.due_date}
            valueTaxRate={project.project.tax_rate}
            valueExpirationDate={project.project.expiring_date}
            valueProjectStatus={project.project.project_status}
            valueRegisteredDate={project.project.registered_date}
            valueBillingDate={project.project.billing_date}
            valueCount={project.project.count}
            onChangeProjectName={(e) =>
              setProject({
                ...project,
                project: {
                  ...project.project,
                  project_name: e.target.value,
                },
              })
            }
            onChangeTaxRate={(num) =>
              setProject({
                ...project,
                project: {
                  ...project.project,
                  tax_rate: num,
                },
              })
            }
            onChangeDueDate={(e) =>
              setProject({
                ...project,
                project: {
                  ...project.project,
                  due_date: e.target.value,
                },
              })
            }
            onChangeProjectStatus={(e) =>
              setProject({
                ...project,
                project: {
                  ...project.project,
                  project_status: e.target.value,
                },
              })
            }
            onChangeCount={(num) =>
              setProject({
                ...project,
                project: {
                  ...project.project,
                  count: num,
                },
              })
            }
            onChangeRegisteredDate={(e) =>
              setProject({
                ...project,
                project: {
                  ...project.project,
                  registered_date: e.target.value,
                },
              })
            }
            onChangeBillingDate={(e) =>
              setProject({
                ...project,
                project: {
                  ...project.project,
                  billing_date: e.target.value,
                },
              })
            }
            onChangeExpirationDate={(e) =>
              setProject({
                ...project,
                project: {
                  ...project.project,
                  expiring_date: e.target.value,
                },
              })
            }
            onChangeSelectClientName={(index) =>
              setProject({
                ...project,
                project: {
                  ...project.project,
                  client_name: project.clients[index]['client_name'],
                  postal_code: project.clients[index]['postal_code'],
                  address: project.clients[index]['address'],
                },
              })
            }
            onChangeSelectMemberName={(index) =>
              setProject({
                ...project,
                project: {
                  ...project.project,
                  assigner: project.users[index]['name'],
                  email: project.users[index]['email'],
                },
              })
            }
            onClickCopyProject={handleClickCopyProject}
            onClickExportEstimate={handleClickExportEstimateProject}
            onClickExportBill={handleClickExportBill}
            onClickExportReceipt={handleClickExportReceipt}
            onClickExportInvoiceBeforeRegister={
              handleClickExportInvoiceBeforeRegister
            }
            isDisPlayed={!isCreate}
            isDisabled={isLoadingAction}
          />

          <Box my={10} opacity={isCreate ? 0.3 : 1}>
            <Flex alignItems="center">
              <Text
                as="h2"
                ml={3}
                mr={10}
                fontSize="20px"
                fontWeight="semibold"
              >
                部品一覧
              </Text>
              <IconButton
                aria-label="add"
                disabled={isLoadingAction || isCreate}
                onClick={handleAddProduct}
                icon={<AiOutlinePlus />}
              />
            </Flex>
            <TableContainer mt={2}>
              <Table variant="striped" colorScheme="linkedin">
                <TableBottom4
                  amount={Math.round(project.project.total_amount || 0)}
                  totalAmount={Math.round(
                    project.project.total_amount *
                      (1 + project.project.tax_rate / 100) || 0
                  )}
                  valueProjectStatus={project.project.project_status}
                  valueNoteForEstimate={project.project.note_estimate}
                  valueNoteForBill={project.project.note_bill}
                  onChangeNoteForEstimate={(e) =>
                    setProject({
                      ...project,
                      project: {
                        ...project.project,
                        note_estimate: e.target.value,
                      },
                    })
                  }
                  onChangeNoteForBill={(e) =>
                    setProject({
                      ...project,
                      project: {
                        ...project.project,
                        note_bill: e.target.value,
                      },
                    })
                  }
                  isDisabled={isLoadingAction}
                />

                <TableProductList
                  lots={project.project.lots}
                  onClickExportProduct={handleClickExportEstimateProduct}
                  onChangeStampType={handleSelectStampType}
                  onClickEditProduct={handleEditProduct}
                  onClickDeleteProduct={handleDeleteProduct}
                  onClickEditInstruction={handleEditInstruction}
                  isDisabled={isLoadingAction}
                  isDisabledButton={isCreate}
                />
              </Table>
            </TableContainer>
          </Box>

          {isCreate ? (
            <Flex my={4} mx={6} justify="right">
              <AlertModal
                buttonMessage="新規登録"
                title="案件登録"
                body="この内容で新規作成しますか？"
                onClick={handleCreateProject}
                width="150px"
                disabled={
                  !project.project.client_name ||
                  !project.project.assigner ||
                  !project.project.project_name ||
                  !project.project.due_date ||
                  !project.project.tax_rate ||
                  !project.project.expiring_date ||
                  !project.project.registered_date ||
                  isLoadingAction
                }
              />
            </Flex>
          ) : (
            <>
              <Flex my={4} mx={6} justify="space-between" align="flex-end">
                <AlertModal
                  body="データを変更した場合、更新ボタンを押さないと編集内容が破棄されますがよろしいですか？"
                  buttonMessage="案件一覧に戻る"
                  title="案件一覧に戻る"
                  onClick={() => navigate('/projects/index')}
                  disabled={isLoadingAction}
                  primaryColor={false}
                />
                <AlertModal
                  buttonMessage="更新"
                  title="案件更新"
                  body="この内容で更新しますか？"
                  onClick={handleUpdateProject}
                  width="150px"
                  disabled={isLoadingAction}
                />
              </Flex>
              <Flex my={4} mx={6} justify="right">
                <AlertModal
                  body="この案件を削除しますか？その場合、本案件に関連するすべての製品の見積書も削除されます。"
                  buttonMessage="削除"
                  title="案件削除"
                  onClick={handleDeleteProject}
                  disabled={isLoadingAction}
                  primaryColor={false}
                  color="red"
                />
              </Flex>
            </>
          )}
          {isBrowserBack && (
            <AlertModalWithoutButton
              title="戻る"
              body="データを変更した場合、更新ボタンを押さないと編集内容が破棄されますがよろしいですか？"
              onClick={() => {
                setIsCreate(false)
                window.history.back()
              }}
              onClickCancel={() => {
                setIsBrowserBack(false)
                window.history.pushState(null, '', window.location.href)
              }}
            />
          )}
        </Box>
      )}
    </>
  )
}
