import React from 'react'
import { Td, IconButton, Text, Flex, Switch } from '@chakra-ui/react'
import { PrimaryInput } from '../../atoms/PrimaryInput'
import { PrimaryNumberInput } from '../../atoms/PrimaryNumberInput'
import { AiOutlineDelete, AiOutlinePlus } from 'react-icons/ai'

export const TableBodyInstruction3 = (props) => {
  const {
    type,
    process,
    valueProcessName,
    valueEtaSetUpMinute,
    valueEtaProcessingMinute,
    valueEtaHandworkMinute,
    valueDueDate,
    valueIsOutsource,
    onChangeProcessName,
    onChangeEtaSetUpMinute,
    onChanceEtaProcessingMinute,
    onChangeEtaHandworkMinute,
    onChangeDueDate,
    onChangeIsOutsource,
    onClickEditOutSource,
    onClickAddProcess,
    onClickDeleteProcess,
    isCreate,
    isDisabled,
    disabledAddButton,
  } = props

  return (
    <>
      <Td>
        <PrimaryInput
          placeholder=""
          disabled={isDisabled}
          value={valueProcessName}
          onChange={(e) => onChangeProcessName(e)}
          htmlSize={16}
        />
      </Td>
      <Td>
        <PrimaryNumberInput
          value={valueEtaSetUpMinute}
          minValue={0}
          maxValue={100000}
          onChange={(num) => onChangeEtaSetUpMinute(num)}
          width="120px"
          disabled={isDisabled}
        />
      </Td>
      <Td>
        <Text>{process.act_setup_minute ?? 0}</Text>
      </Td>
      <Td>
        <PrimaryNumberInput
          value={valueEtaProcessingMinute}
          minValue={0}
          maxValue={100000}
          onChange={(num) => onChanceEtaProcessingMinute(num)}
          width="120px"
          disabled={isDisabled}
        />
      </Td>
      <Td>
        <Text>{process.act_kakou_minute ?? 0}</Text>
      </Td>
      <Td>
        <PrimaryNumberInput
          value={valueEtaHandworkMinute}
          minValue={0}
          maxValue={100000}
          onChange={(num) => onChangeEtaHandworkMinute(num)}
          width="120px"
          disabled={isDisabled}
        />
      </Td>
      <Td>
        <Text>{process.act_handwork_minute ?? 0}</Text>
      </Td>
      <Td>
        <PrimaryInput
          type="date"
          min="2000-01-01"
          max="2050-01-01"
          value={valueDueDate}
          onChange={(e) => onChangeDueDate(e)}
          htmlSize={16}
          disabled={isDisabled}
        />
      </Td>
      <Td>
        <Text>{process.completed_number ?? 0}</Text>
      </Td>
      <Td>
        <Text>{process.defective_number ?? 0}</Text>
      </Td>
      <Td>
        <Text>{process.workers && process.workers.join(', ')}</Text>
      </Td>
      <Td>
        <Flex justify="space-around">
          <Switch
            value={valueIsOutsource}
            onChange={(e) => onChangeIsOutsource(e)}
            isChecked={valueIsOutsource}
            isDisabled={isDisabled}
          />
          <Text
            display={
              (!valueIsOutsource || isCreate || type == 'adding') && 'none'
            }
            ml={2}
            color="#3b5998"
            fontWeight="semibold"
            as="a"
            cursor="pointer"
            opacity={isDisabled && 0.3}
            onClick={() => onClickEditOutSource(process.process_plan_id)}
          >
            依頼書類
          </Text>
        </Flex>
      </Td>
      <Td>
        {type == 'adding' ? (
          <IconButton
            aria-label="add"
            disabled={isDisabled || disabledAddButton}
            onClick={onClickAddProcess}
            icon={<AiOutlinePlus />}
          />
        ) : (
          <IconButton
            aria-label="delete"
            disabled={isDisabled}
            onClick={onClickDeleteProcess}
            icon={<AiOutlineDelete />}
          />
        )}
      </Td>
    </>
  )
}
