import React from 'react'
import {
  Thead,
  Tr,
  Th,
  Tbody,

} from '@chakra-ui/react'

import { TableBodyProductListOnlyExport } from '../../molecules/project/TableBodyProductListOnlyExport'

export const TableProductListOnlyExport = (props) => {
  const {
    lots,
    lotObj,
    onChangeInstructionIndex,
    onChangeProductNum,
    onChanceProductName,
    onChangeCount,
    onChangeUnit,
    onChangePrice,
    onChangeInstructionIndexAdd,
    onChangeProductNumAdd,
    onChanceProductNameAdd,
    onChangeCountAdd,
    onChangeUnitAdd,
    onChangePriceAdd,
    onClickAddProduct,
    onClickDeleteProduct,
    isDisabled,
  } = props

  return (
    <>
      <Thead>
        <Tr>
          {/* <Th>指示書番号</Th> */}
          <Th>品番</Th>
          <Th>品名</Th>
          <Th>数量</Th>
          <Th>単位</Th>
          <Th>単価</Th>
          <Th>小計</Th>
          <Th isNumeric>追加/削除</Th>
        </Tr>
      </Thead>
      <Tbody>
        {console.log(lots)}
        {lots.map((lot, index) => {
          return (
            <Tr key={index}>
              <TableBodyProductListOnlyExport
                type="existing"
                instructionIndex={lot.instruction_num}
                productNum={lot.product_num}
                productName={lot.product_name}
                count={lot.lot_amount}
                price={lot.unit_cost}
                amount={lot.lot_amount * lot.unit_cost}
                unit={lot.unit}
                onChangeInstructionIndex={(e) =>
                  onChangeInstructionIndex(e, index)
                }
                onChangeProductNum={(e) => onChangeProductNum(e, index)}
                onChanceProductName={(e) => onChanceProductName(e, index)}
                onChangeUnit={(e) => onChangeUnit(e, index)}
                onChangeCount={(num) => onChangeCount(num, index)}
                onChangePrice={(num) => onChangePrice(num, index)}
                onClickDeleteProduct={() => onClickDeleteProduct(index)}
                isDisabled={isDisabled}
              />
            </Tr>
          )
        })}
        <Tr>
          <TableBodyProductListOnlyExport
            type="adding"
            instructionIndex={lotObj.instruction_num}
            productNum={lotObj.product_num}
            productName={lotObj.product_name}
            count={lotObj.lot_amount}
            price={lotObj.unit_cost}
            amount={lotObj.lot_amount * lotObj.unit_cost}
            unit={lotObj.unit}
            onChangeInstructionIndex={(e) => onChangeInstructionIndexAdd(e)}
            onChangeProductNum={(e) => onChangeProductNumAdd(e)}
            onChanceProductName={(e) => onChanceProductNameAdd(e)}
            onChangeUnit={(e) => onChangeUnitAdd(e)}
            onChangeCount={(num) => onChangeCountAdd(num)}
            onChangePrice={(num) => onChangePriceAdd(num)}
            onClickAddProduct={onClickAddProduct}
            isDisabled={isDisabled}
            isDisabledAddButton={
              !lotObj.product_num ||
              !lotObj.product_name ||
              !lotObj.lot_amount ||
              !lotObj.unit_cost 
            }
          />
        </Tr>
      </Tbody>
    </>
  )
}
