import React from 'react'
import { Box, Flex, Radio, RadioGroup, Text } from '@chakra-ui/react'
import { PrimaryInput } from '../../atoms/PrimaryInput'
import { PrimarySelectBox } from '../../atoms/PrimarySelectBox'
import { PrimaryButton } from '../../atoms/PrimaryButton'

export const FormSearch2 = (props) => {
  const {
    valueStartDate,
    valueEndDate,
    lowerDate,
    upperDate,
    valueIsMass,
    onChangeStartDate,
    onChangeEndDate,
    onChangeIsMass,
    onClickAnalysis,
    isSearching,
    disabled,
  } = props

  return (
    <>
      <Flex justify="center" alignItems="center" textAlign="center">
        <Box>
          <Flex alignItems="center" textAlign="center" my={2}>
            <Text w="150px">対象期間</Text>
            <PrimaryInput
              type="date"
              value={valueStartDate}
              max={upperDate}
              onChange={(e) => onChangeStartDate(e)}
              width="200px"
              disabled={isSearching}
            />
            <Text w="35px">~</Text>
            <PrimaryInput
              type="date"
              value={valueEndDate}
              min={lowerDate}
              onChange={(e) => onChangeEndDate(e)}
              width="200px"
              disabled={isSearching}
            />
          </Flex>
          <Flex alignItems="center" textAlign="center" my={2}>
            <Text w="150px">試作・量産</Text>
            <RadioGroup
              onChange={(value) => onChangeIsMass(value)}
              value={valueIsMass}
            >
              <Flex>
                <Radio h="50px" lineHeight="50px" value={false}>
                  試作
                </Radio>
                <Box w="70px" />
                <Radio h="50px" lineHeight="50px" value={true}>
                  量産
                </Radio>
              </Flex>
            </RadioGroup>
          </Flex>
          {/* <Flex justify="center" alignItems="center" textAlign="center" my={4}>
            <PrimaryButton onClick={onClickAnalysis} disabled={disabled}>
              分析
            </PrimaryButton>
          </Flex> */}
        </Box>
      </Flex>
    </>
  )
}
