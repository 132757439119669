import React, { useEffect, useState } from 'react'
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Heading,
  Image,
  Box,
} from '@chakra-ui/react'
import { IndividualLaborData } from '../templates/analysis/IndividualLaborData'
import { MerchandiseCostData } from '../templates/analysis/MerchandiseCostData'
import { TradeData } from '../templates/analysis/TradeData'
import { useGetData } from '../../hooks/useGetData'
import { FormSearch2 } from '../molecules/analysis/FormSearch2'
import { Result2 } from '../molecules/analysis/Result2'

export const DataAnalysis3 = () => {
  const { getMasters, masterList, isLoading } = useGetData()
  useEffect(() => {
    getMasters()
  }, [])
  // const workers = [{ name: 'Ronaldo' }, { name: 'Messi' }]
  const defaultRange = {
    lowerDate: '2000-01-01',
    upperDate: '2050-01-01',
  }

  const initSearchItems = {
    startDate: '',
    endDate: '',
    isMass: false,
  }
  const [searchItems, setSearchItems] = useState(initSearchItems)
  const [isSearching, setIsSearching] = useState(false)

    const testResult = [
      { text: 'title1' },
      { text: 'title2' },
      { text: 'title3' },
      { text: 'title4' },
      { text: 'title5' },
    ]

  const [result, setResult] = useState(testResult)

  const handleClickAnalysis = () => {
    setIsSearching(true)
    // alert() // 処理
    setResult(testResult)
    // setSearchItems(initSearchItems)
    setIsSearching(false)
  }

  return (
    <>
      {console.log(searchItems)}
      <Heading my={4} textAlign="center">
        売上分析
      </Heading>
      {masterList.users && (
        // <Tabs>
        //   <TabList>
        //     <Tab>個人労働データ集計</Tab>
        //     <Tab>商品別原価集計</Tab>
        //     <Tab>売掛金/買掛金 照合</Tab>
        //   </TabList>
        //   <TabPanels>
        //     <TabPanel>
        <>
          <FormSearch2
            valueStartDate={searchItems.startDate}
            valueEndDate={searchItems.endDate}
            lowerDate={searchItems.startDate ?? defaultRange.lowerDate}
            upperDate={searchItems.endDate ?? defaultRange.upperDate}
            valueIsMass={searchItems.isMass}
            // workers={masterList.users}
            // defaultWorker={searchItems.worker}
            onChangeStartDate={(e) =>
              setSearchItems({ ...searchItems, startDate: e.target.value })
            }
            onChangeEndDate={(e) =>
              setSearchItems({ ...searchItems, endDate: e.target.value })
            }
            onChangeIsMass={(value) =>
              setSearchItems({ ...searchItems, isMass: JSON.parse(value) })
            }
            // onChangeSelectWorker={(index) =>
            //   setSearchItems({
            //     ...searchItems,
            //     worker: masterList.users[index]['name'],
            //   })
            // }
            onClickAnalysis={handleClickAnalysis}
            isSearching={isSearching}
            disabled={
              !searchItems.startDate ||
              !searchItems.endDate 
            }
          />

          <Box mt={10}>
            <Result2
              result={result}
              searchItems={searchItems}
              isSearching={isSearching}
            />
          </Box>
        </>

        //       <IndividualLaborData
        //         result={result}
        //         valueStartDate={searchItems.startDate}
        //         valueEndDate={searchItems.endDate}
        //         lowerDate={searchItems.startDate ?? defaultRange.lowerDate}
        //         upperDate={searchItems.endDate ?? defaultRange.upperDate}
        //         workers={masterList.users}
        //         defaultWorker={searchItems.worker}
        //         onChangeStartDate={(e) =>
        //           setSearchItems({ ...searchItems, startDate: e.target.value })
        //         }
        //         onChangeEndDate={(e) =>
        //           setSearchItems({ ...searchItems, endDate: e.target.value })
        //         }
        //         onChangeSelectWorker={(index) =>
        //           setSearchItems({
        //             ...searchItems,
        //             worker: masterList.users[index]['name'],
        //           })
        //         }
        //         onClickAnalysis={handleClickAnalysis}
        //         isSearching={isSearching}
        //         disabled={
        //           !searchItems.startDate ||
        //           !searchItems.endDate ||
        //           !searchItems.worker
        //         }
        //       />
        //     </TabPanel>
        //     <TabPanel>
        //       <MerchandiseCostData />
        //     </TabPanel>
        //     <TabPanel>
        //       <TradeData />
        //     </TabPanel>
        //   </TabPanels>
        // </Tabs>
      )}
    </>
  )
}
