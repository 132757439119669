export const stampTypes = [
  { name: '印鑑', value: 'stamp' },
  { name: 'サイン', value: 'signature' },
  { name: 'なし', value: "" },
]

export const factories = [
  { name: '志木工場', value: '志木工場' },
  { name: '朝霞工場', value: '朝霞工場' },
  { name: '栃木工場', value: '栃木工場' },
]

export const paymentMethods = [
  { name: '全額銀行振込', value: '全額銀行振込' },
  { name: '全額手形（手形サイト120日）', value: '全額手形（手形サイト120日）' },
  { name: '手形50％　振込50％', value: '手形50％　振込50％' },
]