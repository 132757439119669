import React from 'react'
import {
  TableCaption,
  Grid,
  Heading,
  GridItem,
  Textarea,
} from '@chakra-ui/react'

export const TableBottom4 = (props) => {
  const {
    amount,
    totalAmount,
    valueProjectStatus,
    valueNoteForEstimate,
    valueNoteForBill,
    onChangeNoteForEstimate,
    onChangeNoteForBill,
    isDisabled,
  } = props

  return (
    <TableCaption mt={0}>
      <Grid
        h={85}
        templateRows="repeat(4, 1fr)"
        templateColumns="repeat(7, 1fr)"
        gap={1}
      >
        {valueProjectStatus == 'done' ? (
          <GridItem rowSpan={4} colSpan={5}>
            <Textarea
              placeholder="請求書用備考欄"
              onChange={(e) => onChangeNoteForBill(e)}
              value={valueNoteForBill}
              h={85}
              size="sm"
              bg="white"
              _focus={{ boxShadow: 'none' }}
              disabled={isDisabled}
            />
          </GridItem>
        ) : (
          <GridItem rowSpan={4} colSpan={5}>
            <Textarea
              placeholder="見積書用備考欄"
              onChange={(e) => onChangeNoteForEstimate(e)}
              value={valueNoteForEstimate}
              h={85}
              size="sm"
              bg="white"
              _focus={{ boxShadow: 'none' }}
              disabled={isDisabled}
            />
          </GridItem>
        )}
        <GridItem rowSpan={1} colSpan={1} bg="papayawhip">
          <Heading size="sm" lineHeight={10} opacity={isDisabled && 0.3}>
            小計
          </Heading>
        </GridItem>
        <GridItem rowSpan={1} colSpan={1} bg="papayawhip">
          <Heading
            size="sm"
            lineHeight={10}
            textAlign="right"
            mr="2"
            opacity={isDisabled && 0.3}
          >
            ¥{amount.toLocaleString()}
          </Heading>
        </GridItem>
        <GridItem rowSpan={1} colSpan={1} bg="papayawhip">
          <Heading size="sm" lineHeight={10} opacity={isDisabled && 0.3}>
            合計(内税)
          </Heading>
        </GridItem>
        <GridItem rowSpan={1} colSpan={1} bg="papayawhip">
          <Heading
            size="sm"
            lineHeight={10}
            textAlign="right"
            mr="2"
            opacity={isDisabled && 0.3}
          >
            ¥{totalAmount.toLocaleString()}
          </Heading>
        </GridItem>
      </Grid>
    </TableCaption>
  )
}
