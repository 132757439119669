import React from 'react'
import { Td, IconButton } from '@chakra-ui/react'
import { AiOutlineDelete, AiOutlinePlus } from 'react-icons/ai'
import { PrimaryNumberInput } from '../../atoms/PrimaryNumberInput'

export const TableBodyDailyReportLower3 = (props) => {
  const {
    type,
    setupTime,
    processingTime,
    handWorkTime,
    completedCount,
    defectiveCount,
    onChangeSetupTime,
    onChangeProcessingTime,
    onChangeHandWorkTime,
    onChangeCompletedCount,
    onChangeDefectiveCount,
    onClickAddProcessReport,
    onClickDeleteProcessReport,
    disabledProcessingTime,
    disabledAddButton,
    disabled,
  } = props

  return (
    <>
      <Td>
        <PrimaryNumberInput
          value={setupTime}
          minValue={0}
          maxValue={1000}
          step={5}
          onChange={(num) => onChangeSetupTime(num)}
          width="160px"
          disabled={disabled}
        />
      </Td>
      <Td>
        <PrimaryNumberInput
          value={processingTime}
          minValue={0}
          maxValue={1000}
          step={5}
          onChange={(num) => onChangeProcessingTime(num)}
          width="160px"
          disabled={disabled || disabledProcessingTime}
        />
      </Td>
      <Td>
        <PrimaryNumberInput
          value={handWorkTime}
          minValue={0}
          maxValue={1000}
          step={5}
          onChange={(num) => onChangeHandWorkTime(num)}
          width="160px"
          disabled={disabled}
        />
      </Td>
      <Td>
        <PrimaryNumberInput
          value={completedCount}
          minValue={0}
          maxValue={1000}
          onChange={(num) => onChangeCompletedCount(num)}
          width="160px"
          disabled={disabled}
        />
      </Td>
      <Td>
        <PrimaryNumberInput
          value={defectiveCount}
          minValue={0}
          maxValue={1000}
          onChange={(num) => onChangeDefectiveCount(num)}
          width="160px"
          disabled={disabled}
        />
      </Td>
      <Td isNumeric>
        {type == 'adding' ? (
          <IconButton
            aria-label="add"
            disabled={disabledAddButton || disabled}
            onClick={onClickAddProcessReport}
            icon={<AiOutlinePlus />}
          />
        ) : (
          <IconButton
            aria-label="delete"
            disabled={disabled}
            onClick={onClickDeleteProcessReport}
            icon={<AiOutlineDelete />}
          />
        )}
      </Td>
    </>
  )
}
