import React from 'react'
import { Td, IconButton, Text } from '@chakra-ui/react'
import { AiOutlineEdit } from 'react-icons/ai'
import { AlertDeleteModal } from '../../organisms/AlertDeleteModal'
import { PrimaryButton } from '../../atoms/PrimaryButton'
import { stampTypes } from '../../../constants/MasterInjectHelper'
import { AlertModalWithRadioButton } from '../../organisms/AlertModalWithRadioButton'

export const TableBodyProductList = (props) => {
  const {
    instructionIndex,
    productNum,
    productName,
    count,
    price,
    amount,
    stamp,
    onClickEditInstruction,
    onClickExportProduct,
    onChangeStampType,
    onClickEditProduct,
    onClickDeleteProduct,
    isDisabled,
    isDisabledButton,
  } = props

  return (
    <>
      <Td>
        <Text opacity={isDisabled && 0.3}>{instructionIndex}</Text>
      </Td>
      <Td>
        {isDisabledButton ? (
          <Text>{productName}</Text>
        ) : (
          <Text
            color="#3b5998"
            fontWeight="semibold"
            as="a"
            cursor="pointer"
            opacity={isDisabled && 0.3}
            onClick={onClickEditProduct}
          >
            {productNum}
          </Text>
        )}
      </Td>
      <Td>
        <Text opacity={isDisabled && 0.3}>{productName}</Text>
      </Td>
      <Td>
        <Text opacity={isDisabled && 0.3}>{count.toLocaleString()}</Text>
      </Td>
      <Td>
        <Text opacity={isDisabled && 0.3}>¥{price.toLocaleString()}</Text>
      </Td>
      <Td>
        <Text opacity={isDisabled && 0.3}>¥{amount.toLocaleString()}</Text>
      </Td>
      <Td>
        {isDisabledButton ? (
          <Text>-</Text>
        ) : (
          <Text
            color="#3b5998"
            fontWeight="semibold"
            as="a"
            cursor="pointer"
            opacity={isDisabled && 0.3}
            onClick={onClickEditInstruction}
          >
            作業指示書
          </Text>
        )}
      </Td>
      <Td>
        {/* <PrimaryButton
          onClick={onClickExportProduct}
          disabled={isDisabled || isDisabledButton}
        >
          出力
        </PrimaryButton> */}
        <AlertModalWithRadioButton
          buttonMessage="出力"
          title="詳細見積書出力"
          body="署名のタイプを選択してください。"
          value={stamp}
          options={stampTypes}
          onClick={onClickExportProduct}
          onChange={onChangeStampType}
          width="150px"
          disabled={isDisabled || isDisabledButton}
        />
      </Td>
      <Td isNumeric>
        <IconButton
          aria-label="edit"
          disabled={isDisabled || isDisabledButton}
          onClick={onClickEditProduct}
          icon={<AiOutlineEdit />}
        />
      </Td>
      <Td isNumeric>
        <AlertDeleteModal
          title="部品削除"
          body1={`部品名：${productName}を案件から削除しますか？`}
          body2="同時に見積書も削除されます。"
          disabled={isDisabled || isDisabledButton}
          onClick={onClickDeleteProduct}
        />
      </Td>
    </>
  )
}
