import React, { useState } from 'react'
import { PrimaryButton } from '../atoms/PrimaryButton'
import { PrimaryInput } from '../atoms/PrimaryInput'
import {
  Flex,
  Card,
  CardHeader,
  Heading,
  CardBody,
  Stack,
  Box,
  StackDivider,
  InputRightElement,
  Button,
  InputGroup,
} from '@chakra-ui/react'
import BeatLoader from 'react-spinners/BeatLoader'
import { useLogin3 } from '../../hooks/users/useLogin3'

export const Login2 = () => {
  const { mutate, isLoading } = useLogin3()
  const initUser = {
    user_id: '',
    password: '',
  }
  const [user, setUser] = useState(initUser)
  const [show, setShow] = useState(false)

  const handleLogin = () => {
    mutate({ userId: user.user_id, password: user.password })
  }

  return (
    <Flex h="100vh" justifyContent="center" alignItems="center">
      <Card w="sm">
        <CardHeader>
          <Heading size="lg">ログイン</Heading>
        </CardHeader>
        <CardBody>
          <Stack divider={<StackDivider />} spacing={4}>
            <Box>
              <Heading size="md" mb={2}>
                ユーザーID
              </Heading>
              <PrimaryInput
                placeholder=""
                value={user.id}
                onChange={(e) => setUser({ ...user, user_id: e.target.value })}
                width="343px"
              />
            </Box>
            <Box>
              <Heading size="md" mb={2}>
                パスワード
              </Heading>
              <InputGroup>
                <PrimaryInput
                  placeholder=""
                  type={show ? 'text' : 'password'}
                  value={user.pass}
                  onChange={(e) =>
                    setUser({ ...user, password: e.target.value })
                  }
                  width="350px"
                >
                  <InputRightElement width="4.5rem">
                    <Button
                      h="1.75rem"
                      size="sm"
                      onClick={() => setShow(!show)}
                    >
                      {show ? 'Hide' : 'Show'}
                    </Button>
                  </InputRightElement>
                </PrimaryInput>
              </InputGroup>
            </Box>
            <PrimaryButton
              disabled={!user.user_id || !user.password || isLoading}
              onClick={handleLogin}
            >
              {isLoading ? <BeatLoader /> : 'ログイン'}
            </PrimaryButton>
          </Stack>
        </CardBody>
      </Card>
    </Flex>
  )
}
