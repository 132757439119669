import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { useMessage } from '../../../hooks/useMessage'
import { BeatLoader } from 'react-spinners'
import {
  Box,
  Flex,
  HStack,
  Heading,
  Radio,
  RadioGroup,
  Text,
  Textarea,
} from '@chakra-ui/react'
import {
  clockingInAndOut,
  postPaintingReport,
  targetPaintingReport,
} from '../../../urls'
import { AlertModal } from '../../organisms/AlertModal'
import { useCurrentUser2 } from '../../../hooks/users/useCurrentUser2'
import { useLogout2 } from '../../../hooks/users/useLogout2'
import { useGetData } from '../../../hooks/useGetData'
import { PrimaryNumberInput } from '../../atoms/PrimaryNumberInput'
import { PrimaryInput } from '../../atoms/PrimaryInput'
import { PrimarySelectBox } from '../../atoms/PrimarySelectBox'
import axios from 'axios'
import { StringSelectBox } from '../../atoms/StringSelectBox'
import { useBlockBrowserBack } from '../../../hooks/users/useBlockBrowserBack'
import { AlertModalWithoutButton } from '../../organisms/AlertModalWithoutButton'
import { DigitalClock } from '../../atoms/DigitalClock'

export const Painting = () => {
  const location = useLocation()
  const { logout } = useLogout2()
  const { message } = useMessage()
  const navigate = useNavigate()
  const user = useCurrentUser2()
    const { isBrowserBack, setIsBrowserBack } = useBlockBrowserBack()
  const [isUpdate, setIsUpdate] = useState(false)
  const [isLoadingAction, setIsLoadingAction] = useState(false)
  const [isSucceeded, setIsSucceeded] = useState(false)
  const { getProducts, productList, getMasters, masterList, isLoading } =
    useGetData()
  const today = new Date()

  const initPaintingReport = {
    painting_record_id: '',
    working_date: null,
    lot_num: '',
    product_number: '',
    product_name: '',
    machine_name: '',
    input_count: 0,
    process_name: '',
    registered_by: '',
    approved_by: '',
    start_at: null,
    finish_at: null,
    worker: '',
    inspector: '',
    robot_program_number: '',
    // 塗装成分
    ingredient: {
      painting: {
        name: '',
        mixing_ratio: 0,
      },
      thinner: {
        name: '',
        mixing_ratio: 0,
      },
      hardening_agent: {
        name: '',
        mixing_ratio: 0,
      },
    },
    viscosity: 0,
    // 乾燥炉
    drying: [
      {
        temperature: 0,
        time: 0,
      },
      {
        temperature: 0,
        time: 0,
      },
      {
        temperature: 0,
        time: 0,
      },
    ],
    // ロットナンバー、液晶パネル
    panel: [
      {
        registered_at: null,
        paint: 0,
        thinner: 0,
        hardening_agent: 0,
        atomization_pressure: 0,
        pattern_pressure: 0,
        discharge_pressure: 0,
      },
      {
        registered_at: null,
        paint: 0,
        thinner: 0,
        hardening_agent: 0,
        atomization_pressure: 0,
        pattern_pressure: 0,
        discharge_pressure: 0,
      },
      {
        registered_at: null,
        paint: 0,
        thinner: 0,
        hardening_agent: 0,
        atomization_pressure: 0,
        pattern_pressure: 0,
        discharge_pressure: 0,
      },
    ],
    // 使用重量
    result: [
      {
        usage: 0,
        paint_weight: 0,
        thinner_weight: 0,
        hardening_agent_weight: 0,
        temperature: 0,
        humidity: 0,
        is_tone_condition: false,
        is_membrane_pressure_result: false,
        minimum_membrane_pressure: 0,
        maximum_membrane_pressure: 0,
        is_cross_cut_test_result: false,
      },
      {
        usage: 0,
        paint_weight: 0,
        thinner_weight: 0,
        hardening_agent_weight: 0,
        temperature: 0,
        humidity: 0,
        is_tone_condition: false,
        is_membrane_pressure_result: false,
        minimum_membrane_pressure: 0,
        maximum_membrane_pressure: 0,
        is_cross_cut_test_result: false,
      },
      {
        usage: 0,
        paint_weight: 0,
        thinner_weight: 0,
        hardening_agent_weight: 0,
        temperature: 0,
        humidity: 0,
        is_tone_condition: false,
        is_membrane_pressure_result: false,
        minimum_membrane_pressure: 0,
        maximum_membrane_pressure: 0,
        is_cross_cut_test_result: false,
      },
    ],
    // 数量
    count: {
      inspected_count: 0,
      not_inspected_count: 0,
      non_defective_count: 0,
      defective_count: 0,
      // 不良数
      defective_detail_count: {
        bump: 0,
        fluff: 0,
        scratch: 0,
        thread_waste: 0,
        silver: 0,
        cissing: 0,
        lack_of_hiding: 0,
        hole: 0,
        dirt: 0,
        citron: 0,
        sagging: 0,
        molding: 0,
        other: 0,
      },
    },
    // 量産開始時外観判定
    appearance_condition: {
      is_confirmation_appearance_condition: false,
      is_approval_appearance_condition: false,
      appearance_approval_name: '',
      appearance_confirmation_name: '',
      defective_reason: '',
    },
  }
  const [paintingReport, setPaintingReport] = useState(initPaintingReport)
    const [searchParams] = useSearchParams()
    const id = searchParams.get('id')

  useEffect(() => {
    if (user.is_working) {
      const getData = async () => {
        if (id) {
          const report = await axios.get(targetPaintingReport(id))
          setPaintingReport(report.data)
          setIsUpdate(true)
        }
        await getProducts()
        getMasters()
      }
      getData()
    }
  }, [])

  const params = {
    method: isUpdate ? 'PUT' : 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(paintingReport),
  }
  const handleClickClockOut = () => {
    const postParams = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        working_hour_id: '',
        work_start_time: '',
        work_end_time: '',
        user_id: user.user_id,
        work_place: '',
        lot_processes: '',
      }),
    }
    setIsLoadingAction(true)
    fetch(clockingInAndOut, postParams)
      .then((res) => res.json())
      .then((myjson) => {
        console.log(myjson)
        message({
          title: '退勤を記録しました。',
          status: 'success',
        })
        logout()
      })
      .catch(() =>
        message({ title: '通信エラーが発生しました', status: 'warning' })
      )
      .finally(() => {
        setIsLoadingAction(false)
      })
  }

  const handleClickUpdateReport = () => {
    setIsLoadingAction(true)
    fetch(targetPaintingReport(id), params)
      .then((res) => res.json())
      .then((myjson) => {
        message({ title: '日報の更新に成功しました。', status: 'success' })
        console.log(myjson)
        // navigate('/daily_report/index')
        setIsSucceeded(true)
      })
      .catch(() => {
        message({ title: '通信エラーが発生しました', status: 'warning' })
      })
      .finally(() => {
        setIsLoadingAction(false)
      })
  }

  const handleClickRegisterReport = () => {
    setIsLoadingAction(true)
    fetch(postPaintingReport, params)
      .then((res) => res.json())
      .then((myjson) => {
        message({ title: '日報の記録に成功しました。', status: 'success' })
        console.log(myjson)
        setIsSucceeded(true)
      })
      .catch(() => {
        message({ title: '通信エラーが発生しました', status: 'warning' })
      })
      .finally(() => {
        setIsLoadingAction(false)
      })
  }

  return (
    <Box m={2}>
      {isLoading ? (
        <Flex h="100vh" alignItems="center" justifyContent="center" mt={-8}>
          <BeatLoader size={30} color={'#3b5998'} />
        </Flex>
      ) : (
        <>
          <DigitalClock />
          <Flex justify="space-between" align="flex-end">
            <Text w="200px" visibility="hidden" />
            <Heading textAlign="center" mb={10}>
              塗装作業日報
            </Heading>
            <Flex textAlign="center" border="1px">
              <Box w="150px" borderRight="1px">
                <Text borderBottom="1px">確認</Text>
                <PrimarySelectBox
                  options={masterList.users}
                  defaultOption={paintingReport.approved_by}
                  optionElement="name"
                  onChangeSelect={(index) =>
                    setPaintingReport({
                      ...paintingReport,
                      approved_by: masterList.users[index]['name'],
                    })
                  }
                  width="149px"
                  disabled={isLoadingAction}
                />
              </Box>
              <Box w="150px">
                <Text borderBottom="1px">検査員</Text>
                <PrimarySelectBox
                  options={masterList.users}
                  defaultOption={paintingReport.registered_by}
                  optionElement="name"
                  onChangeSelect={(index) =>
                    setPaintingReport({
                      ...paintingReport,
                      registered_by: masterList.users[index]['name'],
                    })
                  }
                  width="149px"
                  disabled={isLoadingAction}
                />
              </Box>
            </Flex>
          </Flex>
          <Box mt={10} w="1200px" border="1px">
            <Flex textAlign="center" align="center" borderBottom="1px">
              <Text w="200px" h="50px" lineHeight="50px" borderRight="1px">
                作業日
                <Box as="span" color="red">
                  *
                </Box>
              </Text>
              <PrimaryInput
                type="date"
                min="2000-01-01"
                max="2050-01-01"
                value={paintingReport.working_date}
                onChange={(e) =>
                  setPaintingReport({
                    ...paintingReport,
                    working_date: e.target.value,
                  })
                }
                width="200px"
                disabled={isLoadingAction}
              />
              <Text
                w="200px"
                h="50px"
                lineHeight="50px"
                borderLeft="1px"
                borderRight="1px"
              >
                ロットNo.
              </Text>
              <Text w="600px" h="50px" lineHeight="50px">
                {paintingReport.lot_num}
              </Text>
            </Flex>
            <Flex textAlign="center" align="center" borderBottom="1px">
              <Text w="200px" h="50px" lineHeight="50px" borderRight="1px">
                品番
                <Box as="span" color="red">
                  *
                </Box>
              </Text>
              <PrimarySelectBox
                options={productList}
                defaultOption={paintingReport.product_number}
                optionElement="product_number"
                onChangeSelect={(index) =>
                  setPaintingReport({
                    ...paintingReport,
                    product_number: productList[index]['product_number'],
                    product_name: productList[index]['product_name'],
                  })
                }
                width="200px"
                disabled={isLoadingAction}
              />
              <Text
                w="200px"
                h="50px"
                lineHeight="50px"
                borderLeft="1px"
                borderRight="1px"
              >
                品名
              </Text>
              <PrimaryInput
                value={paintingReport.product_name}
                onChange={(e) =>
                  setPaintingReport({
                    ...paintingReport,
                    product_name: e.target.value,
                  })
                }
                width="600px"
                disabled
              />
            </Flex>
            <Flex textAlign="center" align="center">
              <Text w="200px" h="50px" lineHeight="50px" borderRight="1px">
                機械名
              </Text>
              <StringSelectBox
                options={masterList.machines}
                defaultOption={paintingReport.machine_name}
                onChangeSelect={(value) => {
                  setPaintingReport({
                    ...paintingReport,
                    machine_name: value,
                  })
                }}
                flexGrow={1}
                disabled={isLoadingAction}
              />
              <Text
                w="200px"
                h="50px"
                lineHeight="50px"
                borderLeft="1px"
                borderRight="1px"
              >
                投入数
              </Text>
              <PrimaryNumberInput
                value={paintingReport.input_count}
                minValue={0}
                maxValue={100000}
                onChange={(num) =>
                  setPaintingReport({
                    ...paintingReport,
                    input_count: num,
                  })
                }
                width="200px"
                disabled={isLoadingAction}
              />
              <Text
                w="200px"
                h="50px"
                lineHeight="50px"
                borderLeft="1px"
                borderRight="1px"
              >
                工程名
              </Text>
              <PrimaryInput
                value={paintingReport.process_name}
                onChange={(e) =>
                  setPaintingReport({
                    ...paintingReport,
                    process_name: e.target.value,
                  })
                }
                width="200px"
                disabled={isLoadingAction}
              />
            </Flex>
          </Box>

          <Flex mt={10}>
            <Box>
              <Flex
                textAlign="center"
                align="center"
                borderTop="1px"
                borderLeft="1px"
                borderRight="1px"
              >
                <Text w="200px" h="50px" lineHeight="50px">
                  使用ロボットプログラムNo.
                </Text>
                <PrimaryInput
                  value={paintingReport.robot_program_number}
                  onChange={(e) =>
                    setPaintingReport({
                      ...paintingReport,
                      robot_program_number: e.target.value,
                    })
                  }
                  width="400px"
                  disabled={isLoadingAction}
                />
              </Flex>
              <Box w="800px" border="1px">
                <Flex textAlign="center" align="center" borderBottom="1px">
                  <Text
                    w="200px"
                    h="50px"
                    lineHeight="50px"
                    borderRight="1px"
                  />
                  <Text w="400px" h="50px" lineHeight="50px" borderRight="1px">
                    名称
                  </Text>
                  <Text w="200px" h="50px" lineHeight="50px">
                    配合比
                  </Text>
                </Flex>
                <Flex textAlign="center" align="center" borderBottom="1px">
                  <Text w="200px" h="50px" lineHeight="50px" borderRight="1px">
                    塗料
                  </Text>
                  <Box py="5px" borderRight="1px">
                    <PrimaryInput
                      value={paintingReport.ingredient.painting.name}
                      onChange={(e) =>
                        setPaintingReport({
                          ...paintingReport,
                          ingredient: {
                            ...paintingReport.ingredient,
                            painting: {
                              ...paintingReport.ingredient.painting,
                              name: e.target.value,
                            },
                          },
                        })
                      }
                      width="398px"
                      disabled={isLoadingAction}
                    />
                  </Box>
                  <Box>
                    <PrimaryNumberInput
                      value={paintingReport.ingredient.painting.mixing_ratio}
                      minValue={0}
                      maxValue={100000}
                      step={0.1}
                      precision={1}
                      onChange={(num) =>
                        setPaintingReport({
                          ...paintingReport,
                          ingredient: {
                            ...paintingReport.ingredient,
                            painting: {
                              ...paintingReport.ingredient.painting,
                              mixing_ratio: num,
                            },
                          },
                        })
                      }
                      width="200px"
                      disabled={isLoadingAction}
                    />
                  </Box>
                </Flex>
                <Flex textAlign="center" align="center" borderBottom="1px">
                  <Text w="200px" h="50px" lineHeight="50px" borderRight="1px">
                    シンナー
                  </Text>
                  <Box py="5px" borderRight="1px">
                    <PrimaryInput
                      value={paintingReport.ingredient.thinner.name}
                      onChange={(e) =>
                        setPaintingReport({
                          ...paintingReport,
                          ingredient: {
                            ...paintingReport.ingredient,
                            thinner: {
                              ...paintingReport.ingredient.thinner,
                              name: e.target.value,
                            },
                          },
                        })
                      }
                      width="398px"
                      disabled={isLoadingAction}
                    />
                  </Box>
                  <Box>
                    <PrimaryNumberInput
                      value={paintingReport.ingredient.thinner.mixing_ratio}
                      minValue={0}
                      maxValue={100000}
                      step={0.1}
                      precision={1}
                      onChange={(num) =>
                        setPaintingReport({
                          ...paintingReport,
                          ingredient: {
                            ...paintingReport.ingredient,
                            thinner: {
                              ...paintingReport.ingredient.thinner,
                              mixing_ratio: num,
                            },
                          },
                        })
                      }
                      width="200px"
                      disabled={isLoadingAction}
                    />
                  </Box>
                </Flex>
                <Flex textAlign="center" align="center" borderBottom="1px">
                  <Text w="200px" h="50px" lineHeight="50px" borderRight="1px">
                    硬化剤
                  </Text>
                  <Box py="5px" borderRight="1px">
                    <PrimaryInput
                      value={paintingReport.ingredient.hardening_agent.name}
                      onChange={(e) =>
                        setPaintingReport({
                          ...paintingReport,
                          ingredient: {
                            ...paintingReport.ingredient,
                            hardening_agent: {
                              ...paintingReport.ingredient.hardening_agent,
                              name: e.target.value,
                            },
                          },
                        })
                      }
                      width="398px"
                      disabled={isLoadingAction}
                    />
                  </Box>
                  <Box>
                    <PrimaryNumberInput
                      value={
                        paintingReport.ingredient.hardening_agent.mixing_ratio
                      }
                      minValue={0}
                      maxValue={100000}
                      step={0.1}
                      precision={1}
                      onChange={(num) =>
                        setPaintingReport({
                          ...paintingReport,
                          ingredient: {
                            ...paintingReport.ingredient,
                            hardening_agent: {
                              ...paintingReport.ingredient.hardening_agent,
                              mixing_ratio: num,
                            },
                          },
                        })
                      }
                      width="200px"
                      disabled={isLoadingAction}
                    />
                  </Box>
                </Flex>
                <Flex textAlign="center" align="center" borderBottom="1px">
                  <Text w="200px" h="50px" lineHeight="50px" borderRight="1px">
                    塗料粘度
                  </Text>
                  <Flex borderRight="1px" width="400px">
                    <Box py="5px">
                      <PrimaryNumberInput
                        value={paintingReport.viscosity}
                        minValue={0}
                        maxValue={10000}
                        step={0.1}
                        precision={1}
                        onChange={(num) =>
                          setPaintingReport({
                            ...paintingReport,
                            viscosity: num,
                          })
                        }
                        width="200px"
                        disabled={isLoadingAction}
                      />
                    </Box>
                    <Text h="50px" lineHeight="50px">
                      秒
                    </Text>
                  </Flex>
                  <Text w="200px" h="50px" lineHeight="50px">
                    確認印
                  </Text>
                </Flex>
                <Flex textAlign="center" align="center">
                  <Text
                    w="200px"
                    h="50px"
                    lineHeight="50px"
                    borderRight="1px"
                    borderBottom="1px"
                  >
                    乾燥炉
                  </Text>
                  <Text
                    w="100px"
                    h="50px"
                    lineHeight="50px"
                    borderRight="1px"
                    borderBottom="1px"
                  >
                    ①
                  </Text>
                  <Text
                    w="100px"
                    h="50px"
                    lineHeight="50px"
                    borderRight="1px"
                    borderBottom="1px"
                  >
                    ②
                  </Text>
                  <Text
                    w="200px"
                    h="50px"
                    lineHeight="50px"
                    borderRight="1px"
                    borderBottom="1px"
                  >
                    ③
                  </Text>
                  <Text w="200px" h="50px" lineHeight="50px" />
                </Flex>
                <Flex textAlign="center" align="center">
                  <Flex w="200px" flexDirection="column" borderRight="1px">
                    <Text h="50px" lineHeight="50px">
                      乾燥温度
                    </Text>
                    <Text h="50px" lineHeight="50px">
                      乾燥時間
                    </Text>
                  </Flex>
                  {paintingReport.drying.map((elm, index) => {
                    return (
                      <Flex
                        key={index}
                        flexDirection="column"
                        borderRight="1px"
                      >
                        <Box>
                          <Flex width={index == 2 ? '198px' : '99px'}>
                            <Box py="5px">
                              <PrimaryNumberInput
                                value={elm.temperature}
                                step={0.1}
                                precision={1}
                                onChange={(num) =>
                                  setPaintingReport({
                                    ...paintingReport,
                                    drying: paintingReport.drying.map(
                                      (info, number) =>
                                        index == number
                                          ? {
                                              ...info,
                                              temperature: num,
                                            }
                                          : info
                                    ),
                                  })
                                }
                                flexGrow={1}
                                disabled={isLoadingAction}
                              />
                            </Box>
                            <Text h="50px" lineHeight="50px">
                              ℃
                            </Text>
                          </Flex>
                        </Box>
                        <Box>
                          <Flex width={index == 2 ? '198px' : '99px'}>
                            <Box py="5px">
                              <PrimaryNumberInput
                                value={elm.time}
                                minValue={0}
                                maxValue={10000}
                                onChange={(num) =>
                                  setPaintingReport({
                                    ...paintingReport,
                                    drying: paintingReport.drying.map(
                                      (info, number) =>
                                        index == number
                                          ? {
                                              ...info,
                                              time: num,
                                            }
                                          : info
                                    ),
                                  })
                                }
                                flexGrow={1}
                                disabled={isLoadingAction}
                              />
                            </Box>
                            <Text h="50px" lineHeight="50px">
                              分
                            </Text>
                          </Flex>
                        </Box>
                      </Flex>
                    )
                  })}
                </Flex>
              </Box>
            </Box>
            <Box>
              <Text textAlign="center" w="200px" h="450px" lineHeight="450px">
                →
              </Text>
            </Box>
            <Box>
              <Text h="50px" lineHeight="50px">
                ロットナンバー、液晶パネルの数値を記入
              </Text>
              <Box w="800px" borderTop="1px" borderLeft="1px">
                <Flex textAlign="center" align="center" borderBottom="1px">
                  <Flex w="200px" flexDirection="column" borderRight="1px">
                    <Text h="50px" lineHeight="50px" borderBottom="1px">
                      確認時間
                    </Text>
                    <Text h="50px" lineHeight="50px" borderBottom="1px">
                      塗料
                    </Text>
                    <Text h="50px" lineHeight="50px" borderBottom="1px">
                      シンナー
                    </Text>
                    <Text h="50px" lineHeight="50px" borderBottom="1px">
                      硬化剤
                    </Text>
                    <Text h="50px" lineHeight="50px" borderBottom="1px">
                      霧化圧
                    </Text>
                    <Text h="50px" lineHeight="50px" borderBottom="1px">
                      パターン圧
                    </Text>
                    <Text h="50px" lineHeight="50px" borderBottom="1px">
                      吐出圧
                    </Text>
                    <Text h="50px" lineHeight="50px">
                      確認印
                    </Text>
                  </Flex>
                  {paintingReport.panel.map((elm, index) => {
                    return (
                      <Flex
                        key={index}
                        flexDirection="column"
                        borderRight="1px"
                      >
                        <Box pt="5px" pb="4px" borderBottom="1px">
                          <PrimaryInput
                            type="time"
                            min="00:00"
                            max="23:59"
                            value={elm.registered_at}
                            onChange={(e) =>
                              setPaintingReport({
                                ...paintingReport,
                                panel: paintingReport.panel.map(
                                  (info, number) =>
                                    index == number
                                      ? {
                                          ...info,
                                          registered_at: e.target.value,
                                        }
                                      : info
                                ),
                              })
                            }
                            width="198px"
                            disabled={isLoadingAction}
                          />
                        </Box>
                        <Box pt="5px" pb="4px" borderBottom="1px">
                          <PrimaryNumberInput
                            value={elm.paint}
                            step={0.1}
                            precision={1}
                            onChange={(num) =>
                              setPaintingReport({
                                ...paintingReport,
                                panel: paintingReport.panel.map(
                                  (info, number) =>
                                    index == number
                                      ? {
                                          ...info,
                                          paint: num,
                                        }
                                      : info
                                ),
                              })
                            }
                            width="198px"
                            disabled={isLoadingAction}
                          />
                        </Box>
                        <Box pt="5px" pb="4px" borderBottom="1px">
                          <PrimaryNumberInput
                            value={elm.thinner}
                            step={0.1}
                            precision={1}
                            onChange={(num) =>
                              setPaintingReport({
                                ...paintingReport,
                                panel: paintingReport.panel.map(
                                  (info, number) =>
                                    index == number
                                      ? {
                                          ...info,
                                          thinner: num,
                                        }
                                      : info
                                ),
                              })
                            }
                            width="198px"
                            disabled={isLoadingAction}
                          />
                        </Box>
                        <Box pt="5px" pb="4px" borderBottom="1px">
                          <PrimaryNumberInput
                            value={elm.hardening_agent}
                            step={0.1}
                            precision={1}
                            onChange={(num) =>
                              setPaintingReport({
                                ...paintingReport,
                                panel: paintingReport.panel.map(
                                  (info, number) =>
                                    index == number
                                      ? {
                                          ...info,
                                          hardening_agent: num,
                                        }
                                      : info
                                ),
                              })
                            }
                            width="198px"
                            disabled={isLoadingAction}
                          />
                        </Box>
                        <Box pt="5px" pb="4px" borderBottom="1px">
                          <PrimaryNumberInput
                            value={elm.atomization_pressure}
                            step={0.1}
                            precision={1}
                            onChange={(num) =>
                              setPaintingReport({
                                ...paintingReport,
                                panel: paintingReport.panel.map(
                                  (info, number) =>
                                    index == number
                                      ? {
                                          ...info,
                                          atomization_pressure: num,
                                        }
                                      : info
                                ),
                              })
                            }
                            width="198px"
                            disabled={isLoadingAction}
                          />
                        </Box>
                        <Box pt="5px" pb="4px" borderBottom="1px">
                          <PrimaryNumberInput
                            value={elm.pattern_pressure}
                            step={0.1}
                            precision={1}
                            onChange={(num) =>
                              setPaintingReport({
                                ...paintingReport,
                                panel: paintingReport.panel.map(
                                  (info, number) =>
                                    index == number
                                      ? {
                                          ...info,
                                          pattern_pressure: num,
                                        }
                                      : info
                                ),
                              })
                            }
                            width="198px"
                            disabled={isLoadingAction}
                          />
                        </Box>
                        <Box pt="5px" pb="4px" borderBottom="1px">
                          <PrimaryNumberInput
                            value={elm.discharge_pressure}
                            step={0.1}
                            precision={1}
                            onChange={(num) =>
                              setPaintingReport({
                                ...paintingReport,
                                panel: paintingReport.panel.map(
                                  (info, number) =>
                                    index == number
                                      ? {
                                          ...info,
                                          discharge_pressure: num,
                                        }
                                      : info
                                ),
                              })
                            }
                            width="198px"
                            disabled={isLoadingAction}
                          />
                        </Box>
                        <Box w="200px" h="50px" />
                      </Flex>
                    )
                  })}
                </Flex>
              </Box>
            </Box>
          </Flex>

          <Flex mt={10}>
            <Text h="50px" lineHeight="50px" w="1000px">
              下記に調合毎に使用(作製)重量を記入する
            </Text>
            <Text h="50px" lineHeight="50px">
              塗膜信頼性検査実施記録欄
            </Text>
          </Flex>
          <Box borderBottom="1px" w="1203px">
            {paintingReport.result.map((elm, index) => {
              return (
                <Flex key={index}>
                  <Box>
                    <Box w="600px" borderTop="2px" borderLeft="1px">
                      <Flex
                        textAlign="center"
                        align="center"
                        borderBottom="1px"
                      >
                        <Text
                          w="200px"
                          h="50px"
                          lineHeight="50px"
                          borderRight="1px"
                        >
                          使用時間
                        </Text>
                        <Text
                          w="100px"
                          h="50px"
                          lineHeight="50px"
                          borderRight="1px"
                        >
                          塗料
                        </Text>
                        <Text
                          w="100px"
                          h="50px"
                          lineHeight="50px"
                          borderRight="1px"
                        >
                          シンナー
                        </Text>
                        <Text w="200px" h="50px" lineHeight="50px">
                          硬化剤
                        </Text>
                      </Flex>
                      <Flex
                        textAlign="center"
                        align="center"
                        borderBottom="1px"
                      >
                        <Box py="5px" borderRight="1px">
                          <PrimaryNumberInput
                            value={elm.usage}
                            minValue={0}
                            maxValue={100000}
                            step={0.1}
                            precision={1}
                            onChange={(num) =>
                              setPaintingReport({
                                ...paintingReport,
                                result: paintingReport.result.map(
                                  (info, number) =>
                                    index == number
                                      ? {
                                          ...info,
                                          usage: num,
                                        }
                                      : info
                                ),
                              })
                            }
                            width="199px"
                            disabled={isLoadingAction}
                          />
                        </Box>
                        <Box py="5px" borderRight="1px">
                          <PrimaryNumberInput
                            value={elm.paint_weight}
                            minValue={0}
                            maxValue={100000}
                            step={0.1}
                            precision={1}
                            onChange={(num) =>
                              setPaintingReport({
                                ...paintingReport,
                                result: paintingReport.result.map(
                                  (info, number) =>
                                    index == number
                                      ? {
                                          ...info,
                                          paint_weight: num,
                                        }
                                      : info
                                ),
                              })
                            }
                            width="99px"
                            disabled={isLoadingAction}
                          />
                        </Box>
                        <Box py="5px" borderRight="1px">
                          <PrimaryNumberInput
                            value={elm.thinner_weight}
                            minValue={0}
                            maxValue={100000}
                            step={0.1}
                            precision={1}
                            onChange={(num) =>
                              setPaintingReport({
                                ...paintingReport,
                                result: paintingReport.result.map(
                                  (info, number) =>
                                    index == number
                                      ? {
                                          ...info,
                                          thinner_weight: num,
                                        }
                                      : info
                                ),
                              })
                            }
                            width="99px"
                            disabled={isLoadingAction}
                          />
                        </Box>
                        <Box py="5px">
                          <PrimaryNumberInput
                            value={elm.hardening_agent_weight}
                            minValue={0}
                            maxValue={100000}
                            step={0.1}
                            precision={1}
                            onChange={(num) =>
                              setPaintingReport({
                                ...paintingReport,
                                result: paintingReport.result.map(
                                  (info, number) =>
                                    index == number
                                      ? {
                                          ...info,
                                          hardening_agent_weight: num,
                                        }
                                      : info
                                ),
                              })
                            }
                            width="199px"
                            disabled={isLoadingAction}
                          />
                        </Box>
                      </Flex>
                      <Flex
                        textAlign="center"
                        align="center"
                        borderBottom="1px"
                      >
                        <Text
                          w="200px"
                          h="50px"
                          lineHeight="50px"
                          borderRight="1px"
                        >
                          確認項目
                        </Text>
                        <Text
                          w="100px"
                          h="50px"
                          lineHeight="50px"
                          borderRight="1px"
                        >
                          室温
                        </Text>
                        <Text
                          w="100px"
                          h="50px"
                          lineHeight="50px"
                          borderRight="1px"
                        >
                          湿度
                        </Text>
                        <Text w="200px" h="50px" lineHeight="50px">
                          色調
                        </Text>
                      </Flex>
                      <Flex
                        textAlign="center"
                        align="center"
                        borderBottom="1px"
                      >
                        <Text
                          w="200px"
                          h="50px"
                          lineHeight="50px"
                          borderRight="1px"
                        >
                          結果
                        </Text>
                        <Box borderRight="1px">
                          <Flex width="99px">
                            <Box py="5px">
                              <PrimaryNumberInput
                                value={elm.temperature}
                                step={0.1}
                                precision={1}
                                onChange={(num) =>
                                  setPaintingReport({
                                    ...paintingReport,
                                    result: paintingReport.result.map(
                                      (info, number) =>
                                        index == number
                                          ? {
                                              ...info,
                                              temperature: num,
                                            }
                                          : info
                                    ),
                                  })
                                }
                                disabled={isLoadingAction}
                              />
                            </Box>
                            <Text h="50px" lineHeight="50px">
                              ℃
                            </Text>
                          </Flex>
                        </Box>
                        <Box borderRight="1px">
                          <Flex width="99px">
                            <Box py="5px">
                              <PrimaryNumberInput
                                value={elm.humidity}
                                minValue={0}
                                maxValue={100}
                                step={0.1}
                                precision={1}
                                onChange={(num) =>
                                  setPaintingReport({
                                    ...paintingReport,
                                    result: paintingReport.result.map(
                                      (info, number) =>
                                        index == number
                                          ? {
                                              ...info,
                                              humidity: num,
                                            }
                                          : info
                                    ),
                                  })
                                }
                                disabled={isLoadingAction}
                              />
                            </Box>
                            <Text h="50px" lineHeight="50px">
                              %
                            </Text>
                          </Flex>
                        </Box>
                        <Box>
                          <RadioGroup
                            onChange={(value) =>
                              setPaintingReport({
                                ...paintingReport,
                                result: paintingReport.result.map(
                                  (info, number) =>
                                    index == number
                                      ? {
                                          ...info,
                                          is_tone_condition: JSON.parse(value),
                                        }
                                      : info
                                ),
                              })
                            }
                            value={elm.is_tone_condition}
                          >
                            <Flex justify="space-around" w="199px">
                              <Radio value={true}>OK</Radio>
                              <Radio value={false}>NG</Radio>
                            </Flex>
                          </RadioGroup>
                        </Box>
                      </Flex>
                      <Flex textAlign="center" align="center">
                        <Text
                          w="200px"
                          h="50px"
                          lineHeight="50px"
                          borderRight="1px"
                        >
                          確認者
                        </Text>
                        <Box w="100px" h="50px" borderRight="1px" />
                        <Box w="100px" h="50px" borderRight="1px" />
                        <Box w="200px" h="50px" />
                        {/* <Box py="5px" borderRight="1px">
                        <PrimarySelectBox
                          options={masterList.users}
                          defaultOption={paintingReport.product_number}
                          optionElement="name"
                          onChangeSelect={(index) =>
                            setPaintingReport({
                              ...paintingReport,
                              product_number: masterList.users[index]['name'],
                            })
                          }
                          width="98px"
                          disabled={isLoadingAction}
                        />
                      </Box>
                      <Box py="5px" borderRight="1px">
                        <PrimarySelectBox
                          options={masterList.users}
                          defaultOption={paintingReport.product_number}
                          optionElement="name"
                          onChangeSelect={(index) =>
                            setPaintingReport({
                              ...paintingReport,
                              product_number: masterList.users[index]['name'],
                            })
                          }
                          width="99px"
                          disabled={isLoadingAction}
                        />
                      </Box>
                      <Box py="5px">
                        <PrimarySelectBox
                          options={masterList.users}
                          defaultOption={paintingReport.product_number}
                          optionElement="name"
                          onChangeSelect={(index) =>
                            setPaintingReport({
                              ...paintingReport,
                              product_number: masterList.users[index]['name'],
                            })
                          }
                          width="200px"
                          disabled={isLoadingAction}
                        />
                      </Box> */}
                      </Flex>
                    </Box>
                  </Box>
                  <Box borderTop="2px" borderLeft="1px">
                    <Text
                      textAlign="center"
                      w="400px"
                      h="51px"
                      lineHeight="51px"
                      borderBottom="1px"
                    >
                      膜厚測定結果
                    </Text>
                    <Box w="400px" h="50px" />
                    <RadioGroup
                      onChange={(value) =>
                        setPaintingReport({
                          ...paintingReport,
                          result: paintingReport.result.map((info, number) =>
                            index == number
                              ? {
                                  ...info,
                                  is_membrane_pressure_result:
                                    JSON.parse(value),
                                }
                              : info
                          ),
                        })
                      }
                      value={elm.is_membrane_pressure_result}
                    >
                      <Flex
                        justify="space-around"
                        w="400px"
                        h="50px"
                        lineHeight="50px"
                      >
                        <Radio value={true}>OK</Radio>
                        <Radio value={false}>NG</Radio>
                      </Flex>
                    </RadioGroup>
                    <Flex
                      justify="flex-end"
                      align="center"
                      borderBottom="1px"
                      py="6px"
                    >
                      <PrimaryNumberInput
                        value={elm.minimum_membrane_pressure}
                        minValue={0}
                        maxValue={100000}
                        onChange={(num) =>
                          setPaintingReport({
                            ...paintingReport,
                            result: paintingReport.result.map((info, number) =>
                              index == number
                                ? {
                                    ...info,
                                    minimum_membrane_pressure: num,
                                  }
                                : info
                            ),
                          })
                        }
                        width="120px"
                        disabled={isLoadingAction}
                      />
                      <Text mx={1}>μm</Text>
                      <Text mx={1}>~</Text>
                      <PrimaryNumberInput
                        value={elm.maximum_membrane_pressure}
                        minValue={0}
                        maxValue={100000}
                        onChange={(num) =>
                          setPaintingReport({
                            ...paintingReport,
                            result: paintingReport.result.map((info, number) =>
                              index == number
                                ? {
                                    ...info,
                                    maximum_membrane_pressure: num,
                                  }
                                : info
                            ),
                          })
                        }
                        width="120px"
                        disabled={isLoadingAction}
                      />
                      <Text mx={1}>μm</Text>
                    </Flex>
                    <Text h="50px" lineHeight="50px">
                      確認者
                    </Text>
                  </Box>
                  <Box borderTop="2px" borderLeft="1px" borderRight="1px">
                    <Text
                      textAlign="center"
                      w="200px"
                      h="51px"
                      lineHeight="51px"
                      borderBottom="1px"
                    >
                      クロスカット試験結果
                    </Text>
                    <Box w="200px" h="50px" />
                    <RadioGroup
                      onChange={(value) =>
                        setPaintingReport({
                          ...paintingReport,
                          result: paintingReport.result.map((info, number) =>
                            index == number
                              ? {
                                  ...info,
                                  is_cross_cut_test_result: JSON.parse(value),
                                }
                              : info
                          ),
                        })
                      }
                      value={elm.is_cross_cut_test_result}
                    >
                      <Flex
                        justify="space-around"
                        w="200px"
                        h="50px"
                        lineHeight="50px"
                      >
                        <Radio value={true}>OK</Radio>
                        <Radio value={false}>NG</Radio>
                      </Flex>
                    </RadioGroup>
                    <Box w="200px" h="53px" borderBottom="1px" />
                    <Text h="50px" lineHeight="50px">
                      確認者
                    </Text>
                  </Box>
                </Flex>
              )
            })}
          </Box>

          <Flex mt={10}>
            <Box h="286px" border="1px">
              <Text
                textAlign="center"
                w="600px"
                h="50px"
                lineHeight="50px"
                borderBottom="1px"
              >
                量産開始時 外観判定
              </Text>
              <Flex textAlign="center" borderBottom="1px">
                <Text w="300px" h="50px" lineHeight="50px" borderRight="1px">
                  確認
                </Text>
                <Text w="300px" h="50px" lineHeight="50px">
                  承認
                </Text>
              </Flex>
              <Flex align="center" borderBottom="1px">
                <RadioGroup
                  onChange={(value) =>
                    setPaintingReport({
                      ...paintingReport,
                      appearance_condition: {
                        ...paintingReport.appearance_condition,
                        is_confirmation_appearance_condition: JSON.parse(value),
                      },
                    })
                  }
                  value={
                    paintingReport.appearance_condition
                      .is_confirmation_appearance_condition
                  }
                >
                  <Flex
                    justify="space-around"
                    w="300px"
                    h="50px"
                    borderRight="1px"
                  >
                    <Radio value={true}>OK</Radio>
                    <Radio value={false}>NG</Radio>
                  </Flex>
                </RadioGroup>
                <RadioGroup
                  onChange={(value) =>
                    setPaintingReport({
                      ...paintingReport,
                      appearance_condition: {
                        ...paintingReport.appearance_condition,
                        is_approval_appearance_condition: JSON.parse(value),
                      },
                    })
                  }
                  value={
                    paintingReport.appearance_condition
                      .is_approval_appearance_condition
                  }
                >
                  <Flex justify="space-around" w="300px" h="50px">
                    <Radio value={true}>OK</Radio>
                    <Radio value={false}>NG</Radio>
                  </Flex>
                </RadioGroup>
              </Flex>
              <Flex align="center" borderBottom="1px">
                <Box py="5px" borderRight="1px">
                  <PrimarySelectBox
                    options={masterList.users}
                    defaultOption={
                      paintingReport.appearance_condition
                        .appearance_confirmation_name
                    }
                    optionElement="name"
                    onChangeSelect={(index) =>
                      setPaintingReport({
                        ...paintingReport,
                        appearance_condition: {
                          ...paintingReport.appearance_condition,
                          appearance_confirmation_name:
                            masterList.users[index]['name'],
                        },
                      })
                    }
                    width="299px"
                    disabled={isLoadingAction}
                  />
                </Box>
                <Box py="5px">
                  <PrimarySelectBox
                    options={masterList.users}
                    defaultOption={
                      paintingReport.appearance_condition
                        .appearance_approval_name
                    }
                    optionElement="name"
                    onChangeSelect={(index) =>
                      setPaintingReport({
                        ...paintingReport,
                        appearance_condition: {
                          ...paintingReport.appearance_condition,
                          appearance_approval_name:
                            masterList.users[index]['name'],
                        },
                      })
                    }
                    width="300px"
                    disabled={isLoadingAction}
                  />
                </Box>
              </Flex>
              <Textarea
                onChange={(e) =>
                  setPaintingReport({
                    ...paintingReport,
                    appearance_condition: {
                      ...paintingReport.appearance_condition,
                      defective_reason: e.target.value,
                    },
                  })
                }
                value={paintingReport.appearance_condition.defective_reason}
                h="100px"
                flexGrow={1}
                bg="white"
                _focus={{ boxShadow: 'none' }}
                variant="unstyled"
                placeholder="*NG理由・処置記録"
                isDisabled={isLoadingAction}
              />
            </Box>
            <Box w="200px" />
            <Box border="1px">
              <Flex textAlign="center" borderBottom="1px">
                <Text w="400px" h="50px" lineHeight="50px" borderRight="1px">
                  作業時間
                </Text>
                <Text w="200px" h="50px" lineHeight="50px" borderRight="1px">
                  作業者
                </Text>
                <Text w="200px" h="50px" lineHeight="50px">
                  工程検査員
                </Text>
              </Flex>
              <Flex borderBottom="1px">
                <Flex flexDirection="column">
                  <Flex textAlign="center">
                    <Text
                      w="200px"
                      h="50px"
                      lineHeight="50px"
                      borderBottom="1px"
                      borderRight="1px"
                    >
                      開始
                    </Text>
                    <Box pt="5px" pb="4px" borderBottom="1px" borderRight="1px">
                      <PrimaryInput
                        type="time"
                        min="00:00"
                        max="23:59"
                        value={paintingReport.start_at}
                        onChange={(e) =>
                          setPaintingReport({
                            ...paintingReport,
                            start_at: e.target.value,
                          })
                        }
                        width="199px"
                        disabled={isLoadingAction}
                      />
                    </Box>
                  </Flex>
                  <Flex textAlign="center">
                    <Text
                      w="200px"
                      h="50px"
                      lineHeight="50px"
                      borderRight="1px"
                    >
                      終了
                    </Text>
                    <Box pt="5px" pb="4px" borderRight="1px">
                      <PrimaryInput
                        type="time"
                        min="00:00"
                        max="23:59"
                        value={paintingReport.finish_at}
                        onChange={(e) =>
                          setPaintingReport({
                            ...paintingReport,
                            finish_at: e.target.value,
                          })
                        }
                        width="199px"
                        disabled={isLoadingAction}
                      />
                    </Box>
                  </Flex>
                </Flex>
                <Box py="25px" borderRight="1px">
                  <PrimarySelectBox
                    options={masterList.users}
                    defaultOption={paintingReport.worker}
                    optionElement="name"
                    onChangeSelect={(index) =>
                      setPaintingReport({
                        ...paintingReport,
                        worker: masterList.users[index]['name'],
                      })
                    }
                    width="199px"
                    disabled={isLoadingAction}
                  />
                </Box>
                <Box py="25px">
                  <PrimarySelectBox
                    options={masterList.users}
                    defaultOption={paintingReport.inspector}
                    optionElement="name"
                    onChangeSelect={(index) =>
                      setPaintingReport({
                        ...paintingReport,
                        inspector: masterList.users[index]['name'],
                      })
                    }
                    width="200px"
                    disabled={isLoadingAction}
                  />
                </Box>
              </Flex>
              <Flex textAlign="center" borderBottom="1px">
                <Text w="200px" h="50px" lineHeight="50px" borderRight="1px">
                  総検査数
                </Text>
                <Text w="200px" h="50px" lineHeight="50px" borderRight="1px">
                  未検査数
                </Text>
                <Text w="200px" h="50px" lineHeight="50px" borderRight="1px">
                  良品数
                </Text>
                <Text w="100px" h="50px" lineHeight="50px" borderRight="1px">
                  不良数
                </Text>
                <Text w="100px" h="50px" lineHeight="50px">
                  不良率
                </Text>
              </Flex>
              <Flex textAlign="center">
                <Box py="5px" w="200px" borderRight="1px">
                  <PrimaryNumberInput
                    value={paintingReport.count.inspected_count}
                    minValue={0}
                    maxValue={100000}
                    onChange={(num) =>
                      setPaintingReport({
                        ...paintingReport,
                        count: {
                          ...paintingReport.count,
                          inspected_count: num,
                        },
                      })
                    }
                    disabled={isLoadingAction}
                  />
                </Box>
                <Box py="5px" w="200px" borderRight="1px">
                  <PrimaryNumberInput
                    value={paintingReport.count.not_inspected_count}
                    minValue={0}
                    maxValue={100000}
                    onChange={(num) =>
                      setPaintingReport({
                        ...paintingReport,
                        count: {
                          ...paintingReport.count,
                          not_inspected_count: num,
                        },
                      })
                    }
                    disabled={isLoadingAction}
                  />
                </Box>
                <Box py="5px" w="200px" borderRight="1px">
                  <PrimaryNumberInput
                    value={paintingReport.count.non_defective_count}
                    minValue={0}
                    maxValue={100000}
                    onChange={(num) =>
                      setPaintingReport({
                        ...paintingReport,
                        count: {
                          ...paintingReport.count,
                          non_defective_count: num,
                        },
                      })
                    }
                    disabled={isLoadingAction}
                  />
                </Box>
                <Box py="5px" w="100px" borderRight="1px">
                  <PrimaryNumberInput
                    value={paintingReport.count.defective_count}
                    minValue={0}
                    maxValue={100000}
                    onChange={(num) =>
                      setPaintingReport({
                        ...paintingReport,
                        count: {
                          ...paintingReport.count,
                          defective_count: num,
                        },
                      })
                    }
                    disabled={isLoadingAction}
                  />
                </Box>
                <Text w="100px" h="50px" lineHeight="50px">
                  {paintingReport.count.inspected_count
                    ? Math.round(
                        (paintingReport.count.defective_count /
                          paintingReport.count.inspected_count) *
                          1000
                      ) / 10
                    : 0}
                  %
                </Text>
              </Flex>
              <Flex borderTop="2px" textAlign="center">
                <Flex flexDirection="column" borderRight="1px">
                  <Text w="199px" h="50px" lineHeight="50px" borderBottom="1px">
                    不良内容
                  </Text>
                  <Text w="199px" h="50px" lineHeight="50px" borderBottom="1px">
                    数量
                  </Text>
                  <Text w="199px" h="50px" lineHeight="50px">
                    占有率
                  </Text>
                </Flex>
                <Flex
                  textAlign="center"
                  flexDirection="column"
                  borderRight="1px"
                >
                  <Text w="99px" h="50px" lineHeight="50px" borderBottom="1px">
                    ブツ
                  </Text>
                  <Flex
                    align="center"
                    pt="5px"
                    pb="4px"
                    w="99px"
                    borderBottom="1px"
                  >
                    <PrimaryNumberInput
                      value={paintingReport.count.defective_detail_count.bump}
                      minValue={0}
                      maxValue={100000}
                      onChange={(num) =>
                        setPaintingReport({
                          ...paintingReport,
                          count: {
                            ...paintingReport.count,
                            defective_detail_count: {
                              ...paintingReport.count.defective_detail_count,
                              bump: num,
                            },
                          },
                        })
                      }
                      disabled={isLoadingAction}
                    />
                    <Text>ヶ</Text>
                  </Flex>
                  <Text w="99px" h="50px" lineHeight="50px">
                    {paintingReport.count.defective_count
                      ? Math.round(
                          (paintingReport.count.defective_detail_count.bump /
                            paintingReport.count.defective_count) *
                            1000
                        ) / 10
                      : 0}
                    %
                  </Text>
                </Flex>
                <Flex
                  textAlign="center"
                  flexDirection="column"
                  borderRight="1px"
                >
                  <Text w="99px" h="50px" lineHeight="50px" borderBottom="1px">
                    ケバ
                  </Text>
                  <Flex
                    align="center"
                    pt="5px"
                    pb="4px"
                    w="99px"
                    borderBottom="1px"
                  >
                    <PrimaryNumberInput
                      value={paintingReport.count.defective_detail_count.fluff}
                      minValue={0}
                      maxValue={100000}
                      onChange={(num) =>
                        setPaintingReport({
                          ...paintingReport,
                          count: {
                            ...paintingReport.count,
                            defective_detail_count: {
                              ...paintingReport.count.defective_detail_count,
                              fluff: num,
                            },
                          },
                        })
                      }
                      disabled={isLoadingAction}
                    />
                    <Text>ヶ</Text>
                  </Flex>
                  <Text w="99px" h="50px" lineHeight="50px">
                    {paintingReport.count.defective_count
                      ? Math.round(
                          (paintingReport.count.defective_detail_count.fluff /
                            paintingReport.count.defective_count) *
                            1000
                        ) / 10
                      : 0}
                    %
                  </Text>
                </Flex>
                <Flex
                  textAlign="center"
                  flexDirection="column"
                  borderRight="1px"
                >
                  <Text w="99px" h="50px" lineHeight="50px" borderBottom="1px">
                    糸ごみ
                  </Text>
                  <Flex
                    align="center"
                    pt="5px"
                    pb="4px"
                    w="99px"
                    borderBottom="1px"
                  >
                    <PrimaryNumberInput
                      value={
                        paintingReport.count.defective_detail_count.thread_waste
                      }
                      minValue={0}
                      maxValue={100000}
                      onChange={(num) =>
                        setPaintingReport({
                          ...paintingReport,
                          count: {
                            ...paintingReport.count,
                            defective_detail_count: {
                              ...paintingReport.count.defective_detail_count,
                              thread_waste: num,
                            },
                          },
                        })
                      }
                      disabled={isLoadingAction}
                    />
                    <Text>ヶ</Text>
                  </Flex>
                  <Text w="99px" h="50px" lineHeight="50px">
                    {paintingReport.count.defective_count
                      ? Math.round(
                          (paintingReport.count.defective_detail_count
                            .thread_waste /
                            paintingReport.count.defective_count) *
                            1000
                        ) / 10
                      : 0}
                    %
                  </Text>
                </Flex>
                <Flex
                  textAlign="center"
                  flexDirection="column"
                  borderRight="1px"
                >
                  <Text w="99px" h="50px" lineHeight="50px" borderBottom="1px">
                    キズ
                  </Text>
                  <Flex
                    align="center"
                    pt="5px"
                    pb="4px"
                    w="99px"
                    borderBottom="1px"
                  >
                    <PrimaryNumberInput
                      value={
                        paintingReport.count.defective_detail_count.scratch
                      }
                      minValue={0}
                      maxValue={100000}
                      onChange={(num) =>
                        setPaintingReport({
                          ...paintingReport,
                          count: {
                            ...paintingReport.count,
                            defective_detail_count: {
                              ...paintingReport.count.defective_detail_count,
                              scratch: num,
                            },
                          },
                        })
                      }
                      disabled={isLoadingAction}
                    />
                    <Text>ヶ</Text>
                  </Flex>
                  <Text w="99px" h="50px" lineHeight="50px">
                    {paintingReport.count.defective_count
                      ? Math.round(
                          (paintingReport.count.defective_detail_count.scratch /
                            paintingReport.count.defective_count) *
                            1000
                        ) / 10
                      : 0}
                    %
                  </Text>
                </Flex>
                <Flex
                  textAlign="center"
                  flexDirection="column"
                  borderRight="1px"
                >
                  <Text w="99px" h="50px" lineHeight="50px" borderBottom="1px">
                    シルバー
                  </Text>
                  <Flex
                    align="center"
                    pt="5px"
                    pb="4px"
                    w="99px"
                    borderBottom="1px"
                  >
                    <PrimaryNumberInput
                      value={paintingReport.count.defective_detail_count.silver}
                      minValue={0}
                      maxValue={100000}
                      onChange={(num) =>
                        setPaintingReport({
                          ...paintingReport,
                          count: {
                            ...paintingReport.count,
                            defective_detail_count: {
                              ...paintingReport.count.defective_detail_count,
                              silver: num,
                            },
                          },
                        })
                      }
                      disabled={isLoadingAction}
                    />
                    <Text>ヶ</Text>
                  </Flex>
                  <Text w="99px" h="50px" lineHeight="50px">
                    {paintingReport.count.defective_count
                      ? Math.round(
                          (paintingReport.count.defective_detail_count.silver /
                            paintingReport.count.defective_count) *
                            1000
                        ) / 10
                      : 0}
                    %
                  </Text>
                </Flex>
                <Flex textAlign="center" flexDirection="column">
                  <Text w="99px" h="50px" lineHeight="50px" borderBottom="1px">
                    ハジキ
                  </Text>
                  <Flex
                    align="center"
                    pt="5px"
                    pb="4px"
                    w="99px"
                    borderBottom="1px"
                  >
                    <PrimaryNumberInput
                      value={
                        paintingReport.count.defective_detail_count.cissing
                      }
                      minValue={0}
                      maxValue={100000}
                      onChange={(num) =>
                        setPaintingReport({
                          ...paintingReport,
                          count: {
                            ...paintingReport.count,
                            defective_detail_count: {
                              ...paintingReport.count.defective_detail_count,
                              cissing: num,
                            },
                          },
                        })
                      }
                      disabled={isLoadingAction}
                    />
                    <Text>ヶ</Text>
                  </Flex>
                  <Text w="99px" h="50px" lineHeight="50px">
                    {paintingReport.count.defective_count
                      ? Math.round(
                          (paintingReport.count.defective_detail_count.cissing /
                            paintingReport.count.defective_count) *
                            1000
                        ) / 10
                      : 0}
                    %
                  </Text>
                </Flex>
              </Flex>
              <Flex borderTop="2px" textAlign="center">
                <Flex flexDirection="column" borderRight="1px">
                  <Text w="199px" h="50px" lineHeight="50px" borderBottom="1px">
                    不良内容
                  </Text>
                  <Text w="199px" h="50px" lineHeight="50px" borderBottom="1px">
                    数量
                  </Text>
                  <Text w="199px" h="50px" lineHeight="50px">
                    占有率
                  </Text>
                </Flex>
                <Flex
                  textAlign="center"
                  flexDirection="column"
                  borderRight="1px"
                >
                  <Text w="99px" h="50px" lineHeight="50px" borderBottom="1px">
                    スワレ
                  </Text>
                  <Flex
                    align="center"
                    pt="5px"
                    pb="4px"
                    w="99px"
                    borderBottom="1px"
                  >
                    <PrimaryNumberInput
                      value={
                        paintingReport.count.defective_detail_count
                          .lack_of_hiding
                      }
                      minValue={0}
                      maxValue={100000}
                      onChange={(num) =>
                        setPaintingReport({
                          ...paintingReport,
                          count: {
                            ...paintingReport.count,
                            defective_detail_count: {
                              ...paintingReport.count.defective_detail_count,
                              lack_of_hiding: num,
                            },
                          },
                        })
                      }
                      disabled={isLoadingAction}
                    />
                    <Text>ヶ</Text>
                  </Flex>
                  <Text w="99px" h="50px" lineHeight="50px">
                    {paintingReport.count.defective_count
                      ? Math.round(
                          (paintingReport.count.defective_detail_count
                            .lack_of_hiding /
                            paintingReport.count.defective_count) *
                            1000
                        ) / 10
                      : 0}
                    %
                  </Text>
                </Flex>
                <Flex
                  textAlign="center"
                  flexDirection="column"
                  borderRight="1px"
                >
                  <Text w="99px" h="50px" lineHeight="50px" borderBottom="1px">
                    ワキ
                  </Text>
                  <Flex
                    align="center"
                    pt="5px"
                    pb="4px"
                    w="99px"
                    borderBottom="1px"
                  >
                    <PrimaryNumberInput
                      value={paintingReport.count.defective_detail_count.hole}
                      minValue={0}
                      maxValue={100000}
                      onChange={(num) =>
                        setPaintingReport({
                          ...paintingReport,
                          count: {
                            ...paintingReport.count,
                            defective_detail_count: {
                              ...paintingReport.count.defective_detail_count,
                              hole: num,
                            },
                          },
                        })
                      }
                      disabled={isLoadingAction}
                    />
                    <Text>ヶ</Text>
                  </Flex>
                  <Text w="99px" h="50px" lineHeight="50px">
                    {paintingReport.count.defective_count
                      ? Math.round(
                          (paintingReport.count.defective_detail_count.hole /
                            paintingReport.count.defective_count) *
                            1000
                        ) / 10
                      : 0}
                    %
                  </Text>
                </Flex>
                <Flex
                  textAlign="center"
                  flexDirection="column"
                  borderRight="1px"
                >
                  <Text w="99px" h="50px" lineHeight="50px" borderBottom="1px">
                    汚れ
                  </Text>
                  <Flex
                    align="center"
                    pt="5px"
                    pb="4px"
                    w="99px"
                    borderBottom="1px"
                  >
                    <PrimaryNumberInput
                      value={paintingReport.count.defective_detail_count.dirt}
                      minValue={0}
                      maxValue={100000}
                      onChange={(num) =>
                        setPaintingReport({
                          ...paintingReport,
                          count: {
                            ...paintingReport.count,
                            defective_detail_count: {
                              ...paintingReport.count.defective_detail_count,
                              dirt: num,
                            },
                          },
                        })
                      }
                      disabled={isLoadingAction}
                    />
                    <Text>ヶ</Text>
                  </Flex>
                  <Text w="99px" h="50px" lineHeight="50px">
                    {paintingReport.count.defective_count
                      ? Math.round(
                          (paintingReport.count.defective_detail_count.dirt /
                            paintingReport.count.defective_count) *
                            1000
                        ) / 10
                      : 0}
                    %
                  </Text>
                </Flex>
                <Flex
                  textAlign="center"
                  flexDirection="column"
                  borderRight="1px"
                >
                  <Text w="99px" h="50px" lineHeight="50px" borderBottom="1px">
                    ユズ
                  </Text>
                  <Flex
                    align="center"
                    pt="5px"
                    pb="4px"
                    w="99px"
                    borderBottom="1px"
                  >
                    <PrimaryNumberInput
                      value={paintingReport.count.defective_detail_count.citron}
                      minValue={0}
                      maxValue={100000}
                      onChange={(num) =>
                        setPaintingReport({
                          ...paintingReport,
                          count: {
                            ...paintingReport.count,
                            defective_detail_count: {
                              ...paintingReport.count.defective_detail_count,
                              citron: num,
                            },
                          },
                        })
                      }
                      disabled={isLoadingAction}
                    />
                    <Text>ヶ</Text>
                  </Flex>
                  <Text w="99px" h="50px" lineHeight="50px">
                    {paintingReport.count.defective_count
                      ? Math.round(
                          (paintingReport.count.defective_detail_count.citron /
                            paintingReport.count.defective_count) *
                            1000
                        ) / 10
                      : 0}
                    %
                  </Text>
                </Flex>
                <Flex
                  textAlign="center"
                  flexDirection="column"
                  borderRight="1px"
                >
                  <Text w="99px" h="50px" lineHeight="50px" borderBottom="1px">
                    タマリ
                  </Text>
                  <Flex
                    align="center"
                    pt="5px"
                    pb="4px"
                    w="99px"
                    borderBottom="1px"
                  >
                    <PrimaryNumberInput
                      value={
                        paintingReport.count.defective_detail_count.sagging
                      }
                      minValue={0}
                      maxValue={100000}
                      onChange={(num) =>
                        setPaintingReport({
                          ...paintingReport,
                          count: {
                            ...paintingReport.count,
                            defective_detail_count: {
                              ...paintingReport.count.defective_detail_count,
                              sagging: num,
                            },
                          },
                        })
                      }
                      disabled={isLoadingAction}
                    />
                    <Text>ヶ</Text>
                  </Flex>
                  <Text w="99px" h="50px" lineHeight="50px">
                    {paintingReport.count.defective_count
                      ? Math.round(
                          (paintingReport.count.defective_detail_count.sagging /
                            paintingReport.count.defective_count) *
                            1000
                        ) / 10
                      : 0}
                    %
                  </Text>
                </Flex>
                <Flex textAlign="center" flexDirection="column">
                  <Text w="99px" h="50px" lineHeight="50px" borderBottom="1px">
                    成形品
                  </Text>
                  <Flex
                    align="center"
                    pt="5px"
                    pb="4px"
                    w="99px"
                    borderBottom="1px"
                  >
                    <PrimaryNumberInput
                      value={
                        paintingReport.count.defective_detail_count.molding
                      }
                      minValue={0}
                      maxValue={100000}
                      onChange={(num) =>
                        setPaintingReport({
                          ...paintingReport,
                          count: {
                            ...paintingReport.count,
                            defective_detail_count: {
                              ...paintingReport.count.defective_detail_count,
                              molding: num,
                            },
                          },
                        })
                      }
                      disabled={isLoadingAction}
                    />
                    <Text>ヶ</Text>
                  </Flex>
                  <Text w="99px" h="50px" lineHeight="50px">
                    {paintingReport.count.defective_count
                      ? Math.round(
                          (paintingReport.count.defective_detail_count.molding /
                            paintingReport.count.defective_count) *
                            1000
                        ) / 10
                      : 0}
                    %
                  </Text>
                </Flex>
              </Flex>
              <Flex borderTop="2px" textAlign="center">
                <Flex flexDirection="column" borderRight="1px">
                  <Text w="199px" h="50px" lineHeight="50px" borderBottom="1px">
                    不良内容
                  </Text>
                  <Text w="199px" h="50px" lineHeight="50px" borderBottom="1px">
                    数量
                  </Text>
                  <Text w="199px" h="50px" lineHeight="50px">
                    占有率
                  </Text>
                </Flex>
                <Flex
                  textAlign="center"
                  flexDirection="column"
                  borderRight="1px"
                >
                  <Text w="99px" h="50px" lineHeight="50px" borderBottom="1px">
                    その他
                  </Text>
                  <Flex
                    align="center"
                    pt="5px"
                    pb="4px"
                    w="99px"
                    borderBottom="1px"
                  >
                    <PrimaryNumberInput
                      value={paintingReport.count.defective_detail_count.other}
                      minValue={0}
                      maxValue={100000}
                      onChange={(num) =>
                        setPaintingReport({
                          ...paintingReport,
                          count: {
                            ...paintingReport.count,
                            defective_detail_count: {
                              ...paintingReport.count.defective_detail_count,
                              other: num,
                            },
                          },
                        })
                      }
                      disabled={isLoadingAction}
                    />
                    <Text>ヶ</Text>
                  </Flex>
                  <Text w="99px" h="50px" lineHeight="50px">
                    {paintingReport.count.defective_count
                      ? Math.round(
                          (paintingReport.count.defective_detail_count.other /
                            paintingReport.count.defective_count) *
                            1000
                        ) / 10
                      : 0}
                    %
                  </Text>
                </Flex>
              </Flex>
            </Box>
          </Flex>
          <Flex justify="flex-end" my={5}>
            <Text>01版 文書番号 : L4-0744</Text>
          </Flex>

          <Flex justifyContent="center" alignItems="center">
            <HStack>
              <AlertModal
                buttonMessage="キャンセル"
                buttonLeft="日報登録に戻る"
                buttonRight="ログアウト"
                title="キャンセル"
                colorScheme="blackAlpha"
                body="日報登録を中断してログアウトしますか？"
                onClick={() => logout()}
                onClickCancel={() => navigate('/daily_report/mass_produce')}
                disabled={isLoadingAction}
              />
              <AlertModal
                buttonMessage={isUpdate ? '更新' : '記録'}
                title={isUpdate ? '更新' : '記録'}
                body={`この内容で日報を${isUpdate ? '更新' : '記録'}しますか？`}
                onClick={
                  isUpdate ? handleClickUpdateReport : handleClickRegisterReport
                }
                disabled={
                  isLoadingAction ||
                  !paintingReport.product_number ||
                  !paintingReport.working_date
                }
              />
            </HStack>
          </Flex>
          {isSucceeded && (
            <AlertModal
              needButton={false}
              buttonLeft="勤務に戻る"
              buttonRight="退勤"
              title="退勤"
              body="このまま退勤しますか？"
              onClick={() => handleClickClockOut()}
              onClickCancel={() => logout()}
            />
          )}
        </>
      )}
      {isBrowserBack && (
        <AlertModalWithoutButton
          title="戻る"
          body="データを変更した場合、更新ボタンを押さないと編集内容が破棄されますがよろしいですか？"
          onClick={() => window.history.back()}
          onClickCancel={() => {
            window.history.pushState(null, '', window.location.href)
            setIsBrowserBack(false)
          }}
        />
      )}
    </Box>
  )
}
