import React from 'react'
import {
  Grid,
  GridItem,
  Stack,
  Flex,
  Text,
  Box,
  Select,
} from '@chakra-ui/react'
import { PrimarySelectBox } from '../../atoms/PrimarySelectBox'
import { PrimaryInput } from '../../atoms/PrimaryInput'
import { useMessage } from '../../../hooks/useMessage'
import { PrimaryNumberInput } from '../../atoms/PrimaryNumberInput'
import { AlertModal } from '../AlertModal'

export const CommonInfoProject3 = (props) => {
  const {
    projectId,
    clients,
    defaultClient,
    member,
    defaultAssigner,
    valueProjectName,
    valueDueDate,
    valueTaxRate,
    valueExpirationDate,
    valueProjectStatus,
    valueCount,
    valueRegisteredDate,
    valueBillingDate,
    onChangeProjectName,
    onChangeDueDate,
    onChangeTaxRate,
    onChangeProjectStatus,
    onChangeExpirationDate,
    onChangeCount,
    onChangeRegisteredDate,
    onChangeBillingDate,
    onChangeSelectClientName,
    onChangeSelectMemberName,
    onClickCopyProject,
    onClickExportEstimate,
    onClickExportBill,
    onClickExportReceipt,
    isDisPlayed,
    isDisabled,
  } = props
  const { message } = useMessage()

  return (
    <Grid
      m={4}
      h={360}
      w="1270px"
      winW="750px"
      templateRows="repeat(10, 1fr)"
      templateColumns="repeat(12, 1fr)"
      gap={2}
    >
      <GridItem rowSpan={10} colSpan={3} align="right">
        <Stack spacing={2}>
          <Box display={!isDisPlayed && 'none'}>
            <Flex justify="space-between" align="center">
              <Text>案件番号</Text>
              <Text opacity={isDisabled && 0.3}>{projectId}</Text>
            </Flex>
          </Box>
          <Flex justify="space-between" align="center">
            <Text>
              顧客名
              <Box as="span" color="red">
                *
              </Box>
            </Text>
            <PrimarySelectBox
              options={clients}
              defaultOption={defaultClient}
              optionElement="client_name"
              onChangeSelect={onChangeSelectClientName}
              width="210px"
              disabled={isDisabled}
            />
          </Flex>
          <Flex justify="space-between" align="center">
            <Text>
              担当
              <Box as="span" color="red">
                *
              </Box>
            </Text>
            <PrimarySelectBox
              options={member}
              defaultOption={defaultAssigner}
              optionElement="name"
              onChangeSelect={onChangeSelectMemberName}
              width="210px"
              onFocus={() =>
                defaultAssigner &&
                message({
                  title: '重要情報です。編集の必要性を確認してください',
                  status: 'warning',
                  position: 'top',
                  duration: 5000,
                })
              }
              disabled={isDisabled}
            />
          </Flex>
          <Flex justify="space-between" align="center">
            <Text>
              案件名
              <Box as="span" color="red">
                *
              </Box>
            </Text>
            <PrimaryInput
              placeholder=""
              value={valueProjectName}
              htmlSize={20}
              onChange={(e) => onChangeProjectName(e)}
              disabled={isDisabled}
            />
          </Flex>
          <Flex justify="space-between" align="center">
            <Text mt={2}>
              納期
              <Box as="span" color="red">
                *
              </Box>
            </Text>
            <PrimaryInput
              type="date"
              min="2000-01-01"
              max="2050-01-01"
              value={valueDueDate}
              onChange={(e) => onChangeDueDate(e)}
              width="210px"
              disabled={isDisabled}
            />
          </Flex>
          <Flex justify="space-between" align="center">
            <Text>
              消費税率(%)
              <Box as="span" color="red">
                *
              </Box>
            </Text>
            <PrimaryNumberInput
              value={valueTaxRate}
              minValue={0}
              maxValue={99}
              onChange={(num) => onChangeTaxRate(num)}
              width="210px"
              disabled={isDisabled}
            />
          </Flex>
          <Flex justify="space-between" align="center">
            <Text>
              有効期限
              <Box as="span" color="red">
                *
              </Box>
            </Text>
            <PrimaryInput
              placeholder="発行より1ヶ月"
              value={valueExpirationDate}
              onChange={(e) => onChangeExpirationDate(e)}
              htmlSize={20}
              disabled={isDisabled}
            />
          </Flex>
          <Box display={!isDisPlayed && 'none'}>
            <Flex justify="space-between" align="center">
              <Text>ステータス</Text>
              <Select
                onChange={(e) => onChangeProjectStatus(e)}
                value={valueProjectStatus}
                bg="white"
                w="210px"
                _focus={{ boxShadow: 'none' }}
                disabled={isDisabled}
              >
                <option value="pending">検討中</option>
                <option value="manufacturing">製作中</option>
                <option value="done">納品済み</option>
                <option value="canceled">失注</option>
              </Select>
            </Flex>
          </Box>
        </Stack>
      </GridItem>
      <GridItem rowSpan={10} colStart={5} colEnd={8} align="center">
        <Stack spacing={2}>
          <Flex justify="space-between" align="center" mt={isDisPlayed ? 8 : 2}>
            <Text>
              見積日
              <Box as="span" color="red">
                *
              </Box>
            </Text>
            <PrimaryInput
              type="date"
              min="2000-01-01"
              max="2050-01-01"
              value={valueRegisteredDate}
              onChange={(e) => onChangeRegisteredDate(e)}
              width="210px"
              disabled={isDisabled}
            />
          </Flex>
          <Flex justify="space-between" align="center" mt={isDisPlayed ? 8 : 2}>
            <Text>請求日</Text>
            <PrimaryInput
              type="date"
              min="2000-01-01"
              max="2050-01-01"
              value={valueBillingDate}
              onChange={(e) => onChangeBillingDate(e)}
              width="210px"
              disabled={isDisabled}
            />
          </Flex>
          <Flex justify="space-between" align="center">
            <Text>数量</Text>
            <PrimaryNumberInput
              value={valueCount}
              minValue={0}
              maxValue={99}
              onChange={(num) => onChangeCount(num)}
              width="210px"
              disabled={isDisabled}
            />
          </Flex>
        </Stack>
      </GridItem>
      <GridItem
        rowSpan={10}
        colStart={11}
        colEnd={13}
        align="right"
        mr={2}
        mt={isDisPlayed ? 8 : 2}
      >
        <Box width="150px" display={!isDisPlayed && 'none'}>
          <Stack spacing={2}>
            <AlertModal
              body="この内容で案件を複製しますか？"
              buttonMessage="案件コピー"
              title="案件コピー"
              onClick={onClickCopyProject}
              disabled={isDisabled}
            />
            <AlertModal
              buttonMessage="簡易見積書出力"
              title="簡易見積書出力"
              body="この案件の見積書をダウンロードしますか？"
              onClick={onClickExportEstimate}
              disabled={isDisabled}
              colorScheme="yellow"
            />
            <AlertModal
              buttonMessage="請求書出力"
              title="請求書出力"
              body="この案件の請求書をダウンロードしますか？"
              onClick={onClickExportBill}
              disabled={isDisabled}
              colorScheme="yellow"
            />
            <AlertModal
              buttonMessage="納品書・受領書出力"
              title="納品書・物品受領書出力"
              body="この案件の納品書・物品受領書をダウンロードしますか？"
              onClick={onClickExportReceipt}
              disabled={isDisabled}
              colorScheme="yellow"
            />
          </Stack>
        </Box>
      </GridItem>
    </Grid>
  )
}
