import { Box, Flex, Heading, Text } from '@chakra-ui/react'
import React from 'react'
import { PrimaryNumberInput } from '../../atoms/PrimaryNumberInput'

export const CategorizationLabel = (props) => {
  const {
    categoryTitle,
    sellingPrice,
    buyingPrice,
    grossProfit,
    grossProfitMargin,
    bgColor = 'papayawhip',
    children,
    isShowDeliveryCostInput = false,
    valueSellingDeliveryCost,
    valueBuyingDeliveryCost,
    onChangeSellingDeliveryCost,
    onChangeBuyingDeliveryCost,
    isDisabled,
    isDisableBuyingCost = false,
  } = props
  return (
    <>
      <Box>
        <Flex justify="flex-end" align="center">
          <Flex
            justify="space-between"
            width="450px"
            fontSize="12px"
            fontWeight="semibold"
            color="#4A5568"
            mr="5px"
          >
            <Text mr="20px">売単価</Text>
            <Text visibility={isDisableBuyingCost && 'hidden'}>仕入単価</Text>
            <Text visibility={!grossProfit && 'hidden'}>粗利益</Text>
            <Text mr="50px" visibility={!grossProfit && 'hidden'}>
              粗利率
            </Text>
          </Flex>
        </Flex>
        <Flex justify="space-between" align="center" bg={bgColor} mb={2}>
          <Heading textAlign="center" as="h3" size="lg" my={4}>
            {categoryTitle}
          </Heading>
          {children}
          <Flex justify="space-between" align="center" width="455px">
            {isShowDeliveryCostInput ? (
              <>
                <PrimaryNumberInput
                  value={valueSellingDeliveryCost || 0}
                  minValue={0}
                  maxValue={100000}
                  // precision={2}
                  onChange={(num) => onChangeSellingDeliveryCost(num)}
                  width="100px"
                  disabled={isDisabled}
                />
                <Box mr="100px">
                  <PrimaryNumberInput
                    value={valueBuyingDeliveryCost || 0}
                    minValue={0}
                    maxValue={100000}
                    // precision={2}
                    onChange={(num) => onChangeBuyingDeliveryCost(num)}
                    width="100px"
                    disabled={isDisabled}
                  />
                </Box>
              </>
            ) : (
              <>
                <Text>¥{sellingPrice}</Text>
                <Text visibility={isDisableBuyingCost && 'hidden'}>
                  ¥{buyingPrice}
                </Text>
              </>
            )}
            <Text visibility={!grossProfit && 'hidden'}>
              ¥{grossProfit || 0}
            </Text>
            <Text mr="40px" visibility={!grossProfit && 'hidden'}>
              {Math.round(grossProfitMargin * 100) || 0}%
            </Text>
          </Flex>
        </Flex>
      </Box>
    </>
  )
}
