import React from 'react'
import { Box, Flex, Text, Select, Switch } from '@chakra-ui/react'
import { PrimaryInput } from '../../atoms/PrimaryInput'
import { PrimaryButton } from '../../atoms/PrimaryButton'
import { PrimarySelectBox } from '../../atoms/PrimarySelectBox'

export const FormSearchDailyReport = (props) => {
  const {
    masterList,
    recordTypes,
    products,
    valueRecordType,
    valueRegisteredBy,
    valueWorkingDateFrom,
    valueWorkingDateTo,
    valueUpdatingDateFrom,
    valueUpdatingDateTo,
    valueProductNumber,
    valueIsConfirmed,
    valueIsApproved,
    onChangeRecordType,
    onChangeRegisteredBy,
    onChangeWorkingDateFrom,
    onChangeWorkingDateTo,
    onChangeUpdatingDateFrom,
    onChangeUpdatingDateTo,
    onChangeProductNumber,
    onChangeIsConfirmed,
    onChangeIsApproved,
    lowerDate,
    upperDate,
    onClickSearch,
    onClickReset,
    isDisabled,
  } = props

  return (
    <>
      <Flex justify="center" alignItems="center" textAlign="center" my={4}>
        <Box>
          <Flex alignItems="center" textAlign="center" my={2}>
            <Text w="150px">日報種別</Text>
            <Select
              onChange={onChangeRecordType}
              bg="white"
              w="200px"
              _focus={{ boxShadow: 'none' }}
              value={valueRecordType}
              disabled={isDisabled}
            >
              {recordTypes.map((option, index) => {
                return (
                  <option key={index} value={option.value} hidden={index == 0}>
                    {option.name}
                  </option>
                )
              })}
            </Select>
            <Text w="150px">作業日</Text>
            <PrimaryInput
              type="date"
              value={valueWorkingDateFrom}
              max={upperDate}
              onChange={onChangeWorkingDateFrom}
              width="200px"
              disabled={isDisabled}
            />
            <Text w="35px">~</Text>
            <PrimaryInput
              type="date"
              value={valueWorkingDateTo}
              min={lowerDate}
              onChange={onChangeWorkingDateTo}
              width="200px"
              disabled={isDisabled}
            />
          </Flex>
          <Flex alignItems="center" textAlign="center" my={2}>
            <Text w="150px">品番</Text>
            <PrimarySelectBox
              options={products}
              defaultOption={valueProductNumber}
              optionElement="product_number"
              onChangeSelect={onChangeProductNumber}
              width="200px"
              disabled={isDisabled}
            />
            <Text w="150px">確認済み</Text>
            <Switch
              value={valueIsConfirmed}
              onChange={onChangeIsConfirmed}
              isChecked={valueIsConfirmed}
              isDisabled={isDisabled}
            />
            <Text w="150px">承認済み</Text>
            <Switch
              value={valueIsApproved}
              onChange={onChangeIsApproved}
              isChecked={valueIsApproved}
              isDisabled={isDisabled}
            />
          </Flex>
          <Flex alignItems="center" textAlign="center" my={2}>
            <Text w="150px">作成者</Text>
            <PrimarySelectBox
              options={masterList.users}
              defaultOption={valueRegisteredBy}
              optionElement="name"
              onChangeSelect={onChangeRegisteredBy}
              width="200px"
              disabled={isDisabled}
            />
            <Text w="150px">最終更新日時</Text>
            <PrimaryInput
              type="date"
              value={valueUpdatingDateFrom}
              max={upperDate}
              onChange={onChangeUpdatingDateFrom}
              width="200px"
              disabled={isDisabled}
            />
            <Text w="35px">~</Text>
            <PrimaryInput
              type="date"
              value={valueUpdatingDateTo}
              min={lowerDate}
              onChange={onChangeUpdatingDateTo}
              width="200px"
              disabled={isDisabled}
            />
          </Flex>
        </Box>
      </Flex>
      <Flex justify="center" alignItems="center" textAlign="center" my={2}>
        <Box mr={4}>
          <PrimaryButton
            colorScheme="gray"
            onClick={onClickReset}
            disabled={isDisabled}
          >
            リセット
          </PrimaryButton>
        </Box>
        <PrimaryButton onClick={onClickSearch} disabled={isDisabled}>
          検索
        </PrimaryButton>
      </Flex>
    </>
  )
}
