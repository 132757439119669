import { useToast } from "@chakra-ui/react";

export const useMessage = () => {
    const toast=useToast()
    const message=({title, status, position, duration=2000})=>{
        toast({
            title,
            status,
            position,
            duration
        })
    }

    return {message}
}