import React, { useEffect, useState } from 'react'
import { Box, Flex, Heading, Stack, Text, Textarea } from '@chakra-ui/react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { BeatLoader } from 'react-spinners'
import { useGetData } from '../../../hooks/useGetData'
import { CommonInfoOutsource } from '../../organisms/outsource/CommonInfoOutsource'
import {
  factories,
  paymentMethods,
} from '../../../constants/MasterInjectHelper'
import { initAddOutsource } from '../../../constants/initOutsource'
import { TableOutsourceCreated } from '../../organisms/outsource/TableOutsourceCreated'
import { TableOutsourceProviding } from '../../organisms/outsource/TableOutsourceProviding'
import { PrimarySelectBox } from '../../atoms/PrimarySelectBox'
import { AlertModal } from '../../organisms/AlertModal'
import { useMessage } from '../../../hooks/useMessage'
import {
  createOutsource,
  exportOnly,
  exportOutsource,
  putOutsource,
} from '../../../urls'
import { CommonInfoOutsourceForExport } from '../../organisms/outsource/CommonInfoOutsourceForExport'
import { useBlockBrowserBack } from '../../../hooks/users/useBlockBrowserBack'
import { AlertModalWithoutButton } from '../../organisms/AlertModalWithoutButton'

export const Outsource4 = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { message } = useMessage()
  const [searchParams] = useSearchParams()
  const { isBrowserBack, setIsBrowserBack } = useBlockBrowserBack()
  const {
    getMasters,
    masterList,
    getTargetInstruction,
    instruction,
    getTargetOutsource,
    outsource,
    setOutsource,
    isLoading,
    setIsLoading,
  } = useGetData()

  const [isLoadingAction, setIsLoadingAction] = useState()
  const [isCreate, setIsCreate] = useState(false)
  const [addOutsource, setAddOutsource] = useState(initAddOutsource)
  const isOnlyExport = searchParams.get('isOnlyExport')
  const lotNum = searchParams.get('lotNum')
  const outSourceId = searchParams.get('outSourceId')
  const processPlanId = searchParams.get('processPlanId')
  const processName = searchParams.get('processName')

  useEffect(() => {
    const getData = async () => {
      !isOnlyExport && (await getTargetInstruction(lotNum))
      await getMasters()
      if (outSourceId) {
        getTargetOutsource(outSourceId)
      } else if (processPlanId) {
        setOutsource({
          ...outsource,
          process_plan_id: processPlanId,
        })
        setIsCreate(true)
        setIsLoading(false)
      }
    }
    getData()
  }, [])

  const handleChangeSelectCreator = (num) => {
    setOutsource({
      ...outsource,
      created_by: masterList.users[num]['name'],
    })
  }

  const handleChangeSelectCompany = (num) => {
    console.log(num)
    setOutsource({
      ...outsource,
      outsource_company_id: masterList.companies[num]['outsource_company_name'],
      payment_method: masterList.companies[num]['payment_method'],
    })
  }

  const handleChangeOutsourcingPerson = (e) => {
    setOutsource({
      ...outsource,
      outsourcing_person: e.target.value,
    })
  }

  const handleChangeCreatedOrderedNum = (e, index) => {
    setOutsource({
      ...outsource,
      created_products: outsource.created_products.map((info, number) =>
        index == number
          ? {
              ...info,
              ordered_num: e.target.value,
            }
          : info
      ),
    })
  }

  const handleChangeCreatedProductNum = (e, index) => {
    setOutsource({
      ...outsource,
      created_products: outsource.created_products.map((info, number) =>
        index == number
          ? {
              ...info,
              product_num: e.target.value,
            }
          : info
      ),
    })
  }

  const handleChangeCreatedProductName = (e, index) => {
    setOutsource({
      ...outsource,
      created_products: outsource.created_products.map((info, number) =>
        index == number
          ? {
              ...info,
              product_name: e.target.value,
            }
          : info
      ),
    })
  }

  const handleChangeCreatedCount = (num, index) => {
    setOutsource({
      ...outsource,
      created_products: outsource.created_products.map((info, number) =>
        index == number
          ? {
              ...info,
              count: num,
            }
          : info
      ),
    })
  }

  const handleChangeCreatedUnitPrice = (num, index) => {
    setOutsource({
      ...outsource,
      created_products: outsource.created_products.map((info, number) =>
        index == number
          ? {
              ...info,
              unit_price: num,
            }
          : info
      ),
    })
  }

  const handleChangeCreatedDueDate = (e, index) => {
    setOutsource({
      ...outsource,
      created_products: outsource.created_products.map((info, number) =>
        index == number
          ? {
              ...info,
              due_date: e.target.value,
            }
          : info
      ),
    })
  }

  const handleChangeCreatedCarriedIn = (num, index) => {
    setOutsource({
      ...outsource,
      created_products: outsource.created_products.map((info, number) =>
        index == number
          ? {
              ...info,
              carried_in: factories[num]['name'],
            }
          : info
      ),
    })
  }

  const handleChangeCreatedOrderedNumAdd = (e) => {
    setAddOutsource({
      ...addOutsource,
      createdProduct: {
        ...addOutsource.createdProduct,
        orderedNum: e.target.value,
      },
    })
  }

  const handleChangeCreatedProductNumAdd = (e) => {
    setAddOutsource({
      ...addOutsource,
      createdProduct: {
        ...addOutsource.createdProduct,
        productNum: e.target.value,
      },
    })
  }

  const handleChangeCreatedProductNameAdd = (e) => {
    setAddOutsource({
      ...addOutsource,
      createdProduct: {
        ...addOutsource.createdProduct,
        productName: e.target.value,
      },
    })
  }

  const handleChangeCreatedCountAdd = (num) => {
    setAddOutsource({
      ...addOutsource,
      createdProduct: {
        ...addOutsource.createdProduct,
        count: num,
      },
    })
  }

  const handleChangeCreatedUnitPriceAdd = (num) => {
    setAddOutsource({
      ...addOutsource,
      createdProduct: {
        ...addOutsource.createdProduct,
        unitPrice: num,
      },
    })
  }

  const handleChangeCreatedDueDateAdd = (e) => {
    setAddOutsource({
      ...addOutsource,
      createdProduct: {
        ...addOutsource.createdProduct,
        dueDate: e.target.value,
      },
    })
  }

  const handleChangeCreatedCarriedInAdd = (num) => {
    setAddOutsource({
      ...addOutsource,
      createdProduct: {
        ...addOutsource.createdProduct,
        carriedIn: factories[num]['name'],
      },
    })
  }

  const handleClickAddCreatedProduct = () => {
    setOutsource({
      ...outsource,
      created_products: [
        ...outsource.created_products,
        {
          outsource_product_id: '',
          outsource_created_product_id: '',
          product_name: addOutsource.createdProduct.productName,
          count: addOutsource.createdProduct.count,
          unit_price: addOutsource.createdProduct.unitPrice,
          is_delete: false,
          due_date: addOutsource.createdProduct.dueDate,
          carried_in: addOutsource.createdProduct.carriedIn,
          ordered_num: addOutsource.createdProduct.orderedNum,
          product_num: addOutsource.createdProduct.productNum,
        },
      ],
    })
    setAddOutsource(initAddOutsource)
  }

  const handleClickDeleteCreatedProduct = (index) => {
    setOutsource({
      ...outsource,
      created_products: outsource.created_products.map((info, num) =>
        index == num
          ? {
              ...info,
              is_delete: true,
            }
          : info
      ),
    })
  }

  const handleChangeProvidingIsProvided = (e, index) => {
    setOutsource({
      ...outsource,
      providing_products: outsource.providing_products.map((info, number) =>
        index == number
          ? {
              ...info,
              is_provided: JSON.parse(e.target.value),
            }
          : info
      ),
    })
  }

  const handleChangeProvidingIsFree = (e, index) => {
    setOutsource({
      ...outsource,
      providing_products: outsource.providing_products.map((info, number) =>
        index == number
          ? {
              ...info,
              is_free: JSON.parse(e.target.value),
            }
          : info
      ),
    })
  }

  const handleChangeProvidingProductNum = (e, index) => {
    setOutsource({
      ...outsource,
      providing_products: outsource.providing_products.map((info, number) =>
        index == number
          ? {
              ...info,
              product_num: e.target.value,
            }
          : info
      ),
    })
  }

  const handleChangeProvidingProductName = (e, index) => {
    setOutsource({
      ...outsource,
      providing_products: outsource.providing_products.map((info, number) =>
        index == number
          ? {
              ...info,
              product_name: e.target.value,
            }
          : info
      ),
    })
  }

  const handleChangeProvidingCount = (num, index) => {
    setOutsource({
      ...outsource,
      providing_products: outsource.providing_products.map((info, number) =>
        index == number
          ? {
              ...info,
              count: num,
            }
          : info
      ),
    })
  }

  const handleChangeProvidingUnitPrice = (num, index) => {
    setOutsource({
      ...outsource,
      providing_products: outsource.providing_products.map((info, number) =>
        index == number
          ? {
              ...info,
              unit_price: num,
            }
          : info
      ),
    })
  }

  const handleChangeProvidingIsProvidedAdd = (e) => {
    setAddOutsource({
      ...addOutsource,
      providingProduct: {
        ...addOutsource.providingProduct,
        isProvided: JSON.parse(e.target.value),
      },
    })
  }

  const handleChangeProvidingIsFreeAdd = (e) => {
    setAddOutsource({
      ...addOutsource,
      providingProduct: {
        ...addOutsource.providingProduct,
        isFree: JSON.parse(e.target.value),
      },
    })
  }

  const handleChangeProvidingProductNumAdd = (e) => {
    setAddOutsource({
      ...addOutsource,
      providingProduct: {
        ...addOutsource.providingProduct,
        productNum: e.target.value,
      },
    })
  }

  const handleChangeProvidingProductNameAdd = (e) => {
    setAddOutsource({
      ...addOutsource,
      providingProduct: {
        ...addOutsource.providingProduct,
        productName: e.target.value,
      },
    })
  }

  const handleChangeProvidingCountAdd = (num) => {
    setAddOutsource({
      ...addOutsource,
      providingProduct: {
        ...addOutsource.providingProduct,
        count: num,
      },
    })
  }

  const handleChangeProvidingUnitPriceAdd = (num) => {
    setAddOutsource({
      ...addOutsource,
      providingProduct: {
        ...addOutsource.providingProduct,
        unitPrice: num,
      },
    })
  }

  const handleClickAddProvidingProduct = () => {
    setOutsource({
      ...outsource,
      providing_products: [
        ...outsource.providing_products,
        {
          outsource_product_id: '',
          outsource_created_product_id: '',
          product_name: addOutsource.providingProduct.productName,
          count: addOutsource.providingProduct.count,
          unit_price: addOutsource.providingProduct.unitPrice,
          is_delete: false,
          is_provided: addOutsource.providingProduct.isProvided,
          is_free: addOutsource.providingProduct.isFree,
          product_num: addOutsource.providingProduct.productNum,
        },
      ],
    })
    setAddOutsource(initAddOutsource)
  }

  const handleClickDeleteProvidingProduct = (index) => {
    setOutsource({
      ...outsource,
      providing_products: outsource.providing_products.map((info, num) =>
        index == num
          ? {
              ...info,
              is_delete: true,
            }
          : info
      ),
    })
  }

  const handleChangeCurrency = (e) => {
    console.log(e)
    setOutsource({
      ...outsource,
      currency: e.target.value,
    })
  }

  const handleChangeTaxRate = (num) => {
    setOutsource({
      ...outsource,
      tax_rate: num,
    })
  }

  const handleChangeNote = (e) => {
    setOutsource({
      ...outsource,
      note: e.target.value,
    })
  }

  const handleChangePaymentMethods = (index) => {
    setOutsource({
      ...outsource,
      payment_method: paymentMethods[index]['value'],
    })
  }

  const postParams = {
    method: isCreate ? 'POST' : 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(outsource),
  }

  const handleCreateOutsource = () => {
    setIsLoadingAction(true)
    fetch(createOutsource, postParams)
      .then((res) => res.json())
      .then((myjson) => {
        message({
          title: '発注・納品書の作成に成功しました。',
          status: 'success',
        })
        console.log(myjson)
        setOutsource(myjson)
        setIsCreate(false)
        setIsLoadingAction(false)
      })
      .catch(() =>
        message({ title: '通信エラーが発生しました', status: 'warning' })
      )
      .finally(() => setIsLoadingAction(false))
  }

  const handleUpdateOutsource = () => {
    setIsLoadingAction(true)
    fetch(putOutsource(outsource.outsource_id), postParams)
      .then((res) => res.json())
      .then((myjson) => {
        setOutsource(myjson)
        message({
          title: '発注・納品書の更新に成功しました。',
          status: 'success',
        })
      })
      .catch(() =>
        message({ title: '通信エラーが発生しました', status: 'warning' })
      )
      .finally(() => setIsLoadingAction(false))
  }

  const handleExportOutsource = () => {
    setIsLoadingAction(true)
    fetch(exportOutsource(outsource.outsource_id), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((myjson) => {
        message({
          title: '書類の作成に成功しました。',
          status: 'success',
          duration: 10000,
        })
        window.open(myjson.message)
      })
      .catch(() =>
        message({ title: '通信エラーが発生しました', status: 'warning' })
      )
      .finally(() => setIsLoadingAction(false))
  }

  const handleClickExportPurchaseBeforeRegister = () => {
    // 未登録案件発注書ダウンロード
    const params = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        project: {},
        outsource: outsource,
      }),
    }
    setIsLoadingAction(true)
    fetch(exportOnly, params)
      .then((res) => res.json())
      .then((myjson) => {
        message({
          title: '書類の作成に成功しました。',
          status: 'success',
          duration: 10000,
        })
        window.open(myjson.message)
      })
      .catch(() =>
        message({ title: '通信エラーが発生しました', status: 'warning' })
      )
      .finally(() => setIsLoadingAction(false))
  }

  const amount = () => {
    return (
      outsource.created_products.reduce(
        (sum, element) =>
          sum + (!element.is_delete && element.count * element.unit_price),
        0
      ) -
      outsource.providing_products.reduce(
        (sum, element) =>
          sum + (!element.is_delete && element.count * element.unit_price),
        0
      )
    )
  }

  return (
    <>
      {isLoading ? (
        <Flex h="100vh" alignItems="center" justifyContent="center">
          <BeatLoader size={30} color={'#3b5998'} />
        </Flex>
      ) : (
        <Box m={6}>
          <Heading mb={10} align="center">
            発注・納品書類
            {/* {location.state.isOnlyExport ? '出力' : isCreate ? '登録' : '詳細'} */}
            {isOnlyExport ? '出力' : isCreate ? '登録' : '詳細'}
          </Heading>
          {/* {location.state.isOnlyExport ? ( */}
          {isOnlyExport ? (
            <CommonInfoOutsourceForExport
              members={masterList.users}
              companies={masterList.companies}
              defaultCreator={outsource.created_by}
              defaultCompany={outsource.outsource_company_id}
              valueOutsourcingPerson={outsource.outsourcing_person}
              onChangeSelectCreator={handleChangeSelectCreator}
              onChangeSelectCompany={handleChangeSelectCompany}
              onChangeOutsourcingPerson={handleChangeOutsourcingPerson}
              isDisabled={isLoadingAction}
            />
          ) : (
            <CommonInfoOutsource
              // instruction={location.state.instruction}
              // processName={location.state.process.process_name}
              instruction={instruction.instruction}
              processName={processName}
              members={masterList.users}
              companies={masterList.companies}
              defaultCreator={outsource.created_by}
              defaultCompany={outsource.outsource_company_id}
              valueOutsourcingPerson={outsource.outsourcing_person}
              onChangeSelectCreator={handleChangeSelectCreator}
              onChangeSelectCompany={handleChangeSelectCompany}
              onChangeOutsourcingPerson={handleChangeOutsourcingPerson}
              isDisabled={isLoadingAction}
            />
          )}
          <Box>
            <Heading as="h3" size="md" my={4}>
              製品
            </Heading>
            <TableOutsourceCreated
              createdProducts={outsource.created_products}
              addOutsource={addOutsource.createdProduct}
              onChangeOrderedNum={handleChangeCreatedOrderedNum}
              onChangeProductNum={handleChangeCreatedProductNum}
              onChanceProductName={handleChangeCreatedProductName}
              onChangeCount={handleChangeCreatedCount}
              onChangeUnitPrice={handleChangeCreatedUnitPrice}
              onChangeDueDate={handleChangeCreatedDueDate}
              onChangeCarriedIn={handleChangeCreatedCarriedIn}
              onChangeOrderedNumAdd={handleChangeCreatedOrderedNumAdd}
              onChangeProductNumAdd={handleChangeCreatedProductNumAdd}
              onChanceProductNameAdd={handleChangeCreatedProductNameAdd}
              onChangeCountAdd={handleChangeCreatedCountAdd}
              onChangeUnitPriceAdd={handleChangeCreatedUnitPriceAdd}
              onChangeDueDateAdd={handleChangeCreatedDueDateAdd}
              onChangeCarriedInAdd={handleChangeCreatedCarriedInAdd}
              onClickAddProduct={handleClickAddCreatedProduct}
              onClickDeleteCreatedProduct={handleClickDeleteCreatedProduct}
              isLoadingAction={isLoadingAction}
            />
          </Box>
          <Box>
            <Heading as="h3" size="md" my={4}>
              支給
            </Heading>
            <TableOutsourceProviding
              amount={amount()}
              valueCurrency={outsource.currency}
              valueTaxRate={outsource.tax_rate}
              createdProducts={outsource.providing_products}
              addOutsource={addOutsource.providingProduct}
              onChangeIsProvided={handleChangeProvidingIsProvided}
              onChangeCurrency={handleChangeCurrency}
              onChangeTaxRate={handleChangeTaxRate}
              onChangeIsFree={handleChangeProvidingIsFree}
              onChangeProductNum={handleChangeProvidingProductNum}
              onChanceProductName={handleChangeProvidingProductName}
              onChangeCount={handleChangeProvidingCount}
              onChangeUnitPrice={handleChangeProvidingUnitPrice}
              onChangeIsProvidedAdd={handleChangeProvidingIsProvidedAdd}
              onChangeIsFreeAdd={handleChangeProvidingIsFreeAdd}
              onChangeProductNumAdd={handleChangeProvidingProductNumAdd}
              onChanceProductNameAdd={handleChangeProvidingProductNameAdd}
              onChangeCountAdd={handleChangeProvidingCountAdd}
              onChangeUnitPriceAdd={handleChangeProvidingUnitPriceAdd}
              onClickAddProduct={handleClickAddProvidingProduct}
              onClickDeleteProvidingProduct={handleClickDeleteProvidingProduct}
              isLoadingAction={isLoadingAction}
            />
          </Box>
          <Box mb={20}>
            <Flex justify="center" align="center" bg="#CFEDFB" py="30px">
              <Flex mr="100px" align="center">
                <Text mr="20px" opacity={isLoadingAction && 0.3}>
                  備考欄
                </Text>
                <Textarea
                  placeholder="備考欄"
                  onChange={handleChangeNote}
                  value={outsource.note}
                  h="100px"
                  w="400px"
                  size="sm"
                  bg="white"
                  _focus={{ boxShadow: 'none' }}
                  isDisabled={isLoadingAction}
                />
              </Flex>
              <Flex mr="100px" align="center">
                <Box mr="20px" opacity={isLoadingAction && 0.3}>
                  <Text>支払い条件</Text>
                  <Text ml="20px">月末締め翌月未支払</Text>
                </Box>
                <PrimarySelectBox
                  options={paymentMethods}
                  defaultOption={outsource.payment_method}
                  optionElement="name"
                  onChangeSelect={handleChangePaymentMethods}
                  width="250px"
                  disabled={isLoadingAction}
                />
              </Flex>
            </Flex>
          </Box>
          <Box>
            {/* {!location.state.isOnlyExport ? ( */}
            {!isOnlyExport ? (
              <Flex my={4} mx={6} justify="space-between" align="flex-end">
                <AlertModal
                  body="データを変更した場合、更新ボタンを押さないと編集内容が破棄されますがよろしいですか？"
                  buttonMessage="作業指示・実績書に戻る"
                  title="作業指示・実績書に戻る"
                  onClick={() =>
                    // navigate('/projects/instruction', {
                    //   state: {
                    //     lotNum: location.state.instruction.lot_num,
                    //     instructionNum:
                    //       location.state.instruction.instruction_num,
                    //   },
                    // })
                    navigate(
                      `/projects/instruction?lotNum=${instruction.instruction.lot_num}&instructionNum=${instruction.instruction.instruction_num}`
                    )
                  }
                  disabled={isLoadingAction}
                  primaryColor={false}
                />
                {isCreate ? (
                  <AlertModal
                    buttonMessage="新規登録"
                    title="新規登録"
                    body="この内容で新規作成しますか？"
                    onClick={handleCreateOutsource}
                    width="150px"
                    disabled={
                      !outsource.outsource_company_id ||
                      !outsource.outsourcing_person ||
                      !outsource.created_by ||
                      isLoadingAction
                    }
                  />
                ) : (
                  <Flex direction="column">
                    <Stack spacing={2}>
                      <AlertModal
                        buttonMessage="更新"
                        title="更新"
                        body="この内容で更新しますか？"
                        onClick={handleUpdateOutsource}
                        disabled={isLoadingAction}
                      />
                      <AlertModal
                        buttonMessage="発注・納品書出力"
                        title="発注・納品書出力"
                        body="発注・納品書をダウンロードしますか？"
                        onClick={handleExportOutsource}
                        disabled={isLoadingAction}
                        colorScheme="yellow"
                      />
                    </Stack>
                  </Flex>
                )}
              </Flex>
            ) : (
              <Flex my={4} mx={6} justify="center" align="flex-end">
                <AlertModal
                  buttonMessage="発注書出力"
                  title="発注書出力"
                  body="未登録案件の発注書をダウンロードしますか？"
                  onClick={handleClickExportPurchaseBeforeRegister}
                  disabled={isLoadingAction}
                  colorScheme="yellow"
                />
              </Flex>
            )}
            {isBrowserBack && (
              <AlertModalWithoutButton
                title="戻る"
                body="データを変更した場合、更新ボタンを押さないと編集内容が破棄されますがよろしいですか？"
                onClick={() => window.history.back()}
                onClickCancel={() => {
                  window.history.pushState(null, '', window.location.href)
                  setIsBrowserBack(false)
                }}
              />
            )}
          </Box>
        </Box>
      )}
    </>
  )
}
