import React, { useEffect } from "react"
import { Router } from "./router/Router";
import {ReactQueryDevtools} from "@tanstack/react-query-devtools"
import { useGetData } from "./hooks/useGetData";

function App() {
  return (
    <>
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
      <Router />
    </>
  );
}

export default App;
