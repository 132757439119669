import React, { useRef, useState } from 'react'
import {
  useDisclosure,
  Button,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
} from '@chakra-ui/react'
import { PrimaryButton } from '../atoms/PrimaryButton'

export const AlertModal = (props) => {
  const {
    needButton=true,
    buttonMessage,
    buttonLeft="キャンセル",
    buttonRight="実行",
    title,
    body,
    onClick,
    onClickCancel=()=>null,
    colorScheme,
    width,
    disabled,
    primaryColor = true,
    color,
  } = props
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef()

  return (
    <>
      {needButton && (
        <>
          {primaryColor ? (
            <PrimaryButton
              onClick={onOpen}
              colorScheme={colorScheme}
              width={width}
              disabled={disabled}
            >
              {buttonMessage}
            </PrimaryButton>
          ) : (
            <Button
              onClick={onOpen}
              disabled={disabled}
              size="xs"
              textDecoration="underline"
              color={color}
            >
              {buttonMessage}
            </Button>
          )}
        </>
      )}
      <AlertDialog
        isOpen={needButton ? isOpen : true}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader
              fontSize="lg"
              fontWeight="bold"
              textAlign="center"
            >
              {title}
            </AlertDialogHeader>
            <AlertDialogBody fontSize="md">{body}</AlertDialogBody>
            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                onClick={() => {
                  onClickCancel()
                  onClose()
                }}
                disabled={disabled}
                mr={4}
              >
                {buttonLeft}
              </Button>
              <PrimaryButton
                onClick={() => {
                  onClose()
                  onClick()
                }}
                disabled={disabled}
              >
                {buttonRight}
              </PrimaryButton>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}
