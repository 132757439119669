import React from 'react'
import { Tabs, TabList, TabPanels, Tab, TabPanel, Heading } from '@chakra-ui/react'
import { ShowListPrototype2 } from './ShowListPrototype2'
import { ShowList9 } from './ShowList9'

export const ProjectsList = () => {
  return (
    <>
      <Heading my={4} textAlign="center">
        案件一覧
      </Heading>
      <Tabs>
        <TabList>
          <Tab>試作領域</Tab>
          {/* <Tab>量産領域</Tab> */}
        </TabList>

        <TabPanels>
          <TabPanel>
            <ShowListPrototype2 />
          </TabPanel>
          <TabPanel>
            <ShowList9 />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  )
}
