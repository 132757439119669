import { login } from '../../urls'
import { useMutation } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { useMessage } from '../useMessage'
import { useCookies } from 'react-cookie'

export const useLogin3 = () => {
  const { message } = useMessage()
  const navigate = useNavigate()
  const [cookies, setCookie, removeCookie] = useCookies(['user'])

  const activateUser = async ({ userId, password }) => {
    const res = await fetch(login, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        "user_id": userId,
        "password": password,
      }),
    })
    const data = await res.json()
    return data
  }

  return useMutation(activateUser, {
    onSuccess: (data) => {
        if(data.message){
          message({
            title: 'IDまたはパスワードが間違っています',
            status: 'error',
          })
        }
        else{
          setCookie('user', data, {maxAge: 60*30})
          message({ title: 'ログインに成功しました', status: 'success' })
          data.access_level == 'worker'
            ? navigate('/daily_report', { state: data, replace: true })
            : navigate('/menu', { replace: true })
        }
    },
  })
}
