import 'gantt-task-react/dist/index.css'

import React from 'react'
import { ViewMode } from 'gantt-task-react'
import { Button, Checkbox, Flex } from '@chakra-ui/react'

export const ViewSwitcher = ({
  onViewModeChange,
  onViewListChange,
  isChecked,
}) => {
  return (
    <Flex
      p={4}
      gap="10px"
      backgroundColor="white"
      flexWrap="wrap"
      justifyContent="flex-end"
      alignItems="center"
    >
      <Button onClick={() => onViewModeChange(ViewMode.Hour)}>1時間毎</Button>
      <Button onClick={() => onViewModeChange(ViewMode.QuarterDay)}>
        6時間毎
      </Button>
      <Button onClick={() => onViewModeChange(ViewMode.HalfDay)}>半日毎</Button>
      <Button onClick={() => onViewModeChange(ViewMode.Day)}>1日毎</Button>
      <Button onClick={() => onViewModeChange(ViewMode.Week)}>1週間毎</Button>
      <Button onClick={() => onViewModeChange(ViewMode.Month)}>1ヶ月毎</Button>

      <Checkbox
        defaultChecked={isChecked}
        onChange={() => onViewListChange(!isChecked)}
      >
        タスクリストを表示
      </Checkbox>
    </Flex>
  )
}
