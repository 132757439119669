export const initOutsource = {
  outsource_id: 'OS00000000000000000000',
  process_plan_id: '',
  outsource_company_id: '',
  outsourcing_person: '',
  created_by: '',
  currency: '日本円',
  tax_rate: 10,
  note: '',
  payment_method: '',
  created_products: [
    // {
    //   outsource_product_id: '',
    //   outsource_created_product_id: '',
    //   product_name: '',
    //   count: 0,
    //   unit_price: 0,
    //   is_delete: false,
    //   due_date: '',
    //   carried_in: '',
    //   ordered_num: '',
    //   product_num: '',
    // },
  ],
  providing_products: [
    // {
    //   outsource_product_id: '',
    //   outsource_providing_product_id: '',
    //   product_name: '',
    //   count: 0,
    //   unit_price: 0,
    //   is_delete: false,
    //   is_provided: false,
    //   is_free: true,
    // },
  ],
}

export const initAddOutsource = {
  createdProduct: {
    productName: '',
    count: 1,
    unitPrice: 0,
    dueDate: '',
    carriedIn: '',
    orderedNum: '',
    productNum: '',
  },
  providingProduct: {
    productNum: '',
    productName: '',
    count: 1,
    unitPrice: 0,
    isProvided: false,
    isFree: true,
  },
}
