import React, { useRef } from 'react'
import {
  Box,
  Flex,
  Text,
  Select,
  ButtonGroup,
  useDisclosure,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  IconButton,
  AlertDialogFooter,
} from '@chakra-ui/react'
import { PrimaryInput } from '../atoms/PrimaryInput'
import { PrimaryButton } from '../atoms/PrimaryButton'
import { PrimarySelectBox } from '../atoms/PrimarySelectBox'
import { AiOutlineSearch } from 'react-icons/ai'
import { PrimaryNumberInput } from '../atoms/PrimaryNumberInput'

export const SearchModalPrototype2 = (props) => {
  const {
    clients,
    defaultClient,
    valueProjectName,
    valueProductName,
    valueLotNum,
    assigners,
    defaultAssigner,
    defaultStatus,
    valueStartDate,
    valueEndDate,
    lowerDate,
    upperDate,
    valueMinAmount,
    valueMaxAmount,
    minAmount,
    maxAmount,
    onChangeSelectClientName,
    onChangeProjectName,
    onChangeProductName,
    onChangeLotNum,
    onChangeStartDate,
    onChangeEndDate,
    onChangeSelectStatus,
    onChangeSelectAssigner,
    onChangeMinAmount,
    onChangeMaxAmount,
    onClickSearch,
    onClickReset,
    isDisabledResetButton,
    title,
  } = props
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef()

  const statusOptions = [
    { value: '', label: '' },
    { value: 'pending', label: '検討中' },
    { value: 'manufacturing', label: '製作中' },
    { value: 'done', label: '納品済み' },
    { value: 'canceled', label: '失注' },
  ]

  return (
    <Box my={4}>
      <form name="form">
        <IconButton
          aria-label="search"
          onClick={onOpen}
          icon={<AiOutlineSearch />}
        />
        <AlertDialog
          isOpen={isOpen}
          leastDestructiveRef={cancelRef}
          onClose={onClose}
        >
          <AlertDialogOverlay>
            <AlertDialogContent maxW="47rem">
              <AlertDialogHeader
                fontSize="xl"
                fontWeight="bold"
                textAlign="center"
              >
                {title}
              </AlertDialogHeader>
              <AlertDialogBody fontSize="md">
                <Box my={2}>
                  <Flex alignItems="center" textAlign="center">
                    <Text w="150px">顧客名</Text>
                    <PrimarySelectBox
                      options={clients}
                      defaultOption={defaultClient}
                      optionElement="client_name"
                      onChangeSelect={onChangeSelectClientName}
                      width="200px"
                    />
                    <Text w="150px">担当者</Text>
                    <PrimarySelectBox
                      options={assigners}
                      defaultOption={defaultAssigner}
                      optionElement="name"
                      onChangeSelect={onChangeSelectAssigner}
                      width="200px"
                    />
                  </Flex>
                </Box>
                <Box my={2}>
                  <Flex alignItems="center" textAlign="center">
                    <Text w="150px">ステータス</Text>
                    <Select
                      onChange={(e) => onChangeSelectStatus(e)}
                      bg="white"
                      w="200px"
                      _focus={{ boxShadow: 'none' }}
                      value={defaultStatus}
                    >
                      {statusOptions.map((option) => {
                        return (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        )
                      })}
                    </Select>
                    <Text w="150px">案件名</Text>
                    <PrimaryInput
                      placeholder=""
                      width="200px"
                      value={valueProjectName}
                      onChange={(e) => onChangeProjectName(e)}
                    />
                  </Flex>
                </Box>
                <Box my={2}>
                  <Flex alignItems="center" textAlign="center">
                    <Text w="150px">部品名</Text>
                    <PrimaryInput
                      placeholder=""
                      width="200px"
                      value={valueProductName}
                      onChange={(e) => onChangeProductName(e)}
                    />
                    <Text w="150px">品番</Text>
                    <PrimaryInput
                      placeholder=""
                      width="200px"
                      value={valueLotNum}
                      onChange={(e) => onChangeLotNum(e)}
                    />
                  </Flex>
                </Box>
                <Box my={2}>
                  <Flex alignItems="center" textAlign="center">
                    <Text w="150px">見積書作成日</Text>
                    <PrimaryInput
                      type="date"
                      value={valueStartDate}
                      max={upperDate}
                      onChange={(e) => onChangeStartDate(e)}
                      width="200px"
                    />
                    <Text w="35px">~</Text>
                    <PrimaryInput
                      type="date"
                      value={valueEndDate}
                      min={lowerDate}
                      onChange={(e) => onChangeEndDate(e)}
                      width="200px"
                    />
                  </Flex>
                </Box>
                <Box my={2}>
                  <Flex alignItems="center" textAlign="center">
                    <Text w="150px">見積り金額(万円)</Text>
                    <PrimaryNumberInput
                      width="200px"
                      value={valueMinAmount}
                      minValue={minAmount}
                      maxValue={
                        valueMaxAmount !== undefined
                          ? valueMaxAmount
                          : maxAmount
                      }
                      step={0.1}
                      onChange={(num) => onChangeMinAmount(num)}
                    />
                    <Text w="35px">~</Text>
                    <PrimaryNumberInput
                      width="200px"
                      value={valueMaxAmount}
                      minValue={
                        valueMinAmount !== undefined
                          ? valueMinAmount
                          : minAmount
                      }
                      maxValue={maxAmount}
                      step={0.1}
                      onChange={(num) => onChangeMaxAmount(num)}
                    />
                  </Flex>
                </Box>
              </AlertDialogBody>
              <AlertDialogFooter>
                <Flex justifyContent="flex-end" my={4}>
                  <ButtonGroup>
                    <PrimaryButton
                      onClick={() => {
                        onClickSearch()
                        onClose()
                      }}
                    >
                      検索
                    </PrimaryButton>
                    <PrimaryButton
                      disabled={isDisabledResetButton}
                      onClick={() => {
                        onClickReset()
                        document.form.reset()
                        onClose()
                      }}
                    >
                      リセット
                    </PrimaryButton>
                  </ButtonGroup>
                </Flex>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </form>
    </Box>
  )
}
