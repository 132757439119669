import React from 'react'
import { TableContainer, Table, Thead, Tr, Th, Tbody } from '@chakra-ui/react'
import { TableBodyInstruction3 } from '../../molecules/instruction/TableBodyInstruction3'

export const TableInstruction3 = (props) => {
  const {
    processes,
    addProcess,
    onChangeProcessName,
    onChangeEtaSetUpMinute,
    onChanceEtaProcessingMinute,
    onChangeEtaHandworkMinute,
    onChangeDueDate,
    onChangeIsOutsource,
    onChangeProcessNameAdd,
    onChangeEtaSetUpMinuteAdd,
    onChanceEtaProcessingMinuteAdd,
    onChangeEtaHandworkMinuteAdd,
    onChangeDueDateAdd,
    onChangeIsOutsourceAdd,
    onClickEditOutSource,
    onClickAddProcess,
    onClickDeleteProcess,
    isCreate,
    isLoadingAction,
  } = props

  return (
    <TableContainer>
      <Table variant="striped" colorScheme="linkedin">
        <Thead>
          <Tr>
            <Th>工程情報</Th>
            <Th>段取時間(分)</Th>
            <Th>実績</Th>
            <Th>加工時間(分)</Th>
            <Th>実績</Th>
            <Th>ハンドワーク時間(分)</Th>
            <Th>実績</Th>
            <Th>工程納期</Th>
            <Th>完了数</Th>
            <Th>不良数</Th>
            <Th>作業者名</Th>
            <Th>外注</Th>
            <Th>追加/削除</Th>
          </Tr>
        </Thead>
        <Tbody>
          {processes.map((process, index) => {
            return (
              <Tr key={index}>
                <TableBodyInstruction3
                  type="existing"
                  process={process}
                  valueProcessName={process.process_name}
                  valueEtaSetUpMinute={process.eta_setup_minute}
                  valueEtaProcessingMinute={process.eta_kakou_minute}
                  valueEtaHandworkMinute={process.eta_handwork_minute}
                  valueDueDate={process.due_date}
                  valueIsOutsource={process.is_outsource}
                  onChangeProcessName={(e) => onChangeProcessName(e, index)}
                  onChangeEtaSetUpMinute={(num) =>
                    onChangeEtaSetUpMinute(num, index)
                  }
                  onChanceEtaProcessingMinute={(num) =>
                    onChanceEtaProcessingMinute(num, index)
                  }
                  onChangeEtaHandworkMinute={(num) =>
                    onChangeEtaHandworkMinute(num, index)
                  }
                  onChangeDueDate={(e) => onChangeDueDate(e, index)}
                  onChangeIsOutsource={(e) => onChangeIsOutsource(e, index)}
                  onClickEditOutSource={() => onClickEditOutSource(process)}
                  onClickDeleteProcess={() => onClickDeleteProcess(index)}
                  isCreate={isCreate}
                  isDisabled={process.is_delete || isLoadingAction}
                />
              </Tr>
            )
          })}
          <Tr>
            <TableBodyInstruction3
              type="adding"
              process={addProcess}
              valueProcessName={addProcess.processName}
              valueEtaSetUpMinute={addProcess.etaSetupMinute}
              valueEtaProcessingMinute={addProcess.etaProcessingMinute}
              valueEtaHandworkMinute={addProcess.etaHandworkMinute}
              valueDueDate={addProcess.dueDate}
              valueIsOutsource={addProcess.isOutsource}
              onChangeProcessName={(e) => onChangeProcessNameAdd(e)}
              onChangeEtaSetUpMinute={(num) => onChangeEtaSetUpMinuteAdd(num)}
              onChanceEtaProcessingMinute={(num) =>
                onChanceEtaProcessingMinuteAdd(num)
              }
              onChangeEtaHandworkMinute={(num) =>
                onChangeEtaHandworkMinuteAdd(num)
              }
              onChangeDueDate={(e) => onChangeDueDateAdd(e)}
              onChangeIsOutsource={(e) => onChangeIsOutsourceAdd(e)}
              onClickAddProcess={onClickAddProcess}
              isDisabled={isLoadingAction}
              disabledAddButton={
                addProcess.isOutsource
                  ? !addProcess.processName ||
                    !addProcess.dueDate
                  : !addProcess.processName ||
                    !addProcess.etaSetupMinute ||
                    !addProcess.etaProcessingMinute ||
                    !addProcess.etaHandworkMinute ||
                    !addProcess.dueDate
              }
            />
          </Tr>
        </Tbody>
      </Table>
    </TableContainer>
  )
}
