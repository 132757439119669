import React from 'react'
import { Routes, Route } from 'react-router-dom'
import { AuthRoute } from './AuthRoute'
import { AuthenticatedRoute } from './AuthenticatedRoute'
import { UnAuthRoute } from './UnAuthRoute'
import { Login2 } from '../components/pages/Login2'

export const Router = () => {
  return (
    <>
        <Routes>
          <Route
            path=""
            element={
              <UnAuthRoute path="/">
                <Login2 />
              </UnAuthRoute>
            }
          />
          <Route
            path="/*"
            element={
              <AuthRoute path="/*">
                <AuthenticatedRoute />
              </AuthRoute>
            }
          />
        </Routes>
    </>
  )
}
