import React from 'react'
import { Box, Flex, IconButton, Switch, Text, Textarea } from '@chakra-ui/react'
import { PrimaryInput } from '../../../atoms/PrimaryInput'
import { PrimaryNumberInput } from '../../../atoms/PrimaryNumberInput'
import { PrimarySelectBox } from '../../../atoms/PrimarySelectBox'
import { AiOutlineDelete, AiOutlinePlus } from 'react-icons/ai'
import { StringSelectBox } from '../../../atoms/StringSelectBox'

export const FormDryingFurnace = (props) => {
  const { printReport, setPrintReport, isLoadingAction, masterList, dryingFurnace } = props

  return (
    <Flex
      textAlign="center"
      align="center"
      borderLeft="1px"
      borderRight="1px"
      borderBottom="1px"
      w="1450px"
    >
      <Text w="100px" minWidth="100px" h="50px" lineHeight="50px">
        乾燥炉
      </Text>
      <Box w="1350px" minWidth="1350px" borderLeft="1px">
        <Flex textAlign="center" align="center" borderBottom="1px">
          <Text
            w="50px"
            minWidth="50px"
            h="50px"
            lineHeight="50px"
            borderRight="1px"
          >
            工程
          </Text>
          <Text
            w="195px"
            minWidth="195px"
            h="50px"
            lineHeight="50px"
            borderRight="1px"
          >
            インクロッド
          </Text>
          <Text
            w="195px"
            minWidth="195px"
            h="50px"
            lineHeight="50px"
            borderRight="1px"
          >
            インク粘度
          </Text>
          <Text
            w="195px"
            minWidth="195px"
            h="50px"
            lineHeight="50px"
            borderRight="1px"
          >
            乾燥温度
          </Text>
          <Text
            w="195px"
            minWidth="195px"
            h="50px"
            lineHeight="50px"
            borderRight="1px"
          >
            乾燥時間
          </Text>
          <Text
            w="195px"
            minWidth="195px"
            h="50px"
            lineHeight="50px"
            borderRight="1px"
          >
            室内温度
          </Text>
          <Text
            w="195px"
            minWidth="195px"
            h="50px"
            lineHeight="50px"
            borderRight="1px"
          >
            室内湿度
          </Text>
          <Text
            w="50px"
            minWidth="50px"
            h="50px"
            lineHeight="50px"
            borderRight="1px"
          >
            取り手
          </Text>
          <Text w="80px" minWidth="80px" h="50px" lineHeight="50px">
            追加/削除
          </Text>
        </Flex>
        {printReport.dry_heating.drying_furnace.map((elm, index) => {
          return (
            <Flex
              textAlign="center"
              align="center"
              borderBottom={
                printReport.dry_heating.drying_furnace.length !== index + 1 &&
                '1px'
              }
              key={index}
            >
              <Text
                w="50px"
                minWidth="50px"
                h="50px"
                lineHeight="50px"
                borderRight="1px"
              >
                {index}
              </Text>
              <Box w="194px" minWidth="194px">
                {/* <PrimaryInput
                  value={elm.ink_lot_num}
                  onChange={(e) =>
                    setPrintReport({
                      ...printReport,
                      dry_heating: {
                        ...printReport.dry_heating,
                        drying_furnace:
                          printReport.dry_heating.drying_furnace.map(
                            (info, number) =>
                              index == number
                                ? {
                                    ...info,
                                    ink_lot_num: e.target.value,
                                  }
                                : info
                          ),
                      },
                    })
                  }
                  width="100%"
                  disabled={isLoadingAction}
                /> */}
                <StringSelectBox
                  options={masterList.ink_names}
                  defaultOption={elm.ink_lot_num}
                  onChangeSelect={(value) =>
                    setPrintReport({
                      ...printReport,
                      dry_heating: {
                        ...printReport.dry_heating,
                        drying_furnace:
                          printReport.dry_heating.drying_furnace.map(
                            (info, number) =>
                              index == number
                                ? {
                                    ...info,
                                    ink_lot_num: value,
                                  }
                                : info
                          ),
                      },
                    })
                  }
                  width="100%"
                  disabled={isLoadingAction}
                />
              </Box>
              <Flex w="195px" minWidth="195px" borderLeft="1px">
                <Box pt="5px" flexGrow={1}>
                  <PrimaryNumberInput
                    value={elm.ink_viscosity}
                    minValue={0}
                    maxValue={10000}
                    onChange={(num) =>
                      setPrintReport({
                        ...printReport,
                        dry_heating: {
                          ...printReport.dry_heating,
                          drying_furnace:
                            printReport.dry_heating.drying_furnace.map(
                              (info, number) =>
                                index == number
                                  ? {
                                      ...info,
                                      ink_viscosity: num,
                                    }
                                  : info
                            ),
                        },
                      })
                    }
                    disabled={isLoadingAction}
                  />
                </Box>
                <Text h="50px" lineHeight="50px">
                  dPa•s
                </Text>
              </Flex>
              <Flex w="195px" minWidth="195px" borderLeft="1px">
                <Box pt="5px" flexGrow={1}>
                  <PrimaryNumberInput
                    value={elm.drying_temperature}
                    onChange={(num) =>
                      setPrintReport({
                        ...printReport,
                        dry_heating: {
                          ...printReport.dry_heating,
                          drying_furnace:
                            printReport.dry_heating.drying_furnace.map(
                              (info, number) =>
                                index == number
                                  ? {
                                      ...info,
                                      drying_temperature: num,
                                    }
                                  : info
                            ),
                        },
                      })
                    }
                    disabled={isLoadingAction}
                  />
                </Box>
                <Text h="50px" lineHeight="50px">
                  ℃
                </Text>
              </Flex>
              <Flex w="195px" minWidth="195px" borderLeft="1px">
                <Box pt="5px" flexGrow={1}>
                  <PrimaryNumberInput
                    value={elm.drying_time}
                    minValue={0}
                    maxValue={1000}
                    onChange={(num) =>
                      setPrintReport({
                        ...printReport,
                        dry_heating: {
                          ...printReport.dry_heating,
                          drying_furnace:
                            printReport.dry_heating.drying_furnace.map(
                              (info, number) =>
                                index == number
                                  ? {
                                      ...info,
                                      drying_time: num,
                                    }
                                  : info
                            ),
                        },
                      })
                    }
                    disabled={isLoadingAction}
                  />
                </Box>
                <Text h="50px" lineHeight="50px">
                  分
                </Text>
              </Flex>
              <Flex w="195px" minWidth="195px" borderLeft="1px">
                <Box pt="5px" flexGrow={1}>
                  <PrimaryNumberInput
                    value={elm.indoor_temperature}
                    onChange={(num) =>
                      setPrintReport({
                        ...printReport,
                        dry_heating: {
                          ...printReport.dry_heating,
                          drying_furnace:
                            printReport.dry_heating.drying_furnace.map(
                              (info, number) =>
                                index == number
                                  ? {
                                      ...info,
                                      indoor_temperature: num,
                                    }
                                  : info
                            ),
                        },
                      })
                    }
                    disabled={isLoadingAction}
                  />
                </Box>
                <Text h="50px" lineHeight="50px">
                  ℃
                </Text>
              </Flex>
              <Flex w="195px" minWidth="195px" borderLeft="1px">
                <Box pt="5px" flexGrow={1}>
                  <PrimaryNumberInput
                    value={elm.indoor_humidity}
                    minValue={0}
                    maxValue={100}
                    onChange={(num) =>
                      setPrintReport({
                        ...printReport,
                        dry_heating: {
                          ...printReport.dry_heating,
                          drying_furnace:
                            printReport.dry_heating.drying_furnace.map(
                              (info, number) =>
                                index == number
                                  ? {
                                      ...info,
                                      indoor_humidity: num,
                                    }
                                  : info
                            ),
                        },
                      })
                    }
                    disabled={isLoadingAction}
                  />
                </Box>
                <Text h="50px" lineHeight="50px">
                  %
                </Text>
              </Flex>
              <Switch
                w="50px"
                minWidth="50px"
                h="50px"
                lineHeight="55px"
                borderLeft="1px"
                value={elm.is_recipient}
                onChange={(e) =>
                  setPrintReport({
                    ...printReport,
                    dry_heating: {
                      ...printReport.dry_heating,
                      drying_furnace:
                        printReport.dry_heating.drying_furnace.map(
                          (info, number) =>
                            index == number
                              ? {
                                  ...info,
                                  is_recipient: e.target.checked,
                                }
                              : info
                        ),
                    },
                  })
                }
                isChecked={elm.is_recipient}
                isDisabled={isLoadingAction}
              />
              <Flex
                w="80px"
                minWidth="80px"
                h="50px"
                justify="center"
                align="center"
                borderLeft="1px"
              >
                {printReport.dry_heating.drying_furnace.length == index + 1 ? (
                  <IconButton
                    aria-label="追加"
                    disabled={isLoadingAction}
                    onClick={() =>
                      setPrintReport({
                        ...printReport,
                        dry_heating: {
                          ...printReport.dry_heating,
                          drying_furnace: [
                            ...printReport.dry_heating.drying_furnace,
                            dryingFurnace,
                          ],
                        },
                      })
                    }
                    icon={<AiOutlinePlus />}
                  />
                ) : (
                  <IconButton
                    aria-label="削除"
                    disabled={isLoadingAction}
                    onClick={() => {
                      console.log(
                        printReport.dry_heating.time.filter(
                          (num) => index !== num
                        )
                      )
                      setPrintReport({
                        ...printReport,
                        dry_heating: {
                          ...printReport.dry_heating,
                          drying_furnace:
                            printReport.dry_heating.drying_furnace.filter(
                              (_, num) => index !== num
                            ),
                        },
                      })
                    }}
                    icon={<AiOutlineDelete />}
                  />
                )}
              </Flex>
            </Flex>
          )
        })}
      </Box>
    </Flex>
  )
}
