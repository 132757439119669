import React from 'react'
import { Button } from '@chakra-ui/react'

export const PrimaryButton = (props) => {
  const { onClick, children, colorScheme="facebook", width, disabled } = props
  return (
    <>
      <Button
        colorScheme={colorScheme}
        variant="solid"
        onClick={onClick}
        width={width}
        disabled={disabled}
      >
        {children}
      </Button>
    </>
  )
}
