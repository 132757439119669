import React, { useRef } from 'react'
import {
  useDisclosure,
  Button,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  IconButton,
} from '@chakra-ui/react'
import { PrimaryButton } from '../atoms/PrimaryButton'
import { AiOutlineDelete } from 'react-icons/ai'

export const AlertDeleteModal = (props) => {
  const { title, body1, body2, body3, onClick, disabled } = props
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef()

  return (
    <>
      <IconButton
        aria-label="delete"
        disabled={disabled}
        onClick={onOpen}
        icon={<AiOutlineDelete />}
      />
      <AlertDialog
        isOpen={isOpen}
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader
              fontSize="lg"
              fontWeight="bold"
              p="24px"
            >
              {title}
            </AlertDialogHeader>
            <AlertDialogBody py={0} fontSize="md">
              {body1}
            </AlertDialogBody>
            <AlertDialogBody py={0} fontSize="md">
              {body2}
            </AlertDialogBody>
            <AlertDialogBody py={0} fontSize="md">
              {body3}
            </AlertDialogBody>
            <AlertDialogFooter py="24px">
              <Button
                ref={cancelRef}
                onClick={onClose}
                disabled={disabled}
                mr={4}
              >
                キャンセル
              </Button>
              <PrimaryButton
                onClick={() => {
                  onClose()
                  onClick()
                }}
                disabled={disabled}
              >
                実行
              </PrimaryButton>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}
