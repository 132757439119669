export function initTasks() {
  const currentDate = new Date()
  const tasks = [
    {
      start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 5),
      end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 9),
      name: '成形機1号機180T',
      id: 'ProjectSample',
      progress: 0,
      type: 'project',  //タスクの表示種類(project, task milestone)
      hideChildren: false,
      displayOrder: 1,
    },
    {
      start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 5),
      end: new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        5,
        12,
        28
      ),
      name: 'クオン',
      id: 'Task 0',
      progress: 100,
      type: 'task',
      project: 'ProjectSample',
      displayOrder: 2,
    },
    {
      start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 6),
      end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 7, 0, 0),
      name: '増山勝久',
      id: 'Task 1',
      progress: 100,
      dependencies: ['Task 0'],
      type: 'task',
      project: 'ProjectSample',
      displayOrder: 3,
    },
    {
      start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 8),
      end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 9, 0, 0),
      name: 'タム',
      id: 'Task 2',
      progress: 100,
      dependencies: ['Task 1'],
      type: 'task',
      project: 'ProjectSample',
      displayOrder: 4,
    },
    {
      start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 6),
      end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 15),
      name: '成形機2号機140T',
      id: '成形機2号機140T',
      progress: 0,
      type: 'project',
      hideChildren: false,
      displayOrder: 5,
    },
    {
      start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 6),
      end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 9, 0, 0),
      name: 'クオン',
      id: 'Task 3',
      progress: 100,
      type: 'task',
      project: '成形機2号機140T',
      displayOrder: 6,
    },
    {
      start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 11),
      end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 13),
      name: '増山勝久',
      id: 'Task 4',
      type: 'task',
      progress: 20,
      dependencies: ['Task 3'],
      project: '成形機2号機140T',
      displayOrder: 7,
    },
    {
      start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 13),
      end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 15),
      name: 'タム',
      id: 'Task 5',
      type: 'task',
      progress: 0,
      dependencies: ['Task 4'],
      project: '成形機2号機140T',
      displayOrder: 8,
    },
    // {
    //   start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 15),
    //   end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 15),
    //   name: 'タム',
    //   id: 'Task 5',
    //   progress: 20,
    //   type: 'task',
    //   dependencies: ['Task 4'],
    //   project: '成形機2号機140T',
    //   displayOrder: 8,
    // },
    // {
    //   start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 15),
    //   end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 15),
    //   name: 'タム',
    //   id: 'Task 5',
    //   progress: currentDate.getMonth(),
    //   type: 'milestone',
    //   dependencies: ['Task 4'],
    //   project: '成形機2号機140T',
    //   displayOrder: 8,
    // },
    // {
    //   start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 18),
    //   end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 19),
    //   name: 'Party Time',
    //   id: 'Task 9',
    //   progress: 0,
    //   isDisabled: true,
    //   type: 'task',
    // },
  ]
  return tasks
}

export function getStartEndDateForProject(tasks, projectId) {
  const projectTasks = tasks.filter((t) => t.project === projectId)
  let start = projectTasks[0].start
  let end = projectTasks[0].end

  for (let i = 0; i < projectTasks.length; i++) {
    const task = projectTasks[i]
    if (start.getTime() > task.start.getTime()) {
      start = task.start
    }
    if (end.getTime() < task.end.getTime()) {
      end = task.end
    }
  }
  return [start, end]
}
