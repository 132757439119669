import { Route, Navigate, Routes } from 'react-router-dom'
import { useCurrentUser2 } from '../hooks/users/useCurrentUser2'

export const UnAuthRoute = ({ path, children }) => {
  const user = useCurrentUser2()
  return (
    <Routes>
      <Route path={path} element={user ? <Navigate to="/menu" /> : children} />
    </Routes>
  )
}
