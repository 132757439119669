import React, { useState, useEffect } from 'react'
import { exportOnly } from '../../../urls'
import {
  Box,
  Heading,
  Flex,
  Table,
  TableContainer,
  Text,
} from '@chakra-ui/react'
import { useMessage } from '../../../hooks/useMessage'
import { AlertModal } from '../../organisms/AlertModal'
import { TableBottom4 } from '../../molecules/formatA/TableBottom4'
import { BeatLoader } from 'react-spinners'
import { useGetData } from '../../../hooks/useGetData'
import { CommonInfoProject3 } from '../../organisms/project/CommonInfoProject3'
import { TableProductListOnlyExport } from '../../organisms/project/TableProductListOnlyExport'
import { useBlockBrowserBack } from '../../../hooks/users/useBlockBrowserBack'
import { AlertModalWithoutButton } from '../../organisms/AlertModalWithoutButton'

export const ProjectOnlyExport = () => {
  const { message } = useMessage()
  const { project, setProject, isLoading, getNewProject } = useGetData()
const { isBrowserBack, setIsBrowserBack } = useBlockBrowserBack()
  const [isLoadingAction, setIsLoadingAction] = useState()

  const initLotObj = {
    instruction_num: '',
    product_num: '',
    product_name: '',
    lot_amount: 0,
    unit_cost: 0,
    unit: '',
  }
  const [lotObj, setLotObj] = useState(initLotObj)

  useEffect(() => {
    getNewProject()
  }, [])

  const handleClickExportInvoiceBeforeRegister = () => {
    // 未登録案件請求書ダウンロード
    const params = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        project: project.project,
        outsource: {},
      }),
    }
    setIsLoadingAction(true)
    fetch(exportOnly, params)
      .then((res) => res.json())
      .then((myjson) => {
        message({
          title: '書類の作成に成功しました。',
          status: 'success',
          duration: 10000,
        })
        window.open(myjson.message)
      })
      .catch(() =>
        message({ title: '通信エラーが発生しました', status: 'warning' })
      )
      .finally(() => setIsLoadingAction(false))
  }

  return (
    <>
      {isLoading ? (
        <Flex h="100vh" alignItems="center" justifyContent="center">
          <BeatLoader size={30} color={'#3b5998'} />
        </Flex>
      ) : (
        <Box m={2}>
          <Heading mb={10} align="center">
            試作案件直接出力
          </Heading>
          <CommonInfoProject3
            projectId={project.project.project_id}
            clients={project.clients}
            defaultClient={project.project.client_name}
            member={project.users}
            defaultAssigner={project.project.assigner}
            valueProjectName={project.project.project_name}
            valueDueDate={project.project.due_date}
            valueTaxRate={project.project.tax_rate}
            valueExpirationDate={project.project.expiring_date}
            valueProjectStatus={project.project.project_status}
            valueRegisteredDate={project.project.registered_date}
            valueBillingDate={project.project.billing_date}
            valueCount={project.project.count}
            onChangeProjectName={(e) =>
              setProject({
                ...project,
                project: {
                  ...project.project,
                  project_name: e.target.value,
                },
              })
            }
            onChangeTaxRate={(num) =>
              setProject({
                ...project,
                project: {
                  ...project.project,
                  tax_rate: num,
                },
              })
            }
            onChangeDueDate={(e) =>
              setProject({
                ...project,
                project: {
                  ...project.project,
                  due_date: e.target.value,
                },
              })
            }
            onChangeProjectStatus={(e) =>
              setProject({
                ...project,
                project: {
                  ...project.project,
                  project_status: e.target.value,
                },
              })
            }
            onChangeCount={(num) =>
              setProject({
                ...project,
                project: {
                  ...project.project,
                  count: num,
                },
              })
            }
            onChangeRegisteredDate={(e) =>
              setProject({
                ...project,
                project: {
                  ...project.project,
                  registered_date: e.target.value,
                },
              })
            }
            onChangeBillingDate={(e) =>
              setProject({
                ...project,
                project: {
                  ...project.project,
                  billing_date: e.target.value,
                },
              })
            }
            onChangeExpirationDate={(e) =>
              setProject({
                ...project,
                project: {
                  ...project.project,
                  expiring_date: e.target.value,
                },
              })
            }
            onChangeSelectClientName={(index) =>
              setProject({
                ...project,
                project: {
                  ...project.project,
                  client_name: project.clients[index]['client_name'],
                  postal_code: project.clients[index]['postal_code'],
                  address: project.clients[index]['address'],
                },
              })
            }
            onChangeSelectMemberName={(index) =>
              setProject({
                ...project,
                project: {
                  ...project.project,
                  assigner: project.users[index]['name'],
                  email: project.users[index]['email'],
                },
              })
            }
            isDisPlayed={false}
            isDisabled={isLoadingAction}
          />

          <Box my={10}>
            <Flex alignItems="center">
              <Text
                as="h2"
                ml={3}
                mr={10}
                fontSize="20px"
                fontWeight="semibold"
              >
                部品一覧
              </Text>
            </Flex>
            <TableContainer mt={2}>
              <Table variant="striped" colorScheme="linkedin">
                <TableBottom4
                  amount={Math.round(
                    project.project.lots.reduce((sum, lot) => {
                      return lot.lot_amount * lot.unit_cost + sum
                    }, 0) || 0
                  )}
                  totalAmount={Math.round(
                    project.project.lots.reduce((sum, lot) => {
                      return lot.lot_amount * lot.unit_cost + sum
                    }, 0) *
                      (1 + project.project.tax_rate / 100) || 0
                  )}
                  valueProjectStatus="done"
                  valueNoteForBill={project.project.note_bill}
                  onChangeNoteForBill={(e) =>
                    setProject({
                      ...project,
                      project: {
                        ...project.project,
                        note_bill: e.target.value,
                      },
                    })
                  }
                  isDisabled={isLoadingAction}
                />

                <TableProductListOnlyExport
                  lots={project.project.lots}
                  lotObj={lotObj}
                  onChangeInstructionIndex={(e, index) =>
                    setProject({
                      ...project,
                      project: {
                        ...project.project,
                        lots: project.project.lots.map((info, number) =>
                          index == number
                            ? {
                                ...info,
                                instruction_num: e.target.value,
                              }
                            : info
                        ),
                      },
                    })
                  }
                  onChangeProductNum={(e, index) =>
                    setProject({
                      ...project,
                      project: {
                        ...project.project,
                        lots: project.project.lots.map((info, number) =>
                          index == number
                            ? {
                                ...info,
                                product_num: e.target.value,
                              }
                            : info
                        ),
                      },
                    })
                  }
                  onChanceProductName={(e, index) =>
                    setProject({
                      ...project,
                      project: {
                        ...project.project,
                        lots: project.project.lots.map((info, number) =>
                          index == number
                            ? {
                                ...info,
                                product_name: e.target.value,
                              }
                            : info
                        ),
                      },
                    })
                  }
                  onChangeCount={(num, index) =>
                    setProject({
                      ...project,
                      project: {
                        ...project.project,
                        lots: project.project.lots.map((info, number) =>
                          index == number
                            ? {
                                ...info,
                                lot_amount: num,
                              }
                            : info
                        ),
                      },
                    })
                  }
                  onChangeUnit={(e, index) =>
                    setProject({
                      ...project,
                      project: {
                        ...project.project,
                        lots: project.project.lots.map((info, number) =>
                          index == number
                            ? {
                                ...info,
                                unit: e.target.value,
                              }
                            : info
                        ),
                      },
                    })
                  }
                  onChangePrice={(num, index) =>
                    setProject({
                      ...project,
                      project: {
                        ...project.project,
                        lots: project.project.lots.map((info, number) =>
                          index == number
                            ? {
                                ...info,
                                unit_cost: num,
                              }
                            : info
                        ),
                      },
                    })
                  }
                  onChangeInstructionIndexAdd={(e) =>
                    setLotObj({
                      ...lotObj,
                      instruction_num: e.target.value,
                    })
                  }
                  onChangeProductNumAdd={(e) =>
                    setLotObj({
                      ...lotObj,
                      product_num: e.target.value,
                    })
                  }
                  onChanceProductNameAdd={(e) =>
                    setLotObj({
                      ...lotObj,
                      product_name: e.target.value,
                    })
                  }
                  onChangeUnitAdd={(e) =>
                    setLotObj({
                      ...lotObj,
                      unit: e.target.value,
                    })
                  }
                  onChangeCountAdd={(num) =>
                    setLotObj({
                      ...lotObj,
                      lot_amount: num,
                    })
                  }
                  onChangePriceAdd={(num) =>
                    setLotObj({
                      ...lotObj,
                      unit_cost: num,
                    })
                  }
                  onClickAddProduct={() => {
                    setProject({
                      ...project,
                      project: {
                        ...project.project,
                        lots: [...project.project.lots, lotObj],
                      },
                    })
                    setLotObj(initLotObj)
                  }}
                  onClickDeleteProduct={(index) =>
                    setProject({
                      ...project,
                      project: {
                        ...project.project,
                        lots: project.project.lots.filter(
                          (_, num) => index !== num
                        ),
                      },
                    })
                  }
                  isDisabled={isLoadingAction}
                />
              </Table>
            </TableContainer>
          </Box>

          <Flex my={4} mx={6} justify="center">
            <AlertModal
              buttonMessage="請求書出力"
              title="請求書出力"
              body="未登録案件の請求書をダウンロードしますか？"
              onClick={handleClickExportInvoiceBeforeRegister}
              width="150px"
              disabled={
                !project.project.client_name ||
                !project.project.assigner ||
                !project.project.project_name ||
                !project.project.due_date ||
                !project.project.tax_rate ||
                !project.project.expiring_date ||
                !project.project.registered_date ||
                isLoadingAction
              }
            />
          </Flex>
          {isBrowserBack && (
            <AlertModalWithoutButton
              title="戻る"
              body="データを変更した場合、更新ボタンを押さないと編集内容が破棄されますがよろしいですか？"
              // onClick={() => window.history.back()}
              onClick={() => {
                // setIsBrowserBack(false)
                window.history.back()
              }}
              onClickCancel={() => {
                window.history.pushState(null, '', window.location.href)
                setIsBrowserBack(false)
              }}
            />
          )}
        </Box>
      )}
    </>
  )
}
