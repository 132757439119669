import React from 'react'
import {
  Box,
  Flex,
  Grid,
  GridItem,
  Stack,
  Text,
} from '@chakra-ui/react'
import { useMessage } from '../../../hooks/useMessage'
import { PrimaryInput } from '../../atoms/PrimaryInput'
import { PrimarySelectBox } from '../../atoms/PrimarySelectBox'

export const CommonInfoOutsource = (props) => {
  const {
    instruction,
    processName,
    members,
    companies,
    defaultCreator,
    defaultCompany,
    valueOutsourcingPerson,
    onChangeSelectCreator,
    onChangeSelectCompany,
    onChangeOutsourcingPerson,
    isDisabled,
  } = props

  const { message } = useMessage()

  return (
    <Grid
      h={340}
      templateRows="repeat(10, 1fr)"
      templateColumns="repeat(12, 1fr)"
      gap={2}
    >
      <GridItem rowSpan={7} colSpan={3} align="right">
        <Stack spacing={2}>
          <Flex justify="space-between" align="center">
            <Text>指示書No.</Text>
            <Text>{instruction.instruction_num}</Text>
          </Flex>
          <Flex justify="space-between" align="center">
            <Text>工程</Text>
            <Text>{processName}</Text>
          </Flex>
          <Flex justify="space-between" align="center">
            <Text>顧客名</Text>
            <Text>{instruction.client_name}</Text>
          </Flex>
          <Flex justify="space-between" align="center">
            <Text>客先注番</Text>
            <Text>{instruction.customer_order_num}</Text>
          </Flex>
          <Flex justify="space-between" align="center">
            <Text>品番</Text>
            <Text>{instruction.product_num}</Text>
          </Flex>
          <Flex justify="space-between" align="center">
            <Text>品名</Text>
            <Text>{instruction.product_name}</Text>
          </Flex>
          <Flex justify="space-between" align="center">
            <Text>設変No.</Text>
            <Text>{instruction.setting_change_num}</Text>
          </Flex>
        </Stack>
      </GridItem>
      <GridItem rowSpan={7} colStart={4} colEnd={9} />
      <GridItem rowSpan={7} colStart={10} colEnd={13}>
        <Flex justify="space-between" align="center" h="40px">
          <Text mt={2}>指示日</Text>
          <Text>{instruction.registered_date}</Text>
        </Flex>
        <Flex justify="space-between" align="center">
          <Text mt={2}>受注日</Text>
          <Text>{instruction.ordered_date}</Text>
        </Flex>
        <Flex justify="space-between" align="center">
          <Text mt={2}>希望納期</Text>
          <Text>{instruction.due_date}</Text>
        </Flex>
        <Flex justify="space-between" align="center" h="40px">
          <Text>営業担当者</Text>
          <Text>{instruction.assigner}</Text>
        </Flex>
        <Flex justify="space-between" align="center">
          <Text>
            外注書作成者
            <Box as="span" color="red" mr={2}>
              *
            </Box>
          </Text>
          <PrimarySelectBox
            options={members}
            defaultOption={defaultCreator}
            optionElement="name"
            onChangeSelect={onChangeSelectCreator}
            width="165px"
            onFocus={() =>
              defaultCreator &&
              message({
                title: '重要情報です。編集の必要性を確認してください',
                status: 'warning',
                position: 'top',
                duration: 5000,
              })
            }
            disabled={isDisabled}
          />
        </Flex>
      </GridItem>
      <GridItem rowSpan={1} colSpan={3} align="right">
        <Flex justify="space-between" align="center" h="40px">
          <Text>受注数量</Text>
          <Text>{instruction.lot_amount}</Text>
        </Flex>
      </GridItem>
      <GridItem rowSpan={1} colStart={4} colEnd={7}>
        <Flex justify="space-between" align="center" h="40px">
          <Text ml={12}>在庫引当数</Text>
          <Text>{instruction.stock_count}</Text>
        </Flex>
      </GridItem>
      <GridItem rowSpan={1} colStart={7} colEnd={9}>
        <Flex justify="space-around" align="center" h="40px">
          <Text>制作数量</Text>
          <Text>{instruction.lot_amount - instruction.stock_count}</Text>
        </Flex>
      </GridItem>
      <GridItem rowSpan={1} colStart={10} colEnd={12} />
      <GridItem rowSpan={1} colSpan={3} align="right">
        <Flex justify="space-between" align="center" h="40px">
          <Text>
            仕入先
            <Box as="span" color="red" mr={2}>
              *
            </Box>
          </Text>
          <PrimarySelectBox
            options={companies}
            defaultOption={defaultCompany}
            optionElement="outsource_company_name"
            onChangeSelect={onChangeSelectCompany}
            width="165px"
            disabled={isDisabled}
          />
        </Flex>
      </GridItem>
      <GridItem rowSpan={1} colStart={4} colEnd={7}>
        <Flex justify="space-between" align="center">
          <Text ml={12}>
            仕入先担当者
            <Box as="span" color="red" mr={2}>
              *
            </Box>
          </Text>
          <PrimaryInput
            value={valueOutsourcingPerson}
            onChange={(e) => onChangeOutsourcingPerson(e)}
            htmlSize={16}
            disabled={isDisabled}
          />
        </Flex>
      </GridItem>
    </Grid>
  )
}