import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Flex,
  Box,
  Heading,
  Divider,
  Stack,
  Card,
  CardHeader,
  CardBody,
  Text,
} from '@chakra-ui/react'
import { MenuButton } from '../../atoms/MenuButton'
import { useLogout2 } from '../../../hooks/users/useLogout2'
import { useMessage } from '../../../hooks/useMessage'
import { useCurrentUser2 } from '../../../hooks/users/useCurrentUser2'
import { useGetData } from '../../../hooks/useGetData'
import { BeatLoader } from 'react-spinners'
import { clockingInAndOut } from '../../../urls'
import { StringSelectBox } from '../../atoms/StringSelectBox'
import { PrimaryButton } from '../../atoms/PrimaryButton'

export const DailyReportMenu = () => {
  const navigate = useNavigate()
  const { logout } = useLogout2()
  const { message } = useMessage()
  const user = useCurrentUser2()
  const [factory, setFactory] = useState('')
  const [isLoadingAction, setIsLoadingAction] = useState(false)
  const { getDailyReportMasters, isLoading } = useGetData()
  const factories = ['志木工場', '朝霞工場', '栃木工場']

  useEffect(() => {
    if (user.is_working) {
      getDailyReportMasters()
    }
  }, [])

  const postParams = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      working_hour_id: 'WH00000000000000000000',
      work_start_time: '',
      work_end_time: '',
      user_id: user? user.user_id: "",
      work_place: factory,
      lot_processes: '',
    }),
  }

  const handleClickClockIn = () => {
    setIsLoadingAction(true)
    console.log(postParams)
    fetch(clockingInAndOut, postParams)
      .then((res) => res.json())
      .then((myjson) => {
        console.log(myjson)
        message({
          title: '出勤を記録しました。',
          status: 'success',
        })
        logout()
      })
      .catch(() =>
        message({ title: '通信エラーが発生しました', status: 'warning' })
      )
      .finally(() => {
        setIsLoadingAction(false)
      })
  }

  return (
    <Flex h="100vh" justifyContent="center" alignItems="center">
      {user &&
        (user.is_working ? (
          <>
            {isLoading ? (
              <BeatLoader size={30} color={'#3b5998'} />
            ) : (
              <Box
                borderRadius="md"
                shadow="md"
                p={4}
                bg="white"
                textAlign="center"
                w="400px"
              >
                <Heading as="h1" size="lg">
                  日報メニュー
                </Heading>
                <Divider my={4} />
                <Stack spacing={4}>
                  <MenuButton
                    onClick={() => navigate('/daily_report/prototype')}
                  >
                    <Heading as="h2" size="md" zIndex={1}>
                      試作日報作成
                    </Heading>
                  </MenuButton>
                  {/* <MenuButton
                    onClick={() => navigate('/daily_report/mass_produce')}
                  >
                    <Heading as="h2" size="md" zIndex={1}>
                      量産日報作成
                    </Heading>
                  </MenuButton>
                  <MenuButton onClick={() => navigate('/daily_report/index')}>
                    <Heading as="h2" size="md" zIndex={1}>
                      量産日報一覧
                    </Heading>
                  </MenuButton> */}
                </Stack>
              </Box>
            )}
          </>
        ) : (
          <Flex h="80vh" justifyContent="center" alignItems="center">
            <Card w="xs">
              <CardHeader textAlign="center">
                <Heading size="lg">出勤打刻</Heading>
              </CardHeader>
              <CardBody>
                <Flex justifyContent="space-around" alignItems="center">
                  <Text>勤務先</Text>
                  <StringSelectBox
                    options={factories}
                    defaultOption={factory}
                    onChangeSelect={(name) => setFactory(name)}
                    width="200px"
                    disabled={isLoadingAction}
                  />
                </Flex>
                <Flex justifyContent="center" alignItems="center" mt={6}>
                  <PrimaryButton
                    disabled={!factory || isLoadingAction}
                    onClick={() => handleClickClockIn('clockingIn')}
                  >
                    {isLoadingAction ? <BeatLoader /> : '打刻'}
                  </PrimaryButton>
                </Flex>
              </CardBody>
            </Card>
          </Flex>
        ))}
    </Flex>
  )
}
