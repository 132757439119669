import React from 'react'
import { TableCaption, Grid, Heading, GridItem, Select, Flex, Text } from '@chakra-ui/react'
import { PrimaryNumberInput } from '../../atoms/PrimaryNumberInput'

export const TableBottomOutsource = (props) => {
  const {
    amount,
    tax,
    totalAmount,
    valueCurrency,
    valueTaxRate,
    onChangeCurrency,
    onChangeTaxRate,
    isDisabled,
  } = props

  return (
    <TableCaption m={0}>
      <Grid
        h={200}
        templateRows="repeat(4, 1fr)"
        templateColumns="repeat(10, 1fr)"
        gap={1}
      >
        <GridItem rowSpan={4} colSpan={5} />
        <GridItem rowSpan={1} colSpan={1} bg="papayawhip">
          <Heading size="sm" lineHeight={10} opacity={isDisabled && 0.3}>
            小計
          </Heading>
        </GridItem>
        <GridItem rowSpan={1} colSpan={1} bg="papayawhip">
          <Heading
            size="sm"
            lineHeight={10}
            textAlign="right"
            mr="2"
            opacity={isDisabled && 0.3}
          >
            ¥{amount.toLocaleString()}
          </Heading>
        </GridItem>
        <GridItem rowSpan={1} colSpan={1} bg="papayawhip" />
        <GridItem rowSpan={1} colSpan={1} bg="papayawhip">
          <Heading size="sm" lineHeight={10} opacity={isDisabled && 0.3}>
            通貨
          </Heading>
        </GridItem>
        <GridItem rowSpan={1} colSpan={1} bg="papayawhip">
          <Select
            onChange={(e) => onChangeCurrency(e)}
            value={valueCurrency}
            bg="white"
            width="120px"
            _focus={{ boxShadow: 'none' }}
            disabled={isDisabled}
          >
            <option value="日本円">日本円</option>
            <option value="人民元">人民元</option>
            <option value="USドル">USドル</option>
          </Select>
        </GridItem>
        <GridItem rowSpan={1} colSpan={1} bg="papayawhip">
          <Heading size="sm" lineHeight={10} opacity={isDisabled && 0.3}>
            消費税
          </Heading>
        </GridItem>
        <GridItem rowSpan={1} colSpan={1} bg="papayawhip">
          <Heading
            size="sm"
            lineHeight={10}
            textAlign="right"
            mr="2"
            opacity={isDisabled && 0.3}
          >
            ¥{tax.toLocaleString()}
          </Heading>
        </GridItem>
        <GridItem rowSpan={1} colSpan={2} bg="papayawhip" />
        <GridItem rowSpan={1} colSpan={1} bg="papayawhip">
          <Flex>
          <PrimaryNumberInput
            value={valueTaxRate}
            minValue={0}
            maxValue={100000}
            onChange={(num) => onChangeTaxRate(num)}
            width="110px"
            disabled={isDisabled}
            />
            <Text ml={1} mt={2}>%</Text>
            </Flex>
        </GridItem>
        <GridItem rowSpan={1} colSpan={1} bg="papayawhip">
          <Heading size="sm" lineHeight={10} opacity={isDisabled && 0.3}>
            合計(内税)
          </Heading>
        </GridItem>
        <GridItem rowSpan={1} colSpan={1} bg="papayawhip">
          <Heading
            size="sm"
            lineHeight={10}
            textAlign="right"
            mr="2"
            opacity={isDisabled && 0.3}
          >
            ¥{totalAmount.toLocaleString()}
          </Heading>
        </GridItem>
        <GridItem rowSpan={1} colSpan={3} bg="papayawhip" />
      </Grid>
    </TableCaption>
  )
}
