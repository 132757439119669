import React from 'react'
import {
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
} from '@chakra-ui/react'
import { TableBodyOutsourceCreated } from '../../molecules/outsource/TableBodyOutsourceCreated'

export const TableOutsourceCreated = (props) => {
  const {
    createdProducts,
    addOutsource,
    onChangeOrderedNum,
    onChangeProductNum,
    onChanceProductName,
    onChangeCount,
    onChangeUnitPrice,
    onChangeDueDate,
    onChangeCarriedIn,
    onChangeOrderedNumAdd,
    onChangeProductNumAdd,
    onChanceProductNameAdd,
    onChangeCountAdd,
    onChangeUnitPriceAdd,
    onChangeDueDateAdd,
    onChangeCarriedInAdd,
    onClickAddProduct,
    onClickDeleteCreatedProduct,
    isLoadingAction,
  } = props

  return (
    <TableContainer my={6}>
      <Table variant="striped" colorScheme="linkedin">
        <Thead>
          <Tr>
            <Th>注文番号</Th>
            <Th>部品番号</Th>
            <Th>製品名</Th>
            <Th>数量</Th>
            <Th>単価</Th>
            <Th>小計</Th>
            <Th>納期</Th>
            <Th>納入先</Th>
            <Th>追加/削除</Th>
          </Tr>
        </Thead>
        <Tbody>
          {createdProducts.map((product, index) => {
            return (
              <Tr key={index}>
                <TableBodyOutsourceCreated
                  type="existing"
                  valueOrderedNum={product.ordered_num}
                  valueProductNum={product.product_num}
                  valueProductName={product.product_name}
                  valueCount={product.count}
                  valueUnitPrice={product.unit_price}
                  valueDueDate={product.due_date}
                  valueCarriedIn={product.carried_in}
                  onChangeOrderedNum={(e) => onChangeOrderedNum(e, index)}
                  onChangeProductNum={(e) => onChangeProductNum(e, index)}
                  onChanceProductName={(e) => onChanceProductName(e, index)}
                  onChangeCount={(num) => onChangeCount(num, index)}
                  onChangeUnitPrice={(num) => onChangeUnitPrice(num, index)}
                  onChangeDueDate={(e) => onChangeDueDate(e, index)}
                  onChangeCarriedIn={(e) => onChangeCarriedIn(e, index)}
                  onClickDeleteCreatedProduct={() =>
                    onClickDeleteCreatedProduct(index)
                  }
                  isDisabled={product.is_delete || isLoadingAction}
                />
              </Tr>
            )
          })}
          <Tr>
            <TableBodyOutsourceCreated
              type="adding"
              valueOrderedNum={addOutsource.orderedNum}
              valueProductNum={addOutsource.productNum}
              valueProductName={addOutsource.productName}
              valueCount={addOutsource.count}
              valueUnitPrice={addOutsource.unitPrice}
              valueDueDate={addOutsource.dueDate}
              valueCarriedIn={addOutsource.carriedIn}
              onChangeOrderedNum={(e) => onChangeOrderedNumAdd(e)}
              onChangeProductNum={(e) => onChangeProductNumAdd(e)}
              onChanceProductName={(e) => onChanceProductNameAdd(e)}
              onChangeCount={(num) => onChangeCountAdd(num)}
              onChangeUnitPrice={(num) => onChangeUnitPriceAdd(num)}
              onChangeDueDate={(e) => onChangeDueDateAdd(e)}
              onChangeCarriedIn={(e) => onChangeCarriedInAdd(e)}
              onClickAddProduct={onClickAddProduct}
              isDisabled={isLoadingAction}
              disabledAddButton={
                !addOutsource.orderedNum ||
                !addOutsource.productNum ||
                !addOutsource.productName ||
                !addOutsource.count ||
                !addOutsource.unitPrice ||
                !addOutsource.dueDate ||
                !addOutsource.carriedIn
              }
            />
          </Tr>
        </Tbody>
      </Table>
    </TableContainer>
  )
}
