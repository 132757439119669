import { useNavigate } from 'react-router-dom'
import { useMessage } from '../useMessage'
import { useCookies } from 'react-cookie'

export const useLogout2 = () => {
  const { message } = useMessage()
  const navigate = useNavigate()
  const [cookies, setCookie, removeCookie] = useCookies(['user'])
  // `cookies` や`setCookie` を使わなくても全てを書いてあげる必要がある

  const logout = () => {
    removeCookie('user')
    message({ title: 'ログアウトしました', status: 'success' })
    navigate('/', { replace: true })
  }
  return { logout }
}
