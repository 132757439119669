import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { useMessage } from '../../../hooks/useMessage'
import { BeatLoader } from 'react-spinners'
import { Box, Flex, HStack, Heading, Text } from '@chakra-ui/react'
import {
  clockingInAndOut,
  postPrintReport,
  targetPrintingReport,
} from '../../../urls'
import { AlertModal } from '../../organisms/AlertModal'
import { useCurrentUser2 } from '../../../hooks/users/useCurrentUser2'
import { useLogout2 } from '../../../hooks/users/useLogout2'
import { useGetData } from '../../../hooks/useGetData'
import { PrimaryNumberInput } from '../../atoms/PrimaryNumberInput'
import { PrimaryInput } from '../../atoms/PrimaryInput'
import { PrimarySelectBox } from '../../atoms/PrimarySelectBox'
import { FormDryHeating } from '../../organisms/operations/print/FormDryHeating'
import { FormDryingFurnace } from '../../organisms/operations/print/FormDryingFurnace'
import { FormProcessing } from '../../organisms/operations/print/FormProcessing'
import { FormAppearance } from '../../organisms/operations/print/FormAppearance'
import { FormLightning } from '../../organisms/operations/print/FormLightning'
import { FormDoubleFace } from '../../organisms/operations/print/FormDoubleFace'
import { FormPacking } from '../../organisms/operations/print/FormPacking'
import { FormProtectionInspection } from '../../organisms/operations/print/FormProtectionInspection'
import axios from 'axios'
import { useBlockBrowserBack } from '../../../hooks/users/useBlockBrowserBack'
import { AlertModalWithoutButton } from '../../organisms/AlertModalWithoutButton'
import { DigitalClock } from '../../atoms/DigitalClock'

export const Print = () => {
  const location = useLocation()
  const { logout } = useLogout2()
  const { message } = useMessage()
  const navigate = useNavigate()
  const user = useCurrentUser2()
  const { isBrowserBack, setIsBrowserBack } = useBlockBrowserBack()
  const [isLoadingAction, setIsLoadingAction] = useState(false)
  const { getProducts, productList, getMasters, masterList, isLoading } =
    useGetData()

  const timeInfo = {
    id: 0,
    start_at: null,
    finish_at: null,
    count: 0,
    worker: '',
  }
  const defectiveCountDetailInfo = {
    id: 0,
    defective_detail: '',
    count: 0,
  }
  const countInfo = {
    input_count: 0,
    non_defective_count: 0,
    defective_count: 0,
    registered_by: '',
    defective_count_detail: [defectiveCountDetailInfo],
  }

  const dryingFurnace = {
    id: 0,
    ink_lot_num: '',
    ink_viscosity: 0,
    drying_temperature: 0,
    drying_time: 0,
    indoor_temperature: 0,
    indoor_humidity: 0,
    registered_by: '', //印刷と同じ人のサインが入るため不要そう
    is_recipient: false,
  }

  const inkConfirmation = {
    id: 0,
    ink_name: '',
    ink_lot_num: '',
    is_viscosity_confirmed: false,
    is_drying_temperature_confirmed: false,
    is_drying_time_confirmed: false,
    temperature: 0,
    humidity: 0,
  }

  const initPrintReport = {
    printing_record_id: '',
    client_name: '',
    product_number: '',
    product_name: '',
    material_lot_num: '',
    pre_process_lot_num: '',
    molding_at: '',
    registered_by: '',
    dry_heating: {
      // 印刷日
      time: [timeInfo],
      // 数量
      count: countInfo,
      // 乾燥炉
      drying_furnace: [dryingFurnace],
      // 仕様確認
      is_specification_confirmed: false,
      is_plate_confirmed: false,
      note: '',
    },
    punching: {
      time: [timeInfo],
      count: countInfo,
      // 抜き加工確認
      confirmation: {
        is_punching_confirmation: false,
        is_dimension_confirmation: false,
        hydraulic_pressure_confirmation: 0,
        confirmed_by: '',
      },
      note: '',
    },
    appearance_inspection: {
      time: [timeInfo],
      count: countInfo,
      note: '',
    },
    lightning_inspection: {
      time: [timeInfo],
      count: countInfo,
      note: '',
    },
    double_face_pasting: {
      time: [timeInfo],
      count: countInfo,
      note: '',
    },
    packing: {
      time: [timeInfo],
      count: countInfo,
      note: '',
    },
    // drying: {
    //   time: [timeInfo],
    //   count: countInfo,
    //   is_specification_confirmed: false,
    //   is_plate_confirmed: false,
    //   ink_confirmation: [inkConfirmation, inkConfirmation, inkConfirmation],
    //   note: '',
    // },
    protection_inspection: {
      time: [timeInfo],
      count: countInfo,
      is_specification_confirmed: false,
      is_plate_confirmed: false,
      note: '',
    },
    // total
    print_amount: 0,
    print_non_defective_amount: 0,
    print_defective_amount: 0,
    // 確認サイン
    printed_by: '',
    inputted_by: '',
    factory_manager: '',
  }
  const [isUpdate, setIsUpdate] = useState(false)
  const [isSucceeded, setIsSucceeded] = useState(false)
  const [printReport, setPrintReport] = useState(initPrintReport)
  const [searchParams] = useSearchParams()
  const id = searchParams.get('id')

  useEffect(() => {
    if (user.is_working) {
      const getData = async () => {
        if (id) {
          const report = await axios.get(targetPrintingReport(id))
          setPrintReport(report.data)
          setIsUpdate(true)
        }
        await getProducts()
        getMasters()
      }
      getData()
    }
  }, [])

  const params = {
    method: isUpdate ? 'PUT' : 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(printReport),
  }
  const handleClickClockOut = () => {
    const postParams = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        working_hour_id: '',
        work_start_time: '',
        work_end_time: '',
        user_id: user.user_id,
        work_place: '',
        lot_processes: '',
      }),
    }
    setIsLoadingAction(true)
    fetch(clockingInAndOut, postParams)
      .then((res) => res.json())
      .then((myjson) => {
        console.log(myjson)
        message({
          title: '退勤を記録しました。',
          status: 'success',
        })
        logout()
      })
      .catch(() =>
        message({ title: '通信エラーが発生しました', status: 'warning' })
      )
      .finally(() => {
        setIsLoadingAction(false)
      })
  }

  const handleClickUpdateReport = () => {
    setIsLoadingAction(true)
    fetch(targetPrintingReport(id), params)
      .then((res) => res.json())
      .then((myjson) => {
        message({ title: '日報の更新に成功しました。', status: 'success' })
        console.log(myjson)
        // navigate('/daily_report/index')
        setIsSucceeded(true)
      })
      .catch(() => {
        message({ title: '通信エラーが発生しました', status: 'warning' })
      })
      .finally(() => {
        setIsLoadingAction(false)
      })
  }

  const handleClickRegisterReport = () => {
    setIsLoadingAction(true)
    fetch(postPrintReport, params)
      .then((res) => res.json())
      .then((myjson) => {
        message({ title: '日報の記録に成功しました。', status: 'success' })
        console.log(myjson)
        // navigate('/daily_report/index')
        setIsSucceeded(true)
      })
      .catch(() => {
        message({ title: '通信エラーが発生しました', status: 'warning' })
      })
      .finally(() => {
        setIsLoadingAction(false)
      })
  }

  return (
    <Box m={2}>
      {isLoading ? (
        <Flex h="100vh" alignItems="center" justifyContent="center" mt={-8}>
          <BeatLoader size={30} color={'#3b5998'} />
        </Flex>
      ) : (
        <>
          <DigitalClock />
          <Heading textAlign="center" mb={10}>
            印刷日報
          </Heading>
          <Box mt={10}>
            <Flex textAlign="center" align="center" border="1px" mb={4}>
              <Text
                w="100px"
                minWidth="100px"
                h="50px"
                lineHeight="50px"
                borderRight="1px"
              >
                得意先
                <Box as="span" color="red">
                  *
                </Box>
              </Text>
              <PrimarySelectBox
                options={masterList.clients}
                defaultOption={printReport.client_name}
                optionElement="client_name"
                onChangeSelect={(index) =>
                  setPrintReport({
                    ...printReport,
                    client_name: masterList.clients[index]['client_name'],
                  })
                }
                flexGrow={1}
                disabled={isLoadingAction}
              />
              <Text
                w="100px"
                minWidth="100px"
                h="50px"
                lineHeight="50px"
                borderLeft="1px"
                borderRight="1px"
              >
                成形日
                <Box as="span" color="red">
                  *
                </Box>
              </Text>
              <PrimaryInput
                type="date"
                min="2000-01-01"
                max="2050-01-01"
                value={printReport.molding_at}
                onChange={(e) =>
                  setPrintReport({
                    ...printReport,
                    molding_at: e.target.value,
                  })
                }
                flexGrow={1}
                disabled={isLoadingAction}
              />
            </Flex>
            <Flex textAlign="center" align="center">
              <Box flexGrow={1}>
                <Flex
                  textAlign="center"
                  align="center"
                  borderTop="1px"
                  borderLeft="1px"
                >
                  <Text
                    w="100px"
                    minWidth="100px"
                    h="50px"
                    lineHeight="50px"
                    borderRight="1px"
                  >
                    品番
                    <Box as="span" color="red">
                      *
                    </Box>
                  </Text>
                  <PrimarySelectBox
                    options={productList}
                    defaultOption={printReport.product_number}
                    optionElement="product_number"
                    onChangeSelect={(index) =>
                      setPrintReport({
                        ...printReport,
                        product_number: productList[index]['product_number'],
                        product_name: productList[index]['product_name'],
                      })
                    }
                    flexGrow={1}
                    disabled={isLoadingAction}
                  />
                </Flex>
                <Flex
                  textAlign="center"
                  align="center"
                  borderLeft="1px"
                  borderTop="1px"
                  borderBottom="1px"
                >
                  <Text
                    w="100px"
                    minWidth="100px"
                    h="50px"
                    lineHeight="50px"
                    borderRight="1px"
                  >
                    品名
                  </Text>
                  <PrimaryInput
                    flexGrow={1}
                    placeholder="品番を選択してください"
                    value={printReport.product_name}
                    onChange={(e) =>
                      setPrintReport({
                        ...printReport,
                        product_name: e.target.value,
                      })
                    }
                    disabled
                  />
                </Flex>
              </Box>
              <Box flexGrow={1}>
                <Box />
                <Flex textAlign="center" align="center" border="1px">
                  <Text
                    w="50px"
                    minWidth="50px"
                    h="101px"
                    lineHeight="100px"
                    borderRight="1px"
                  >
                    材料
                  </Text>
                  <Text
                    w="100px"
                    minWidth="100px"
                    h="100px"
                    lineHeight="100px"
                    borderRight="1px"
                  >
                    材料ロット
                  </Text>
                  <PrimaryInput
                    flexGrow={1}
                    value={printReport.material_lot_num}
                    onChange={(e) =>
                      setPrintReport({
                        ...printReport,
                        material_lot_num: e.target.value,
                      })
                    }
                  />
                </Flex>
              </Box>
            </Flex>

            {/* 1.印刷 加熱乾燥 */}
            <FormDryHeating
              printReport={printReport}
              setPrintReport={setPrintReport}
              isLoadingAction={isLoadingAction}
              masterList={masterList}
              timeInfo={timeInfo}
              defectiveCountDetailInfo={defectiveCountDetailInfo}
            />
            {/* 乾燥炉 */}
            <FormDryingFurnace
              printReport={printReport}
              setPrintReport={setPrintReport}
              isLoadingAction={isLoadingAction}
              masterList={masterList}
              dryingFurnace={dryingFurnace}
            />

            {/* 2.抜き加工 */}
            <FormProcessing
              printReport={printReport}
              setPrintReport={setPrintReport}
              isLoadingAction={isLoadingAction}
              masterList={masterList}
              timeInfo={timeInfo}
              defectiveCountDetailInfo={defectiveCountDetailInfo}
            />

            {/* 3.外観検査 */}
            <FormAppearance
              printReport={printReport}
              setPrintReport={setPrintReport}
              isLoadingAction={isLoadingAction}
              masterList={masterList}
              timeInfo={timeInfo}
              defectiveCountDetailInfo={defectiveCountDetailInfo}
            />

            {/* 4.点灯検査 */}
            <FormLightning
              printReport={printReport}
              setPrintReport={setPrintReport}
              isLoadingAction={isLoadingAction}
              masterList={masterList}
              timeInfo={timeInfo}
              defectiveCountDetailInfo={defectiveCountDetailInfo}
            />

            {/* 5.WF貼り */}
            <FormDoubleFace
              printReport={printReport}
              setPrintReport={setPrintReport}
              isLoadingAction={isLoadingAction}
              masterList={masterList}
              timeInfo={timeInfo}
              defectiveCountDetailInfo={defectiveCountDetailInfo}
            />

            {/* 6.保護シート */}
            <FormProtectionInspection
              printReport={printReport}
              setPrintReport={setPrintReport}
              isLoadingAction={isLoadingAction}
              masterList={masterList}
              timeInfo={timeInfo}
              defectiveCountDetailInfo={defectiveCountDetailInfo}
            />

            {/* 7.梱包 */}
            <FormPacking
              printReport={printReport}
              setPrintReport={setPrintReport}
              isLoadingAction={isLoadingAction}
              masterList={masterList}
              timeInfo={timeInfo}
              defectiveCountDetailInfo={defectiveCountDetailInfo}
            />

            <Flex
              mt={10}
              w="350px"
              minWidth="350px"
              textAlign="center"
              align="center"
              border="1px"
            >
              <Text
                w="100px"
                minWidth="100px"
                h="50px"
                lineHeight="50px"
                borderRight="1px"
              >
                材料ロット
              </Text>
              <PrimaryInput
                width="250px"
                value={printReport.pre_process_lot_num}
                onChange={(e) =>
                  setPrintReport({
                    ...printReport,
                    pre_process_lot_num: e.target.value,
                  })
                }
              />
            </Flex>

            {/* 1.印刷乾燥 */}
            {/* <FormDrying
              printReport={printReport}
              setPrintReport={setPrintReport}
              isLoadingAction={isLoadingAction}
              masterList={masterList}
              timeInfo={timeInfo}
              defectiveCountDetailInfo={defectiveCountDetailInfo}
            /> */}
          </Box>

          <Flex textAlign="center" align="center" border="1px" mt={10}>
            <Box flexGrow={1} borderRight="1px">
              <Text borderBottom="1px">TOTAL *最終工程者が入力</Text>
              <Flex flexGrow={1}>
                <Box flexGrow={1} borderRight="1px">
                  <Text borderBottom="1px">印刷数</Text>
                  <PrimaryNumberInput
                    value={printReport.print_amount}
                    minValue={0}
                    maxValue={100000}
                    onChange={(num) =>
                      setPrintReport({
                        ...printReport,
                        print_amount: num,
                      })
                    }
                    width="100%"
                    disabled={isLoadingAction}
                  />
                </Box>
                <Box flexGrow={1} borderRight="1px">
                  <Text borderBottom="1px">良品数</Text>
                  <PrimaryNumberInput
                    value={printReport.print_non_defective_amount}
                    minValue={0}
                    maxValue={100000}
                    onChange={(num) =>
                      setPrintReport({
                        ...printReport,
                        print_non_defective_amount: num,
                      })
                    }
                    width="100%"
                    disabled={isLoadingAction}
                  />
                </Box>
                <Box flexGrow={1} borderRight="1px">
                  <Text borderBottom="1px">不良数</Text>
                  <PrimaryNumberInput
                    value={printReport.print_defective_amount}
                    minValue={0}
                    maxValue={100000}
                    onChange={(num) =>
                      setPrintReport({
                        ...printReport,
                        print_defective_amount: num,
                      })
                    }
                    width="100%"
                    disabled={isLoadingAction}
                  />
                </Box>
                <Box w="150px">
                  <Text borderBottom="1px">不良率</Text>
                  <Text lineHeight="40px">
                    {printReport.print_amount > 0
                      ? Math.round(
                          (printReport.print_defective_amount /
                            printReport.print_amount) *
                            10000
                        ) / 100
                      : 0}
                    %
                  </Text>
                </Box>
              </Flex>
            </Box>
            <Box>
              <Text borderBottom="1px">確認サイン</Text>
              <Flex>
                <Box borderRight="1px">
                  <Text borderBottom="1px">印刷者</Text>
                  <PrimarySelectBox
                    options={masterList.users}
                    defaultOption={printReport.printed_by}
                    optionElement="name"
                    onChangeSelect={(index) => {
                      setPrintReport({
                        ...printReport,
                        printed_by: masterList.users[index]['name'],
                      })
                    }}
                    width="150px"
                    disabled={isLoadingAction}
                  />
                </Box>
                <Box borderRight="1px">
                  <Text borderBottom="1px">入力</Text>
                  <PrimarySelectBox
                    options={masterList.users}
                    defaultOption={printReport.inputted_by}
                    optionElement="name"
                    onChangeSelect={(index) => {
                      setPrintReport({
                        ...printReport,
                        inputted_by: masterList.users[index]['name'],
                      })
                    }}
                    width="150px"
                    disabled={isLoadingAction}
                  />
                </Box>
                <Box>
                  <Text borderBottom="1px">工場長</Text>
                  <PrimarySelectBox
                    options={masterList.users}
                    defaultOption={printReport.factory_manager}
                    optionElement="name"
                    onChangeSelect={(index) => {
                      setPrintReport({
                        ...printReport,
                        factory_manager: masterList.users[index]['name'],
                      })
                    }}
                    width="150px"
                    disabled={isLoadingAction}
                  />
                </Box>
              </Flex>
            </Box>
          </Flex>
          <Text mt={4} ml={24}>
            *保存期間 → 打ち切り後15年
          </Text>

          <Flex justifyContent="center" alignItems="center" my={10}>
            <HStack>
              <AlertModal
                buttonMessage="キャンセル"
                buttonLeft="日報登録に戻る"
                buttonRight="ログアウト"
                title="キャンセル"
                colorScheme="blackAlpha"
                body="日報登録を中断してログアウトしますか？"
                onClick={() => logout()}
                onClickCancel={() => navigate('/daily_report/mass_produce')}
                disabled={isLoadingAction}
              />
              <AlertModal
                buttonMessage={isUpdate ? '更新' : '記録'}
                title={isUpdate ? '更新' : '記録'}
                body={`この内容で日報を${isUpdate ? '更新' : '記録'}しますか？`}
                onClick={
                  isUpdate ? handleClickUpdateReport : handleClickRegisterReport
                }
                disabled={
                  isLoadingAction ||
                  !printReport.client_name ||
                  !printReport.product_number ||
                  !printReport.molding_at
                }
              />
            </HStack>
          </Flex>
          {isSucceeded && (
            <AlertModal
              needButton={false}
              buttonLeft="勤務に戻る"
              buttonRight="退勤"
              title="退勤"
              body="このまま退勤しますか？"
              onClick={() => handleClickClockOut()}
              onClickCancel={() => logout()}
            />
          )}
        </>
      )}
      {isBrowserBack && (
        <AlertModalWithoutButton
          title="戻る"
          body="データを変更した場合、更新ボタンを押さないと編集内容が破棄されますがよろしいですか？"
          onClick={() => window.history.back()}
          onClickCancel={() => {
            window.history.pushState(null, '', window.location.href)
            setIsBrowserBack(false)
          }}
        />
      )}
    </Box>
  )
}
