import React from 'react'
import { Box, Flex, IconButton, Text } from '@chakra-ui/react'
import { PrimarySelectBox } from '../../../atoms/PrimarySelectBox'
import { PrimaryInput } from '../../../atoms/PrimaryInput'
import { AiOutlineDelete, AiOutlinePlus } from 'react-icons/ai'

export const FormOperation = (props) => {
  const {
    moldingReport,
    setMoldingReport,
    addOperation,
    setAddOperation,
    masterList,
    workerMaster,
    workerAdminMaster,
    operation,
    isLoadingAction,
  } = props
  return (
    <Box mt={6} textAlign="center">
      <Text w="200px" h="50px" lineHeight="50px" bg="black" textColor="white">
        稼働状況
      </Text>
      <Flex justify="space-between">
        <Box borderLeft="1px" borderRight="1px" borderTop="1px">
          <Flex borderBottom="1px">
            <Box width="250px" />
            <Text
              w="200px"
              minWidth="200px"
              h="50px"
              lineHeight="50px"
              borderLeft="1px"
              borderRight="1px"
            >
              担当者
            </Text>
            <Text
              w="200px"
              minWidth="200px"
              h="50px"
              lineHeight="50px"
              borderRight="1px"
            >
              開始時間
            </Text>
            <Text w="200px" minWidth="200px" h="50px" lineHeight="50px">
              終了時間
            </Text>
          </Flex>
          <Flex borderBottom="1px" align="center">
            <Text w="200px" minWidth="200px">
              a.型替・色替
            </Text>
            <Box>
              {moldingReport.operations.mold_and_color_change.map(
                (elm, num) => {
                  return (
                    <>
                      <Text
                        textAlign="left"
                        borderLeft="1px"
                        borderBottom="1px"
                        display={num !== 0 && 'none'}
                      >
                        追加済み
                      </Text>
                      <Flex borderBottom="1px">
                        <Box
                          w="51px"
                          minWidth="51px"
                          h="50px"
                          lineHeight="50px"
                          borderLeft="1px"
                          borderRight="1px"
                        >
                          <IconButton
                            aria-label="削除"
                            disabled={isLoadingAction}
                            onClick={() => {
                              setMoldingReport({
                                ...moldingReport,
                                operations: {
                                  ...moldingReport.operations,
                                  mold_and_color_change:
                                    moldingReport.operations.mold_and_color_change.filter(
                                      (_, number) => num !== number
                                    ),
                                },
                              })
                            }}
                            icon={<AiOutlineDelete />}
                          />
                        </Box>
                        <Box py="5px" borderRight="1px">
                          <PrimarySelectBox
                            options={masterList.users}
                            defaultOption={elm.worker}
                            optionElement="name"
                            onChangeSelect={(index) =>
                              setMoldingReport({
                                ...moldingReport,
                                operations: {
                                  ...moldingReport.operations,
                                  mold_and_color_change:
                                    moldingReport.operations.mold_and_color_change.map(
                                      (info, number) =>
                                        num == number
                                          ? {
                                              ...info,
                                              worker:
                                                masterList.users[index]['name'],
                                            }
                                          : info
                                    ),
                                },
                              })
                            }
                            width="198px"
                            disabled={isLoadingAction}
                          />
                        </Box>
                        <Box py="5px" borderRight="1px">
                          <PrimaryInput
                            type="time"
                            min="00:00"
                            max="23:59"
                            value={elm.start_at}
                            onChange={(e) =>
                              setMoldingReport({
                                ...moldingReport,
                                operations: {
                                  ...moldingReport.operations,
                                  mold_and_color_change:
                                    moldingReport.operations.mold_and_color_change.map(
                                      (info, number) =>
                                        num == number
                                          ? {
                                              ...info,
                                              start_at: e.target.value,
                                            }
                                          : info
                                    ),
                                },
                              })
                            }
                            width="199px"
                            disabled={isLoadingAction}
                          />
                        </Box>
                        <Box py="5px">
                          <PrimaryInput
                            type="time"
                            min="00:00"
                            max="23:59"
                            value={elm.finish_at}
                            onChange={(e) =>
                              setMoldingReport({
                                ...moldingReport,
                                operations: {
                                  ...moldingReport.operations,
                                  mold_and_color_change:
                                    moldingReport.operations.mold_and_color_change.map(
                                      (info, number) =>
                                        num == number
                                          ? {
                                              ...info,
                                              finish_at: e.target.value,
                                            }
                                          : info
                                    ),
                                },
                              })
                            }
                            width="200px"
                            disabled={isLoadingAction}
                          />
                        </Box>
                      </Flex>
                    </>
                  )
                }
              )}
              <Text textAlign="left" borderLeft="1px" borderBottom="1px">
                未追加
              </Text>
              <Flex borderBottom="1px">
                <Box
                  w="51px"
                  minWidth="51px"
                  h="50px"
                  lineHeight="50px"
                  borderLeft="1px"
                  borderRight="1px"
                >
                  <IconButton
                    aria-label="追加"
                    disabled={isLoadingAction}
                    onClick={() => {
                      setMoldingReport({
                        ...moldingReport,
                        operations: {
                          ...moldingReport.operations,
                          mold_and_color_change: [
                            ...moldingReport.operations.mold_and_color_change,
                            addOperation.mold_and_color_change,
                          ],
                        },
                      })
                      setAddOperation({
                        ...addOperation,
                        mold_and_color_change: operation,
                      })
                    }}
                    icon={<AiOutlinePlus />}
                  />
                </Box>
                <Box py="5px" borderRight="1px">
                  <PrimarySelectBox
                    options={masterList.users}
                    defaultOption={addOperation.mold_and_color_change.worker}
                    optionElement="name"
                    onChangeSelect={(index) =>
                      setAddOperation({
                        ...addOperation,
                        mold_and_color_change: {
                          ...addOperation.mold_and_color_change,
                          worker: masterList.users[index]['name'],
                        },
                      })
                    }
                    width="198px"
                    disabled={isLoadingAction}
                  />
                </Box>
                <Box py="5px" borderRight="1px">
                  <PrimaryInput
                    type="time"
                    min="00:00"
                    max="23:59"
                    value={addOperation.mold_and_color_change.start_at ?? ''}
                    onChange={(e) =>
                      setAddOperation({
                        ...addOperation,
                        mold_and_color_change: {
                          ...addOperation.mold_and_color_change,
                          start_at: e.target.value,
                        },
                      })
                    }
                    width="199px"
                    disabled={isLoadingAction}
                  />
                </Box>
                <Box py="5px">
                  <PrimaryInput
                    type="time"
                    min="00:00"
                    max="23:59"
                    value={addOperation.mold_and_color_change.finish_at ?? ''}
                    onChange={(e) =>
                      setAddOperation({
                        ...addOperation,
                        mold_and_color_change: {
                          ...addOperation.mold_and_color_change,
                          finish_at: e.target.value,
                        },
                      })
                    }
                    width="200px"
                    disabled={isLoadingAction}
                  />
                </Box>
              </Flex>
            </Box>
            {/* <Box>
              {moldingReport.operations.mold_and_color_change.map(
                (elm, num) => {
                  return (
                    <Flex borderBottom="1px">
                      <Box
                        w="51px"
                        minWidth="51px"
                        h="50px"
                        lineHeight="50px"
                        borderLeft="1px"
                        borderRight="1px"
                      >
                        {moldingReport.operations.mold_and_color_change
                          .length ==
                        num + 1 ? (
                          <IconButton
                            aria-label="追加"
                            disabled={isLoadingAction}
                            onClick={() =>
                              setMoldingReport({
                                ...moldingReport,
                                operations: {
                                  ...moldingReport.operations,
                                  mold_and_color_change: [
                                    ...moldingReport.operations
                                      .mold_and_color_change,
                                    operation,
                                  ],
                                },
                              })
                            }
                            icon={<AiOutlinePlus />}
                          />
                        ) : (
                          <IconButton
                            aria-label="削除"
                            disabled={isLoadingAction}
                            onClick={() => {
                              setMoldingReport({
                                ...moldingReport,
                                operations: {
                                  ...moldingReport.operations,
                                  mold_and_color_change:
                                    moldingReport.operations.mold_and_color_change.filter(
                                      (_, number) => num !== number
                                    ),
                                },
                              })
                            }}
                            icon={<AiOutlineDelete />}
                          />
                        )}
                      </Box>
                      <Box py="5px" borderRight="1px">
                        <PrimarySelectBox
                          options={masterList.users}
                          defaultOption={elm.worker}
                          optionElement="name"
                          onChangeSelect={(index) =>
                            setMoldingReport({
                              ...moldingReport,
                              operations: {
                                ...moldingReport.operations,
                                mold_and_color_change:
                                  moldingReport.operations.mold_and_color_change.map(
                                    (info, number) =>
                                      num == number
                                        ? {
                                            ...info,
                                            worker:
                                              masterList.users[index]['name'],
                                          }
                                        : info
                                  ),
                              },
                            })
                          }
                          width="198px"
                          disabled={isLoadingAction}
                        />
                      </Box>
                      <Box py="5px" borderRight="1px">
                        <PrimaryInput
                          type="time"
                          min="00:00"
                          max="23:59"
                          value={elm.start_at}
                          onChange={(e) =>
                            setMoldingReport({
                              ...moldingReport,
                              operations: {
                                ...moldingReport.operations,
                                mold_and_color_change:
                                  moldingReport.operations.mold_and_color_change.map(
                                    (info, number) =>
                                      num == number
                                        ? {
                                            ...info,
                                            start_at: e.target.value,
                                          }
                                        : info
                                  ),
                              },
                            })
                          }
                          width="199px"
                          disabled={isLoadingAction}
                        />
                      </Box>
                      <Box py="5px">
                        <PrimaryInput
                          type="time"
                          min="00:00"
                          max="23:59"
                          value={elm.finish_at}
                          onChange={(e) =>
                            setMoldingReport({
                              ...moldingReport,
                              operations: {
                                ...moldingReport.operations,
                                mold_and_color_change:
                                  moldingReport.operations.mold_and_color_change.map(
                                    (info, number) =>
                                      num == number
                                        ? {
                                            ...info,
                                            finish_at: e.target.value,
                                          }
                                        : info
                                  ),
                              },
                            })
                          }
                          width="200px"
                          disabled={isLoadingAction}
                        />
                      </Box>
                    </Flex>
                  )
                }
              )}
            </Box> */}
          </Flex>
          <Flex borderBottom="1px" align="center">
            <Text w="200px" minWidth="200px">
              b.条件調整
            </Text>
            <Box>
              {moldingReport.operations.condition_adjustment.map((elm, num) => {
                return (
                  <>
                    <Text
                      textAlign="left"
                      borderLeft="1px"
                      borderBottom="1px"
                      display={num !== 0 && 'none'}
                    >
                      追加済み
                    </Text>

                    <Flex borderBottom="1px">
                      <Box
                        w="51px"
                        minWidth="51px"
                        h="50px"
                        lineHeight="50px"
                        borderLeft="1px"
                        borderRight="1px"
                      >
                        <IconButton
                          aria-label="削除"
                          disabled={isLoadingAction}
                          onClick={() => {
                            setMoldingReport({
                              ...moldingReport,
                              operations: {
                                ...moldingReport.operations,
                                condition_adjustment:
                                  moldingReport.operations.condition_adjustment.filter(
                                    (_, number) => num !== number
                                  ),
                              },
                            })
                          }}
                          icon={<AiOutlineDelete />}
                        />
                      </Box>
                      <Box py="5px" borderRight="1px">
                        <PrimarySelectBox
                          options={masterList.users}
                          defaultOption={elm.worker}
                          optionElement="name"
                          onChangeSelect={(index) =>
                            setMoldingReport({
                              ...moldingReport,
                              operations: {
                                ...moldingReport.operations,
                                condition_adjustment:
                                  moldingReport.operations.condition_adjustment.map(
                                    (info, number) =>
                                      num == number
                                        ? {
                                            ...info,
                                            worker:
                                              masterList.users[index]['name'],
                                          }
                                        : info
                                  ),
                              },
                            })
                          }
                          width="198px"
                          disabled={isLoadingAction}
                        />
                      </Box>
                      <Box py="5px" borderRight="1px">
                        <PrimaryInput
                          type="time"
                          min="00:00"
                          max="23:59"
                          value={elm.start_at}
                          onChange={(e) =>
                            setMoldingReport({
                              ...moldingReport,
                              operations: {
                                ...moldingReport.operations,
                                condition_adjustment:
                                  moldingReport.operations.condition_adjustment.map(
                                    (info, number) =>
                                      num == number
                                        ? {
                                            ...info,
                                            start_at: e.target.value,
                                          }
                                        : info
                                  ),
                              },
                            })
                          }
                          width="199px"
                          disabled={isLoadingAction}
                        />
                      </Box>
                      <Box py="5px">
                        <PrimaryInput
                          type="time"
                          min="00:00"
                          max="23:59"
                          value={elm.finish_at}
                          onChange={(e) =>
                            setMoldingReport({
                              ...moldingReport,
                              operations: {
                                ...moldingReport.operations,
                                condition_adjustment:
                                  moldingReport.operations.condition_adjustment.map(
                                    (info, number) =>
                                      num == number
                                        ? {
                                            ...info,
                                            finish_at: e.target.value,
                                          }
                                        : info
                                  ),
                              },
                            })
                          }
                          width="200px"
                          disabled={isLoadingAction}
                        />
                      </Box>
                    </Flex>
                  </>
                )
              })}
              <Text textAlign="left" borderLeft="1px" borderBottom="1px">
                未追加
              </Text>
              <Flex borderBottom="1px">
                <Box
                  w="51px"
                  minWidth="51px"
                  h="50px"
                  lineHeight="50px"
                  borderLeft="1px"
                  borderRight="1px"
                >
                  <IconButton
                    aria-label="追加"
                    disabled={isLoadingAction}
                    onClick={() => {
                      setMoldingReport({
                        ...moldingReport,
                        operations: {
                          ...moldingReport.operations,
                          condition_adjustment: [
                            ...moldingReport.operations.condition_adjustment,
                            addOperation.condition_adjustment,
                          ],
                        },
                      })
                      setAddOperation({
                        ...addOperation,
                        condition_adjustment: operation,
                      })
                    }}
                    icon={<AiOutlinePlus />}
                  />
                </Box>
                <Box py="5px" borderRight="1px">
                  <PrimarySelectBox
                    options={masterList.users}
                    defaultOption={addOperation.condition_adjustment.worker}
                    optionElement="name"
                    onChangeSelect={(index) =>
                      setAddOperation({
                        ...addOperation,
                        condition_adjustment: {
                          ...addOperation.condition_adjustment,
                          worker: masterList.users[index]['name'],
                        },
                      })
                    }
                    width="198px"
                    disabled={isLoadingAction}
                  />
                </Box>
                <Box py="5px" borderRight="1px">
                  <PrimaryInput
                    type="time"
                    min="00:00"
                    max="23:59"
                    value={addOperation.condition_adjustment.start_at ?? ''}
                    onChange={(e) =>
                      setAddOperation({
                        ...addOperation,
                        condition_adjustment: {
                          ...addOperation.condition_adjustment,
                          start_at: e.target.value,
                        },
                      })
                    }
                    width="199px"
                    disabled={isLoadingAction}
                  />
                </Box>
                <Box py="5px">
                  <PrimaryInput
                    type="time"
                    min="00:00"
                    max="23:59"
                    value={addOperation.condition_adjustment.finish_at ?? ''}
                    onChange={(e) =>
                      setAddOperation({
                        ...addOperation,
                        condition_adjustment: {
                          ...addOperation.condition_adjustment,
                          finish_at: e.target.value,
                        },
                      })
                    }
                    width="200px"
                    disabled={isLoadingAction}
                  />
                </Box>
              </Flex>
            </Box>
          </Flex>
          <Flex borderBottom="1px" align="center">
            <Text w="200px" minWidth="200px">
              c.機械・金型故障
            </Text>
            <Box>
              {moldingReport.operations.failure.map((elm, num) => {
                return (
                  <>
                    <Text
                      textAlign="left"
                      borderLeft="1px"
                      borderBottom="1px"
                      display={num !== 0 && 'none'}
                    >
                      追加済み
                    </Text>
                    <Flex borderBottom="1px">
                      <Box
                        w="51px"
                        minWidth="51px"
                        h="50px"
                        lineHeight="50px"
                        borderLeft="1px"
                        borderRight="1px"
                      >
                        <IconButton
                          aria-label="削除"
                          disabled={isLoadingAction}
                          onClick={() => {
                            setMoldingReport({
                              ...moldingReport,
                              operations: {
                                ...moldingReport.operations,
                                failure:
                                  moldingReport.operations.failure.filter(
                                    (_, number) => num !== number
                                  ),
                              },
                            })
                          }}
                          icon={<AiOutlineDelete />}
                        />
                      </Box>
                      <Box py="5px" borderRight="1px">
                        <PrimarySelectBox
                          options={masterList.users}
                          defaultOption={elm.worker}
                          optionElement="name"
                          onChangeSelect={(index) =>
                            setMoldingReport({
                              ...moldingReport,
                              operations: {
                                ...moldingReport.operations,
                                failure: moldingReport.operations.failure.map(
                                  (info, number) =>
                                    num == number
                                      ? {
                                          ...info,
                                          worker:
                                            masterList.users[index]['name'],
                                        }
                                      : info
                                ),
                              },
                            })
                          }
                          width="198px"
                          disabled={isLoadingAction}
                        />
                      </Box>
                      <Box py="5px" borderRight="1px">
                        <PrimaryInput
                          type="time"
                          min="00:00"
                          max="23:59"
                          value={elm.start_at}
                          onChange={(e) =>
                            setMoldingReport({
                              ...moldingReport,
                              operations: {
                                ...moldingReport.operations,
                                failure: moldingReport.operations.failure.map(
                                  (info, number) =>
                                    num == number
                                      ? {
                                          ...info,
                                          start_at: e.target.value,
                                        }
                                      : info
                                ),
                              },
                            })
                          }
                          width="199px"
                          disabled={isLoadingAction}
                        />
                      </Box>
                      <Box py="5px">
                        <PrimaryInput
                          type="time"
                          min="00:00"
                          max="23:59"
                          value={elm.finish_at}
                          onChange={(e) =>
                            setMoldingReport({
                              ...moldingReport,
                              operations: {
                                ...moldingReport.operations,
                                failure: moldingReport.operations.failure.map(
                                  (info, number) =>
                                    num == number
                                      ? {
                                          ...info,
                                          finish_at: e.target.value,
                                        }
                                      : info
                                ),
                              },
                            })
                          }
                          width="200px"
                          disabled={isLoadingAction}
                        />
                      </Box>
                    </Flex>
                  </>
                )
              })}
              <Text textAlign="left" borderLeft="1px" borderBottom="1px">
                未追加
              </Text>
              <Flex borderBottom="1px">
                <Box
                  w="51px"
                  minWidth="51px"
                  h="50px"
                  lineHeight="50px"
                  borderLeft="1px"
                  borderRight="1px"
                >
                  <IconButton
                    aria-label="追加"
                    disabled={isLoadingAction}
                    onClick={() => {
                      setMoldingReport({
                        ...moldingReport,
                        operations: {
                          ...moldingReport.operations,
                          failure: [
                            ...moldingReport.operations.failure,
                            addOperation.failure,
                          ],
                        },
                      })
                      setAddOperation({
                        ...addOperation,
                        failure: operation,
                      })
                    }}
                    icon={<AiOutlinePlus />}
                  />
                </Box>
                <Box py="5px" borderRight="1px">
                  <PrimarySelectBox
                    options={masterList.users}
                    defaultOption={addOperation.failure.worker}
                    optionElement="name"
                    onChangeSelect={(index) =>
                      setAddOperation({
                        ...addOperation,
                        failure: {
                          ...addOperation.failure,
                          worker: masterList.users[index]['name'],
                        },
                      })
                    }
                    width="198px"
                    disabled={isLoadingAction}
                  />
                </Box>
                <Box py="5px" borderRight="1px">
                  <PrimaryInput
                    type="time"
                    min="00:00"
                    max="23:59"
                    value={addOperation.failure.start_at ?? ''}
                    onChange={(e) =>
                      setAddOperation({
                        ...addOperation,
                        failure: {
                          ...addOperation.failure,
                          start_at: e.target.value,
                        },
                      })
                    }
                    width="199px"
                    disabled={isLoadingAction}
                  />
                </Box>
                <Box py="5px">
                  <PrimaryInput
                    type="time"
                    min="00:00"
                    max="23:59"
                    value={addOperation.failure.finish_at ?? ''}
                    onChange={(e) =>
                      setAddOperation({
                        ...addOperation,
                        failure: {
                          ...addOperation.failure,
                          finish_at: e.target.value,
                        },
                      })
                    }
                    width="200px"
                    disabled={isLoadingAction}
                  />
                </Box>
              </Flex>
            </Box>
          </Flex>
          <Flex borderBottom="1px" align="center">
            <Text w="200px" minWidth="200px">
              d.その他
            </Text>
            <Box>
              {moldingReport.operations.other.map((elm, num) => {
                return (
                  <>
                    <Text
                      textAlign="left"
                      borderLeft="1px"
                      borderBottom="1px"
                      display={num !== 0 && 'none'}
                    >
                      追加済み
                    </Text>

                    <Flex borderBottom="1px">
                      <Box
                        w="51px"
                        minWidth="51px"
                        h="50px"
                        lineHeight="50px"
                        borderLeft="1px"
                        borderRight="1px"
                      >
                        <IconButton
                          aria-label="削除"
                          disabled={isLoadingAction}
                          onClick={() => {
                            setMoldingReport({
                              ...moldingReport,
                              operations: {
                                ...moldingReport.operations,
                                other: moldingReport.operations.other.filter(
                                  (_, number) => num !== number
                                ),
                              },
                            })
                          }}
                          icon={<AiOutlineDelete />}
                        />
                      </Box>
                      <Box py="5px" borderRight="1px">
                        <PrimarySelectBox
                          options={masterList.users}
                          defaultOption={elm.worker}
                          optionElement="name"
                          onChangeSelect={(index) =>
                            setMoldingReport({
                              ...moldingReport,
                              operations: {
                                ...moldingReport.operations,
                                other: moldingReport.operations.other.map(
                                  (info, number) =>
                                    num == number
                                      ? {
                                          ...info,
                                          worker:
                                            masterList.users[index]['name'],
                                        }
                                      : info
                                ),
                              },
                            })
                          }
                          width="198px"
                          disabled={isLoadingAction}
                        />
                      </Box>
                      <Box py="5px" borderRight="1px">
                        <PrimaryInput
                          type="time"
                          min="00:00"
                          max="23:59"
                          value={elm.start_at}
                          onChange={(e) =>
                            setMoldingReport({
                              ...moldingReport,
                              operations: {
                                ...moldingReport.operations,
                                other: moldingReport.operations.other.map(
                                  (info, number) =>
                                    num == number
                                      ? {
                                          ...info,
                                          start_at: e.target.value,
                                        }
                                      : info
                                ),
                              },
                            })
                          }
                          width="199px"
                          disabled={isLoadingAction}
                        />
                      </Box>
                      <Box py="5px">
                        <PrimaryInput
                          type="time"
                          min="00:00"
                          max="23:59"
                          value={elm.finish_at}
                          onChange={(e) =>
                            setMoldingReport({
                              ...moldingReport,
                              operations: {
                                ...moldingReport.operations,
                                other: moldingReport.operations.other.map(
                                  (info, number) =>
                                    num == number
                                      ? {
                                          ...info,
                                          finish_at: e.target.value,
                                        }
                                      : info
                                ),
                              },
                            })
                          }
                          width="200px"
                          disabled={isLoadingAction}
                        />
                      </Box>
                    </Flex>
                  </>
                )
              })}
              <Text textAlign="left" borderLeft="1px" borderBottom="1px">
                未追加
              </Text>
              <Flex borderBottom="1px">
                <Box
                  w="51px"
                  minWidth="51px"
                  h="50px"
                  lineHeight="50px"
                  borderLeft="1px"
                  borderRight="1px"
                >
                  <IconButton
                    aria-label="追加"
                    disabled={isLoadingAction}
                    onClick={() => {
                      setMoldingReport({
                        ...moldingReport,
                        operations: {
                          ...moldingReport.operations,
                          other: [
                            ...moldingReport.operations.other,
                            addOperation.other,
                          ],
                        },
                      })
                      setAddOperation({
                        ...addOperation,
                        other: operation,
                      })
                    }}
                    icon={<AiOutlinePlus />}
                  />
                </Box>
                <Box py="5px" borderRight="1px">
                  <PrimarySelectBox
                    options={masterList.users}
                    defaultOption={addOperation.other.worker}
                    optionElement="name"
                    onChangeSelect={(index) =>
                      setAddOperation({
                        ...addOperation,
                        other: {
                          ...addOperation.other,
                          worker: masterList.users[index]['name'],
                        },
                      })
                    }
                    width="198px"
                    disabled={isLoadingAction}
                  />
                </Box>
                <Box py="5px" borderRight="1px">
                  <PrimaryInput
                    type="time"
                    min="00:00"
                    max="23:59"
                    value={addOperation.other.start_at ?? ''}
                    onChange={(e) =>
                      setAddOperation({
                        ...addOperation,
                        other: {
                          ...addOperation.other,
                          start_at: e.target.value,
                        },
                      })
                    }
                    width="199px"
                    disabled={isLoadingAction}
                  />
                </Box>
                <Box py="5px">
                  <PrimaryInput
                    type="time"
                    min="00:00"
                    max="23:59"
                    value={addOperation.other.finish_at ?? ''}
                    onChange={(e) =>
                      setAddOperation({
                        ...addOperation,
                        other: {
                          ...addOperation.other,
                          finish_at: e.target.value,
                        },
                      })
                    }
                    width="200px"
                    disabled={isLoadingAction}
                  />
                </Box>
              </Flex>
            </Box>
          </Flex>
          <Flex borderBottom="1px" align="center">
            <Text w="200px" minWidth="200px" h="51px" lineHeight="50px">
              e.生産
            </Text>
            <Box>
              {moldingReport.operations.production.map((elm, num) => {
                return (
                  <>
                    <Text
                      textAlign="left"
                      borderLeft="1px"
                      borderBottom="1px"
                      display={num !== 0 && 'none'}
                    >
                      追加済み
                    </Text>
                    <Flex borderBottom="1px">
                      <Box
                        w="51px"
                        minWidth="51px"
                        h="50px"
                        lineHeight="50px"
                        borderLeft="1px"
                        borderRight="1px"
                      >
                        <IconButton
                          aria-label="削除"
                          disabled={isLoadingAction}
                          onClick={() => {
                            setMoldingReport({
                              ...moldingReport,
                              operations: {
                                ...moldingReport.operations,
                                production:
                                  moldingReport.operations.production.filter(
                                    (_, number) => num !== number
                                  ),
                              },
                            })
                          }}
                          icon={<AiOutlineDelete />}
                        />
                      </Box>
                      <Box py="5px" borderRight="1px">
                        <PrimarySelectBox
                          options={masterList.users}
                          defaultOption={elm.worker}
                          optionElement="name"
                          onChangeSelect={(index) =>
                            setMoldingReport({
                              ...moldingReport,
                              operations: {
                                ...moldingReport.operations,
                                production:
                                  moldingReport.operations.production.map(
                                    (info, number) =>
                                      num == number
                                        ? {
                                            ...info,
                                            worker:
                                              masterList.users[index]['name'],
                                          }
                                        : info
                                  ),
                              },
                            })
                          }
                          width="198px"
                          disabled={isLoadingAction}
                        />
                      </Box>
                      <Box py="5px" borderRight="1px">
                        <PrimaryInput
                          type="time"
                          min="00:00"
                          max="23:59"
                          value={elm.start_at}
                          onChange={(e) =>
                            setMoldingReport({
                              ...moldingReport,
                              operations: {
                                ...moldingReport.operations,
                                production:
                                  moldingReport.operations.production.map(
                                    (info, number) =>
                                      num == number
                                        ? {
                                            ...info,
                                            start_at: e.target.value,
                                          }
                                        : info
                                  ),
                              },
                            })
                          }
                          width="199px"
                          disabled={isLoadingAction}
                        />
                      </Box>
                      <Box py="5px">
                        <PrimaryInput
                          type="time"
                          min="00:00"
                          max="23:59"
                          value={elm.finish_at}
                          onChange={(e) =>
                            setMoldingReport({
                              ...moldingReport,
                              operations: {
                                ...moldingReport.operations,
                                production:
                                  moldingReport.operations.production.map(
                                    (info, number) =>
                                      num == number
                                        ? {
                                            ...info,
                                            finish_at: e.target.value,
                                          }
                                        : info
                                  ),
                              },
                            })
                          }
                          width="200px"
                          disabled={isLoadingAction}
                        />
                      </Box>
                    </Flex>
                  </>
                )
              })}
              <Text textAlign="left" borderLeft="1px" borderBottom="1px">
                未追加
              </Text>
              <Flex borderBottom="1px">
                <Box
                  w="51px"
                  minWidth="51px"
                  h="50px"
                  lineHeight="50px"
                  borderLeft="1px"
                  borderRight="1px"
                >
                  <IconButton
                    aria-label="追加"
                    disabled={isLoadingAction}
                    onClick={() => {
                      setMoldingReport({
                        ...moldingReport,
                        operations: {
                          ...moldingReport.operations,
                          production: [
                            ...moldingReport.operations.production,
                            addOperation.production,
                          ],
                        },
                      })
                      setAddOperation({
                        ...addOperation,
                        production: operation,
                      })
                    }}
                    icon={<AiOutlinePlus />}
                  />
                </Box>
                <Box py="5px" borderRight="1px">
                  <PrimarySelectBox
                    options={masterList.users}
                    defaultOption={addOperation.production.worker}
                    optionElement="name"
                    onChangeSelect={(index) =>
                      setAddOperation({
                        ...addOperation,
                        production: {
                          ...addOperation.production,
                          worker: masterList.users[index]['name'],
                        },
                      })
                    }
                    width="198px"
                    disabled={isLoadingAction}
                  />
                </Box>
                <Box py="5px" borderRight="1px">
                  <PrimaryInput
                    type="time"
                    min="00:00"
                    max="23:59"
                    value={addOperation.production.start_at ?? ''}
                    onChange={(e) =>
                      setAddOperation({
                        ...addOperation,
                        production: {
                          ...addOperation.production,
                          start_at: e.target.value,
                        },
                      })
                    }
                    width="199px"
                    disabled={isLoadingAction}
                  />
                </Box>
                <Box py="5px">
                  <PrimaryInput
                    type="time"
                    min="00:00"
                    max="23:59"
                    value={addOperation.production.finish_at ?? ''}
                    onChange={(e) =>
                      setAddOperation({
                        ...addOperation,
                        production: {
                          ...addOperation.production,
                          finish_at: e.target.value,
                        },
                      })
                    }
                    width="200px"
                    disabled={isLoadingAction}
                  />
                </Box>
              </Flex>
            </Box>
          </Flex>
        </Box>
        <Box w="50px" minWidth="50px" />
        <Box>
          <Flex
            align="center"
            borderTop="1px"
            borderLeft="1px"
            borderRight="1px"
          >
            <Text
              w="100px"
              minWidth="100px"
              h="50px"
              lineHeight="50px"
              borderRight="1px"
            >
              作成
            </Text>
            <PrimarySelectBox
              options={masterList.users}
              defaultOption={moldingReport.registered_by}
              optionElement="name"
              onChangeSelect={(num) =>
                setMoldingReport({
                  ...moldingReport,
                  registered_by: masterList.users[num]['name'],
                })
              }
              width="200px"
              disabled={isLoadingAction}
            />
          </Flex>
          <Flex
            align="center"
            borderTop="1px"
            borderLeft="1px"
            borderRight="1px"
          >
            <Text
              w="100px"
              minWidth="100px"
              h="50px"
              lineHeight="50px"
              borderRight="1px"
            >
              確認
            </Text>
            <PrimarySelectBox
              options={masterList.users}
              defaultOption={moldingReport.confirmed_by}
              optionElement="name"
              onChangeSelect={(num) =>
                setMoldingReport({
                  ...moldingReport,
                  confirmed_by: masterList.users[num]['name'],
                })
              }
              width="200px"
              disabled={isLoadingAction}
            />
          </Flex>
          <Flex align="center" border="1px">
            <Text
              w="100px"
              minWidth="100px"
              h="50px"
              lineHeight="50px"
              borderRight="1px"
            >
              承認
            </Text>
            <PrimarySelectBox
              options={workerAdminMaster}
              defaultOption={moldingReport.approved_by}
              optionElement="name"
              onChangeSelect={(num) =>
                setMoldingReport({
                  ...moldingReport,
                  approved_by: masterList.users[num]['name'],
                })
              }
              width="200px"
              disabled={isLoadingAction}
            />
          </Flex>
        </Box>
      </Flex>
      <Flex justify="space-between">
        <Text>*責任者による品質管理はその他欄に記入のこと</Text>
        <Text>10版 文書番号 L4-0715</Text>
      </Flex>
    </Box>
  )
}
