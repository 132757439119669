import React from 'react'
import { Input, InputGroup, InputLeftAddon } from '@chakra-ui/react'

export const InputAddonText = (props) => {
  const {
    placeholder,
    addonMessage,
    width = 'auto',
    type,
    value,
    min,
    max,
    maxLength,
    onChange,
    onFocus,
    htmlSize,
    isReadOnly = false,
    disabled,
    children,
  } = props
  return (
      <InputGroup>
        <InputLeftAddon children={addonMessage} />
        <Input
          placeholder={placeholder}
          type={type}
          value={value}
          min={min}
          max={max}
          maxLength={maxLength}
          onChange={onChange}
          onFocus={onFocus}
          bg="white"
          w={width}
          htmlSize={htmlSize}
          _focus={{ boxShadow: 'none' }}
          isReadOnly={isReadOnly}
          disabled={disabled}
        />
        {children}
      </InputGroup>
  )
}
