import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { useMessage } from '../../../hooks/useMessage'
import { BeatLoader } from 'react-spinners'
import { Box, Flex, HStack, Heading, Text } from '@chakra-ui/react'
import {
  clockingInAndOut,
  postMoldingReport,
  targetMoldingReport,
} from '../../../urls'
import { AlertModal } from '../../organisms/AlertModal'
import { useCurrentUser2 } from '../../../hooks/users/useCurrentUser2'
import { useLogout2 } from '../../../hooks/users/useLogout2'
import { useGetData } from '../../../hooks/useGetData'
import { PrimaryNumberInput } from '../../atoms/PrimaryNumberInput'
import { PrimaryInput } from '../../atoms/PrimaryInput'
import axios from 'axios'
import { StringSelectBox } from '../../atoms/StringSelectBox'
import { FormWorkingAchievement } from '../../organisms/operations/molding/FormWorkingAchievement'
import { FormDefective } from '../../organisms/operations/molding/FormDefective'
import { FormConditionConfirmation } from '../../organisms/operations/molding/FormConditionConfirmation'
import { FormTools } from '../../organisms/operations/molding/FormTools'
import { FormOperation } from '../../organisms/operations/molding/FormOperation'
import { useBlockBrowserBack } from '../../../hooks/users/useBlockBrowserBack'
import { AlertModalWithoutButton } from '../../organisms/AlertModalWithoutButton'
import { DigitalClock } from '../../atoms/DigitalClock'

export const Molding = () => {
  const location = useLocation()
  const { logout } = useLogout2()
  const { message } = useMessage()
  const navigate = useNavigate()
  const user = useCurrentUser2()
  const { isBrowserBack, setIsBrowserBack } = useBlockBrowserBack()
  const [isUpdate, setIsUpdate] = useState(false)
  const [isLoadingAction, setIsLoadingAction] = useState(false)
  const [isSucceeded, setIsSucceeded] = useState(false)
  const { getProducts, productList, getMasters, masterList, isLoading } =
    useGetData()
  const [searchParams] = useSearchParams()
  const id = searchParams.get('id')

  const defectives = [
    //変更
    { name: 'short_count', value: 'ショート', count_day: 0, count_night: 0 },
    { name: 'silver_count', value: 'シルバー', count_day: 0, count_night: 0 },
    {
      name: 'foreign_object_count',
      value: '異物',
      count_day: 0,
      count_night: 0,
    },
    {
      name: 'gas_burning_count',
      value: 'ガス焼け(油汚れ)',
      count_day: 0,
      count_night: 0,
    },
    {
      name: 'bleaching_count',
      value: '白化(ダートカス)',
      count_day: 0,
      count_night: 0,
    },
    { name: 'others', value: '', count_day: 0, count_night: 0 },
  ]
  const condition_confirmation = {
    nozzle: 0,
    zone1: 0,
    zone2: 0,
    zone3: 0,
    zone4: 0,
    zone5: 0,
    cavity: 0,
    core: 0,
    drying: 0,
    filling_time: 0,
    measuring_time: 0,
    cycle_time: 0,
    clamping_pressure: 0,
    minimum_cushion_length: 0,
    is_appearance_check: false,
    is_basic_condition_matching: false,
    is_check_thread_cutting_hole_diameter: false,
    registered_by: id ? '' : user.name,
    confirmed_by: '',
  }
  const tool = {
    is_well: false,
    defective_reason: '',
    registered_by: id ? '' : user.name,
    confirmed_by: '',
  }
  const initMoldingReport = {
    molding_record_id: '',
    working_date: null,
    machine_num: '', //追加
    machine_name: '',
    working_lot_num: '',
    supplied_lot_num: '', //追加(支給品関連のロッドNo.)
    registered_by: id ? '' : user.name, //追加
    confirmed_by: '', //追加
    approved_by: '', //追加
    // 作業実績
    working_achievement: {
      is_mass: true,
      product_number: '',
      product_name: '',
      take_count: 0,
      material_name: '',
      grade: '',
      tone: '',
      material_lot_num: [{ lot_num: '' }], //変更
      adjustment_number: '',
      provided_lot_num: '',
      confirmed_by: '', //追加
      count: {
        day: {
          non_defective_count: 0,
          defective_count: 0,
        },
        night: {
          non_defective_count: 0,
          defective_count: 0,
        },
      },
    },
    // 品質関連
    defective: defectives,
    // 条件確認
    condition_confirmation: {
      day: condition_confirmation,
      night: condition_confirmation,
    },
    // 金型・取出機関連
    tools: {
      mold_inspection_result: tool,
      operation_confirmation_result: tool,
    },
    // 稼働状況
    operations: {
      //変更
      mold_and_color_change: [],
      condition_adjustment: [],
      failure: [],
      other: [],
      production: [],
    },
  }

  const operation = {
    id: 0, //フロント的には不要
    operation_details: '', //フロント的には不要
    worker: '',
    start_at: null,
    finish_at: null,
  }

  const initAddOperation = {
    mold_and_color_change: operation,
    condition_adjustment: operation,
    failure: operation,
    other: operation,
    production: operation,
  }

  const [moldingReport, setMoldingReport] = useState(initMoldingReport)
  const [addOperation, setAddOperation] = useState(initAddOperation)
  const [defectiveLength, setDefectiveLength] = useState(defectives.length - 1)

  useEffect(() => {
    if (user.is_working) {
      const getData = async () => {
        if (id) {
          const report = await axios.get(targetMoldingReport(id))
          setMoldingReport(report.data)
          setIsUpdate(true)
        }
        await getProducts()
        getMasters()
      }
      getData()
    }
  }, [])

  const [workerMaster, setWorkerMaster] = useState([])
  const [workerAdminMaster, setWorkerAdminMaster] = useState([])
  useEffect(() => {
    if (masterList.users) {
      setWorkerMaster(
        masterList.users.filter((user) => user.access_level == 'worker')
      )
      setWorkerAdminMaster(
        masterList.users.filter((user) => {
          return user.access_level == 'worker' || user.access_level == 'admin'
        })
      )
    }
  }, masterList.users)

  const params = {
    method: isUpdate ? 'PUT' : 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(moldingReport),
  }
  const handleClickClockOut = () => {
    const postParams = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        working_hour_id: '',
        work_start_time: '',
        work_end_time: '',
        user_id: user.user_id,
        work_place: '',
        lot_processes: '',
      }),
    }
    setIsLoadingAction(true)
    fetch(clockingInAndOut, postParams)
      .then((res) => res.json())
      .then((myjson) => {
        console.log(myjson)
        message({
          title: '退勤を記録しました。',
          status: 'success',
        })
        logout()
      })
      .catch(() =>
        message({ title: '通信エラーが発生しました', status: 'warning' })
      )
      .finally(() => {
        setIsLoadingAction(false)
      })
  }

  const handleClickUpdateReport = () => {
    setIsLoadingAction(true)
    fetch(targetMoldingReport(id), params)
      .then((res) => res.json())
      .then((myjson) => {
        message({ title: '日報の更新に成功しました。', status: 'success' })
        console.log(myjson)
        // navigate('/daily_report/index')
        setIsSucceeded(true)
      })
      .catch(() => {
        message({ title: '通信エラーが発生しました', status: 'warning' })
      })
      .finally(() => {
        setIsLoadingAction(false)
      })
  }

  const handleClickRegisterReport = () => {
    setIsLoadingAction(true)
    fetch(postMoldingReport, params)
      .then((res) => res.json())
      .then((myjson) => {
        message({ title: '日報の記録に成功しました。', status: 'success' })
        console.log(myjson)
        // navigate('/daily_report/index')
        setIsSucceeded(true)
      })
      .catch(() => {
        message({ title: '通信エラーが発生しました', status: 'warning' })
      })
      .finally(() => {
        setIsLoadingAction(false)
      })
  }

  return (
    <Box m={2}>
      {isLoading || workerMaster.length == 0 ? (
        <Flex h="100vh" alignItems="center" justifyContent="center" mt={-8}>
          <BeatLoader size={30} color={'#3b5998'} />
        </Flex>
      ) : (
        <>
          <DigitalClock />
          <Flex justify="space-between">
            <Flex align="center">
              <PrimaryNumberInput
                value={moldingReport.machine_num}
                minValue={0}
                maxValue={100}
                onChange={(num) =>
                  setMoldingReport({
                    ...moldingReport,
                    machine_num: num,
                  })
                }
                width="70px"
                disabled={isLoadingAction}
              />
              <Text>号機</Text>
              <StringSelectBox
                options={masterList.machines}
                defaultOption={moldingReport.machine_name}
                onChangeSelect={(value) => {
                  setMoldingReport({
                    ...moldingReport,
                    machine_name: value,
                  })
                }}
                width="140px"
                disabled={isLoadingAction}
              />
              <Box color="red">*</Box>
            </Flex>
            <Heading textAlign="center">成形作業日報</Heading>
            <Box>
              <Flex align="center">
                <Text w="80px">
                  作業日
                  <Box as="span" color="red">
                    *
                  </Box>
                </Text>
                <PrimaryInput
                  type="date"
                  min="2000-01-01"
                  max="2050-01-01"
                  value={moldingReport.working_date}
                  onChange={(e) =>
                    setMoldingReport({
                      ...moldingReport,
                      working_date: e.target.value,
                    })
                  }
                  width="150px"
                  disabled={isLoadingAction}
                />
              </Flex>
              <Flex align="center">
                <Text w="80px">
                  ロッドNo.
                  <Box as="span" color="red">
                    *
                  </Box>
                </Text>
                <PrimaryInput
                  value={moldingReport.working_lot_num}
                  onChange={(e) =>
                    setMoldingReport({
                      ...moldingReport,
                      working_lot_num: e.target.value,
                    })
                  }
                  width="150px"
                  disabled={isLoadingAction}
                />
              </Flex>
            </Box>
          </Flex>
          <Flex justify="space-between" textAlign="center">
            <Box w="600px">
              {/* 作業実績 */}
              <FormWorkingAchievement
                moldingReport={moldingReport}
                setMoldingReport={setMoldingReport}
                masterList={masterList}
                productList={productList}
                isLoadingAction={isLoadingAction}
              />
              {/* 品質関連 */}
              <FormDefective
                moldingReport={moldingReport}
                setMoldingReport={setMoldingReport}
                defectiveLength={defectiveLength}
                setDefectiveLength={setDefectiveLength}
                isLoadingAction={isLoadingAction}
              />
            </Box>
            <Box w="50px" minWidth="50px" />
            <Box w="600px">
              {/* 条件確認 */}
              <FormConditionConfirmation
                moldingReport={moldingReport}
                setMoldingReport={setMoldingReport}
                masterList={masterList}
                isLoadingAction={isLoadingAction}
              />
              {/* 金型・取出機関連 */}
              <FormTools
                moldingReport={moldingReport}
                setMoldingReport={setMoldingReport}
                masterList={masterList}
                isLoadingAction={isLoadingAction}
              />
            </Box>
          </Flex>
          {/* 稼働状況 */}
          <FormOperation
            moldingReport={moldingReport}
            setMoldingReport={setMoldingReport}
            addOperation={addOperation}
            setAddOperation={setAddOperation}
            masterList={masterList}
            workerMaster={workerMaster}
            workerAdminMaster={workerAdminMaster}
            operation={operation}
            isLoadingAction={isLoadingAction}
          />

          <Flex justifyContent="center" alignItems="center" mt={10}>
            <HStack>
              <AlertModal
                buttonMessage="キャンセル"
                buttonLeft="日報登録に戻る"
                buttonRight="ログアウト"
                title="キャンセル"
                colorScheme="blackAlpha"
                body="日報登録を中断してログアウトしますか？"
                onClick={() => logout()}
                onClickCancel={() => navigate('/daily_report/mass_produce')}
                disabled={isLoadingAction}
              />
              <AlertModal
                buttonMessage={isUpdate ? '更新' : '記録'}
                title={isUpdate ? '更新' : '記録'}
                body={`この内容で日報を${isUpdate ? '更新' : '記録'}しますか？`}
                onClick={
                  isUpdate ? handleClickUpdateReport : handleClickRegisterReport
                }
                disabled={
                  isLoadingAction ||
                  !moldingReport.machine_name ||
                  !moldingReport.working_date ||
                  !moldingReport.working_achievement.product_number ||
                  !moldingReport.working_lot_num
                }
              />
            </HStack>
          </Flex>
          {isSucceeded && (
            <AlertModal
              needButton={false}
              buttonLeft="勤務に戻る"
              buttonRight="退勤"
              title="退勤"
              body="このまま退勤しますか？"
              onClick={() => handleClickClockOut()}
              onClickCancel={() => logout()}
            />
          )}
        </>
      )}
      {isBrowserBack && (
        <AlertModalWithoutButton
          title="戻る"
          body="データを変更した場合、更新ボタンを押さないと編集内容が破棄されますがよろしいですか？"
          onClick={() => window.history.back()}
          onClickCancel={() => {
            window.history.pushState(null, '', window.location.href)
            setIsBrowserBack(false)
          }}
        />
      )}
    </Box>
  )
}
