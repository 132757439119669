import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import BeatLoader from 'react-spinners/BeatLoader'
import { Box, Text, Flex, Heading } from '@chakra-ui/react'
import { useGetData } from '../../../hooks/useGetData'
import { TableDailyReportList } from '../../organisms/operations/TableDailyReportList'
import { FormSearchDailyReport } from '../../molecules/operation/FormSearchDailyReport'

export const DailyReportList2 = () => {
  const initKeyword = {
    working_date_from: null,
    working_date_to: null,
    updating_date_from: null,
    updating_date_to: null,
    product_number: null,
    record_type: null,
    registered_by: null,
    is_confirmed: null,
    is_approved: null,
  }
  const defaultRange = {
    lowerDate: '2000-01-01',
    upperDate: '2050-01-01',
  }

  const recordTypes = [
    { value: '', name: '選択' },
    { value: 'laser', name: 'レーザー' },
    { value: 'painting', name: '塗装' },
    { value: 'molding', name: '成形' },
    { value: 'inspection', name: '検査' },
    { value: 'printing', name: '印刷' },
  ]

  const [keyword, setKeyword] = useState(initKeyword)
  const [isSearching, setIsSearching] = useState(false)
  const [isSearched, setIsSearched] = useState(false)
  const navigate = useNavigate()
  const {
    getDailyReportList,
    dailyReportList,
    getProducts,
    productList,
    getMasters,
    masterList,
    isLoading,
    setIsLoading,
  } = useGetData()

  useEffect(() => {
    const getProductMasters = async () => {
      await getMasters()
      await getProducts()
      await getDailyReportList(keyword)
      setIsLoading(false)
    }
    getProductMasters()
  }, [])

  const handleClickSearch = async () => {
    setIsSearching(true)
    await getDailyReportList(keyword)
    setIsSearching(false)
    setIsSearched(true)
  }

  const handleOpenReportDetail = (report) => {
    navigate(
      `/daily_report/${
        report.record_type == 'laser'
          ? 'laser'
          : report.record_type == 'inspection'
          ? 'inspection'
          : report.record_type == 'painting'
          ? 'painting'
          : report.record_type == 'molding'
          ? 'molding'
          : 'print'
      }?id=${report.id}`
    )
  }

  return (
    <Box m={2}>
      {isLoading ? (
        <Flex h="100vh" alignItems="center" justifyContent="center" mt={-8}>
          <BeatLoader size={30} color={'#3b5998'} />
        </Flex>
      ) : (
        <>
          <Heading mb={10} align="center">
            量産日報一覧
          </Heading>
          <FormSearchDailyReport
            masterList={masterList}
            recordTypes={recordTypes}
            products={productList}
            valueRecordType={keyword.record_type ?? ''}
            valueRegisteredBy={keyword.registered_by ?? ''}
            valueWorkingDateFrom={keyword.working_date_from ?? ''}
            valueWorkingDateTo={keyword.working_date_to ?? ''}
            valueUpdatingDateFrom={keyword.updating_date_from ?? ''}
            valueUpdatingDateTo={keyword.updating_date_to ?? ''}
            valueProductNumber={keyword.product_number ?? ''}
            valueIsConfirmed={keyword.is_confirmed}
            valueIsApproved={keyword.is_approved}
            onChangeRecordType={(e) =>
              setKeyword({ ...keyword, record_type: e.target.value })
            }
            onChangeRegisteredBy={(index) =>
              setKeyword({
                ...keyword,
                registered_by: masterList.users[index]['name'],
              })
            }
            onChangeWorkingDateFrom={(e) =>
              setKeyword({ ...keyword, working_date_from: e.target.value })
            }
            onChangeWorkingDateTo={(e) =>
              setKeyword({ ...keyword, working_date_to: e.target.value })
            }
            onChangeUpdatingDateFrom={(e) =>
              setKeyword({ ...keyword, updating_date_from: e.target.value })
            }
            onChangeUpdatingDateTo={(e) =>
              setKeyword({ ...keyword, updating_date_to: e.target.value })
            }
            onChangeProductNumber={(index) =>
              setKeyword({
                ...keyword,
                product_number: productList[index]['product_number'],
              })
            }
            onChangeIsConfirmed={(e) =>
              setKeyword({ ...keyword, is_confirmed: e.target.checked })
            }
            onChangeIsApproved={(e) =>
              setKeyword({ ...keyword, is_approved: e.target.checked })
            }
            lowerDate={defaultRange.lowerDate}
            upperDate={defaultRange.upperDate}
            onClickSearch={handleClickSearch}
            onClickReset={() => setKeyword(initKeyword)}
            isDisabled={isSearching}
          />
          {!isSearching ? (
            <>
              {console.log(keyword)}
              {dailyReportList.length > 0 ? (
                <Box mt={10}>
                  <TableDailyReportList
                    reports={dailyReportList}
                    recordTypes={recordTypes}
                    onClickOpenReportDetail={handleOpenReportDetail}
                  />
                </Box>
              ) : (
                isSearched && (
                  <Text mt={20} textAlign="center">
                    条件に一致する日報が存在しません。
                  </Text>
                )
              )}
            </>
          ) : (
            <Flex h="100vh" alignItems="center" justifyContent="center">
              <BeatLoader size={30} color={'#3b5998'} />
            </Flex>
          )}
        </>
      )}
    </Box>
  )
}
