import React from 'react'
import {
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Heading,
} from '@chakra-ui/react'
import { TableBodyDailyReportLower3 } from '../../molecules/operation/TableBodyDailyReportLower3'
import { TableBodyDailyReportUpper3 } from '../../molecules/operation/TableBodyDailyReportUpper3'

export const TableDailyReport3 = (props) => {
  const {
    instructions,
    instructionNumIndex,
    machines,
    // processNames,
    processes,
    addProcessReport,
    onChangeInstructionNum,
    onChangeProcessName,
    onChangeSetupTime,
    onChangeProcessingTime,
    onChangeMachine,
    onChangeHandWorkTime,
    onChangeCompletedCount,
    onChangeDefectiveCount,
    onChangeAddInstructionNum,
    onChangeAddProcessName,
    onChangeAddMachine,
    onChangeAddSetupTime,
    onChangeAddProcessingTime,
    onChangeAddHandWorkTime,
    onChangeAddCompletedCount,
    onChangeAddDefectiveCount,
    onClickDeleteProcessReport,
    onClickAddProcessReport,
    disabled,
  } = props

  // const [product, setProduct] = useRecoilState(productState)

  return (
    <>
      {/* {console.log(product)} */}
      {console.log(processes, 'lot_processes')}
      {/* {console.log(addProcessReport, 'addProcessReport')} */}
      {/* {console.log(instructionNumIndex, 'instructionNumIndex')} */}
      {/* {console.log(processNames[instructionNumIndex], 'processNames')}
      {console.log(processNames[instructionNumIndex]['process_name'], 'processName')} */}

      <Heading
        ml={6}
        as="h4"
        size="md"
        display={processes.length == 0 && 'none'}
      >
        追加済みリスト
      </Heading>
      <TableContainer mb={2}>
        <Table variant="striped" colorScheme="linkedin">
          {processes.map((process, index) => {
            return (
              <React.Fragment key={index}>
                <Thead>
                  <Tr>
                    <Th>指示書No.</Th>
                    <Th>品番</Th>
                    <Th>品名</Th>
                    <Th>作業工程</Th>
                    <Th>使用機械</Th>
                    <Th></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    {/* {console.log(material)} */}
                    <TableBodyDailyReportUpper3
                      instructions={instructions}
                      instructionNum={process.instruction_num}
                      instructionNumIndex={process.instruction_num_index}
                      // processNames={processNames}
                      productNum={process.product_num}
                      productName={process.product_name}
                      processName={process.process_name}
                      machines={machines}
                      machine={process.machine_name}
                      onChangeInstructionNum={(selectedNum) =>
                        onChangeInstructionNum(selectedNum, index)
                      }
                      onChangeProcessName={(selectedNum) =>
                        onChangeProcessName(selectedNum, index)
                      }
                      onChangeMachine={(selectedNum) =>
                        onChangeMachine(selectedNum, index)
                      }
                      disabled={disabled}
                    />
                  </Tr>
                </Tbody>

                <Thead>
                  <Tr>
                    <Th>段取り時間[min]</Th>
                    <Th>機械使用時間[min]</Th>
                    <Th>ハンドワーク時間[min]</Th>
                    <Th>完了数</Th>
                    <Th>不良数</Th>
                    <Th isNumeric>削除</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    {/* {console.log(material)} */}
                    <TableBodyDailyReportLower3
                      type="existing"
                      setupTime={process.act_setup_minute}
                      processingTime={process.act_kakou_minute}
                      handWorkTime={process.act_handwork_minute}
                      completedCount={process.completed_number}
                      defectiveCount={process.defective_number}
                      onChangeSetupTime={(num) => onChangeSetupTime(num, index)}
                      onChangeProcessingTime={(num) =>
                        onChangeProcessingTime(num, index)
                      }
                      onChangeHandWorkTime={(num) =>
                        onChangeHandWorkTime(num, index)
                      }
                      onChangeCompletedCount={(num) =>
                        onChangeCompletedCount(num, index)
                      }
                      onChangeDefectiveCount={(num) =>
                        onChangeDefectiveCount(num, index)
                      }
                      onClickDeleteProcessReport={() =>
                        onClickDeleteProcessReport(index)
                      }
                      disabledProcessingTime={process.machine_name == '未使用'}
                      disabled={disabled}
                    />
                  </Tr>
                </Tbody>
              </React.Fragment>
            )
          })}

          <Heading
            mt={10}
            ml={6}
            as="h4"
            size="md"
            display={processes.length == 0 && 'none'}
          >
            新規追加
          </Heading>
          <Thead>
            <Tr>
              <Th>指示書No.</Th>
              <Th>品番</Th>
              <Th>品名</Th>
              <Th>作業工程</Th>
              <Th>使用機械</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              {/* {console.log(material)} */}
              <TableBodyDailyReportUpper3
                instructions={instructions}
                instructionNum={addProcessReport.instructionNum}
                instructionNumIndex={addProcessReport.instructionNumIndex}
                // processNames={processNames}
                productNum={addProcessReport.productNum}
                productName={addProcessReport.productName}
                processName={addProcessReport.processName}
                machines={machines}
                machine={addProcessReport.machine}
                onChangeInstructionNum={onChangeAddInstructionNum}
                onChangeProcessName={onChangeAddProcessName}
                onChangeMachine={onChangeAddMachine}
                disabled={disabled}
              />
            </Tr>
          </Tbody>

          <Thead>
            <Tr>
              <Th>段取り時間[min]</Th>
              <Th>機械使用時間[min]</Th>
              <Th>ハンドワーク時間[min]</Th>
              <Th>完了数</Th>
              <Th>不良数</Th>
              <Th isNumeric>追加</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              {/* {console.log(material)} */}
              <TableBodyDailyReportLower3
                type="adding"
                setupTime={addProcessReport.setupTime}
                processingTime={addProcessReport.processingTime}
                handWorkTime={addProcessReport.handWorkTime}
                completedCount={addProcessReport.completedCount}
                defectiveCount={addProcessReport.defectiveCount}
                onChangeSetupTime={onChangeAddSetupTime}
                onChangeProcessingTime={onChangeAddProcessingTime}
                onChangeHandWorkTime={onChangeAddHandWorkTime}
                onChangeCompletedCount={onChangeAddCompletedCount}
                onChangeDefectiveCount={onChangeAddDefectiveCount}
                onClickAddProcessReport={onClickAddProcessReport}
                disabledProcessingTime={addProcessReport.machine == '未使用'}
                disabledAddButton={
                  !addProcessReport.instructionNum ||
                  !addProcessReport.processName ||
                  !addProcessReport.machine
                }
                disabled={disabled}
              />
            </Tr>
          </Tbody>
          {/* <TableBodyAddDailyReport
            instructions={instructions}
            instructionNum={addProcessReport.instructionNum}
            instructionNumIndex={addProcessReport.instructionNumIndex}
            // processNames={instructions[instructionNumIndex]['process_name']}
            productNum={addProcessReport.productNum}
            productName={addProcessReport.productName}
            processName={addProcessReport.processName}
            machines={machines}
            machine={addProcessReport.machine}
            setupTime={addProcessReport.setupTime}
            processingTime={addProcessReport.processingTime}
            handWorkTime={addProcessReport.handWorkTime}
            onChangeAddInstructionNum={onChangeAddInstructionNum}
            onChangeAddProcessName={onChangeAddProcessName}
            onChangeAddMachine={onChangeAddMachine}
            onChangeAddSetupTime={onChangeAddSetupTime}
            onChangeAddProcessingTime={onChangeAddProcessingTime}
            onChangeAddHandWorkTime={onChangeAddHandWorkTime}
            onClickAddProcessReport={onClickAddProcessReport}
            disabledAddProcessingTime={addProcessReport.machine == '未使用'}
            disabledAddButtonForMachineName={
              addProcessReport.machine !== '未使用' &&
              !addProcessReport.processingTime
            }
            disabledAddButtonForUpperFlag={
              !addProcessReport.instructionNum ||
              !addProcessReport.processName ||
              !addProcessReport.machine
            }
            disabledAddButtonForLowerFlag={
              !addProcessReport.setupTime && !addProcessReport.handWorkTime
            }
            disabled={disabled}
          /> */}
        </Table>
      </TableContainer>
    </>
  )
}
