import React from 'react'
import { Td, Text } from '@chakra-ui/react'

export const TableBodyMassProjectList = (props) => {
  const { project, onClickOpenProjectDetail } = props

  return (
    <>
      <Td>
        <Text>{project.client_name}</Text>
      </Td>
      <Td>
        <Text>{project.assigner}</Text>
      </Td>
      <Td>
        <Text
          color="#3b5998"
          fontWeight="semibold"
          as="a"
          cursor="pointer"
          onClick={() => onClickOpenProjectDetail(project.project_id)}
        >
          {project.project_name}
        </Text>
      </Td>
      <Td>
        <Text>{project.registered_date}</Text>
      </Td>
    </>
  )
}
