import React, { useEffect, useState, useRef } from 'react'
import { createLot, productEstimate, putLot } from '../../../urls'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import {
  Box,
  Heading,
  Flex,
  Text,
  Textarea,
  IconButton,
  RadioGroup,
  Stack,
  Radio,
} from '@chakra-ui/react'
import { BeatLoader } from 'react-spinners'
import { useRecoilState } from 'recoil'
import { AlertModal } from '../../organisms/AlertModal'
import { useGetData } from '../../../hooks/useGetData'
import { useMessage } from '../../../hooks/useMessage'
import { CommonInfoLotPrototype3 } from '../../organisms/lot/CommonInfoLotPrototype3'
import { TableMaterialPrototype3 } from '../../organisms/lot/TableMaterialPrototype3'
import { TableMachiningPrototype3 } from '../../organisms/lot/TableMachiningPrototype3'
import { PrimaryNumberInput } from '../../atoms/PrimaryNumberInput'
import { TableToolCosts } from '../../organisms/lot/TableToolCosts'
import { productState5 } from '../../../hooks/store/productState5'
import { CategorizationLabel } from '../../molecules/formatB/CategorizationLabel'
import { AiOutlineUpload } from 'react-icons/ai'
import axios from 'axios'
import { AlertModalWithRadioButton } from '../../organisms/AlertModalWithRadioButton'
import { stampTypes } from '../../../constants/MasterInjectHelper'
import { useBlockBrowserBack } from '../../../hooks/users/useBlockBrowserBack'
import { AlertModalWithoutButton } from '../../organisms/AlertModalWithoutButton'

export const Prototype4 = () => {
  const navigate = useNavigate()
  const { message } = useMessage()
  const [searchParams] = useSearchParams()
  const { isBrowserBack, setIsBrowserBack } = useBlockBrowserBack(2)
  const {
    getTargetProject,
    project,
    getTargetProduct,
    getMasters,
    masterList, 
    isLoading,
  } = useGetData()
  const [isCreate, setIsCreate] = useState(false)
  const [isLoadingAction, setIsLoadingAction] = useState()
  const [product, setProduct] = useRecoilState(productState5)
  const inputRef = useRef(null)
  const [file, setFile] = useState(new Blob())

  const initAddProduct = {
    direct: {
      materialName: '',
      materialChargeQuantity: 0,
      materialUnit: 0,
      sellingPrice: 0,
      buyingPrice: 0,
      processingName: '',
      machineName: '',
      manHours: 0,
      takeCount: 1,
      machiningUnit: '',
      wageRate: 0,
      minWageRate: 0,
      machine: '',
      workingName: '',
    },
    indirect: {
      materialName: '',
      materialChargeQuantity: 0,
      materialUnit: '',
      sellingPrice: 0,
      buyingPrice: 0,
      processingName: '',
      machineName: '',
      manHours: 0,
      takeCount: 1,
      machiningUnit: '',
      wageRate: 0,
      minWageRate: 0,
      machine: '',
      workingName: '',
    },
    toolCost: {
      toolName: '',
      amount: 0,
      sellingPrice: 0,
      buyingPrice: 0,
    },
  }
  const [addProductInfo, setAddProductInfo] = useState(initAddProduct)
  const lotNum = searchParams.get('lotNum')
  const projectId = searchParams.get('projectId')

  useEffect(() => {
    const getData = async () => {
      await getTargetProject(projectId)
      if (lotNum) {
        await getTargetProduct(lotNum)
      } else {
        setIsCreate(true)
        setProduct({
          ...product,
          lots: {
            ...product.lots,
            project_id: projectId,
          },
        })
      }
      getMasters()
    }
    getData()
  }, [])

  const postParams = {
    method: isCreate ? 'POST' : 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(product.lots),
  }

  const sendSketch = (sketch) => {
    const formData = new FormData()
    formData.append('lot_num', sketch)
    formData.append('sketch', file)
    axios
      .put(`https://miyama-back.datamission.jp/api/sketch/${sketch}/`, formData)
      .then((res) => {})
      .catch((err) => {
        message({
          title: '画像のアップロードに失敗しました。',
          status: 'warning',
        })
      })
  }

  const handleCreateProduct = () => {
    setIsLoadingAction(true)
    fetch(createLot, postParams)
      .then((res) => res.json())
      .then((myjson) => {
        if (file && file.name) {
          sendSketch(myjson.lot_num)
        }
        message({ title: '部品の新規追加に成功しました。', status: 'success' })
        setIsCreate(false)
        setProduct({
          ...product,
          lots: myjson,
        })
      })
      .catch(() => {
        message({ title: '通信エラーが発生しました', status: 'warning' })
      })
      .finally(() => {
        setIsLoadingAction(false)
      })
  }

  const handleUpdateProduct = () => {
    setIsLoadingAction(true)
    fetch(putLot(product.lots.lot_num), postParams)
      .then((res) => res.json())
      .then((myjson) => {
        setProduct({
          ...product,
          lots: myjson,
        })
        if (file && file.name) {
          sendSketch(product.lots.lot_num)
        }
        message({ title: '部品の更新に成功しました。', status: 'success' })
      })
      .catch(() =>
        message({ title: '通信エラーが発生しました', status: 'warning' })
      )
      .finally(() => setIsLoadingAction(false))
  }

  const handleDeleteProduct = () => {
    setIsLoadingAction(true)
    const postIdParams = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        lot_num: product.lots.lot_num,
      }),
    }
    fetch(putLot(product.lots.lot_num), postIdParams)
      .then(() => {
        message({ title: '部品の削除に成功しました。', status: 'success' })
        navigate('/projects/format_a', {
          state: { projectId: product.lots.project_id },
        })
      })
      .catch(() =>
        message({ title: '通信エラーが発生しました', status: 'warning' })
      )
      .finally(() => setIsLoadingAction(false))
  }

  const handleExportProduct = () => {
    const exportParams = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        stamp_type: product.lots.stamp_type,
      }),
    }
    setIsLoadingAction(true)
    fetch(productEstimate(product.lots.lot_num), exportParams)
      .then((res) => res.json())
      .then((myjson) => {
        message({
          title: '書類の作成に成功しました。',
          status: 'success',
          duration: 10000,
        })
        window.open(myjson.message)
      })
      .catch(() =>
        message({ title: '通信エラーが発生しました', status: 'warning' })
      )
      .finally(() => {
        setIsLoadingAction(false)
      })
  }

  const handleChangeMaterialName = (e, id, index) => {
    id == 'd'
      ? setProduct({
          ...product,
          lots: {
            ...product.lots,
            direct_material_costs: product.lots.direct_material_costs.map(
              (info, number) =>
                index == number
                  ? {
                      ...info,
                      material_name: e.target.value,
                    }
                  : info
            ),
          },
        })
      : setProduct({
          ...product,
          lots: {
            ...product.lots,
            indirect_material_costs: product.lots.indirect_material_costs.map(
              (info, number) =>
                index == number
                  ? {
                      ...info,
                      material_name: e.target.value,
                    }
                  : info
            ),
          },
        })
  }
  const handleChangeMaterialChargeQuantity = (num, id, index) => {
    id == 'd'
      ? setProduct({
          ...product,
          lots: {
            ...product.lots,
            direct_material_costs: product.lots.direct_material_costs.map(
              (info, number) =>
                index == number
                  ? {
                      ...info,
                      material_charge_quantity: num,
                    }
                  : info
            ),
          },
        })
      : setProduct({
          ...product,
          lots: {
            ...product.lots,
            indirect_material_costs: product.lots.indirect_material_costs.map(
              (info, number) =>
                index == number
                  ? {
                      ...info,
                      material_charge_quantity: num,
                    }
                  : info
            ),
          },
        })
  }
  const handleChangeMaterialUnit = (e, id, index) => {
    id == 'd'
      ? setProduct({
          ...product,
          lots: {
            ...product.lots,
            direct_material_costs: product.lots.direct_material_costs.map(
              (info, number) =>
                index == number
                  ? {
                      ...info,
                      unit: e.target.value,
                    }
                  : info
            ),
          },
        })
      : setProduct({
          ...product,
          lots: {
            ...product.lots,
            indirect_material_costs: product.lots.indirect_material_costs.map(
              (info, number) =>
                index == number
                  ? {
                      ...info,
                      unit: e.target.value,
                    }
                  : info
            ),
          },
        })
  }
  const handleChangeMaterialSellingPrice = (num, id, index) => {
    id == 'd'
      ? setProduct({
          ...product,
          lots: {
            ...product.lots,
            direct_material_costs: product.lots.direct_material_costs.map(
              (info, number) =>
                index == number
                  ? {
                      ...info,
                      selling_price: num,
                    }
                  : info
            ),
          },
        })
      : setProduct({
          ...product,
          lots: {
            ...product.lots,
            indirect_material_costs: product.lots.indirect_material_costs.map(
              (info, number) =>
                index == number
                  ? {
                      ...info,
                      selling_price: num,
                    }
                  : info
            ),
          },
        })
  }
  const handleChangeMaterialBuyingPrice = (num, id, index) => {
    id == 'd'
      ? setProduct({
          ...product,
          lots: {
            ...product.lots,
            direct_material_costs: product.lots.direct_material_costs.map(
              (info, number) =>
                index == number
                  ? {
                      ...info,
                      buying_price: num,
                    }
                  : info
            ),
          },
        })
      : setProduct({
          ...product,
          lots: {
            ...product.lots,
            indirect_material_costs: product.lots.indirect_material_costs.map(
              (info, number) =>
                index == number
                  ? {
                      ...info,
                      buying_price: num,
                    }
                  : info
            ),
          },
        })
  }

  const handleClickDeleteMaterial = (id, index) => {
    id == 'd'
      ? setProduct({
          ...product,
          lots: {
            ...product.lots,
            direct_material_costs: product.lots.direct_material_costs.map(
              (info, number) =>
                index == number
                  ? {
                      ...info,
                      is_delete: true,
                    }
                  : info
            ),
          },
        })
      : setProduct({
          ...product,
          lots: {
            ...product.lots,
            indirect_material_costs: product.lots.indirect_material_costs.map(
              (info, number) =>
                index == number
                  ? {
                      ...info,
                      is_delete: true,
                    }
                  : info
            ),
          },
        })
  }
  const handleChangeMaterialNameAdd = (e, id) =>
    id == 'd'
      ? setAddProductInfo({
          ...addProductInfo,
          direct: {
            ...addProductInfo.direct,
            materialName: e.target.value,
          },
        })
      : setAddProductInfo({
          ...addProductInfo,
          indirect: {
            ...addProductInfo.indirect,
            materialName: e.target.value,
          },
        })

  const handleChangeMaterialChargeQuantityAdd = (num, id) =>
    id == 'd'
      ? setAddProductInfo({
          ...addProductInfo,
          direct: {
            ...addProductInfo.direct,
            materialChargeQuantity: num,
          },
        })
      : setAddProductInfo({
          ...addProductInfo,
          indirect: {
            ...addProductInfo.indirect,
            materialChargeQuantity: num,
          },
        })

  const handleChangeMaterialUnitAdd = (e, id) =>
    id == 'd'
      ? setAddProductInfo({
          ...addProductInfo,
          direct: {
            ...addProductInfo.direct,
            materialUnit: e.target.value,
          },
        })
      : setAddProductInfo({
          ...addProductInfo,
          indirect: {
            ...addProductInfo.indirect,
            materialUnit: e.target.value,
          },
        })
  const handleChangeMaterialSellingPriceAdd = (num, id) =>
    id == 'd'
      ? setAddProductInfo({
          ...addProductInfo,
          direct: {
            ...addProductInfo.direct,
            sellingPrice: num,
          },
        })
      : setAddProductInfo({
          ...addProductInfo,
          indirect: {
            ...addProductInfo.indirect,
            sellingPrice: num,
          },
        })
  const handleChangeMaterialBuyingPriceAdd = (num, id) =>
    id == 'd'
      ? setAddProductInfo({
          ...addProductInfo,
          direct: {
            ...addProductInfo.direct,
            buyingPrice: num,
          },
        })
      : setAddProductInfo({
          ...addProductInfo,
          indirect: {
            ...addProductInfo.indirect,
            buyingPrice: num,
          },
        })

  const handleClickAddMaterial = (id) => {
    id == 'd'
      ? setProduct({
          ...product,
          lots: {
            ...product.lots,
            direct_material_costs: [
              ...product.lots.direct_material_costs,
              {
                material_name: addProductInfo.direct.materialName,
                material_charge_quantity:
                  addProductInfo.direct.materialChargeQuantity,
                unit: addProductInfo.direct.materialUnit,
                selling_price: addProductInfo.direct.sellingPrice,
                buying_price: addProductInfo.direct.buyingPrice,
                is_delete: false,
              },
            ],
          },
        })
      : setProduct({
          ...product,
          lots: {
            ...product.lots,
            indirect_material_costs: [
              ...product.lots.indirect_material_costs,
              {
                material_name: addProductInfo.indirect.materialName,
                material_charge_quantity:
                  addProductInfo.indirect.materialChargeQuantity,
                unit: addProductInfo.indirect.materialUnit,
                selling_price: addProductInfo.indirect.sellingPrice,
                buying_price: addProductInfo.indirect.buyingPrice,
                is_delete: false,
              },
            ],
          },
        })
    setAddProductInfo(initAddProduct)
  }

  const handleChangeProcessingName = (e, id, index) => {
    id == 'd'
      ? setProduct({
          ...product,
          lots: {
            ...product.lots,
            direct_process_costs: product.lots.direct_process_costs.map(
              (info, number) =>
                index == number
                  ? {
                      ...info,
                      process_name: e.target.value,
                    }
                  : info
            ),
          },
        })
      : setProduct({
          ...product,
          lots: {
            ...product.lots,
            indirect_process_costs: product.lots.indirect_process_costs.map(
              (info, number) =>
                index == number
                  ? {
                      ...info,
                      process_name: e.target.value,
                    }
                  : info
            ),
          },
        })
  }
  const handleChangeMachineName = (selectedName, id, index) => {
    id == 'd'
      ? setProduct({
          ...product,
          lots: {
            ...product.lots,
            direct_process_costs: product.lots.direct_process_costs.map(
              (info, number) =>
                index == number
                  ? {
                      ...info,
                      which_machine: selectedName,
                    }
                  : info
            ),
          },
        })
      : setProduct({
          ...product,
          lots: {
            ...product.lots,
            indirect_process_costs: product.lots.indirect_process_costs.map(
              (info, number) =>
                index == number
                  ? {
                      ...info,
                      which_machine: selectedName,
                    }
                  : info
            ),
          },
        })
  }
  const handleChangeManHours = (num, id, index) => {
    id == 'd'
      ? setProduct({
          ...product,
          lots: {
            ...product.lots,
            direct_process_costs: product.lots.direct_process_costs.map(
              (info, number) =>
                index == number
                  ? {
                      ...info,
                      process_plan_hours: num,
                    }
                  : info
            ),
          },
        })
      : setProduct({
          ...product,
          lots: {
            ...product.lots,
            indirect_process_costs: product.lots.indirect_process_costs.map(
              (info, number) =>
                index == number
                  ? {
                      ...info,
                      process_plan_hours: num,
                    }
                  : info
            ),
          },
        })
  }
  const handleChangeMachiningUnit = (e, id, index) => {
    id == 'd'
      ? setProduct({
          ...product,
          lots: {
            ...product.lots,
            direct_process_costs: product.lots.direct_process_costs.map(
              (info, number) =>
                index == number
                  ? {
                      ...info,
                      unit: e.target.value,
                    }
                  : info
            ),
          },
        })
      : setProduct({
          ...product,
          lots: {
            ...product.lots,
            indirect_process_costs: product.lots.indirect_process_costs.map(
              (info, number) =>
                index == number
                  ? {
                      ...info,
                      unit: e.target.value,
                    }
                  : info
            ),
          },
        })
  }
  const handleChangeWageRate = (num, id, index) => {
    id == 'd'
      ? setProduct({
          ...product,
          lots: {
            ...product.lots,
            direct_process_costs: product.lots.direct_process_costs.map(
              (info, number) =>
                index == number
                  ? {
                      ...info,
                      process_plan_wage: num,
                    }
                  : info
            ),
          },
        })
      : setProduct({
          ...product,
          lots: {
            ...product.lots,
            indirect_process_costs: product.lots.indirect_process_costs.map(
              (info, number) =>
                index == number
                  ? {
                      ...info,
                      process_plan_wage: num,
                    }
                  : info
            ),
          },
        })
  }
  const handleChangeMinWageRate = (num, id, index) => {
    id == 'd'
      ? setProduct({
          ...product,
          lots: {
            ...product.lots,
            direct_process_costs: product.lots.direct_process_costs.map(
              (info, number) =>
                index == number
                  ? {
                      ...info,
                      process_plan_min_wage: num,
                    }
                  : info
            ),
          },
        })
      : setProduct({
          ...product,
          lots: {
            ...product.lots,
            indirect_process_costs: product.lots.indirect_process_costs.map(
              (info, number) =>
                index == number
                  ? {
                      ...info,
                      process_plan_min_wage: num,
                    }
                  : info
            ),
          },
        })
  }

  const handleChangeTakeCount = (num, id, index) => {
    id == 'd'
      ? setProduct({
          ...product,
          lots: {
            ...product.lots,
            direct_process_costs: product.lots.direct_process_costs.map(
              (info, number) =>
                index == number
                  ? {
                      ...info,
                      take_count: num,
                    }
                  : info
            ),
          },
        })
      : setProduct({
          ...product,
          lots: {
            ...product.lots,
            indirect_process_costs: product.lots.indirect_process_costs.map(
              (info, number) =>
                index == number
                  ? {
                      ...info,
                      take_count: num,
                    }
                  : info
            ),
          },
        })
  }

  const handleClickDeleteMachining = (id, index) => {
    id == 'd'
      ? setProduct({
          ...product,
          lots: {
            ...product.lots,
            direct_process_costs: product.lots.direct_process_costs.map(
              (info, number) =>
                index == number
                  ? {
                      ...info,
                      is_delete: true,
                    }
                  : info
            ),
          },
        })
      : setProduct({
          ...product,
          lots: {
            ...product.lots,
            indirect_process_costs: product.lots.indirect_process_costs.map(
              (info, number) =>
                index == number
                  ? {
                      ...info,
                      is_delete: true,
                    }
                  : info
            ),
          },
        })
  }

  const handleChangeProcessingNameAdd = (e, id) =>
    id == 'd'
      ? setAddProductInfo({
          ...addProductInfo,
          direct: {
            ...addProductInfo.direct,
            processingName: e.target.value,
          },
        })
      : setAddProductInfo({
          ...addProductInfo,
          indirect: {
            ...addProductInfo.indirect,
            processingName: e.target.value,
          },
        })

  const handleChangeMachineNameAdd = (selectedName, id) =>
    id == 'd'
      ? setAddProductInfo({
          ...addProductInfo,
          direct: {
            ...addProductInfo.direct,
            machineName: selectedName,
          },
        })
      : setAddProductInfo({
          ...addProductInfo,
          indirect: {
            ...addProductInfo.indirect,
            machineName: selectedName,
          },
        })

  const handleChangeManHoursAdd = (num, id) =>
    id == 'd'
      ? setAddProductInfo({
          ...addProductInfo,
          direct: {
            ...addProductInfo.direct,
            manHours: num,
          },
        })
      : setAddProductInfo({
          ...addProductInfo,
          indirect: {
            ...addProductInfo.indirect,
            manHours: num,
          },
        })

  const handleChangeMachiningUnitAdd = (e, id) =>
    id == 'd'
      ? setAddProductInfo({
          ...addProductInfo,
          direct: {
            ...addProductInfo.direct,
            machiningUnit: e.target.value,
          },
        })
      : setAddProductInfo({
          ...addProductInfo,
          indirect: {
            ...addProductInfo.indirect,
            machiningUnit: e.target.value,
          },
        })

  const handleChangeWageRateAdd = (num, id) =>
    id == 'd'
      ? setAddProductInfo({
          ...addProductInfo,
          direct: {
            ...addProductInfo.direct,
            wageRate: num,
          },
        })
      : setAddProductInfo({
          ...addProductInfo,
          indirect: {
            ...addProductInfo.indirect,
            wageRate: num,
          },
        })
  const handleChangeMinWageRateAdd = (num, id) =>
    id == 'd'
      ? setAddProductInfo({
          ...addProductInfo,
          direct: {
            ...addProductInfo.direct,
            minWageRate: num,
          },
        })
      : setAddProductInfo({
          ...addProductInfo,
          indirect: {
            ...addProductInfo.indirect,
            minWageRate: num,
          },
        })

  const handleChangeAddTakeCount = (num, id) =>
    id == 'd'
      ? setAddProductInfo({
          ...addProductInfo,
          direct: {
            ...addProductInfo.direct,
            takeCount: num,
          },
        })
      : setAddProductInfo({
          ...addProductInfo,
          indirect: {
            ...addProductInfo.indirect,
            takeCount: num,
          },
        })

  const handleClickAddMachining = (id) => {
    id == 'd'
      ? setProduct({
          ...product,
          lots: {
            ...product.lots,
            direct_process_costs: [
              ...product.lots.direct_process_costs,
              {
                process_name: addProductInfo.direct.processingName,
                which_machine: addProductInfo.direct.machineName,
                process_plan_hours: addProductInfo.direct.manHours,
                unit: addProductInfo.direct.machiningUnit,
                process_plan_wage: addProductInfo.direct.wageRate,
                process_plan_min_wage: addProductInfo.direct.minWageRate,
                take_count: addProductInfo.direct.takeCount,
                is_delete: false,
              },
            ],
          },
        })
      : setProduct({
          ...product,
          lots: {
            ...product.lots,
            indirect_process_costs: [
              ...product.lots.indirect_process_costs,
              {
                process_name: addProductInfo.indirect.processingName,
                which_machine: addProductInfo.indirect.machineName,
                process_plan_hours: addProductInfo.indirect.manHours,
                unit: addProductInfo.indirect.machiningUnit,
                process_plan_wage: addProductInfo.indirect.wageRate,
                process_plan_min_wage: addProductInfo.indirect.minWageRate,
                take_count: addProductInfo.indirect.takeCount,
                is_delete: false,
              },
            ],
          },
        })
    setAddProductInfo(initAddProduct)
  }

  const handleChangeNote = (e) => {
    setProduct({
      ...product,
      lots: {
        ...product.lots,
        note_estimate: e.target.value,
      },
    })
  }

  const handleChangeFile = (e) => {
    const files = e.target.files
    if (!files) return
    setFile(...files)
  }

  const handleChangeToolName = (e, index) => {
    setProduct({
      ...product,
      lots: {
        ...product.lots,
        tool_costs: product.lots.tool_costs.map((info, num) =>
          index == num
            ? {
                ...info,
                tool_name: e.target.value,
              }
            : info
        ),
      },
    })
  }
  const handleChangeAmount = (num, index) => {
    setProduct({
      ...product,
      lots: {
        ...product.lots,
        tool_costs: product.lots.tool_costs.map((info, number) =>
          index == number
            ? {
                ...info,
                amount: num,
              }
            : info
        ),
      },
    })
  }
  const handleChangeSellingPrice = (num, index) => {
    setProduct({
      ...product,
      lots: {
        ...product.lots,
        tool_costs: product.lots.tool_costs.map((info, number) =>
          index == number
            ? {
                ...info,
                selling_price: num,
              }
            : info
        ),
      },
    })
  }
  const handleChangeBuyingPrice = (num, index) => {
    setProduct({
      ...product,
      lots: {
        ...product.lots,
        tool_costs: product.lots.tool_costs.map((info, number) =>
          index == number
            ? {
                ...info,
                buying_price: num,
              }
            : info
        ),
      },
    })
  }

  const handleChangeToolNameAdd = (e) =>
    setAddProductInfo({
      ...addProductInfo,
      toolCost: {
        ...addProductInfo.toolCost,
        toolName: e.target.value,
      },
    })
  const handleChangeAmountAdd = (num) =>
    setAddProductInfo({
      ...addProductInfo,
      toolCost: {
        ...addProductInfo.toolCost,
        amount: num,
      },
    })
  const handleChangeSellingPriceAdd = (num) =>
    setAddProductInfo({
      ...addProductInfo,
      toolCost: {
        ...addProductInfo.toolCost,
        sellingPrice: num,
      },
    })
  const handleChangeBuyingPriceAdd = (num) =>
    setAddProductInfo({
      ...addProductInfo,
      toolCost: {
        ...addProductInfo.toolCost,
        buyingPrice: num,
      },
    })
  const handleClickAddToolCost = () => {
    setProduct({
      ...product,
      lots: {
        ...product.lots,
        tool_costs: [
          ...product.lots.tool_costs,
          {
            is_delete: false,
            tool_name: addProductInfo.toolCost.toolName,
            amount: addProductInfo.toolCost.amount,
            selling_price: addProductInfo.toolCost.sellingPrice,
            buying_price: addProductInfo.toolCost.buyingPrice,
          },
        ],
      },
    })
    setAddProductInfo(initAddProduct)
  }

  const handleClickDeleteToolCost = (index) => {
    setProduct({
      ...product,
      lots: {
        ...product.lots,
        tool_costs: product.lots.tool_costs.map((info, num) =>
          index == num
            ? {
                ...info,
                is_delete: true,
              }
            : info
        ),
      },
    })
  }

  const handleSelectStampType = (value) => {
    setProduct({
      ...product,
      lots: {
        ...product.lots,
        stamp_type: value,
      },
    })
  }

  const handleEditInstruction = () => {
    product.lots.instruction_num
      ? navigate(
          `/projects/instruction?lotNum=${product.lots.lot_num}&instructionNum=${product.lots.instruction_num}`
        )
      : navigate(`/projects/instruction?lotNum=${product.lots.lot_num}`)
  }

  const directSellingCost = () => {
    return (
      product.lots.direct_material_costs.reduce(
        (sum, element) =>
          sum +
          (!element.is_delete &&
            (element.material_charge_quantity * element.selling_price || 0)),
        0
      ) +
      product.lots.direct_process_costs.reduce(
        (sum, element) =>
          sum +
          ((!element.is_delete &&
            (element.process_plan_hours * element.process_plan_wage) /
              element.take_count) ||
            0),
        0
      )
    )
  }
  const directBuyingCost = () => {
    return (
      product.lots.direct_material_costs.reduce(
        (sum, element) =>
          sum +
          (!element.is_delete &&
            (element.material_charge_quantity * element.buying_price || 0)),
        0
      ) +
      product.lots.direct_process_costs.reduce(
        (sum, element) =>
          sum +
          ((!element.is_delete &&
            (element.process_plan_hours * element.process_plan_min_wage) /
              element.take_count) ||
            0),
        0
      )
    )
  }
  const indirectSellingCost = () => {
    return (
      (product.lots.indirect_material_costs.reduce(
        (sum, element) =>
          sum +
          (!element.is_delete &&
            (element.material_charge_quantity * element.selling_price || 0)),
        0
      ) +
        product.lots.indirect_process_costs.reduce(
          (sum, element) =>
            sum +
            ((!element.is_delete &&
              (element.process_plan_hours * element.process_plan_wage) /
                element.take_count) ||
              0),
          0
        )) /
        product.lots.created_amount || 0
    )
  }
  const indirectBuyingCost = () => {
    return (
      (product.lots.indirect_material_costs.reduce(
        (sum, element) =>
          sum +
          ((!element.is_delete &&
            element.material_charge_quantity * element.buying_price) ||
            0),
        0
      ) +
        product.lots.indirect_process_costs.reduce(
          (sum, element) =>
            sum +
            ((!element.is_delete &&
              (element.process_plan_hours * element.process_plan_min_wage) /
                element.take_count) ||
              0),
          0
        )) /
        product.lots.created_amount || 0
    )
  }
  const toolSellingCost = () => {
    return product.lots.tool_costs.reduce(
      (sum, element) =>
        sum +
        ((!element.is_delete && element.amount * element.selling_price) || 0),
      0
    )
  }
  const toolBuyingCost = () => {
    return product.lots.tool_costs.reduce(
      (sum, element) =>
        sum +
        ((!element.is_delete && element.amount * element.buying_price) || 0),
      0
    )
  }
  const productSellingCost = () => {
    return directSellingCost() + indirectSellingCost()
  }
  const productBuyingCost = () => {
    return directBuyingCost() + indirectBuyingCost()
  }
  const unitSellingCostEstimate = () => {
    return (
      (productSellingCost() * (1 + product.lots.management_profit_rate / 100) ||
        0) +
      (product.lots.selling_delivery_cost / product.lots.created_amount || 0)
    )
  }
  const unitBuyingCostEstimate = () => {
    return (
      productBuyingCost() +
      (product.lots.buying_delivery_cost / product.lots.created_amount || 0)
    )
  }
  const totalSellingCost = () => {
    return (
      Math.round(
        (unitSellingCostEstimate() * product.lots.created_amount +
          toolSellingCost()) *
          100 || 0
      ) / 100
    )
  }
  const totalBuyingCost = () => {
    return (
      Math.round(
        (unitBuyingCostEstimate() * product.lots.created_amount +
          toolBuyingCost()) *
          100 || 0
      ) / 100
    )
  }

  return (
    <>
      {isLoading ? (
        <Flex h="100vh" alignItems="center" justifyContent="center">
          <BeatLoader size={30} color={'#3b5998'} />
        </Flex>
      ) : (
        <Box m={2}>
          <Heading textAlign="center" mb={10}>
            {isCreate ? '部品登録' : '部品詳細'}
          </Heading>
          <Box width="1270px" minWidth="1270px">
            <CommonInfoLotPrototype3
              isCreate={isCreate}
              // lotIndex={location.state.lotIndex}
              projectId={project.project.project_id}
              clientName={project.project.client_name}
              assigner={project.project.assigner}
              projectName={project.project.project_name}
              dueDate={project.project.due_date}
              registeredDate={project.project.registered_date}
              count={project.project.count}
              valueProductName={product.lots.product_name}
              valueProductNum={product.lots.product_num}
              valueLotAmount={product.lots.lot_amount}
              valueCreatedAmount={product.lots.created_amount}
              valueSettingChangeNum={product.lots.setting_change_num}
              onChangeProductName={(e) =>
                setProduct({
                  ...product,
                  lots: {
                    ...product.lots,
                    product_name: e.target.value,
                  },
                })
              }
              onChangeProductNum={(e) =>
                setProduct({
                  ...product,
                  lots: {
                    ...product.lots,
                    product_num: e.target.value,
                  },
                })
              }
              onChangeLotAmount={(num) =>
                setProduct({
                  ...product,
                  lots: {
                    ...product.lots,
                    lot_amount: num,
                  },
                })
              }
              onChangeCreatedAmount={(num) =>
                setProduct({
                  ...product,
                  lots: {
                    ...product.lots,
                    created_amount: num,
                  },
                })
              }
              onChangeSettingChangeNum={(num) =>
                setProduct({
                  ...product,
                  lots: {
                    ...product.lots,
                    setting_change_num: num,
                  },
                })
              }
              isDisabled={isLoadingAction}
            />
          </Box>

          <Box width="1470px" minWidth="1470px">
            <Box borderRadius="md" shadow="lg" p={4} mb={16}>
              <CategorizationLabel
                categoryTitle="②直接原価(③+④)"
                sellingPrice={(
                  Math.round(100 * directSellingCost()) / 100
                ).toLocaleString()}
                buyingPrice={(
                  Math.round(100 * directBuyingCost()) / 100
                ).toLocaleString()}
              />
              <TableMaterialPrototype3
                id="d"
                tableName="③材料費明細"
                materialLists={product.lots.direct_material_costs}
                onChangeMaterialName={handleChangeMaterialName}
                onChangeMaterialChargeQuantity={
                  handleChangeMaterialChargeQuantity
                }
                onChangeMaterialUnit={handleChangeMaterialUnit}
                onChangeMaterialSellingPrice={handleChangeMaterialSellingPrice}
                onChangeMaterialBuyingPrice={handleChangeMaterialBuyingPrice}
                onClickDeleteMaterial={handleClickDeleteMaterial}
                addProductInfo={addProductInfo.direct}
                onChangeMaterialNameAdd={handleChangeMaterialNameAdd}
                onChangeMaterialChargeQuantityAdd={
                  handleChangeMaterialChargeQuantityAdd
                }
                onChangeMaterialUnitAdd={handleChangeMaterialUnitAdd}
                onChangeMaterialSellingPriceAdd={
                  handleChangeMaterialSellingPriceAdd
                }
                onChangeMaterialBuyingPriceAdd={
                  handleChangeMaterialBuyingPriceAdd
                }
                onClickAddMaterial={handleClickAddMaterial}
                isDisabled={isLoadingAction}
                disabledAddButton={
                  !addProductInfo.direct.materialName ||
                  !addProductInfo.direct.materialChargeQuantity ||
                  !addProductInfo.direct.sellingPrice ||
                  !addProductInfo.direct.buyingPrice
                }
              />
              <TableMachiningPrototype3
                id="d"
                tableName="④加工費明細"
                processingList={product.lots.direct_process_costs}
                machines={masterList.machines}
                onChangeProcessingName={handleChangeProcessingName}
                onChangeMachineName={handleChangeMachineName}
                onChangeManHours={handleChangeManHours}
                onChangeMachiningUnit={handleChangeMachiningUnit}
                onChangeWageRate={handleChangeWageRate}
                onChangeMinWageRate={handleChangeMinWageRate}
                onChangeTakeCount={handleChangeTakeCount}
                onClickDeleteMachining={handleClickDeleteMachining}
                addProductInfo={addProductInfo.direct}
                onChangeProcessingNameAdd={handleChangeProcessingNameAdd}
                onChangeMachineNameAdd={handleChangeMachineNameAdd}
                onChangeManHoursAdd={handleChangeManHoursAdd}
                onChangeMachiningUnitAdd={handleChangeMachiningUnitAdd}
                onChangeWageRateAdd={handleChangeWageRateAdd}
                onChangeMinWageRateAdd={handleChangeMinWageRateAdd}
                onChangeTakeCountAdd={handleChangeAddTakeCount}
                onClickAddMachining={handleClickAddMachining}
                isDisabled={isLoadingAction}
                disabledAddButton={
                  !addProductInfo.direct.processingName ||
                  !addProductInfo.direct.machineName ||
                  !addProductInfo.direct.manHours ||
                  !addProductInfo.direct.wageRate ||
                  !addProductInfo.direct.minWageRate ||
                  !addProductInfo.direct.takeCount
                }
              />
            </Box>
            <Box borderRadius="md" shadow="lg" p={4} mb={16}>
              <CategorizationLabel
                categoryTitle="⑤間接原価((⑥+⑦)/①)"
                sellingPrice={(
                  Math.round(indirectSellingCost() * 100) / 100
                ).toLocaleString()}
                buyingPrice={(
                  Math.round(indirectBuyingCost() * 100) / 100
                ).toLocaleString()}
              />
              <TableMaterialPrototype3
                id="in"
                tableName="⑥材料費"
                materialLists={product.lots.indirect_material_costs}
                onChangeMaterialName={handleChangeMaterialName}
                onChangeMaterialChargeQuantity={
                  handleChangeMaterialChargeQuantity
                }
                onChangeMaterialUnit={handleChangeMaterialUnit}
                onChangeMaterialSellingPrice={handleChangeMaterialSellingPrice}
                onChangeMaterialBuyingPrice={handleChangeMaterialBuyingPrice}
                onClickDeleteMaterial={handleClickDeleteMaterial}
                addProductInfo={addProductInfo.indirect}
                onChangeMaterialNameAdd={handleChangeMaterialNameAdd}
                onChangeMaterialChargeQuantityAdd={
                  handleChangeMaterialChargeQuantityAdd
                }
                onChangeMaterialUnitAdd={handleChangeMaterialUnitAdd}
                onClickAddMaterial={handleClickAddMaterial}
                onChangeMaterialSellingPriceAdd={
                  handleChangeMaterialSellingPriceAdd
                }
                onChangeMaterialBuyingPriceAdd={
                  handleChangeMaterialBuyingPriceAdd
                }
                isDisabled={isLoadingAction}
                disabledAddButton={
                  !addProductInfo.indirect.materialName ||
                  !addProductInfo.indirect.materialChargeQuantity ||
                  !addProductInfo.indirect.sellingPrice ||
                  !addProductInfo.indirect.buyingPrice
                }
              />
              <TableMachiningPrototype3
                id="in"
                tableName="⑦加工費"
                processingList={product.lots.indirect_process_costs}
                machines={masterList.machines}
                onChangeProcessingName={handleChangeProcessingName}
                onChangeMachineName={handleChangeMachineName}
                onChangeManHours={handleChangeManHours}
                onChangeMachiningUnit={handleChangeMachiningUnit}
                onChangeWageRate={handleChangeWageRate}
                onChangeMinWageRate={handleChangeMinWageRate}
                onChangeTakeCount={handleChangeTakeCount}
                onClickDeleteMachining={handleClickDeleteMachining}
                addProductInfo={addProductInfo.indirect}
                onChangeProcessingNameAdd={handleChangeProcessingNameAdd}
                onChangeMachineNameAdd={handleChangeMachineNameAdd}
                onChangeManHoursAdd={handleChangeManHoursAdd}
                onChangeMachiningUnitAdd={handleChangeMachiningUnitAdd}
                onChangeWageRateAdd={handleChangeWageRateAdd}
                onChangeMinWageRateAdd={handleChangeMinWageRateAdd}
                onChangeTakeCountAdd={handleChangeAddTakeCount}
                onClickAddMachining={handleClickAddMachining}
                isDisabled={isLoadingAction}
                disabledAddButton={
                  !addProductInfo.indirect.processingName ||
                  !addProductInfo.indirect.machineName ||
                  !addProductInfo.indirect.manHours ||
                  !addProductInfo.indirect.wageRate ||
                  !addProductInfo.indirect.minWageRate ||
                  !addProductInfo.indirect.takeCount
                }
              />
            </Box>
            <Box borderRadius="md" shadow="lg" p={4} pb={12} mb={16}>
              <CategorizationLabel
                categoryTitle="⑧製品原価(②+⑤)"
                sellingPrice={(
                  Math.round(productSellingCost() * 100) / 100
                ).toLocaleString()}
                buyingPrice={(
                  Math.round(productBuyingCost() * 100) / 100
                ).toLocaleString()}
              />
              <CategorizationLabel
                categoryTitle="⑨管理費"
                sellingPrice={(product.lots.is_direct_management_profit
                  ? product.lots.management_profit
                  : Math.round(
                      productSellingCost() *
                        product.lots.management_profit_rate || 0
                    ) / 100
                ).toLocaleString()}
                bgColor="#CFEDFB"
                isDisableBuyingCost={true}
              >
                <Flex align="center">
                  <RadioGroup
                    onChange={(value) =>
                      setProduct({
                        ...product,
                        lots: {
                          ...product.lots,
                          is_direct_management_profit: JSON.parse(value),
                        },
                      })
                    }
                    value={product.lots.is_direct_management_profit.toString()}
                    isDisabled={isLoadingAction}
                    mr={10}
                    p="6px"
                    bgColor="white"
                  >
                    <Stack direction="row" spacing={4}>
                      <Radio value="true">直接入力</Radio>
                      <Radio value="false">割合入力</Radio>
                    </Stack>
                  </RadioGroup>
                  {product.lots.is_direct_management_profit ? (
                    <>
                      <PrimaryNumberInput
                        value={product.lots.management_profit}
                        minValue={0}
                        maxValue={100000}
                        onChange={(num) =>
                          setProduct({
                            ...product,
                            lots: {
                              ...product.lots,
                              management_profit: num,
                            },
                          })
                        }
                        width="120px"
                        disabled={isLoadingAction}
                      />
                      <Box width={4} />
                    </>
                  ) : (
                    <>
                      <PrimaryNumberInput
                        value={product.lots.management_profit_rate}
                        minValue={0}
                        maxValue={100000}
                        onChange={(num) =>
                          setProduct({
                            ...product,
                            lots: {
                              ...product.lots,
                              management_profit_rate: num,
                            },
                          })
                        }
                        width="120px"
                        disabled={isLoadingAction}
                      />
                      <Text>%</Text>
                    </>
                  )}
                </Flex>
              </CategorizationLabel>

              <CategorizationLabel
                categoryTitle="⑩運賃・梱包"
                bgColor="#CFEDFB"
                valueSellingDeliveryCost={product.lots.selling_delivery_cost}
                valueBuyingDeliveryCost={product.lots.buying_delivery_cost}
                onChangeSellingDeliveryCost={(num) =>
                  setProduct({
                    ...product,
                    lots: {
                      ...product.lots,
                      selling_delivery_cost: num,
                    },
                  })
                }
                onChangeBuyingDeliveryCost={(num) =>
                  setProduct({
                    ...product,
                    lots: {
                      ...product.lots,
                      buying_delivery_cost: num,
                    },
                  })
                }
                isShowDeliveryCostInput={true}
                isDisabled={isLoadingAction}
              />
              <CategorizationLabel
                categoryTitle="⑪見積書単価合計(⑧+⑨+⑩/①)"
                sellingPrice={(
                  Math.round(unitSellingCostEstimate() * 100) / 100
                ).toLocaleString()}
                buyingPrice={(
                  Math.round(unitBuyingCostEstimate() * 100) / 100
                ).toLocaleString()}
              />
              <CategorizationLabel
                categoryTitle="⑫製品合計(⑪x①)"
                sellingPrice={(
                  Math.round(
                    unitSellingCostEstimate() *
                      product.lots.created_amount *
                      100
                  ) / 100
                ).toLocaleString()}
                buyingPrice={(
                  Math.round(
                    unitBuyingCostEstimate() * product.lots.created_amount * 100
                  ) / 100
                ).toLocaleString()}
                grossProfit={(
                  Math.round(
                    (unitSellingCostEstimate() - unitBuyingCostEstimate()) *
                      product.lots.created_amount *
                      100
                  ) / 100
                ).toLocaleString()}
                grossProfitMargin={
                  1 - unitBuyingCostEstimate() / unitSellingCostEstimate() ||
                  (0).toLocaleString()
                }
              />
            </Box>
            <Box borderRadius="md" shadow="lg" p={4} mb={16}>
              <CategorizationLabel
                categoryTitle="⑬型・治工具"
                sellingPrice={(
                  Math.round(toolSellingCost() * 100) / 100
                ).toLocaleString()}
                buyingPrice={(
                  Math.round(toolBuyingCost() * 100) / 100
                ).toLocaleString()}
              />
              <TableToolCosts
                toolCostList={product.lots.tool_costs}
                addToolCost={addProductInfo.toolCost}
                onChangeToolName={handleChangeToolName}
                onChangeAmount={handleChangeAmount}
                onChangeSellingPrice={handleChangeSellingPrice}
                onChangeBuyingPrice={handleChangeBuyingPrice}
                onChangeToolNameAdd={handleChangeToolNameAdd}
                onChangeAmountAdd={handleChangeAmountAdd}
                onChangeSellingPriceAdd={handleChangeSellingPriceAdd}
                onChangeBuyingPriceAdd={handleChangeBuyingPriceAdd}
                onClickAddToolCost={handleClickAddToolCost}
                onClickDeleteToolCost={handleClickDeleteToolCost}
                isDisabled={isLoadingAction}
                disabledAddElement={
                  product.lots.tool_costs &&
                  product.lots.tool_costs.filter(
                    (toolCost) =>
                      toolCost.tool_name &&
                      toolCost.amount &&
                      toolCost.selling_price &&
                      toolCost.buying_price
                  ).length !== product.lots.tool_costs.length
                }
                disabledAddButton={
                  !addProductInfo.toolCost.toolName ||
                  !addProductInfo.toolCost.amount ||
                  !addProductInfo.toolCost.sellingPrice ||
                  !addProductInfo.toolCost.buyingPrice
                }
              />
            </Box>
            <Box borderRadius="md" shadow="lg" p={4} pb={12} mb={16}>
              <CategorizationLabel
                categoryTitle="⑭見積総合計(⑫+⑬)"
                sellingPrice={totalSellingCost().toLocaleString()}
                buyingPrice={totalBuyingCost().toLocaleString()}
                grossProfit={(
                  totalSellingCost() - totalBuyingCost()
                ).toLocaleString()}
                grossProfitMargin={
                  1 - totalBuyingCost() / totalSellingCost() ||
                  (0).toLocaleString()
                }
              />
              <Flex mr="150px" align="center" mt={10}>
                <Flex mr="20px" direction="column">
                  <Flex align="center" justify="space-around">
                    <Text>略図</Text>
                    <IconButton
                      aria-label="upload"
                      disabled={isLoadingAction}
                      onClick={() => inputRef.current.click()}
                      icon={<AiOutlineUpload />}
                    />
                    <input
                      hidden
                      ref={inputRef}
                      type="file"
                      onChange={(e) => handleChangeFile(e)}
                    />
                  </Flex>
                  <Text border="1px" width="300px">
                    {file && file.name}
                  </Text>
                </Flex>
                <Textarea
                  placeholder="製品備考欄"
                  onChange={(e) => handleChangeNote(e)}
                  value={product.lots.note_estimate}
                  h={100}
                  size="sm"
                  bg="white"
                  _focus={{ boxShadow: 'none' }}
                  isDisabled={isLoadingAction}
                />
              </Flex>
            </Box>{' '}
          </Box>

          {isCreate ? (
            <Flex my={4} mx={2} justify="space-between" align="flex-end">
              <AlertModal
                body="データを入力した場合、登録ボタンを押さないと内容が破棄されますがよろしいですか？"
                buttonMessage="案件詳細に戻る"
                title="案件詳細に戻る"
                onClick={() => navigate(`/projects/format_a?id=${projectId}`)}
                disabled={isLoadingAction}
                primaryColor={false}
              />
              <AlertModal
                buttonMessage="新規登録"
                title="部品登録"
                body="この内容で新規作成しますか？"
                onClick={handleCreateProduct}
                width="150px"
                disabled={
                  !product.lots.product_name ||
                  !product.lots.product_num ||
                  !product.lots.lot_amount ||
                  !product.lots.created_amount ||
                  isLoadingAction
                }
              />
            </Flex>
          ) : (
            <>
              <Flex my={4} mx={2} justify="space-between" align="flex-end">
                <Flex align="center">
                  <AlertModal
                    body="データを変更した場合、更新ボタンを押さないと編集内容が破棄されますがよろしいですか？"
                    buttonMessage="案件詳細に戻る"
                    title="案件詳細に戻る"
                    onClick={() =>
                      navigate(`/projects/format_a?id=${projectId}`)
                    }
                    disabled={
                      !product.lots.product_name ||
                      !product.lots.product_num ||
                      !product.lots.lot_amount ||
                      !product.lots.created_amount ||
                      isLoadingAction
                    }
                    primaryColor={false}
                  />
                  <Text
                    color="#3b5998"
                    fontSize="13px"
                    fontWeight="semibold"
                    textDecoration="underline"
                    ml={6}
                    as="a"
                    cursor="pointer"
                    opacity={isLoadingAction && 0.3}
                    onClick={handleEditInstruction}
                  >
                    作業指示書
                  </Text>
                </Flex>
                <AlertModal
                  buttonMessage="更新"
                  title="部品更新"
                  body="この内容で更新しますか？"
                  onClick={handleUpdateProduct}
                  width="150px"
                  disabled={isLoadingAction}
                />
              </Flex>
              <Flex my={4} mx={2} justify="right">
                <AlertModalWithRadioButton
                  buttonMessage="詳細見積書出力"
                  title="詳細見積書出力"
                  body="署名のタイプを選択してください。"
                  value={product.lots.stamp_type}
                  options={stampTypes}
                  onClick={handleExportProduct}
                  onChange={handleSelectStampType}
                  width="150px"
                  disabled={isLoadingAction}
                  colorScheme="yellow"
                />
              </Flex>
              <Flex my={4} mx={2} justify="right">
                <AlertModal
                  body="この部品を削除しますか？"
                  buttonMessage="削除"
                  title="部品削除"
                  onClick={handleDeleteProduct}
                  disabled={isLoadingAction}
                  primaryColor={false}
                  color="red"
                />
              </Flex>
            </>
          )}
          {isBrowserBack && (
            <AlertModalWithoutButton
              title="戻る"
              body="データを変更した場合、更新ボタンを押さないと編集内容が破棄されますがよろしいですか？"
              onClick={() => window.history.back()}
              onClickCancel={() => {
                window.history.pushState(null, '', window.location.href)
                setIsBrowserBack(false)
              }}
            />
          )}
        </Box>
      )}
    </>
  )
}
