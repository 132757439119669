import React from 'react'
import {
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  IconButton,
} from '@chakra-ui/react'
import { TableBodyProjectList } from '../molecules/projects/TableBodyProjectList'
import { AiOutlineSortAscending, AiOutlineSortDescending } from 'react-icons/ai'
import { BsSortNumericDown, BsSortNumericUp } from 'react-icons/bs'

export const TableProjectList2 = (props) => {
  const {
    projects,
    isReverse,
    onClickOpenProjectDetail,
    onClickSort,
    onClickSortReverse,
  } = props

  return (
    <TableContainer mb={2}>
      <Table variant="striped" colorScheme="linkedin">
        <Thead>
          <Tr>
            <Th>
              顧客名
              {isReverse['client_name'] ? (
                <IconButton
                  aria-label="search"
                  onClick={onClickSortReverse}
                  icon={<AiOutlineSortDescending />}
                />
              ) : (
                <IconButton
                  aria-label="search"
                  onClick={() => onClickSort('client_name')}
                  icon={<AiOutlineSortAscending />}
                />
              )}
            </Th>
            <Th>担当者</Th>
            <Th>
              案件名
              {isReverse['project_name'] ? (
                <IconButton
                  aria-label="search"
                  onClick={onClickSortReverse}
                  icon={<AiOutlineSortDescending />}
                />
              ) : (
                <IconButton
                  aria-label="search"
                  onClick={() => onClickSort('project_name')}
                  icon={<AiOutlineSortAscending />}
                />
              )}
            </Th>
            <Th>
              数量{' '}
              {isReverse['count'] ? (
                <IconButton
                  aria-label="search"
                  onClick={onClickSortReverse}
                  icon={<BsSortNumericDown />}
                />
              ) : (
                <IconButton
                  aria-label="search"
                  onClick={() => onClickSort('count')}
                  icon={<BsSortNumericUp />}
                />
              )}
            </Th>
            <Th>
              納期
              {isReverse['due_date'] ? (
                <IconButton
                  aria-label="search"
                  onClick={onClickSortReverse}
                  icon={<BsSortNumericDown />}
                />
              ) : (
                <IconButton
                  aria-label="search"
                  onClick={() => onClickSort('due_date')}
                  icon={<BsSortNumericUp />}
                />
              )}
            </Th>
            <Th>ステータス</Th>
            <Th>
              見積作成日
              {isReverse['registered_date'] ? (
                <IconButton
                  aria-label="search"
                  onClick={onClickSortReverse}
                  icon={<BsSortNumericDown />}
                />
              ) : (
                <IconButton
                  aria-label="search"
                  onClick={() => onClickSort('registered_date')}
                  icon={<BsSortNumericUp />}
                />
              )}
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {projects.map((project, index) => {
            return (
              <Tr key={index}>
                <TableBodyProjectList
                  project={project}
                  onClickOpenProjectDetail={onClickOpenProjectDetail}
                />
              </Tr>
            )
          })}
        </Tbody>
      </Table>
    </TableContainer>
  )
}
