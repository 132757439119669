import { Select } from '@chakra-ui/react'
import React from 'react'

export const StringSelectBox = (props) => {
  const {
    onChangeSelect,
    onFocus,
    options,
    defaultOption,
    width = '150px',
    flexGrow,
    disabled,
  } = props

  return (
    <Select
      bg="white"
      borderRadius="base"
      w={width}
      flexGrow={flexGrow}
      disabled={disabled}
      onChange={(e) => {
        onChangeSelect(e.target.value)
      }}
      onFocus={onFocus}
      _focus={{ boxShadow: 'none' }}
      value={
        defaultOption
          ? options.find((option) => {
              return option == defaultOption
            })
          : ''
      }
    >
      <option value="" hidden>
        選択
      </option>
      {options.map((option, index) => {
        return (
          <option key={index} value={option}>
            {option}
          </option>
        )
      })}
    </Select>
  )
}
