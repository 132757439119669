import { extendTheme } from "@chakra-ui/react";

export const theme=extendTheme({
    styles: {
        global: {
            body: {
                backgroundColor: "gray.100",
                color: "gray.700"
            }
        }
    }
})