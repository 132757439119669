import { Box, Text } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'

export const DigitalClock = () => {
const [date, setDate] = useState([])
const [time, setTime] = useState([])
const weekday = ['日', '月', '火', '水', '木', '金', '土']
const UPDATE_CYCLE = 1000

useEffect(() => {
  const timer = setInterval(() => {
    let d = new Date()
    let year = d.getFullYear()
    let month = d.getMonth() + 1
    let day = d.getDate()
    let dayofweek = d.getDay()
    setDate(
      year + '年' + month + '月' + day + '日' + '[' + weekday[dayofweek] + ']'
    )
    let hour = d.getHours().toString().padStart(2, '0')
    let minute = d.getMinutes().toString().padStart(2, '0')
    let seconds = d.getSeconds().toString().padStart(2, '0')
    setTime(hour + ':' + minute + ':' + seconds)
  }, UPDATE_CYCLE)

  return () => {
    clearInterval(timer)
  }
}, [])

return (
  <Box h="30px">
    <Text>
      {date} <span>{time}</span>
    </Text>
  </Box>
)
}
