import React from 'react'
import { Box, Flex, Image, Text } from '@chakra-ui/react'
import { BeatLoader } from 'react-spinners'
import Slider from 'react-slick'
import { Slide } from '../../atoms/Slide'

export const Result2 = (props) => {
  const { result, searchItems, isSearching } = props

  return (
    <>
      {console.log(searchItems, 'search')}
      {isSearching ? (
        <Flex h="100vh" alignItems="center" justifyContent="center">
          <BeatLoader size={30} color={'#3b5998'} />
        </Flex>
      ) : (
        <>
          {result.length > 0 && (
            <Box m="20px" textAlign="center">
              <Text fontSize="2xl" as="b">
                分析結果
              </Text>
              <Flex justify="center" mt={4}>
                {searchItems.startDate == '2023-12-05' ? (
                  <>
                    {searchItems.isMass ? (
                      <>
                        {searchItems.endDate == '2023-12-06' ? (
                          <Image src="https://miyama-back.datamission.jp/media/images/analytics/mass_graph_7.png" />
                        ) : searchItems.endDate == '2023-12-07' ? (
                          <Image src="https://miyama-back.datamission.jp/media/images/analytics/mass_graph_8.png" />
                        ) : searchItems.endDate == '2023-12-08' ? (
                          <Image src="https://miyama-back.datamission.jp/media/images/analytics/mass_graph_9.png" />
                        ) : searchItems.endDate == '2023-12-09' ? (
                          <Image src="https://miyama-back.datamission.jp/media/images/analytics/mass_graph_10.png" />
                        ) : searchItems.endDate == '2023-12-10' ? (
                          <Image src="https://miyama-back.datamission.jp/media/images/analytics/mass_graph_11.png" />
                        ) : searchItems.endDate == '2023-12-11' ? (
                          <Image src="https://miyama-back.datamission.jp/media/images/analytics/mass_graph_12.png" />
                        ) : (
                          <Text>条件に当てはまるデータがありません。</Text>
                        )}
                      </>
                    ) : (
                      <>
                        {searchItems.endDate == '2023-12-06' ? (
                          <Image src="https://miyama-back.datamission.jp/media/images/analytics/test_graph_7.png" />
                        ) : searchItems.endDate == '2023-12-07' ? (
                          <Image src="https://miyama-back.datamission.jp/media/images/analytics/test_graph_8.png" />
                        ) : searchItems.endDate == '2023-12-08' ? (
                          <Image src="https://miyama-back.datamission.jp/media/images/analytics/test_graph_9.png" />
                        ) : searchItems.endDate == '2023-12-09' ? (
                          <Image src="https://miyama-back.datamission.jp/media/images/analytics/test_graph_10.png" />
                        ) : searchItems.endDate == '2023-12-10' ? (
                          <Image src="https://miyama-back.datamission.jp/media/images/analytics/test_graph_11.png" />
                        ) : searchItems.endDate == '2023-12-11' ? (
                          <Image src="https://miyama-back.datamission.jp/media/images/analytics/test_graph_12.png" />
                        ) : (
                          <Text>条件に当てはまるデータがありません。</Text>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {console.log("startDate doesn't match")}
                    <Text>条件に当てはまるデータがありません。</Text>
                  </>
                )}
              </Flex>
            </Box>
          )}
        </>
      )}
    </>
  )
}
