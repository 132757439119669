import React from 'react'
import { Input, InputGroup, InputLeftAddon } from '@chakra-ui/react'
import { PrimaryNumberInput } from './PrimaryNumberInput'

export const InputAddonNumber = (props) => {
    const {
        id,
        addonMessage,
        width = 'auto',
        value,
        defaultValue,
        minValue,
        maxValue,
        onChange,
        bg,
        w,
        flexGrow,
        // htmlSize,
        step,
        precision,
        disabled,
        children,
    } = props
    return (
        <InputGroup>
            <InputLeftAddon children={addonMessage} />
            <PrimaryNumberInput
                id={id}
                value={value}
                defaultValue={defaultValue}
                min={minValue}
                max={maxValue}
                onChange={onChange}
                bg="white"
                w={width}
                // htmlSize={htmlSize}
                flexGrow={flexGrow}
                step={step}
                precision={precision}
                _focus={{ boxShadow: 'none' }}
                disabled={disabled}
                allowMouseWheel
            />
            {/* <Input
          placeholder={placeholder}
          type={type}
          value={value}
          min={min}
          max={max}
          maxLength={maxLength}
          onChange={onChange}
          onFocus={onFocus}
          bg="white"
          w={width}
          htmlSize={htmlSize}
          _focus={{ boxShadow: 'none' }}
          isReadOnly={isReadOnly}
          disabled={disabled}
        /> */}
            {children}
        </InputGroup>
    )
}
