import React from 'react'
import { Box, Flex, IconButton, Text } from '@chakra-ui/react'
import { PrimaryInput } from '../../../atoms/PrimaryInput'
import { PrimaryNumberInput } from '../../../atoms/PrimaryNumberInput'
import { AiOutlinePlus } from 'react-icons/ai'

export const FormDefective = (props) => {
  const {
    moldingReport,
    setMoldingReport,
    defectiveLength,
    setDefectiveLength,
    isLoadingAction,
  } = props
  return (
    <Box mt={6}>
      <Flex h="50px" align="center">
        <Text w="200px" h="50px" lineHeight="50px" bg="black" textColor="white">
          品質関連
        </Text>
        <Text w="200px">昼勤</Text>
        <Text w="200px">夜勤</Text>
      </Flex>
      {moldingReport.defective.map((elm, index) => {
        return (
          <Flex
            h="50px"
            align="center"
            borderTop="1px"
            borderLeft="1px"
            borderRight="1px"
            key={index}
          >
            <Box py={1}>
              <PrimaryInput
                value={elm.value}
                onChange={(e) =>
                  setMoldingReport({
                    ...moldingReport,
                    defective: moldingReport.defective.map((info, number) =>
                      index == number
                        ? {
                            ...info,
                            value: e.target.value,
                          }
                        : info
                    ),
                  })
                }
                width="199px"
                disabled={isLoadingAction || index < 5}
              />
            </Box>
            <Box borderLeft="1px" borderRight="1px" py={1}>
              <PrimaryNumberInput
                value={elm.count_day}
                minValue={0}
                maxValue={100000}
                onChange={(num) =>
                  setMoldingReport({
                    ...moldingReport,
                    defective: moldingReport.defective.map((info, number) =>
                      index == number
                        ? {
                            ...info,
                            count_day: num,
                          }
                        : info
                    ),
                  })
                }
                flexGrow={1}
                disabled={isLoadingAction}
              />
            </Box>
            <PrimaryNumberInput
              value={elm.count_night}
              minValue={0}
              maxValue={100000}
              onChange={(num) =>
                setMoldingReport({
                  ...moldingReport,
                  defective: moldingReport.defective.map((info, number) =>
                    index == number
                      ? {
                          ...info,
                          count_night: num,
                        }
                      : info
                  ),
                })
              }
              flexGrow={1}
              disabled={isLoadingAction}
            />
          </Flex>
        )
      })}
      <Flex
        justify="center"
        align="center"
        borderTop="1px"
        borderLeft="1px"
        borderRight="1px"
      >
        <IconButton
          aria-label="追加"
          disabled={
            isLoadingAction ||
            !moldingReport.defective[defectiveLength].value ||
            (!moldingReport.defective[defectiveLength].count_day &&
            !moldingReport.defective[defectiveLength].count_night)
          }
          onClick={() => {
            setMoldingReport({
              ...moldingReport,
              defective: [
                ...moldingReport.defective,
                { id: 0, name: 'others', value: '', count_day: 0, count_night: 0 },
              ],
            })
            setDefectiveLength(defectiveLength + 1)
          }}
          icon={<AiOutlinePlus />}
        />
      </Flex>
      <Flex h="50px" align="center" border="1px">
        <Text w="200px" h="50px" lineHeight="50px" borderRight="1px">
          合計
        </Text>
        <Text w="200px" h="50px" lineHeight="50px" borderRight="1px">
          {moldingReport.defective.reduce(
            (sum, element) => sum + Number(element.count_day),
            0
          )}
        </Text>
        <Text w="198px" h="50px" lineHeight="50px">
          {moldingReport.defective.reduce(
            (sum, element) => sum + Number(element.count_night),
            0
          )}
        </Text>
      </Flex>
    </Box>
  )
}
