import { ProjectsList } from '../components/pages/projects/ProjectsList'
import { Project8 } from '../components/pages/project/Project8'
import { Prototype4 } from '../components/pages/lot/Prototype4'
import { Instruction4 } from '../components/pages/instruction/Instruction4'
import { Prototype } from '../components/pages/operations/Prototype'
import { Laser } from '../components/pages/operations/Laser'
import { Inspection } from '../components/pages/operations/Inspection'
import { Painting } from '../components/pages/operations/Painting'
import { Print } from '../components/pages/operations/Print'
import { DailyReportMenu } from '../components/pages/operations/DailyReportMenu'
import { DailyReportList2 } from '../components/pages/operations/DailyReportList2'
import { DailyReportMenuForCreationMassProduce } from '../components/pages/operations/DailyReportMenuForCreationMassProduce'
import { Molding } from '../components/pages/operations/Molding'
import { Outsource4 } from '../components/pages/outsource/Outsource4'
import { ProjectOnlyExport } from '../components/pages/project/ProjectOnlyExport'
import { MassProduce02 } from '../components/pages/mass/MassProduce02'
import { DataAnalysis3 } from '../components/pages/DataAnalysis3'

export const salesRoutes = [
  {
    path: '/projects/index',
    element: <ProjectsList />,
  },
  {
    path: '/projects/format_a',
    element: <Project8 />,
  },
  {
    path: '/projects/format_a/export_only',
    element: <ProjectOnlyExport />,
  },
  {
    path: '/projects/mass_produce',
    element: <MassProduce02 />,
  },
  {
    path: '/projects/format_b',
    element: <Prototype4 />,
  },
  {
    path: '/projects/outsource',
    element: <Outsource4 />,
  },
  {
    path: '/projects/instruction',
    element: <Instruction4 />,
  },
]

export const workerRoutes = [
  {
    path: '/daily_report',
    element: <DailyReportMenu />,
  },
  {
    path: '/daily_report/mass_produce',
    element: <DailyReportMenuForCreationMassProduce />,
  },
  {
    path: '/daily_report/index',
    element: <DailyReportList2 />,
  },
  {
    path: '/daily_report/laser',
    element: <Laser />,
  },
  {
    path: '/daily_report/painting',
    element: <Painting />,
  },
  {
    path: '/daily_report/inspection',
    element: <Inspection />,
  },
  {
    path: '/daily_report/molding',
    element: <Molding />,
  },
  {
    path: '/daily_report/Print',
    element: <Print />,
  },
  {
    path: '/daily_report/prototype',
    element: <Prototype />,
  },
]

export const adminRoutes = [
  {
    path: '/data_analysis',
    element: <DataAnalysis3 />,
  },
]
