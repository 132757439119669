import React from 'react'
import {
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from '@chakra-ui/react'

export const PrimaryNumberInput = (props) => {
  const {
    id,
    defaultValue,
    minValue,
    maxValue,
    onChange,
    width,
    flexGrow,
    step,
    precision = 0,
    disabled,
    value,
  } = props
  return (
    <>
      <NumberInput
        id={id}
        value={value}
        defaultValue={defaultValue}
        min={minValue}
        max={maxValue}
        onChange={onChange}
        bg="white"
        w={width}
        flexGrow={flexGrow}
        step={step}
        precision={precision}
        _focus={{ boxShadow: 'none' }}
        disabled={disabled}
        allowMouseWheel
      >
        <NumberInputField />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
    </>
  )
}
