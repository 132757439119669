import React from 'react'
import { Td, IconButton, Text } from '@chakra-ui/react'
import { PrimaryInput } from '../../atoms/PrimaryInput'
import { AiOutlineDelete } from 'react-icons/ai'
import { PrimarySelectBox } from '../../atoms/PrimarySelectBox'
import { StringSelectBox } from '../../atoms/StringSelectBox'

export const TableBodyDailyReportUpper3 = (props) => {
  const {
    disabled,
    instructions,
    instructionNum,
    instructionNumIndex,
    productNum,
    productName,
    processNames,
    processName,
    machines,
    machine,
    onChangeInstructionNum,
    onChangeProcessName,
    onChangeMachine,
  } = props

  return (
    <>
      {/* {console.log(instructionNum)} */}
      <Td>
        <PrimarySelectBox
          options={instructions}
          optionElement="instruction_num"
          defaultOption={instructionNum}
          onChangeSelect={(selectedNum) => onChangeInstructionNum(selectedNum)}
          width="160px"
          disabled={disabled}
        />
      </Td>
      <Td>
        <Text>{productNum}</Text>
      </Td>
      <Td>
        <Text>{productName}</Text>
      </Td>
      <Td>
        {instructionNum ? (
          <StringSelectBox
            options={instructions[instructionNumIndex]['process_name']}
            defaultOption={processName}
            onChangeSelect={onChangeProcessName}
            width="160px"
            disabled={disabled}
          />
        ) : (
          <PrimaryInput placeholder="指示書No.を選択" disabled width="160px" />
        )}
      </Td>
      <Td>
        <StringSelectBox
          options={machines}
          defaultOption={machine}
          onChangeSelect={onChangeMachine}
          width="160px"
          disabled={disabled}
        />
      </Td>
      <Td></Td>
    </>
  )
}
