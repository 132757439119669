import React from 'react'
import {
  Box,
  Flex,
  Grid,
  GridItem,
  Radio,
  RadioGroup,
  Stack,
  Text,
  Textarea,
} from '@chakra-ui/react'
import { useMessage } from '../../../hooks/useMessage'
import { PrimaryInput } from '../../atoms/PrimaryInput'
import { PrimaryNumberInput } from '../../atoms/PrimaryNumberInput'
import { PrimarySelectBox } from '../../atoms/PrimarySelectBox'

export const CommonInfoInstruction3 = (props) => {
  const {
    instruction,
    members,
    materials,
    defaultCreator,
    valueCustomerOrderNum,
    valueOrderedDate,
    valueDueDate,
    valueStockCount,
    valueNoteForInstruction,
    onChangeCustomerOrderNum,
    onChangeOrderedDate,
    onChangeDueDate,
    onChangeSelectCreator,
    onChangeStockCount,
    onChangeIsProvided,
    onChangeNoteForInstruction,
    isDisabled,
  } = props

  const { message } = useMessage()

  return (
    <Grid
      h={460}
      templateRows="repeat(12, 1fr)"
      templateColumns="repeat(12, 1fr)"
      gap={2}
    >
      <GridItem rowSpan={6} colSpan={3} align="right">
        <Stack spacing={2}>
          <Flex justify="space-between" align="center">
            <Text>指示書No.</Text>
            <Text>{instruction.instruction_num}</Text>
          </Flex>
          <Flex justify="space-between" align="center">
            <Text>顧客名</Text>
            <Text>{instruction.client_name}</Text>
          </Flex>
          <Flex justify="space-between" align="center">
            <Text>
              客先注番
              <Box as="span" color="red">
                *
              </Box>
            </Text>
            <PrimaryInput
              placeholder=""
              value={valueCustomerOrderNum}
              onChange={(e) => onChangeCustomerOrderNum(e)}
              htmlSize={16}
              disabled={isDisabled}
            />
          </Flex>
          <Flex justify="space-between" align="center">
            <Text>品番</Text>
            <Text>{instruction.product_num}</Text>
          </Flex>
          <Flex justify="space-between" align="center">
            <Text>品名</Text>
            <Text>{instruction.product_name}</Text>
          </Flex>
          <Flex justify="space-between" align="center">
            <Text>設変No.</Text>
            <Text>{instruction.setting_change_num}</Text>
          </Flex>
        </Stack>
      </GridItem>
      <GridItem rowSpan={6} colStart={4} colEnd={9} />
      <GridItem rowSpan={6} colStart={10} colEnd={13}>
        <Flex justify="space-between" align="center" h="40px">
          <Text mt={2}>指示日</Text>
          <Text>{instruction.registered_date}</Text>
        </Flex>
        <Flex justify="space-between" align="center">
          <Text mt={2}>
            受注日
            <Box as="span" color="red">
              *
            </Box>
          </Text>
          <PrimaryInput
            type="date"
            min="2000-01-01"
            max="2050-01-01"
            value={valueOrderedDate}
            onChange={(e) => onChangeOrderedDate(e)}
            width="165px"
            disabled={isDisabled}
          />
        </Flex>
        <Flex justify="space-between" align="center">
          <Text mt={2}>
            営業希望納期
            <Box as="span" color="red">
              *
            </Box>
          </Text>
          <PrimaryInput
            type="date"
            min="2000-01-01"
            max="2050-01-01"
            value={valueDueDate}
            onChange={(e) => onChangeDueDate(e)}
            width="165px"
            disabled={isDisabled}
          />
        </Flex>
        <Flex justify="space-between" align="center" h="40px">
          <Text>営業担当者</Text>
          <Text>{instruction.assigner}</Text>
        </Flex>
        <Flex justify="space-between" align="center">
          <Text>
            指示書作成者
            <Box as="span" color="red">
              *
            </Box>
          </Text>
          <PrimarySelectBox
            options={members}
            defaultOption={defaultCreator}
            optionElement="name"
            onChangeSelect={onChangeSelectCreator}
            width="165px"
            onFocus={() =>
              defaultCreator &&
              message({
                title: '重要情報です。編集の必要性を確認してください',
                status: 'warning',
                position: 'top',
                duration: 5000,
              })
            }
            disabled={isDisabled}
          />
        </Flex>
      </GridItem>
      <GridItem rowSpan={1} colSpan={3} align="right">
        <Flex justify="space-between" align="center" h="40px">
          <Text>受注数量</Text>
          <Text>{instruction.lot_amount}</Text>
        </Flex>
      </GridItem>
      <GridItem rowSpan={1} colStart={4} colEnd={7}>
        <Flex justify="space-between" align="center">
          <Text ml="60px">在庫引当数</Text>
          <PrimaryNumberInput
            value={valueStockCount}
            minValue={0}
            maxValue={100000}
            onChange={(num) => onChangeStockCount(num)}
            width="165px"
            disabled={isDisabled}
          />
        </Flex>
      </GridItem>
      <GridItem rowSpan={1} colStart={7} colEnd={9}>
        <Flex justify="space-between" align="center" h="40px">
          <Text ml="60px">制作数量</Text>
          <Text>{instruction.lot_amount - valueStockCount}</Text>
        </Flex>
      </GridItem>
      <GridItem rowSpan={1} colStart={10} colEnd={12} />
      <GridItem rowSpan={4} colSpan={3} align="right">
        <Flex justify="space-between" align="center">
          <Text>材質</Text>
          <Flex justify="space-between" align="center" direction="column">
            {console.log(materials)}
            {materials &&
              materials.map((material, index) => {
                return (
                  <Flex justify="space-between" key={index}>
                    <Text mr="10px">{material.material_name}：</Text>
                    <RadioGroup
                      onChange={(e) => onChangeIsProvided(e, index)}
                      value={material.is_provided.toString()}
                      isDisabled={isDisabled}
                    >
                      <Stack direction="row" spacing={4}>
                        <Radio value="true">支給</Radio>
                        <Radio value="false">手配</Radio>
                      </Stack>
                    </RadioGroup>
                  </Flex>
                )
              })}
          </Flex>
        </Flex>
      </GridItem>
      <GridItem rowSpan={4} colStart={4} colEnd={9}>
        <Flex justify="space-between" align="center">
          <Text ml="60px">備考欄</Text>
          <Textarea
            placeholder="備考欄"
            value={valueNoteForInstruction}
            onChange={(e) => onChangeNoteForInstruction(e)}
            h="120px"
            w="405px"
            size="sm"
            bg="white"
            _focus={{ boxShadow: 'none' }}
            disabled={isDisabled}
          />
        </Flex>
      </GridItem>
    </Grid>
  )
}
