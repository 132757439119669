import React from 'react'
import { Navigate } from 'react-router'
import { useMessage } from '../hooks/useMessage'
import { useCurrentUser2 } from '../hooks/users/useCurrentUser2'

export const UserValidationAdmin = ({ children }) => {
  const user = useCurrentUser2()
  const { message } = useMessage()
  return (
    <>
      {user.access_level !== 'admin' ? (
        <>
          {message({
            title: 'ページへのアクセス権限がありません。',
            status: 'warning',
            position: 'top',
            duration: 2000,
          })}
          <Navigate to="/menu" />
        </>
      ) : (
        children
      )}
    </>
  )
}
