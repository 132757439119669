import React from 'react'
import { Td, IconButton, Text, Flex, Switch } from '@chakra-ui/react'
import { PrimaryInput } from '../../atoms/PrimaryInput'
import { PrimaryNumberInput } from '../../atoms/PrimaryNumberInput'
import { AiOutlineDelete, AiOutlinePlus } from 'react-icons/ai'
import { PrimarySelectBox } from '../../atoms/PrimarySelectBox'
import { factories } from '../../../constants/MasterInjectHelper'

export const TableBodyOutsourceCreated = (props) => {
  const {
    type,
    valueOrderedNum,
    valueProductNum,
    valueProductName,
    valueCount,
    valueUnitPrice,
    valueDueDate,
    valueCarriedIn,
    onChangeOrderedNum,
    onChangeProductNum,
    onChanceProductName,
    onChangeCount,
    onChangeUnitPrice,
    onChangeDueDate,
    onChangeCarriedIn,
    onClickAddProduct,
    onClickDeleteCreatedProduct,
    isDisabled,
    disabledAddButton,
  } = props

  return (
    <>
      <Td>
        <PrimaryInput
          placeholder=""
          disabled={isDisabled}
          value={valueOrderedNum}
          onChange={(e) => onChangeOrderedNum(e)}
          htmlSize={16}
        />
      </Td>
      <Td>
        <PrimaryInput
          placeholder=""
          disabled={isDisabled}
          value={valueProductNum}
          onChange={(e) => onChangeProductNum(e)}
          htmlSize={16}
        />
      </Td>
      <Td>
        <PrimaryInput
          placeholder=""
          disabled={isDisabled}
          value={valueProductName}
          onChange={(e) => onChanceProductName(e)}
          htmlSize={16}
        />
      </Td>
      <Td>
        <PrimaryNumberInput
          value={valueCount}
          minValue={0}
          maxValue={100000}
          onChange={(num) => onChangeCount(num)}
          width="120px"
          disabled={isDisabled}
        />
      </Td>
      <Td>
        <PrimaryNumberInput
          value={valueUnitPrice.toLocaleString()}
          minValue={0}
          onChange={(num) => onChangeUnitPrice(num)}
          width="120px"
          disabled={isDisabled}
        />
      </Td>
      <Td>
        <Text opacity={isDisabled && 0.3}>
          ¥{(valueCount * valueUnitPrice || 0).toLocaleString()}
        </Text>
      </Td>
      <Td>
        <PrimaryInput
          type="date"
          min="2000-01-01"
          max="2050-01-01"
          value={valueDueDate}
          onChange={(e) => onChangeDueDate(e)}
          width="145px"
          disabled={isDisabled}
        />
      </Td>
      <Td>
        <PrimarySelectBox
          options={factories}
          defaultOption={valueCarriedIn}
          optionElement="name"
          onChangeSelect={onChangeCarriedIn}
          width="145px"
          disabled={isDisabled}
        />
      </Td>
      <Td>
        {type == 'adding' ? (
          <IconButton
            aria-label="add"
            disabled={isDisabled || disabledAddButton}
            onClick={onClickAddProduct}
            icon={<AiOutlinePlus />}
          />
        ) : (
          <IconButton
            aria-label="delete"
            disabled={isDisabled}
            onClick={onClickDeleteCreatedProduct}
            icon={<AiOutlineDelete />}
          />
        )}
      </Td>
    </>
  )
}
