import { useEffect, useState } from 'react'

export const useBlockBrowserBack = (num) => {
  const [isBrowserBack, setIsBrowserBack] = useState(false)

  useEffect(() => {
    window.history.pushState(null, '', window.location.href)
    window.addEventListener('popstate', blockBrowserBack)
    return () => {
      window.removeEventListener('popstate', blockBrowserBack)
    }
  }, [window.history])

  const blockBrowserBack = () => {
    setIsBrowserBack(true)
  }

  return { isBrowserBack, setIsBrowserBack }
}
