import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { useMessage } from '../../../hooks/useMessage'
import { BeatLoader } from 'react-spinners'
import {
  Box,
  Flex,
  HStack,
  Heading,
  Radio,
  RadioGroup,
  Stack,
  Text,
  Textarea,
} from '@chakra-ui/react'
import {
  clockingInAndOut,
  postInspectionReport,
  targetInspectionReport,
} from '../../../urls'
import { StringSelectBox } from '../../atoms/StringSelectBox'
import { AlertModal } from '../../organisms/AlertModal'
import { useCurrentUser2 } from '../../../hooks/users/useCurrentUser2'
import { useLogout2 } from '../../../hooks/users/useLogout2'
import { useGetData } from '../../../hooks/useGetData'
import { PrimaryNumberInput } from '../../atoms/PrimaryNumberInput'
import { PrimaryInput } from '../../atoms/PrimaryInput'
import { PrimarySelectBox } from '../../atoms/PrimarySelectBox'
import axios from 'axios'
import { useBlockBrowserBack } from '../../../hooks/users/useBlockBrowserBack'
import { AlertModalWithoutButton } from '../../organisms/AlertModalWithoutButton'
import { DigitalClock } from '../../atoms/DigitalClock'

export const Inspection = () => {
  const location = useLocation()
  const { logout } = useLogout2()
  const { message } = useMessage()
  const navigate = useNavigate()
  const user = useCurrentUser2()
    const { isBrowserBack, setIsBrowserBack } = useBlockBrowserBack()
  const [isLoadingAction, setIsLoadingAction] = useState(false)
  const [isUpdate, setIsUpdate] = useState(false)
  const { getProducts, productList, getMasters, masterList, isLoading } =
    useGetData()
  const today = new Date()

  const initInspectionReport = {
    is_outsource: false,
    inspection_record_id: '',
    inspection_date: '',
    start_at: '',
    finish_at: '',
    product_number: '',
    product_name: '',
    product_name_reference: '',
    molding_lot_num: '',
    plating_lot_num: '',
    painting_lot_num: '',
    note: '',
    registered_by: user ? user.name : '',
    approved_by: '',
    inspection_count: 0,
    dirt_count: 0,
    scratch_count: 0,
    silver_count: 0,
    bump_count: 0,
    other_defect_count: 0,
    plating_scratch_count: 0,
    plating_bump_count: 0,
    plating_burn_count: 0,
    plating_peel_off_count: 0,
    plating_float_count: 0,
    plating_color_unevenness_count: 0,
  }
  // const [addProcessReport, setAddProcessReport] = useState(initAddProcessReport)
  const [isSucceeded, setIsSucceeded] = useState(false)
  const [inspectionReport, setInspectionReport] = useState(initInspectionReport)
  const [isOutsource, setIsOutsource] = useState(false)
    const [searchParams] = useSearchParams()
    const id = searchParams.get('id')

  useEffect(() => {
    if (user.is_working) {
      const getData = async () => {
        if (id) {
          const report = await axios.get(targetInspectionReport(id))
          setInspectionReport(report.data)
          setIsUpdate(true)
        }
        await getProducts()
        getMasters()
      }
      getData()
    }
  }, [])

  const params = {
    method: isUpdate ? 'PUT' : 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(inspectionReport),
  }
  const handleClickClockOut = () => {
    const postParams = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        working_hour_id: '',
        work_start_time: '',
        work_end_time: '',
        user_id: user.user_id,
        work_place: '',
        lot_processes: '',
      }),
    }
    setIsLoadingAction(true)
    fetch(clockingInAndOut, postParams)
      .then((res) => res.json())
      .then((myjson) => {
        console.log(myjson)
        message({
          title: '退勤を記録しました。',
          status: 'success',
        })
        logout()
      })
      .catch(() =>
        message({ title: '通信エラーが発生しました', status: 'warning' })
      )
      .finally(() => {
        setIsLoadingAction(false)
      })
  }

  const handleClickUpdateReport = () => {
    setIsLoadingAction(true)
    fetch(targetInspectionReport(id), params)
      .then((res) => res.json())
      .then((myjson) => {
        message({ title: '日報の更新に成功しました。', status: 'success' })
        console.log(myjson)
        setIsSucceeded(true)
        // navigate('/daily_report/index')
      })
      .catch(() => {
        message({ title: '通信エラーが発生しました', status: 'warning' })
      })
      .finally(() => {
        setIsLoadingAction(false)
      })
  }

  const handleClickRegisterReport = () => {
    setIsLoadingAction(true)
    fetch(postInspectionReport, params)
      .then((res) => res.json())
      .then((myjson) => {
        message({ title: '日報の記録に成功しました。', status: 'success' })
        console.log(myjson)
        setIsSucceeded(true)
        // navigate('/daily_report/index')
      })
      .catch(() => {
        message({ title: '通信エラーが発生しました', status: 'warning' })
      })
      .finally(() => {
        setIsLoadingAction(false)
      })
  }

  useEffect(() => {
    setInspectionReport({
      ...inspectionReport,
      non_defective_count:
        Number(inspectionReport.inspection_count) -
        (Number(inspectionReport.dirt_count) +
          Number(inspectionReport.scratch_count) +
          Number(inspectionReport.silver_count) +
          Number(inspectionReport.bump_count) +
          Number(inspectionReport.other_defect_count) +
          Number(inspectionReport.plating_scratch_count) +
          Number(inspectionReport.plating_bump_count) +
          Number(inspectionReport.plating_burn_count) +
          Number(inspectionReport.plating_peel_off_count) +
          Number(inspectionReport.plating_float_count) +
          Number(inspectionReport.plating_color_unevenness_count)),
      defective_count:
        Number(inspectionReport.dirt_count) +
        Number(inspectionReport.scratch_count) +
        Number(inspectionReport.silver_count) +
        Number(inspectionReport.bump_count) +
        Number(inspectionReport.other_defect_count) +
        Number(inspectionReport.plating_scratch_count) +
        Number(inspectionReport.plating_bump_count) +
        Number(inspectionReport.plating_burn_count) +
        Number(inspectionReport.plating_peel_off_count) +
        Number(inspectionReport.plating_float_count) +
        Number(inspectionReport.plating_color_unevenness_count),
    })
  }, [
    inspectionReport.inspection_count,
    inspectionReport.dirt_count,
    inspectionReport.scratch_count,
    inspectionReport.silver_count,
    inspectionReport.bump_count,
    inspectionReport.other_defect_count,
    inspectionReport.plating_scratch_count,
    inspectionReport.plating_bump_count,
    inspectionReport.plating_burn_count,
    inspectionReport.plating_peel_off_count,
    inspectionReport.plating_float_count,
    inspectionReport.plating_color_unevenness_count,
  ])

  return (
    <Box m={2}>
      {isLoading ? (
        <Flex h="100vh" alignItems="center" justifyContent="center" mt={-8}>
          <BeatLoader size={30} color={'#3b5998'} />
        </Flex>
      ) : (
        <>
          <DigitalClock />
          <Flex justify="space-between" align="flex-end">
            <Text w="200px" visibility="hidden" />
            <Heading textAlign="center" mb={10}>
              作業日報
            </Heading>
            <Flex textAlign="center" border="1px">
              <Box w="150px" borderRight="1px">
                <Text borderBottom="1px">確認</Text>
                <PrimarySelectBox
                  options={masterList.users}
                  defaultOption={inspectionReport.approved_by}
                  optionElement="name"
                  onChangeSelect={(index) =>
                    setInspectionReport({
                      ...inspectionReport,
                      approved_by: masterList.users[index]['name'],
                    })
                  }
                  width="149px"
                  disabled={isLoadingAction}
                />
              </Box>
              <Box w="150px">
                <Text borderBottom="1px">検査員</Text>
                <PrimarySelectBox
                  options={masterList.users}
                  defaultOption={inspectionReport.registered_by}
                  optionElement="name"
                  onChangeSelect={(index) =>
                    setInspectionReport({
                      ...inspectionReport,
                      registered_by: masterList.users[index]['name'],
                    })
                  }
                  width="149px"
                  disabled={isLoadingAction}
                />
              </Box>
            </Flex>
          </Flex>
          <Box mt={10} border="1px">
            <RadioGroup
              onChange={(value) => {
                setInspectionReport({
                  ...inspectionReport,
                  is_outsource: JSON.parse(value),
                  product_number: '',
                  product_name: '',
                  molding_lot_num: '',
                  plating_lot_num: '',
                  painting_lot_num: '',
                })
              }}
              value={inspectionReport.is_outsource}
              borderBottom="1px"
            >
              <Stack direction="row">
                <Radio value={false} m={4}>
                  内製
                </Radio>
                <Radio value={true} m={4}>
                  外注
                </Radio>
              </Stack>
            </RadioGroup>
            <Flex textAlign="center" align="center" borderBottom="1px">
              <Text w="50px" h="50px" lineHeight="50px" borderRight="1px">
                1
              </Text>
              <Text w="200px" h="50px" lineHeight="50px" borderRight="1px">
                検査日
                <Box as="span" color="red">
                  *
                </Box>
              </Text>
              <Box flexGrow={1} px={1}>
                <PrimaryInput
                  type="date"
                  min="2000-01-01"
                  max="2050-01-01"
                  value={inspectionReport.inspection_date}
                  onChange={(e) =>
                    setInspectionReport({
                      ...inspectionReport,
                      inspection_date: e.target.value,
                    })
                  }
                  width="100%"
                  disabled={isLoadingAction}
                />
              </Box>
            </Flex>
            <Flex textAlign="center" align="center" borderBottom="1px">
              <Text w="50px" h="50px" lineHeight="50px" borderRight="1px">
                2
              </Text>
              <Text w="200px" h="50px" lineHeight="50px" borderRight="1px">
                作業時間
                <Box as="span" color="red">
                  *
                </Box>
              </Text>
              <Box flexGrow={1} px={1}>
                <Flex align="center">
                  <PrimaryInput
                    type="time"
                    min="00:00"
                    max="23:59"
                    value={inspectionReport.start_at}
                    onChange={(e) =>
                      setInspectionReport({
                        ...inspectionReport,
                        start_at: e.target.value,
                      })
                    }
                    flexGrow={1}
                    disabled={isLoadingAction}
                  />
                  <Text w="50px">~</Text>
                  <PrimaryInput
                    type="time"
                    min="00:00"
                    max="23:59"
                    value={inspectionReport.finish_at}
                    onChange={(e) =>
                      setInspectionReport({
                        ...inspectionReport,
                        finish_at: e.target.value,
                      })
                    }
                    flexGrow={1}
                    disabled={isLoadingAction}
                  />
                </Flex>
              </Box>
            </Flex>
            <Flex textAlign="center" align="center" borderBottom="1px">
              <Text w="50px" h="50px" lineHeight="50px" borderRight="1px">
                3
              </Text>
              <Text w="200px" h="50px" lineHeight="50px" borderRight="1px">
                品番
                <Box as="span" color="red">
                  *
                </Box>
              </Text>
              <Box flexGrow={1} px={1}>
                {isOutsource ? (
                  <PrimaryInput
                    value={inspectionReport.product_number}
                    onChange={(e) =>
                      setInspectionReport({
                        ...inspectionReport,
                        product_number: e.target.value,
                      })
                    }
                    width="100%"
                    disabled={isLoadingAction}
                  />
                ) : (
                  <PrimarySelectBox
                    options={productList}
                    defaultOption={inspectionReport.product_number}
                    optionElement="product_number"
                    onChangeSelect={(index) =>
                      setInspectionReport({
                        ...inspectionReport,
                        product_number: productList[index]['product_number'],
                        product_name: productList[index]['product_name'],
                      })
                    }
                    width="100%"
                    disabled={isLoadingAction}
                  />
                )}
              </Box>
            </Flex>
            <Flex textAlign="center" align="center" borderBottom="1px">
              <Text w="50px" h="50px" lineHeight="50px" borderRight="1px">
                4
              </Text>
              <Text w="200px" h="50px" lineHeight="50px" borderRight="1px">
                品名
              </Text>
              <Box flexGrow={1} px={1}>
                <PrimaryInput
                  value={inspectionReport.product_name}
                  onChange={(e) =>
                    setInspectionReport({
                      ...inspectionReport,
                      product_name: e.target.value,
                    })
                  }
                  width="100%"
                  disabled={!isOutsource || isLoadingAction}
                />
              </Box>
              <Box flexGrow={1} px={1}>
                <PrimaryInput
                  value={inspectionReport.product_name_reference}
                  onChange={(e) =>
                    setInspectionReport({
                      ...inspectionReport,
                      product_name_reference: e.target.value,
                    })
                  }
                  width="100%"
                  disabled={isLoadingAction}
                />
              </Box>
            </Flex>
            <Flex textAlign="center" align="center" borderBottom="1px">
              <Text w="50px" h="50px" lineHeight="50px" borderRight="1px">
                5
              </Text>
              <Text w="200px" h="50px" lineHeight="50px" borderRight="1px">
                成形ロット
              </Text>
              <Box flexGrow={1} px={1}>
                {isOutsource ? (
                  <PrimaryInput
                    value={inspectionReport.molding_lot_num}
                    onChange={(e) =>
                      setInspectionReport({
                        ...inspectionReport,
                        molding_lot_num: e.target.value,
                      })
                    }
                    width="100%"
                    disabled={isLoadingAction}
                  />
                ) : (
                  // <PrimarySelectBox
                  //   options={masterList.lots}
                  //   defaultOption={inspectionReport.molding_lot_num}
                  //   optionElement=""
                  //   onChangeSelect={(index) =>
                  //     setInspectionReport({
                  //       ...inspectionReport,
                  //       molding_lot_num: masterList.lots[index]['name'],
                  //     })
                  //   }
                  //   width="100%"
                  //   disabled={isLoadingAction}
                  // />
                  <StringSelectBox
                    options={masterList.lots}
                    defaultOption={inspectionReport.molding_lot_num}
                    onChangeSelect={(value) =>
                      setInspectionReport({
                        ...inspectionReport,
                        molding_lot_num: value,
                      })
                    }
                    width="100%"
                    disabled={isLoadingAction}
                  />
                )}
              </Box>
            </Flex>
            <Flex textAlign="center" align="center" borderBottom="1px">
              <Text w="50px" h="50px" lineHeight="50px" borderRight="1px">
                6
              </Text>
              <Text w="200px" h="50px" lineHeight="50px" borderRight="1px">
                メッキロット
              </Text>
              <Box flexGrow={1} px={1}>
                {isOutsource ? (
                  <PrimaryInput
                    value={inspectionReport.plating_lot_num}
                    onChange={(e) =>
                      setInspectionReport({
                        ...inspectionReport,
                        plating_lot_num: e.target.value,
                      })
                    }
                    width="100%"
                    disabled={isLoadingAction}
                  />
                ) : (
                  // <PrimarySelectBox
                  //   options={masterList.users}
                  //   defaultOption={inspectionReport.plating_lot_num}
                  //   optionElement="name"
                  //   onChangeSelect={(index) =>
                  //     setInspectionReport({
                  //       ...inspectionReport,
                  //       plating_lot_num: masterList.users[index]['name'],
                  //     })
                  //   }
                  //   width="100%"
                  //   disabled={isLoadingAction}
                  // />
                  <StringSelectBox
                    options={masterList.lots}
                    defaultOption={inspectionReport.plating_lot_num}
                    onChangeSelect={(value) =>
                      setInspectionReport({
                        ...inspectionReport,
                        plating_lot_num: value,
                      })
                    }
                    width="100%"
                    disabled={isLoadingAction}
                  />
                )}
              </Box>
            </Flex>
            <Flex textAlign="center" align="center" borderBottom="1px">
              <Text w="50px" h="50px" lineHeight="50px" borderRight="1px">
                7
              </Text>
              <Text w="200px" h="50px" lineHeight="50px" borderRight="1px">
                塗装ロット
              </Text>
              <Box flexGrow={1} px={1}>
                {isOutsource ? (
                  <PrimaryInput
                    value={inspectionReport.painting_lot_num}
                    onChange={(e) =>
                      setInspectionReport({
                        ...inspectionReport,
                        painting_lot_num: e.target.value,
                      })
                    }
                    width="100%"
                    disabled={isLoadingAction}
                  />
                ) : (
                  // <PrimarySelectBox
                  //   options={masterList.users}
                  //   defaultOption={inspectionReport.painting_lot_num}
                  //   optionElement="name"
                  //   onChangeSelect={(index) =>
                  //     setInspectionReport({
                  //       ...inspectionReport,
                  //       painting_lot_num: masterList.users[index]['name'],
                  //     })
                  //   }
                  //   width="100%"
                  //   disabled={isLoadingAction}
                  // />
                  <StringSelectBox
                    options={masterList.lots}
                    defaultOption={inspectionReport.painting_lot_num}
                    onChangeSelect={(value) =>
                      setInspectionReport({
                        ...inspectionReport,
                        painting_lot_num: value,
                      })
                    }
                    width="100%"
                    disabled={isLoadingAction}
                  />
                )}
              </Box>
            </Flex>
            <Flex textAlign="center" align="center" borderBottom="1px">
              <Text w="50px" h="50px" lineHeight="50px" borderRight="1px">
                8
              </Text>
              <Text w="200px" h="50px" lineHeight="50px" borderRight="1px">
                検査数
              </Text>
              <Box flexGrow={1} px={1}>
                <PrimaryNumberInput
                  value={inspectionReport.inspection_count}
                  minValue={0}
                  maxValue={100000}
                  onChange={(num) =>
                    setInspectionReport({
                      ...inspectionReport,
                      inspection_count: num,
                    })
                  }
                  width="100%"
                  disabled={isLoadingAction}
                />
              </Box>
            </Flex>
            <Flex borderBottom="1px">
              <Flex
                textAlign="center"
                align="center"
                borderRight="1px"
                flexGrow={1}
              >
                <Text
                  w="50px"
                  minWidth="50px"
                  h="305px"
                  lineHeight="305px"
                  borderRight="1px"
                >
                  9
                </Text>
                <Flex direction="column" flexGrow={1}>
                  <Flex textAlign="center" align="center" borderBottom="1px">
                    <Text
                      w="50px"
                      minWidth="50px"
                      h="50px"
                      lineHeight="50px"
                      borderRight="1px"
                    >
                      A
                    </Text>
                    <Text
                      w="150px"
                      minWidth="150px"
                      h="50px"
                      lineHeight="50px"
                      borderRight="1px"
                    >
                      汚れ
                    </Text>
                    <Box flexGrow={1} px={1}>
                      <PrimaryNumberInput
                        value={inspectionReport.dirt_count}
                        minValue={0}
                        maxValue={100000}
                        onChange={(num) =>
                          setInspectionReport({
                            ...inspectionReport,
                            dirt_count: num,
                          })
                        }
                        width="100%"
                        disabled={isLoadingAction}
                      />
                    </Box>
                  </Flex>
                  <Flex textAlign="center" align="center" borderBottom="1px">
                    <Text
                      w="50px"
                      minWidth="50px"
                      h="50px"
                      lineHeight="50px"
                      borderRight="1px"
                    >
                      B
                    </Text>
                    <Text
                      w="150px"
                      minWidth="150px"
                      h="50px"
                      lineHeight="50px"
                      borderRight="1px"
                    >
                      キズ
                    </Text>
                    <Box flexGrow={1} px={1}>
                      <PrimaryNumberInput
                        value={inspectionReport.scratch_count}
                        minValue={0}
                        maxValue={100000}
                        onChange={(num) =>
                          setInspectionReport({
                            ...inspectionReport,
                            scratch_count: num,
                          })
                        }
                        width="100%"
                        disabled={isLoadingAction}
                      />
                    </Box>
                  </Flex>
                  <Flex textAlign="center" align="center" borderBottom="1px">
                    <Text
                      w="50px"
                      minWidth="50px"
                      h="50px"
                      lineHeight="50px"
                      borderRight="1px"
                    >
                      C
                    </Text>
                    <Text
                      w="150px"
                      minWidth="150px"
                      h="50px"
                      lineHeight="50px"
                      borderRight="1px"
                    >
                      シルバー
                    </Text>
                    <Box flexGrow={1} px={1}>
                      <PrimaryNumberInput
                        value={inspectionReport.silver_count}
                        minValue={0}
                        maxValue={100000}
                        onChange={(num) =>
                          setInspectionReport({
                            ...inspectionReport,
                            silver_count: num,
                          })
                        }
                        width="100%"
                        disabled={isLoadingAction}
                      />
                    </Box>
                  </Flex>
                  <Flex textAlign="center" align="center" borderBottom="1px">
                    <Text
                      w="50px"
                      minWidth="50px"
                      h="50px"
                      lineHeight="50px"
                      borderRight="1px"
                    >
                      D
                    </Text>
                    <Text
                      w="150px"
                      minWidth="150px"
                      h="50px"
                      lineHeight="50px"
                      borderRight="1px"
                    >
                      ブツ
                    </Text>
                    <Box flexGrow={1} px={1}>
                      <PrimaryNumberInput
                        value={inspectionReport.bump_count}
                        minValue={0}
                        maxValue={100000}
                        onChange={(num) =>
                          setInspectionReport({
                            ...inspectionReport,
                            bump_count: num,
                          })
                        }
                        width="100%"
                        disabled={isLoadingAction}
                      />
                    </Box>
                  </Flex>
                  <Flex textAlign="center" align="center" borderBottom="1px">
                    <Text
                      w="50px"
                      minWidth="50px"
                      h="50px"
                      lineHeight="50px"
                      borderRight="1px"
                    >
                      E
                    </Text>
                    <Text
                      w="150px"
                      minWidth="150px"
                      h="50px"
                      lineHeight="50px"
                      borderRight="1px"
                    />
                    <Box flexGrow={1} px={1} />
                  </Flex>
                  <Flex textAlign="center" align="center">
                    <Text
                      w="50px"
                      minWidth="50px"
                      h="50px"
                      lineHeight="50px"
                      borderRight="1px"
                    >
                      F
                    </Text>
                    <Text
                      w="150px"
                      minWidth="150px"
                      h="50px"
                      lineHeight="50px"
                      borderRight="1px"
                    >
                      その他
                    </Text>
                    <Box flexGrow={1} px={1}>
                      <PrimaryNumberInput
                        value={inspectionReport.other_defect_count}
                        minValue={0}
                        maxValue={100000}
                        onChange={(num) =>
                          setInspectionReport({
                            ...inspectionReport,
                            other_defect_count: num,
                          })
                        }
                        width="100%"
                        disabled={isLoadingAction}
                      />
                    </Box>
                  </Flex>
                </Flex>
              </Flex>
              <Flex textAlign="center" align="center" flexGrow={1}>
                <Text
                  w="50px"
                  minWidth="50px"
                  px="10px"
                  pt="100px"
                  h="305px"
                  borderRight="1px"
                >
                  メッキ品
                </Text>
                <Flex direction="column" flexGrow={1}>
                  <Flex textAlign="center" align="center" borderBottom="1px">
                    <Text
                      w="150px"
                      minWidth="150px"
                      h="50px"
                      lineHeight="50px"
                      borderRight="1px"
                    >
                      キズ
                    </Text>
                    <Box flexGrow={1} px={1}>
                      <PrimaryNumberInput
                        value={inspectionReport.plating_scratch_count}
                        minValue={0}
                        maxValue={100000}
                        onChange={(num) =>
                          setInspectionReport({
                            ...inspectionReport,
                            plating_scratch_count: num,
                          })
                        }
                        width="100%"
                        disabled={isLoadingAction}
                      />
                    </Box>
                  </Flex>
                  <Flex textAlign="center" align="center" borderBottom="1px">
                    <Text
                      w="150px"
                      minWidth="150px"
                      h="50px"
                      lineHeight="50px"
                      borderRight="1px"
                    >
                      ブツ
                    </Text>
                    <Box flexGrow={1} px={1}>
                      <PrimaryNumberInput
                        value={inspectionReport.plating_bump_count}
                        minValue={0}
                        maxValue={100000}
                        onChange={(num) =>
                          setInspectionReport({
                            ...inspectionReport,
                            plating_bump_count: num,
                          })
                        }
                        width="100%"
                        disabled={isLoadingAction}
                      />
                    </Box>
                  </Flex>
                  <Flex textAlign="center" align="center" borderBottom="1px">
                    <Text
                      w="150px"
                      minWidth="150px"
                      h="50px"
                      lineHeight="50px"
                      borderRight="1px"
                    >
                      ヤケ
                    </Text>
                    <Box flexGrow={1} px={1}>
                      <PrimaryNumberInput
                        value={inspectionReport.plating_burn_count}
                        minValue={0}
                        maxValue={100000}
                        onChange={(num) =>
                          setInspectionReport({
                            ...inspectionReport,
                            plating_burn_count: num,
                          })
                        }
                        width="100%"
                        disabled={isLoadingAction}
                      />
                    </Box>
                  </Flex>
                  <Flex textAlign="center" align="center" borderBottom="1px">
                    <Text
                      w="150px"
                      minWidth="150px"
                      h="50px"
                      lineHeight="50px"
                      borderRight="1px"
                    >
                      剥げ
                    </Text>
                    <Box flexGrow={1} px={1}>
                      <PrimaryNumberInput
                        value={inspectionReport.plating_peel_off_count}
                        minValue={0}
                        maxValue={100000}
                        onChange={(num) =>
                          setInspectionReport({
                            ...inspectionReport,
                            plating_peel_off_count: num,
                          })
                        }
                        width="100%"
                        disabled={isLoadingAction}
                      />
                    </Box>
                  </Flex>
                  <Flex textAlign="center" align="center" borderBottom="1px">
                    <Text
                      w="150px"
                      minWidth="150px"
                      h="50px"
                      lineHeight="50px"
                      borderRight="1px"
                    >
                      浮き
                    </Text>
                    <Box flexGrow={1} px={1}>
                      <PrimaryNumberInput
                        value={inspectionReport.plating_float_count}
                        minValue={0}
                        maxValue={100000}
                        onChange={(num) =>
                          setInspectionReport({
                            ...inspectionReport,
                            plating_float_count: num,
                          })
                        }
                        width="100%"
                        disabled={isLoadingAction}
                      />
                    </Box>
                  </Flex>
                  <Flex textAlign="center" align="center">
                    <Text
                      w="150px"
                      minWidth="150px"
                      h="50px"
                      lineHeight="50px"
                      borderRight="1px"
                    >
                      色ムラ
                    </Text>
                    <Box flexGrow={1} px={1}>
                      <PrimaryNumberInput
                        value={inspectionReport.plating_color_unevenness_count}
                        minValue={0}
                        maxValue={100000}
                        onChange={(num) =>
                          setInspectionReport({
                            ...inspectionReport,
                            plating_color_unevenness_count: num,
                          })
                        }
                        width="100%"
                        disabled={isLoadingAction}
                      />
                    </Box>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
            <Flex textAlign="center" align="center" borderBottom="1px">
              <Text w="50px" h="50px" lineHeight="50px" borderRight="1px">
                9
              </Text>
              <Text w="200px" h="50px" lineHeight="50px" borderRight="1px">
                不良数
              </Text>
              <Text ml={5} h="50px" lineHeight="50px">
                {inspectionReport.defective_count}
              </Text>
            </Flex>
            <Flex textAlign="center" align="center" borderBottom="1px">
              <Text w="50px" h="50px" lineHeight="50px" borderRight="1px">
                10
              </Text>
              <Text w="200px" h="50px" lineHeight="50px" borderRight="1px">
                良品数
              </Text>
              <Text ml={5} h="50px" lineHeight="50px">
                {inspectionReport.non_defective_count}
              </Text>
            </Flex>
            <Flex textAlign="center">
              <Text
                w="303px"
                minWidth="250px"
                h="100px"
                lineHeight="100px"
                borderRight="1px"
              >
                備考
              </Text>
              <Textarea
                onChange={(e) =>
                  setInspectionReport({
                    ...inspectionReport,
                    note: e.target.value,
                  })
                }
                value={inspectionReport.note}
                h="100px"
                flexGrow={1}
                bg="white"
                _focus={{ boxShadow: 'none' }}
                isDisabled={isLoadingAction}
              />
            </Flex>
          </Box>

          <Flex justifyContent="center" alignItems="center" my={10}>
            <HStack>
              <AlertModal
                buttonMessage="キャンセル"
                buttonLeft="日報登録に戻る"
                buttonRight="ログアウト"
                title="キャンセル"
                colorScheme="blackAlpha"
                body="日報登録を中断してログアウトしますか？"
                onClick={() => logout()}
                onClickCancel={() => navigate('/daily_report/mass_produce')}
                disabled={isLoadingAction}
              />
              <AlertModal
                buttonMessage={isUpdate ? '更新' : '記録'}
                title={isUpdate ? '更新' : '記録'}
                body={`この内容で日報を${isUpdate ? '更新' : '記録'}しますか？`}
                onClick={
                  isUpdate ? handleClickUpdateReport : handleClickRegisterReport
                }
                disabled={
                  isLoadingAction ||
                  !inspectionReport.product_number ||
                  !inspectionReport.start_at ||
                  !inspectionReport.finish_at ||
                  !inspectionReport.inspection_date
                }
              />
            </HStack>
          </Flex>
          {isSucceeded && (
            <AlertModal
              needButton={false}
              buttonLeft="勤務に戻る"
              buttonRight="退勤"
              title="退勤"
              body="このまま退勤しますか？"
              onClick={() => handleClickClockOut()}
              onClickCancel={() => logout()}
            />
          )}
        </>
      )}
      {isBrowserBack && (
        <AlertModalWithoutButton
          title="戻る"
          body="データを変更した場合、更新ボタンを押さないと編集内容が破棄されますがよろしいですか？"
          onClick={() => window.history.back()}
          onClickCancel={() => {
            window.history.pushState(null, '', window.location.href)
            setIsBrowserBack(false)
          }}
        />
      )}
    </Box>
  )
}
