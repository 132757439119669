import React from 'react'
import {
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Heading,
} from '@chakra-ui/react'
import { TableBodyMachiningPrototype3 } from '../../molecules/lot/TableBodyMachiningPrototype3'

export const TableMachiningPrototype3 = (props) => {
  const {
    id,
    tableName,
    processingList,
    machines,
    onChangeProcessingName,
    onChangeMachineName,
    onChangeTakeCount,
    onChangeManHours,
    onChangeMachiningUnit,
    onChangeWageRate,
    onChangeMinWageRate,
    onClickDeleteMachining,
    addProductInfo,
    onChangeProcessingNameAdd,
    onChangeMachineNameAdd,
    onChangeManHoursAdd,
    onChangeMachiningUnitAdd,
    onChangeWageRateAdd,
    onChangeMinWageRateAdd,
    onChangeTakeCountAdd,
    onClickAddMachining,
    isDisabled,
    disabledAddButton,
  } = props

  return (
    <>
      <Heading as="h4" size="md">
        {tableName}
      </Heading>
      <TableContainer mb={10}>
        <Table variant="striped" colorScheme="linkedin">
          <Thead>
            <Tr>
              <Th>工程名</Th>
              <Th>使用機械</Th>
              <Th>取り数</Th>
              <Th>工数</Th>
              <Th>単位</Th>
              <Th>賃率</Th>
              <Th>賃率(原価)</Th>
              <Th>売単価</Th>
              <Th>仕入単価</Th>
              <Th />
              <Th />
              <Th isNumeric>追加/削除</Th>
            </Tr>
          </Thead>
          <Tbody>
            {processingList.map((processing, index) => {
              return (
                <Tr key={index}>
                  <TableBodyMachiningPrototype3
                    type="existing"
                    machines={machines}
                    processingName={processing.process_name}
                    machineName={processing.which_machine}
                    takeCount={processing.take_count}
                    unit={processing.unit}
                    manHours={processing.process_plan_hours}
                    wageRate={processing.process_plan_wage}
                    minWageRate={processing.process_plan_min_wage}
                    totalSellingPrice={
                      (processing.process_plan_hours *
                        processing.process_plan_wage) /
                        processing.take_count || 0
                    }
                    totalBuyingPrice={
                      (processing.process_plan_hours *
                        processing.process_plan_min_wage) /
                        processing.take_count || 0
                    }
                    onChangeProcessingName={(e) =>
                      onChangeProcessingName(e, id, index)
                    }
                    onChangeMachineName={(e) =>
                      onChangeMachineName(e, id, index)
                    }
                    onChangeTakeCount={(num) =>
                      onChangeTakeCount(num, id, index)
                    }
                    onChangeMachiningUnit={(e) =>
                      onChangeMachiningUnit(e, id, index)
                    }
                    onChangeManHours={(num) => onChangeManHours(num, id, index)}
                    onChangeWageRate={(num) => onChangeWageRate(num, id, index)}
                    onChangeMinWageRate={(num) =>
                      onChangeMinWageRate(num, id, index)
                    }
                    onClickDeleteMachining={() =>
                      onClickDeleteMachining(id, index)
                    }
                    isDisabled={processing.is_delete == true || isDisabled}
                  />
                </Tr>
              )
            })}
            <Tr>
              <TableBodyMachiningPrototype3
                type="adding"
                machines={machines}
                processingName={addProductInfo.processingName}
                machineName={addProductInfo.machineName}
                takeCount={addProductInfo.takeCount}
                unit={addProductInfo.machiningUnit}
                manHours={addProductInfo.manHours}
                wageRate={addProductInfo.wageRate}
                minWageRate={addProductInfo.minWageRate}
                totalSellingPrice={
                  (addProductInfo.manHours * addProductInfo.wageRate) /
                    addProductInfo.takeCount || 0
                }
                totalBuyingPrice={
                  (addProductInfo.manHours * addProductInfo.minWageRate) /
                    addProductInfo.takeCount || 0
                }
                onChangeProcessingName={(e) => onChangeProcessingNameAdd(e, id)}
                onChangeMachineName={(e) => onChangeMachineNameAdd(e, id)}
                onChangeTakeCount={(num) => onChangeTakeCountAdd(num, id)}
                onChangeMachiningUnit={(e) => onChangeMachiningUnitAdd(e, id)}
                onChangeManHours={(num) => onChangeManHoursAdd(num, id)}
                onChangeWageRate={(num) => onChangeWageRateAdd(num, id)}
                onChangeMinWageRate={(num) => onChangeMinWageRateAdd(num, id)}
                onClickAddProduct={() => {
                  onClickAddMachining(id)
                }}
                isDisabled={isDisabled}
                disabledAddButton={disabledAddButton}
              />
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>
    </>
  )
}
