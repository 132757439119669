import React from 'react'
import { Input } from '@chakra-ui/react'

export const PrimaryInput = (props) => {
  const {
    placeholder,
    width = 'auto',
    type,
    value,
    min,
    max,
    maxLength,
    onChange,
    onFocus,
    htmlSize,
    flexGrow,
    isReadOnly=false,
    disabled,
    children,
  } = props
  return (
    <>
      <Input
        placeholder={placeholder}
        type={type}
        value={value}
        min={min}
        max={max}
        maxLength={maxLength}
        onChange={onChange}
        onFocus={onFocus}
        bg="white"
        w={width}
        htmlSize={htmlSize}
        flexGrow={flexGrow}
        _focus={{ boxShadow: 'none' }}
        isReadOnly={isReadOnly}
        disabled={disabled}
      />
      {children}
    </>
  )
}
