import React from 'react'
import { Navigate } from 'react-router'
import { useCurrentUser2 } from '../hooks/users/useCurrentUser2'

export const UserValidation = ({ children }) => {
  const user = useCurrentUser2()
  return (
    <>
      {user.access_level == 'worker' ? (
        <Navigate to="/daily_report" />
      ) : (
        children
      )}
    </>
  )
}
