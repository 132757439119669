import { atom } from 'recoil'

export const initProductInfo = {
  data_type: '',
  users: [
    {
      name: '',
      email: '',
    },
  ],
  machines: [
    '未使用',
    '成形機1号機180T',
    '成形機2号機140T',
    '成形機4号機150T',
    '成形機5号機170T',
    '成形機6号機160T',
    '成形機11号機55T',
    '成形機12号機230T',
    '成形機14号機350T',
    '成形機15号機100T',
    '成形機16号機100T',
    '成形機17号機220T',
    '成形機19号機100T',
    '成形機20号機130T',
    '塗装ロボット',
    '塗装手吹き',
    'パット印刷機',
    'レーザー加工機',
    '印刷機ミノマット1',
    '印刷機ミノマット2',
    '印刷機ミノマット3',
    '印刷機ATMA1',
    '印刷機ATMA2',
    '印刷機ATMA3',
    'プレス機1',
    'プレス機2',
    'マシニング1',
    'マシニング2',
    'マシニング3',
    'マシニング4',
    'マシニング5',
    '旋盤1',
    '旋盤2',
    'ロボドリル',
  ],
  lots: {
    project_id: '',
    lot_num: 0,
    product_num: null,
    product_name: '',
    lot_amount: 0,
    created_amount: 0,
    is_direct_management_profit: false,
    management_profit_rate: 0,
    management_profit: 0,
    buying_delivery_cost: 0,
    selling_delivery_cost: 0,
    note_estimate: '',
    // sketch: null,
    direct_total_cost: 0,
    direct_material_total_cost: 0,
    direct_process_total_cost: 0,
    indirect_total_cost: 0,
    indirect_material_total_cost: 0,
    indirect_process_total_cost: 0,
    overhead_total_cost: 0,
    registered_date: null,
    update_date: '2023-01-01T00:00:00+09:00',
    is_delete: false,
    stamp_type: '',
    direct_material_costs: [],
    direct_process_costs: [],
    indirect_material_costs: [],
    indirect_process_costs: [],
    tool_costs: [],
  },
}

export const productState5 = atom({
  key: 'productState5',
  default: initProductInfo,
})
