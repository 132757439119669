import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { useMessage } from '../../../hooks/useMessage'
import { BeatLoader } from 'react-spinners'
import {
  Box,
  Flex,
  Grid,
  GridItem,
  HStack,
  Heading,
  Text,
  Textarea,
} from '@chakra-ui/react'
import {
  clockingInAndOut,
  postLaserReport,
  targetLaserReport,
} from '../../../urls'
import { StringSelectBox } from '../../atoms/StringSelectBox'
import { AlertModal } from '../../organisms/AlertModal'
import { useCurrentUser2 } from '../../../hooks/users/useCurrentUser2'
import { useLogout2 } from '../../../hooks/users/useLogout2'
import { useGetData } from '../../../hooks/useGetData'
import { PrimaryNumberInput } from '../../atoms/PrimaryNumberInput'
import { PrimaryInput } from '../../atoms/PrimaryInput'
import { PrimarySelectBox } from '../../atoms/PrimarySelectBox'
import axios from 'axios'
import { useBlockBrowserBack } from '../../../hooks/users/useBlockBrowserBack'
import { AlertModalWithoutButton } from '../../organisms/AlertModalWithoutButton'
import { DigitalClock } from '../../atoms/DigitalClock'

export const Laser = () => {
  const location = useLocation()
  const { logout } = useLogout2()
  const { message } = useMessage()
  const navigate = useNavigate()
  const user = useCurrentUser2()
  const { isBrowserBack, setIsBrowserBack } = useBlockBrowserBack()
  const [isLoadingAction, setIsLoadingAction] = useState(false)
  const [isUpdate, setIsUpdate] = useState(false)
  const { getProducts, productList, getMasters, masterList, isLoading } =
    useGetData()

  const initLaserReport = {
    working_date: '',
    lot_num: '',
    product_number: '',
    product_name: '',
    machine_name: '',
    process_name: '',
    note: '',
    confirmation_person: '',
    creator: user.name,
    confirmation_items: {
      worker: user.name,
      used_data_name: '',
      head_height: 0,
      power_from: 0,
      power_to: 0,
      frequency_from: 0,
      frequency_to: 0,
      rate_from: 0,
      rate_to: 0,
      working_time_from: null,
      working_time_to: null,
      confirmation_time: null,
      confirmation_person: null,
    },
    confirmation_points: [
      {
        confirmation_time: null,
        laser_position: false,
        laser_position_confirmation_person: '',
        letter_color: false,
        letter_color_confirmation_person: '',
        processing_status: false,
        processing_status_confirmation_person: '',
      },
      {
        confirmation_time: null,
        laser_position: false,
        laser_position_confirmation_person: '',
        letter_color: false,
        letter_color_confirmation_person: '',
        processing_status: false,
        processing_status_confirmation_person: '',
      },
      {
        confirmation_time: null,
        laser_position: false,
        laser_position_confirmation_person: '',
        letter_color: false,
        letter_color_confirmation_person: '',
        processing_status: false,
        processing_status_confirmation_person: '',
      },
    ],
    defective_types_count: {
      dislodge: '0',
      pinhole: '0',
      black_spot: '0',
      input: 0,
      non_defective: 0,
      defective: 0,
      others: 0,
    },
  }
  // const [addProcessReport, setAddProcessReport] = useState(initAddProcessReport)
  const [isSucceeded, setIsSucceeded] = useState(false)
  const [laserReport, setLaserReport] = useState(initLaserReport)
  const [searchParams] = useSearchParams()
  const id = searchParams.get('id')

  useEffect(() => {
    if (user.is_working) {
      const getData = async () => {
        if (id) {
          const report = await axios.get(targetLaserReport(id))
          setLaserReport(report.data)
          setIsUpdate(true)
        }
        await getProducts()
        getMasters()
      }
      getData()
    }
  }, [])

  const params = {
    method: isUpdate ? 'PUT' : 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(laserReport),
  }
  const handleClickClockOut = () => {
    const postParams = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        working_hour_id: '',
        work_start_time: '',
        work_end_time: '',
        user_id: user.user_id,
        work_place: '',
        lot_processes: '',
      }),
    }
    setIsLoadingAction(true)
    fetch(clockingInAndOut, postParams)
      .then((res) => res.json())
      .then((myjson) => {
        console.log(myjson)
        message({
          title: '退勤を記録しました。',
          status: 'success',
        })
        logout()
      })
      .catch(() =>
        message({ title: '通信エラーが発生しました', status: 'warning' })
      )
      .finally(() => {
        setIsLoadingAction(false)
      })
  }

  const handleClickUpdateReport = () => {
    setIsLoadingAction(true)
    fetch(targetLaserReport(id), params)
      .then((res) => res.json())
      .then((myjson) => {
        message({ title: '日報の更新に成功しました。', status: 'success' })
        console.log(myjson)
        // navigate('/daily_report/index')
        setIsSucceeded(true)
      })
      .catch(() => {
        message({ title: '通信エラーが発生しました', status: 'warning' })
      })
      .finally(() => {
        setIsLoadingAction(false)
      })
  }

  const handleClickRegisterReport = () => {
    setIsLoadingAction(true)
    fetch(postLaserReport, params)
      .then((res) => res.json())
      .then((myjson) => {
        message({ title: '日報の記録に成功しました。', status: 'success' })
        console.log(myjson)
        // navigate('/daily_report/index')
        setIsSucceeded(true)
      })
      .catch(() => {
        message({ title: '通信エラーが発生しました', status: 'warning' })
      })
      .finally(() => {
        setIsLoadingAction(false)
      })
  }

  useEffect(() => {
    setLaserReport({
      ...laserReport,
      defective_types_count: {
        ...laserReport.defective_types_count,
        defective:
          Number(laserReport.defective_types_count.dislodge) +
          Number(laserReport.defective_types_count.pinhole) +
          Number(laserReport.defective_types_count.black_spot) +
          Number(laserReport.defective_types_count.others),
        non_defective:
          Number(laserReport.defective_types_count.input) -
          (Number(laserReport.defective_types_count.dislodge) +
            Number(laserReport.defective_types_count.pinhole) +
            Number(laserReport.defective_types_count.black_spot) +
            Number(laserReport.defective_types_count.others)),
      },
    })
  }, [
    laserReport.defective_types_count.input,
    laserReport.defective_types_count.dislodge,
    laserReport.defective_types_count.pinhole,
    laserReport.defective_types_count.black_spot,
    laserReport.defective_types_count.others,
  ])

  return (
    <Box m={2}>
      {console.log(laserReport)}
      {isLoading ? (
        <Flex h="100vh" alignItems="center" justifyContent="center" mt={-8}>
          <BeatLoader size={30} color={'#3b5998'} />
        </Flex>
      ) : (
        <>
          <DigitalClock />
          <Heading textAlign="center" mb={10}>
            レーザー作業日報
          </Heading>
          {console.log(masterList)}
          <Grid
            templateRows="repeat(12, 1fr)"
            templateColumns="repeat(12, 1fr)"
            gap={1}
            minWidth="1450px"
          >
            <GridItem border="1px" rowSpan={1} colSpan={6} align="right">
              <Flex textAlign="center" align="stretch">
                <Text pt={2} borderRight="1px" w="150px">
                  作業日
                  <Box as="span" color="red">
                    *
                  </Box>
                </Text>
                <PrimaryInput
                  type="date"
                  min="2000-01-01"
                  max="2050-01-01"
                  value={laserReport.working_date}
                  onChange={(e) =>
                    setLaserReport({
                      ...laserReport,
                      working_date: e.target.value,
                    })
                  }
                  flexGrow={1}
                  disabled={isLoadingAction}
                />
              </Flex>
            </GridItem>
            <GridItem border="1px" rowSpan={1} colSpan={6} align="right">
              <Flex textAlign="center" align="stretch">
                <Text pt={2} borderRight="1px" w="150px">
                  ロットNo.
                </Text>
                <PrimaryInput
                  value={laserReport.lot_num}
                  onChange={(e) =>
                    setLaserReport({
                      ...laserReport,
                      lot_num: e.target.value,
                    })
                  }
                  flexGrow={1}
                  disabled={isLoadingAction}
                />
              </Flex>
            </GridItem>
            <GridItem border="1px" rowSpan={1} colSpan={6} align="right">
              <Flex textAlign="center" align="stretch">
                <Text pt={2} borderRight="1px" w="150px">
                  品番
                  <Box as="span" color="red">
                    *
                  </Box>
                </Text>
                <PrimarySelectBox
                  options={productList}
                  defaultOption={laserReport.product_number}
                  optionElement="product_number"
                  onChangeSelect={(index) =>
                    setLaserReport({
                      ...laserReport,
                      product_number: productList[index]['product_number'],
                      product_name: productList[index]['product_name'],
                    })
                  }
                  flexGrow={1}
                  disabled={isLoadingAction}
                />
              </Flex>
            </GridItem>
            <GridItem border="1px" rowSpan={1} colSpan={6} align="right">
              <Flex textAlign="center" align="stretch">
                <Text pt={2} borderRight="1px" w="150px">
                  品名
                </Text>
                <PrimaryInput
                  flexGrow={1}
                  value={laserReport.product_name}
                  onChange={(e) =>
                    setLaserReport({
                      ...laserReport,
                      product_name: e.target.value,
                    })
                  }
                  disabled
                />
              </Flex>
            </GridItem>
            <GridItem border="1px" rowSpan={1} colSpan={6} align="right">
              <Flex textAlign="center" align="stretch">
                <Text pt={2} borderRight="1px" w="150px">
                  機械名
                  <Box as="span" color="red">
                    *
                  </Box>
                </Text>
                <StringSelectBox
                  options={masterList.machines}
                  defaultOption={laserReport.machine_name}
                  onChangeSelect={(value) => {
                    setLaserReport({
                      ...laserReport,
                      machine_name: value,
                    })
                  }}
                  flexGrow={1}
                  disabled={isLoadingAction}
                />
              </Flex>
            </GridItem>
            <GridItem border="1px" rowSpan={1} colSpan={6} align="right">
              <Flex textAlign="center" align="stretch">
                <Text pt={2} borderRight="1px" w="150px">
                  工程
                </Text>
                <PrimaryInput
                  flexGrow={1}
                  value={laserReport.process_name}
                  onChange={(e) =>
                    setLaserReport({
                      ...laserReport,
                      process_name: e.target.value,
                    })
                  }
                  disabled={isLoadingAction}
                />
              </Flex>
            </GridItem>
            <GridItem border="1px" rowSpan={1} colSpan={12} align="right">
              <Flex textAlign="center" align="stretch">
                <Text h="40px" lineHeight="40px" borderRight="1px" w="150px" />
                <Text h="40px" lineHeight="40px" borderRight="1px" w="250px">
                  確認項目
                </Text>
                <Text h="40px" lineHeight="40px" borderRight="1px" w="602px">
                  確認結果
                </Text>
                <Text h="40px" lineHeight="40px" borderRight="1px" w="250px" />
                <Text h="40px" lineHeight="40px" w="200px" />
              </Flex>
            </GridItem>
            <GridItem border="1px" rowSpan={1} colSpan={12} align="right">
              <Flex textAlign="center" align="stretch">
                <Text pt={2} borderRight="1px" w="150px">
                  作業者
                </Text>
                <Text pt={2} borderRight="1px" w="250px">
                  使用データ名
                </Text>
                <PrimaryInput
                  flexGrow={1}
                  value={laserReport.confirmation_items.used_data_name}
                  onChange={(e) =>
                    setLaserReport({
                      ...laserReport,
                      confirmation_items: {
                        ...laserReport.confirmation_items,
                        used_data_name: e.target.value,
                      },
                    })
                  }
                  disabled={isLoadingAction}
                />
                <Text pt={2} borderLeft="1px" borderRight="1px" w="250px">
                  作業時間
                </Text>
                <Text pt={2} w="200px">
                  確認時間
                </Text>
              </Flex>
            </GridItem>
            <GridItem border="1px" rowSpan={1} colSpan={12} align="right">
              <Flex textAlign="center" align="stretch">
                <PrimarySelectBox
                  options={masterList.users}
                  defaultOption={laserReport.confirmation_items.worker}
                  optionElement="name"
                  onChangeSelect={(index) => {
                    setLaserReport({
                      ...laserReport,
                      confirmation_items: {
                        ...laserReport.confirmation_items,
                        worker: masterList.users[index]['name'],
                      },
                    })
                  }}
                  width="150px"
                  disabled={isLoadingAction}
                />
                <Text pt={2} borderLeft="1px" borderRight="1px" w="250px">
                  ヘッド高さ
                </Text>
                <PrimaryNumberInput
                  value={laserReport.confirmation_items.head_height}
                  minValue={0}
                  maxValue={100000}
                  step={0.1}
                  precision={1}
                  onChange={(num) =>
                    setLaserReport({
                      ...laserReport,
                      confirmation_items: {
                        ...laserReport.confirmation_items,
                        head_height: num,
                      },
                    })
                  }
                  flexGrow={1}
                  disabled={isLoadingAction}
                />
                <Box borderLeft="1px" borderRight="1px" px="1px">
                  <PrimaryInput
                    type="time"
                    min="00:00"
                    max="23:59"
                    value={laserReport.confirmation_items.working_time_from}
                    onChange={(e) => {
                      console.log(e.target.value)
                      setLaserReport({
                        ...laserReport,
                        confirmation_items: {
                          ...laserReport.confirmation_items,
                          working_time_from: e.target.value,
                        },
                      })
                    }}
                    width="246px"
                    disabled={isLoadingAction}
                  />
                </Box>
                <PrimaryInput
                  type="time"
                  min="00:00"
                  max="23:59"
                  value={laserReport.confirmation_items.confirmation_time}
                  onChange={(e) => {
                    console.log(e.target.value)
                    setLaserReport({
                      ...laserReport,
                      confirmation_items: {
                        ...laserReport.confirmation_items,
                        confirmation_time: e.target.value,
                      },
                    })
                  }}
                  width="200px"
                  disabled={isLoadingAction}
                />
              </Flex>
            </GridItem>
            <GridItem border="1px" rowSpan={1} colSpan={12} align="right">
              <Flex textAlign="center" align="stretch">
                <Text pt={2} borderRight="1px" w="150px" />
                <Text pt={2} borderRight="1px" w="250px">
                  POWER
                </Text>
                <Box flexGrow={1}>
                  <Flex
                    textAlign="center"
                    justify="space-between"
                    align="center"
                  >
                    <PrimaryNumberInput
                      value={laserReport.confirmation_items.power_from}
                      minValue={0}
                      maxValue={100}
                      step={0.1}
                      precision={1}
                      onChange={(num) =>
                        setLaserReport({
                          ...laserReport,
                          confirmation_items: {
                            ...laserReport.confirmation_items,
                            power_from: num,
                          },
                        })
                      }
                      flexGrow={1}
                      disabled={isLoadingAction}
                    />
                    <Text pt={2} w="50px">
                      ~
                    </Text>
                    <PrimaryNumberInput
                      value={laserReport.confirmation_items.power_to}
                      minValue={0}
                      maxValue={100}
                      step={0.1}
                      precision={1}
                      onChange={(num) =>
                        setLaserReport({
                          ...laserReport,
                          confirmation_items: {
                            ...laserReport.confirmation_items,
                            power_to: num,
                          },
                        })
                      }
                      flexGrow={1}
                      disabled={isLoadingAction}
                    />
                    <Text pt={2} w="70px">
                      %
                    </Text>
                  </Flex>
                </Box>
                <Text pt={2} borderLeft="1px" borderRight="1px" w="250px">
                  ~
                </Text>
                <Text pt={2} w="200px">
                  確認者
                </Text>
              </Flex>
            </GridItem>
            <GridItem border="1px" rowSpan={1} colSpan={12} align="right">
              <Flex textAlign="center" align="stretch">
                <Text pt={2} borderRight="1px" w="150px" />
                <Text pt={2} borderRight="1px" w="250px">
                  FREQUENCY
                </Text>
                <Box flexGrow={1}>
                  <Flex
                    textAlign="center"
                    justify="space-between"
                    align="center"
                  >
                    <PrimaryNumberInput
                      value={laserReport.confirmation_items.frequency_from}
                      minValue={0}
                      maxValue={100000}
                      onChange={(num) =>
                        setLaserReport({
                          ...laserReport,
                          confirmation_items: {
                            ...laserReport.confirmation_items,
                            frequency_from: num,
                          },
                        })
                      }
                      flexGrow={1}
                      disabled={isLoadingAction}
                    />
                    <Text pt={2} w="50px">
                      ~
                    </Text>
                    <PrimaryNumberInput
                      value={laserReport.confirmation_items.frequency_to}
                      minValue={0}
                      maxValue={100000}
                      onChange={(num) =>
                        setLaserReport({
                          ...laserReport,
                          confirmation_items: {
                            ...laserReport.confirmation_items,
                            frequency_to: num,
                          },
                        })
                      }
                      flexGrow={1}
                      disabled={isLoadingAction}
                    />
                    <Text pt={2} w="70px">
                      KHz
                    </Text>
                  </Flex>
                </Box>
                <Box borderLeft="1px" borderRight="1px" px="1px">
                  <PrimaryInput
                    type="time"
                    min="00:00"
                    max="23:59"
                    value={laserReport.confirmation_items.working_time_to}
                    onChange={(e) => {
                      console.log(e.target.value)
                      setLaserReport({
                        ...laserReport,
                        confirmation_items: {
                          ...laserReport.confirmation_items,
                          working_time_to: e.target.value,
                        },
                      })
                    }}
                    width="246px"
                    disabled={isLoadingAction}
                  />
                </Box>
                <PrimarySelectBox
                  options={masterList.users}
                  defaultOption={
                    laserReport.confirmation_items.confirmation_person
                  }
                  optionElement="name"
                  onChangeSelect={(index) =>
                    setLaserReport({
                      ...laserReport,
                      confirmation_items: {
                        ...laserReport.confirmation_items,
                        confirmation_person: masterList.users[index]['name'],
                      },
                    })
                  }
                  width="200px"
                  disabled={isLoadingAction}
                />
              </Flex>
            </GridItem>
            <GridItem border="1px" rowSpan={1} colSpan={12} align="right">
              <Flex textAlign="center" align="stretch">
                <Text pt={2} borderRight="1px" w="150px" />
                <Text pt={2} borderRight="1px" w="250px">
                  RATE
                </Text>
                <Box flexGrow={1}>
                  <Flex
                    textAlign="center"
                    justify="space-between"
                    align="center"
                  >
                    <PrimaryNumberInput
                      value={laserReport.confirmation_items.rate_from}
                      minValue={0}
                      maxValue={100000}
                      onChange={(num) =>
                        setLaserReport({
                          ...laserReport,
                          confirmation_items: {
                            ...laserReport.confirmation_items,
                            rate_from: num,
                          },
                        })
                      }
                      flexGrow={1}
                      disabled={isLoadingAction}
                    />
                    <Text pt={2} w="50px">
                      ~
                    </Text>
                    <PrimaryNumberInput
                      value={laserReport.confirmation_items.rate_to}
                      minValue={0}
                      maxValue={100000}
                      onChange={(num) =>
                        setLaserReport({
                          ...laserReport,
                          confirmation_items: {
                            ...laserReport.confirmation_items,
                            rate_to: num,
                          },
                        })
                      }
                      flexGrow={1}
                      disabled={isLoadingAction}
                    />
                    <Text pt={2} w="70px">
                      mm/sec
                    </Text>
                  </Flex>
                </Box>
                <Text pt={2} borderLeft="1px" borderRight="1px" w="250px" />
                <Text pt={2} w="200px" />
              </Flex>
            </GridItem>
            <GridItem border="1px" rowSpan={6} colSpan={1} align="center">
              <Text pt={2} w="30px" mt="75px">
                不良内訳
              </Text>
            </GridItem>
            <GridItem border="1px" rowSpan={1} colSpan={6} align="right">
              <Flex textAlign="center" align="stretch">
                <Text pt={2} borderRight="1px" w="150px">
                  位置ズレ
                </Text>
                <PrimaryNumberInput
                  value={laserReport.defective_types_count.dislodge}
                  minValue={0}
                  maxValue={100000}
                  onChange={(num) =>
                    setLaserReport({
                      ...laserReport,
                      defective_types_count: {
                        ...laserReport.defective_types_count,
                        dislodge: num,
                      },
                    })
                  }
                  flexGrow={1}
                  disabled={isLoadingAction}
                />
                <Text pt={2} borderLeft="1px" borderRight="1px" w="150px">
                  投入数
                </Text>
                <PrimaryNumberInput
                  value={laserReport.defective_types_count.input}
                  minValue={0}
                  maxValue={100000}
                  onChange={(num) =>
                    setLaserReport({
                      ...laserReport,
                      defective_types_count: {
                        ...laserReport.defective_types_count,
                        input: num,
                      },
                    })
                  }
                  width="200px"
                  disabled={isLoadingAction}
                />
              </Flex>
            </GridItem>
            <GridItem
              border="1px"
              rowSpan={1}
              colStart={10}
              colEnd={13}
              align="right"
            >
              <Flex textAlign="center" align="stretch">
                <Text pt={2} borderRight="1px" flexGrow={1} h="40px">
                  確認
                </Text>
                <Text pt={2} flexGrow={1}>
                  作成
                </Text>
              </Flex>
            </GridItem>
            <GridItem border="1px" rowSpan={1} colSpan={6} align="right">
              <Flex textAlign="center" align="stretch">
                <Text pt={2} borderRight="1px" w="150px">
                  ピンホール
                </Text>
                <PrimaryNumberInput
                  value={laserReport.defective_types_count.pinhole}
                  minValue={0}
                  maxValue={100000}
                  onChange={(num) =>
                    setLaserReport({
                      ...laserReport,
                      defective_types_count: {
                        ...laserReport.defective_types_count,
                        pinhole: num,
                      },
                    })
                  }
                  flexGrow={1}
                  disabled={isLoadingAction}
                />
                <Text pt={2} borderLeft="1px" borderRight="1px" w="150px">
                  良品数
                </Text>
                <Text pt={2} width="200px">
                  {laserReport.defective_types_count.non_defective}
                </Text>
                {/* <PrimaryNumberInput
                  value={laserReport.defective_types_count.non_defective}
                  minValue={0}
                  maxValue={100000}
                  onChange={(num) =>
                    setLaserReport({
                      ...laserReport,
                      defective_types_count: {
                        ...laserReport.defective_types_count,
                        non_defective: num,
                      },
                    })
                  }
                  flexGrow={1}
                  disabled={isLoadingAction}
                /> */}
              </Flex>
            </GridItem>
            <GridItem
              border="1px"
              rowSpan={1}
              colStart={10}
              colEnd={13}
              align="right"
            >
              <Flex textAlign="center" align="stretch">
                <Box borderRight="1px" flexGrow={1}>
                  <PrimarySelectBox
                    options={masterList.users}
                    defaultOption={laserReport.confirmation_person}
                    optionElement="name"
                    onChangeSelect={(index) =>
                      setLaserReport({
                        ...laserReport,
                        confirmation_person: masterList.users[index]['name'],
                      })
                    }
                    width="auto"
                    flexGrow={1}
                    disabled={isLoadingAction}
                  />
                </Box>
                <Box borderRight="1px" flexGrow={1}>
                  <PrimarySelectBox
                    options={masterList.users}
                    defaultOption={laserReport.creator}
                    optionElement="name"
                    onChangeSelect={(index) =>
                      setLaserReport({
                        ...laserReport,
                        creator: masterList.users[index]['name'],
                      })
                    }
                    width="auto"
                    flexGrow={1}
                    disabled={isLoadingAction}
                  />
                </Box>
              </Flex>
            </GridItem>
            <GridItem border="1px" rowSpan={1} colSpan={6} align="right">
              <Flex textAlign="center" align="stretch">
                <Text pt={2} borderRight="1px" w="150px">
                  ヤケ・黒点
                </Text>
                <PrimaryNumberInput
                  value={laserReport.defective_types_count.black_spot}
                  minValue={0}
                  maxValue={100000}
                  onChange={(num) =>
                    setLaserReport({
                      ...laserReport,
                      defective_types_count: {
                        ...laserReport.defective_types_count,
                        black_spot: num,
                      },
                    })
                  }
                  flexGrow={1}
                  disabled={isLoadingAction}
                />
                <Text pt={2} borderLeft="1px" borderRight="1px" w="150px">
                  不良数
                </Text>
                <Text pt={2} width="200px">
                  {laserReport.defective_types_count.defective}
                </Text>
                {/* <PrimaryNumberInput
                  value={laserReport.defective_types_count.defective}
                  minValue={0}
                  maxValue={100000}
                  onChange={(num) =>
                    setLaserReport({
                      ...laserReport,
                      defective_types_count: {
                        ...laserReport.defective_types_count,
                        defective: num,
                      },
                    })
                  }
                  flexGrow={1}
                  disabled={isLoadingAction}
                /> */}
              </Flex>
            </GridItem>
            <GridItem
              visibility="hidden"
              border="1px"
              rowSpan={1}
              colSpan={3}
              align="right"
            />
            <GridItem border="1px" rowSpan={1} colSpan={3} align="right">
              <Flex textAlign="center" align="stretch" h="35px">
                <Text pt={2} borderRight="1px" w="142px" />
                <Text pt={2} flexGrow={1} />
              </Flex>
            </GridItem>
            <GridItem
              border="1px"
              rowSpan={3}
              colStart={5}
              colEnd={8}
              align="right"
            >
              <Textarea
                placeholder="特記事項"
                value={laserReport.note}
                onChange={(e) =>
                  setLaserReport({
                    ...laserReport,
                    note: e.target.value,
                  })
                }
                size="sm"
                bg="white"
                h="120px"
                _focus={{ boxShadow: 'none' }}
                border="none"
                disabled={isLoadingAction}
              />
            </GridItem>
            <GridItem
              border="1px"
              rowSpan={1}
              colStart={2}
              colEnd={5}
              align="right"
            >
              <Flex textAlign="center" align="stretch" h="35px">
                <Text pt={2} borderRight="1px" w="142px" />
                <Text pt={2} flexGrow={1} />
              </Flex>
            </GridItem>
            <GridItem
              border="1px"
              rowSpan={1}
              colStart={2}
              colEnd={5}
              align="right"
            >
              <Flex textAlign="center" align="stretch">
                <Text pt={2} borderRight="1px" w="150px">
                  その他
                </Text>
                <PrimaryNumberInput
                  value={laserReport.defective_types_count.others}
                  minValue={0}
                  maxValue={100000}
                  onChange={(num) =>
                    setLaserReport({
                      ...laserReport,
                      defective_types_count: {
                        ...laserReport.defective_types_count,
                        others: num,
                      },
                    })
                  }
                  flexGrow={1}
                  disabled={isLoadingAction}
                />
              </Flex>
            </GridItem>
            <GridItem rowSpan={1} colStart={10} colEnd={13}>
              <Text pt={2}>01版 文書番号 : L4-0744</Text>
            </GridItem>
          </Grid>

          <Flex justifyContent="center" alignItems="center" my={10}>
            <HStack>
              <AlertModal
                buttonMessage="キャンセル"
                buttonLeft="日報登録に戻る"
                buttonRight="ログアウト"
                title="キャンセル"
                colorScheme="blackAlpha"
                body="日報登録を中断してログアウトしますか？"
                onClick={() => logout()}
                onClickCancel={() => navigate('/daily_report/mass_produce')}
                disabled={isLoadingAction}
              />
              <AlertModal
                buttonMessage={isUpdate ? '更新' : '記録'}
                title={isUpdate ? '更新' : '記録'}
                body={`この内容で日報を${isUpdate ? '更新' : '記録'}しますか？`}
                onClick={
                  isUpdate ? handleClickUpdateReport : handleClickRegisterReport
                }
                disabled={
                  isLoadingAction ||
                  !laserReport.product_number ||
                  !laserReport.machine_name ||
                  !laserReport.working_date
                }
              />
            </HStack>
          </Flex>
          {isSucceeded && (
            <AlertModal
              needButton={false}
              buttonLeft="勤務に戻る"
              buttonRight="退勤"
              title="退勤"
              body="このまま退勤しますか？"
              onClick={() => handleClickClockOut()}
              onClickCancel={() => logout()}
            />
          )}
        </>
      )}
      {isBrowserBack && (
        <AlertModalWithoutButton
          title="戻る"
          body="データを変更した場合、更新ボタンを押さないと編集内容が破棄されますがよろしいですか？"
          onClick={() => window.history.back()}
          onClickCancel={() => {
            window.history.pushState(null, '', window.location.href)
            setIsBrowserBack(false)
          }}
        />
      )}
    </Box>
  )
}
