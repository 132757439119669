import React from 'react'
import {
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
} from '@chakra-ui/react'
import { TableBodyOutsourceProviding } from '../../molecules/outsource/TableBodyOutsourceProviding'
import { TableBottomOutsource } from '../../molecules/outsource/TableBottomOutsource'

export const TableOutsourceProviding = (props) => {
  const {
    amount,
    valueCurrency,
    valueTaxRate,
    createdProducts,
    addOutsource,
    onChangeCurrency,
    onChangeTaxRate,
    onChangeIsProvided,
    onChangeIsFree,
    onChangeProductNum,
    onChanceProductName,
    onChangeCount,
    onChangeUnitPrice,
    onChangeIsProvidedAdd,
    onChangeIsFreeAdd,
    onChangeProductNumAdd,
    onChanceProductNameAdd,
    onChangeCountAdd,
    onChangeUnitPriceAdd,
    onClickAddProduct,
    onClickDeleteProvidingProduct,
    isLoadingAction,
  } = props

  return (
    <TableContainer my={6}>
      <Table variant="striped" colorScheme="linkedin">
        <TableBottomOutsource
          amount={Math.round(amount || 0)}
          tax={Math.round(amount * valueTaxRate || 0) / 100}
          totalAmount={
            Math.round(amount * (1 + valueTaxRate / 100) * 100 || 0) / 100
          }
          valueCurrency={valueCurrency}
          valueTaxRate={valueTaxRate}
          onChangeCurrency={onChangeCurrency}
          onChangeTaxRate={onChangeTaxRate}
          isDisabled={isLoadingAction}
        />
        <Thead>
          <Tr>
            <Th>支給材</Th>
            <Th>有償/無償</Th>
            <Th>部品番号</Th>
            <Th>製品名</Th>
            <Th>数量</Th>
            <Th>単価</Th>
            <Th>小計</Th>
            <Th>追加/削除</Th>
          </Tr>
        </Thead>
        <Tbody>
          {createdProducts.map((product, index) => {
            return (
              <Tr key={index}>
                <TableBodyOutsourceProviding
                  type="existing"
                  valueIsProvided={product.is_provided}
                  valueIsFree={product.is_free}
                  valueProductNum={product.product_num}
                  valueProductName={product.product_name}
                  valueCount={product.count}
                  valueUnitPrice={product.unit_price}
                  onChangeIsProvided={(e) => onChangeIsProvided(e, index)}
                  onChangeIsFree={(e) => onChangeIsFree(e, index)}
                  onChangeProductNum={(e) => onChangeProductNum(e, index)}
                  onChanceProductName={(e) => onChanceProductName(e, index)}
                  onChangeCount={(num) => onChangeCount(num, index)}
                  onChangeUnitPrice={(num) => onChangeUnitPrice(num, index)}
                  onClickDeleteProvidingProduct={() =>
                    onClickDeleteProvidingProduct(index)
                  }
                  isDisabled={product.is_delete || isLoadingAction}
                />
              </Tr>
            )
          })}
          <Tr>
            <TableBodyOutsourceProviding
              type="adding"
              valueIsProvided={addOutsource.isProvided}
              valueIsFree={addOutsource.isFree}
              valueProductNum={addOutsource.productNum}
              valueProductName={addOutsource.productName}
              valueCount={addOutsource.count}
              valueUnitPrice={addOutsource.unitPrice}
              onChangeIsProvided={(e) => onChangeIsProvidedAdd(e)}
              onChangeIsFree={(e) => onChangeIsFreeAdd(e)}
              onChangeProductNum={(e) => onChangeProductNumAdd(e)}
              onChanceProductName={(e) => onChanceProductNameAdd(e)}
              onChangeCount={(num) => onChangeCountAdd(num)}
              onChangeUnitPrice={(num) => onChangeUnitPriceAdd(num)}
              onClickAddProduct={onClickAddProduct}
              isDisabled={isLoadingAction}
              disabledAddButton={
                !addOutsource.productNum ||
                !addOutsource.productName ||
                !addOutsource.count ||
                !addOutsource.unitPrice
              }
            />
          </Tr>
        </Tbody>
      </Table>
    </TableContainer>
  )
}
