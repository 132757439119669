import React from 'react'
import { Td, IconButton } from '@chakra-ui/react'
import { PrimaryInput } from '../../atoms/PrimaryInput'
import { AiOutlineDelete, AiOutlinePlus } from 'react-icons/ai'
import { PrimaryNumberInput } from '../../atoms/PrimaryNumberInput'

export const TableBodyToolCosts = (props) => {
  const {
    type,
    toolName,
    amount,
    sellingPrice,
    buyingPrice,
    totalSellingPrice,
    totalBuyingPrice,
    grossProfit,
    grossProfitMargin,
    onChangeToolName,
    onChangeAmount,
    onChangeSellingPrice,
    onChangeBuyingPrice,
    onClickDeleteToolCost,
    onClickAddToolCost,
    isDisabled,
    disabledAddButton,
  } = props

  return (
    <>
      <Td>
        <PrimaryInput
          placeholder=""
          disabled={isDisabled}
          value={toolName}
          onChange={(e) => onChangeToolName(e)}
          htmlSize={14}
        />
      </Td>
      <Td>
        <PrimaryNumberInput
          defaultValue={0}
          value={amount}
          minValue={0}
          maxValue={100000}
          onChange={(num) => onChangeAmount(num)}
          width="120px"
          disabled={isDisabled}
        />
      </Td>
      <Td>
        <PrimaryNumberInput
          defaultValue={0}
          value={sellingPrice.toLocaleString()}
          minValue={0}
          onChange={(num) => onChangeSellingPrice(num)}
          width="140px"
          disabled={isDisabled}
        />
      </Td>
      <Td>
        <PrimaryNumberInput
          defaultValue={0}
          value={buyingPrice.toLocaleString()}
          minValue={0}
          onChange={(num) => onChangeBuyingPrice(num)}
          width="140px"
          disabled={isDisabled}
        />
      </Td>
      <Td />
      <Td />
      <Td width="80px" textColor={isDisabled && 'gray.400'}>
        ¥{(Math.round(totalSellingPrice * 100 || 0) / 100).toLocaleString()}
      </Td>
      <Td width="70px" textColor={isDisabled && 'gray.400'}>
        ¥{(Math.round(totalBuyingPrice * 100 || 0) / 100).toLocaleString()}
      </Td>
      <Td width="70px" textColor={isDisabled && 'gray.400'}>
        ¥{(Math.round(grossProfit * 100 || 0) / 100).toLocaleString()}
      </Td>
      <Td width="70px" textColor={isDisabled && 'gray.400'}>
        {Math.round(grossProfitMargin * 100 || 0).toLocaleString()}%
      </Td>
      <Td isNumeric width="80px">
        {type == 'adding' ? (
          <IconButton
            aria-label="add"
            disabled={isDisabled || disabledAddButton}
            onClick={onClickAddToolCost}
            icon={<AiOutlinePlus />}
          />
        ) : (
          <IconButton
            aria-label="delete"
            disabled={isDisabled}
            onClick={onClickDeleteToolCost}
            icon={<AiOutlineDelete />}
          />
        )}
      </Td>
    </>
  )
}
